import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef
} from 'react';
import SideBar from '@/components/layout/SideBar';
import StarYellowIcon from '@/assets/svgs/StarYellowIcon';
import WishIcon from '@/assets/svgs/WishIcon';
import ArrowUpIcon from '@/assets/svgs/ArrowUpIcon';
import Search from '@/assets/svgs/Search';
import { openEyeSvg } from '@/assets/svgs';
import CustomPagination from '@/elements/pagination/customPagination';
import GoTOPage from '@/elements/gotopage/gotopage';
import { getNPSReviewsRatings } from '@/services/reviews-ratings-service/get-nps-ratings';
import { LoaderContext } from '@/components/Loader/Loader';
import NPSScoreGraphModal from '@/components/modals/NPSScoreGraphModal';
import moment from 'moment';
import _ from 'lodash';
import { useNavigate } from 'react-router';
import { getInitial } from '@/helper';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DatePickerIcon from '@/assets/svgs/DatePickerIcon';
import dayjs from 'dayjs';
import DownloadFileSvg from '@/assets/svgs/DownloadFileSvg';
import RefreshIcon from '@/assets/svgs/RefreshIcon';

const ReviewsAndRatingsDetailPage = () => {
  const searchRef = useRef();
  const navigate = useNavigate();
  const setLoading = useContext(LoaderContext);
  const [NPSReviewsRatingData, setNPSReviewsRatingData] = useState([]);
  const [OpenNPSGraphModal, setOpenNPSGraphModal] = useState(false);
  const [RefreshList, setRefreshList] = useState(false);
  const [NPSReviewsRatingCount, setNPSReviewsRatingCount] = useState();
  const [showFullNotes, setShowFullNotes] = useState({});
  const [startDate, setStartDate] = useState('');
  const [SearchedText, setSearchedText] = useState('');
  const [endDate, setEndDate] = useState('');
  const [Page, setPage] = useState(1);

  const Ratings = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [pagination, setPagination] = useState({
    recordsPerPage: 20,
    totalRecord: 0,
    search: ''
  });

  useEffect(() => {
    getNPSReviewsAndRatingsDetails();
  }, []);

  useEffect(() => {
    setPage(1);
    getNPSReviewsAndRatingsDetails(
      pagination?.recordsPerPage,
      1,
      SearchedText,
      startDate,
      endDate
    );
  }, [endDate, RefreshList]);

  const ratingStartDate = (val) => {
    let originalDateString = JSON.stringify(val);
    let date = new Date(originalDateString.split('T')[0]);
    let formatedDate = moment(date, 'YYYY-MM-DD')
      .add(1, 'days')
      .format('YYYY-MM-DD');
    setStartDate(formatedDate);
  };

  const ratingEndDate = (val) => {
    let originalDateString = JSON.stringify(val);
    let date = new Date(originalDateString.split('T')[0]);
    let formatedDate = moment(date, 'YYYY-MM-DD')
      .add(1, 'days')
      .format('YYYY-MM-DD');
    setEndDate(formatedDate);
  };

  const getNPSReviewsAndRatingsDetails = async (
    recordsPerPage = pagination?.recordsPerPage,
    currentPage = Page,
    search = pagination?.search,
    filterStartDate = startDate,
    filterEndDate = endDate
  ) => {
    setLoading(true);
    const response = await getNPSReviewsRatings(
      recordsPerPage,
      currentPage,
      search,
      filterStartDate,
      filterEndDate,
      false
    );
    const { meta, data } = response;
    if (meta?.status) {
      setNPSReviewsRatingData(data?.rows);
      setNPSReviewsRatingCount(data?.count);
      setPagination({ ...pagination, totalRecord: data?.count });
    }
    setLoading(false);
  };
  const shouldDisableDate = (date) => {
    return startDate ? dayjs(date).isBefore(dayjs(startDate), 'day') : false;
  };
  function convertArrayOfObjectsToCSV(data) {
    const header = Object.keys(data[0]).join(',');
    const rows = data.map((obj) => Object.values(obj).join(','));
    return [header, ...rows].join('\n');
  }
  function downloadCSV(data, filename) {
    const csv = convertArrayOfObjectsToCSV(data);
    const blob = new Blob([csv], {
      type: 'text/csv;charset=utf-8;'
    });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  const handleDownload = async () => {
    setLoading(true);
    const response = await getNPSReviewsRatings(
      1,
      1,
      SearchedText,
      startDate,
      endDate,
      true
    );
    const { meta, data } = response;
    if (meta?.status) {
      let newData = [];
      data?.rows?.map((item) => {
        newData.push({
          'Company Name': item?.company?.companyName,
          'Job Name': item?.job?.title,
          'NPS Rating': item?.npsRating,
          'NPS Notes': item?.npsNotes,
          'Created Date': moment(item?.updatedAt).format('MM/DD/YYYY')
        });
      });
      downloadCSV(newData, 'NPS Rating');
    }
    setLoading(false);
  };

  const changePage = (event, value) => {
    setPage(value);
    getNPSReviewsAndRatingsDetails(
      pagination?.recordsPerPage,
      value,
      pagination?.search,
      startDate,
      endDate
    );
  };

  const toggleReadMore = (index) => {
    setShowFullNotes((prev) => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const searchCustomer = useCallback(
    _.debounce((searchText, startDate, endDate) => {
      setPagination({ ...pagination, search: searchText });
      setSearchedText(searchText);
      getNPSReviewsAndRatingsDetails(
        pagination?.recordsPerPage,
        1,
        searchText,
        startDate,
        endDate
      );
    }, 500),
    []
  );

  const handleGoToJobDetailPage = (jobData) => {
    navigate('/jobs', {
      state: {
        jobDetails: jobData
      }
    });
  };

  const handleRefresh = () => {
    searchRef.current.value = '';
    setEndDate('');
    setStartDate('');
    setSearchedText('');
    setRefreshList(true);
  };
  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid layoutGrid--full">
          <div className="layoutGrid__col">
            <div className="dataCol">
              <div className="page page__full">
                <div className="page__head">
                  <h2 className="page__head--title">Reviews & Ratings</h2>
                </div>
                <div className="page__body bg--white">
                  <div
                    className="details"
                    style={{ position: 'relative', height: '100%' }}
                  >
                    <div className="reviewSec">
                      <div className="listCol__head--actions w_100">
                        <div className="formRow formRow__3 m_0 w_100">
                          <div className="formCol">
                            <div className="formField">
                              <div className="formField__group sm">
                                <span className="formField__icon">
                                  <Search />
                                </span>
                                <input
                                  ref={searchRef}
                                  type="text"
                                  className="formField__input"
                                  placeholder="Search a customer"
                                  onChange={(event) =>
                                    searchCustomer(
                                      event?.target?.value,
                                      startDate,
                                      endDate
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="formRow m_0">
                            <div className="formField flexRow--datepicker">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  onChange={(val) => ratingStartDate(val)}
                                  className="formField__datepicker"
                                  slots={{ openPickerIcon: DatePickerIcon }}
                                  label="From"
                                  value={startDate}
                                />
                              </LocalizationProvider>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  className="formField__datepicker"
                                  onChange={(val) => ratingEndDate(val)}
                                  slots={{ openPickerIcon: DatePickerIcon }}
                                  shouldDisableDate={shouldDisableDate}
                                  label="To"
                                  value={endDate}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                        </div>
                        <button
                          className="btn btn__white p_0 w_fitContent"
                          onClick={() => handleRefresh()}
                        >
                          <RefreshIcon />
                        </button>
                        <button
                          onClick={() => handleDownload()}
                          className="btn btn__white w_100"
                        >
                          <DownloadFileSvg /> Export CSV
                        </button>
                        <button
                          className="viewNpsLink w_fitContent"
                          onClick={() => setOpenNPSGraphModal(true)}
                        >
                          {openEyeSvg} View NPS Score
                        </button>
                      </div>
                      {NPSReviewsRatingCount ? (
                        <h1 className="reviewSec__title">
                          All Reviews & Ratings <StarYellowIcon />
                        </h1>
                      ) : (
                        ''
                      )}

                      {NPSReviewsRatingCount ? (
                        NPSReviewsRatingData?.map((data, index) => {
                          const isLongNote = data?.npsNotes?.length > 250;
                          const shouldShowFullNote = showFullNotes[index];
                          const displayedNote =
                            isLongNote && !shouldShowFullNote
                              ? `${data?.npsNotes.substring(0, 250)}...`
                              : `${data?.npsNotes} `;
                          return (
                            <div className="reviewBox" key={index}>
                              <div className="reviews__row" key={index}>
                                <div className="reviewDetails">
                                  <div
                                    className={`pic ${data?.company?.companyLogo ? '' : 'pic__rounded'}`}
                                  >
                                    {data?.company?.companyLogo ? (
                                      <>
                                        <img
                                          className="pic__img"
                                          src={data?.company?.companyLogo}
                                          alt=""
                                        />
                                        <span className="wishIcon">
                                          <WishIcon />
                                        </span>
                                      </>
                                    ) : (
                                      <span className="initials">
                                        {getInitial(data?.company?.companyName)}
                                      </span>
                                    )}
                                  </div>
                                  <div className="details">
                                    <h5 className="userName">
                                      <span>
                                        {data?.company?.companyName}{' '}
                                        <span className="reviewDate">
                                          {moment(data?.updatedAt).format(
                                            'MM/DD/YYYY'
                                          )}
                                        </span>
                                      </span>
                                      <button
                                        className="linkJobDetail"
                                        onClick={() =>
                                          handleGoToJobDetailPage(data?.job)
                                        }
                                      >
                                        Go to Job details <ArrowUpIcon />
                                      </button>
                                    </h5>
                                    <div className="ratingInfo">
                                      <div className="ratingCount">
                                        {Ratings.filter(
                                          (rating) =>
                                            parseInt(rating) <= data?.npsRating
                                        ).map((item, index) => (
                                          <div
                                            className="ratingCount__value"
                                            key={index}
                                          >
                                            {item}
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <p>
                                  {displayedNote}
                                  {isLongNote && (
                                    <button
                                      onClick={() => toggleReadMore(index)}
                                      className="readMoreButton"
                                    >
                                      &nbsp;
                                      {shouldShowFullNote
                                        ? 'Read Less'
                                        : 'Read More'}
                                    </button>
                                  )}
                                </p>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="no-data floating tableFloating">
                          No Results Found!
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="pagination">
                  <div className="pagination__result">{`${
                    pagination?.totalRecord > Page * pagination?.recordsPerPage
                      ? Page * pagination?.recordsPerPage
                      : pagination?.totalRecord
                  } of ${pagination?.totalRecord} results`}</div>

                  {pagination?.totalRecord ? (
                    <CustomPagination
                      totalPageCount={Math.ceil(
                        pagination?.totalRecord / pagination.recordsPerPage
                      )}
                      changePage={(event, value) => changePage(event, value)}
                      page={Page}
                    />
                  ) : (
                    ''
                  )}
                  {pagination?.totalRecord ? (
                    <div className="pagination__actions">
                      <GoTOPage changePage={changePage} />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NPSScoreGraphModal
        openModal={OpenNPSGraphModal}
        closeModal={() => setOpenNPSGraphModal(false)}
      />
    </>
  );
};

export default ReviewsAndRatingsDetailPage;
