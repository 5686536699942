import React from 'react';

export default function UnassignedJobs() {
  return (
    <svg
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="80" height="80" rx="40" fill="#FDAE2C" />
      <path
        d="M48.8335 58.75V54.5833C48.8335 52.3732 47.9555 50.2536 46.3927 48.6908C44.8299 47.128 42.7103 46.25 40.5002 46.25H25.9168C23.7067 46.25 21.5871 47.128 20.0243 48.6908C18.4615 50.2536 17.5835 52.3732 17.5835 54.5833V58.75M53.0002 31.6667L63.4168 42.0833M63.4168 31.6667L53.0002 42.0833M41.5418 29.5833C41.5418 34.1857 37.8109 37.9167 33.2085 37.9167C28.6061 37.9167 24.8752 34.1857 24.8752 29.5833C24.8752 24.981 28.6061 21.25 33.2085 21.25C37.8109 21.25 41.5418 24.981 41.5418 29.5833Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
