import React from 'react';

export default function ArrowDownIcon() {
  return (
    <svg
      width="11"
      height="6"
      viewBox="0 0 11 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 6L10.2631 0H0.73686L5.5 6Z"
        fill="#150823"
        fillOpacity="0.8"
      />
    </svg>
  );
}
