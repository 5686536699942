import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import _ from 'lodash';
import { LoaderContext } from '@/components/Loader/Loader';
import {
  getScreeningList,
  changeCandidateStatus11
} from '@/services/screening-service/screening-service';
import CustomPagination from '@/elements/pagination/customPagination';
import GoTOPage from '@/elements/gotopage/gotopage';
import CandidateRecordingList from '@/pages/interviews/candidate-recordings';
import { firstLetterCapital } from '@/helper';
import { SuccessfullyModal } from '@/components/modals/successfully-modal';
import ErrorModal from '@/components/modals/error-modal';
import FeedbackModal from '@/components/modals/feedback-modal';
import PlayIcon from '@/assets/svgs/PlayIcon';
import SortColumn from '@/elements/SortColumn';

const JobCandidate = ({
  jobId,
  changeCandidateFeedbackView,
  changeCandidateSendFeedback,
  selectedJobData
}) => {
  const setLoading = useContext(LoaderContext);
  const [candidates, setCandidates] = useState([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openSuccessfullModal, setOpenSuccessfullModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [selectedCandidateData, setSelectedCandidateData] = useState({});
  const [viewrecordingScreen, setViewrecordingScreen] = useState(false);
  const [sortByASC, setSortByASC] = useState(true);
  const [sortByColoumn, setSortByColoumn] = useState('');
  const [pagination, setPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0,
    search: ''
  });

  useEffect(() => {
    getCandidateList();
  }, [jobId]);

  const searchdelayedCandidates = useCallback(
    _.debounce(
      (searchText) =>
        getCandidateList(
          pagination?.recordsPerPage,
          pagination?.currentPage,
          searchText
        ),
      500
    ),
    []
  );

  const getCandidateList = async (
    recordsPerPage = pagination?.recordsPerPage,
    currentPage = pagination?.currentPage,
    search = pagination?.search,
    columnName = sortByColoumn,
    sort = sortByASC ? 'ASC' : 'DESC'
  ) => {
    setLoading(true);
    const data = await getScreeningList(
      currentPage,
      recordsPerPage,
      search,
      jobId,
      columnName,
      sort
    );
    setPagination((prev) => ({
      ...prev,
      totalRecord: data?.count ? data?.count : 0
    }));
    setCandidates(data?.rows);
    setLoading(false);
  };

  const changePage = (event, value) => {
    setPagination({ ...pagination, currentPage: value });
  };

  const approveCandidate = async () => {
    const response = await changeCandidateStatus11(
      selectedCandidateData?.sreeningId,
      jobId,
      'approved',
      []
    );
    const { meta, data } = response;
    setOpenConfirmationModal(false);
    if (meta?.status) {
      setOpenSuccessfullModal(true);
      getCandidateList();
    } else {
      setOpenErrorModal(true);
      setErrorMsg(meta?.msg);
    }
  };

  const openApproveConfirmation = (data) => {
    setSelectedCandidateData(data);
    setOpenConfirmationModal(true);
  };

  const changeViewRecordingStatus = (status) => {
    setViewrecordingScreen(status);
  };

  const getSortByData = (columnName) => {
    getCandidateList(
      pagination?.recordsPerPage,
      pagination?.currentPage,
      pagination?.search,
      columnName,
      sortByASC ? 'ASC' : 'DESC'
    );
    setSortByColoumn(columnName);
    setSortByASC(!sortByASC);
  };

  return (
    <>
      {viewrecordingScreen ? (
        <>
          <CandidateRecordingList
            changeViewRecordingStatus={changeViewRecordingStatus}
            candidateData={selectedCandidateData}
          />
        </>
      ) : (
        <>
          <div className="tabContainer__item--head">
            <div className="listCol__head--actions flex--between w_100">
              <div className="formField formFieldSearch">
                <div className="formField__group sm">
                  <span className="formField__icon">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                        stroke="#6C737F"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="formField__input"
                    placeholder="Search a candidate"
                    onChange={(e) => searchdelayedCandidates(e?.target?.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <TableContainer component={Paper} className="tableContainer">
            <Table className="table">
              <TableHead>
                <TableRow>
                  <TableCell>S.</TableCell>
                  <TableCell onClick={() => getSortByData('firstName')}>
                    <SortColumn thName={'Full Name'} sortByASC={sortByASC} />
                  </TableCell>
                  <TableCell>Email Id</TableCell>
                  <TableCell>Recruiter Name</TableCell>
                  <TableCell>Feedbacks</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {candidates &&
                  candidates?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell>
                        <div className="profile">
                          {/* Profile Initials */}
                          <div className="profile__initials">
                            {row?.candidate?.firstName?.charAt(0).toUpperCase()}
                          </div>
                          <span className="profile__name">
                            {firstLetterCapital(row?.candidate?.firstName) +
                              ' ' +
                              firstLetterCapital(row?.candidate?.lastName)}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="cellText">
                          <span className="cellText__dark">
                            {row?.candidate?.email}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="cellText">
                          <span className="cellText__dark">
                            {row?.recruiter?.firstName +
                              ' ' +
                              row?.recruiter?.lastName}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="cellText">
                          <Link
                            onClick={() =>
                              changeCandidateFeedbackView(true, row)
                            }
                            className="cellText__dark cell--link"
                          >
                            Feedback
                          </Link>
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="actions flex--end">
                          <Link
                            to={
                              row?.candidate?.resumeUrl
                                ? row?.candidate?.resumeUrl
                                : '#'
                            }
                            target="_blank"
                            className="btn btn__white"
                            onClick={(e) => {
                              if (!row?.candidate?.resumeUrl) {
                                e.preventDefault();
                                setErrorMsg('Resume is not avaliable!');
                                setOpenErrorModal(true);
                              }
                            }}
                          >
                            Resume
                          </Link>
                          <button
                            className="btn btn__white"
                            onClick={() => {
                              setSelectedCandidateData(row);
                              changeViewRecordingStatus(true);
                            }}
                          >
                            View Rec.
                            <PlayIcon />
                          </button>
                          <button
                            className={`btn ${
                              row?.status === 'approved' ||
                              selectedJobData?.status === 'Closed' ||
                              selectedJobData?.status === 'paused' ||
                              selectedJobData?.status === 'delete'
                                ? 'border_0 pr_0 flex--end'
                                : 'btn__text pr_0'
                            }`}
                            onClick={() => openApproveConfirmation(row)}
                            disabled={
                              (row?.hiringStatus === 'pending' &&
                                row?.status === 'approved') ||
                              selectedJobData?.status === 'Closed' ||
                              selectedJobData?.status === 'paused' ||
                              selectedJobData?.status === 'delete'
                                ? true
                                : false
                            }
                          >
                            {row?.hiringStatus == 'pending'
                              ? row?.status === 'approved'
                                ? 'Sent'
                                : 'Approve'
                              : row?.hiringStatus}
                          </button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {candidates?.length == 0 ? (
              <div className="no-data">No Results Found!</div>
            ) : (
              ''
            )}
          </TableContainer>

          <div className="pagination">
            <div className="pagination__result">{`${
              pagination?.totalRecord >
              pagination?.currentPage * pagination?.recordsPerPage
                ? pagination?.recordsPerPage
                : pagination?.totalRecord
            } of ${pagination?.totalRecord} results`}</div>
            {pagination?.totalRecord ? (
              <CustomPagination
                totalPageCount={Math.ceil(
                  pagination?.totalRecord / pagination.recordsPerPage
                )}
                changePage={(event, value) => changePage(event, value)}
              />
            ) : (
              ''
            )}
            {pagination?.totalRecord ? (
              <div className="pagination__actions">
                <GoTOPage changePage={changePage} />
              </div>
            ) : (
              ''
            )}
          </div>
          <SuccessfullyModal
            openModal={openSuccessfullModal}
            closeModal={() => {
              setOpenSuccessfullModal(false);
            }}
            Heading={'Candidate Approved!'}
            sucessfullMsg={`Candidate has been approved for the ${selectedCandidateData?.job?.title} position and forwarded to the ${selectedCandidateData?.company?.companyName}.`}
          />
          <ErrorModal
            openModal={openErrorModal}
            closeModal={() => setOpenErrorModal(false)}
            errorMessage={errorMsg}
          />
          <FeedbackModal
            openModal={openConfirmationModal}
            approveWithoutFeedback={approveCandidate}
            approveWithFeedback={() => {
              setOpenConfirmationModal(false);
              changeCandidateSendFeedback(
                true,
                selectedCandidateData?.sreeningId
              );
            }}
            closeBtnAction={() => setOpenConfirmationModal(false)}
          />
        </>
      )}
    </>
  );
};

export default JobCandidate;
