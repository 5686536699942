import React, { useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import SideBar from '@/components/layout/SideBar';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import _ from 'lodash';
import InterviewCustomers from '@/pages/interviews/interview-customers';
import InterviewRecruiters from '@/pages/interviews/interview-recruiters';
import ScheduledInterviews from '@/pages/interviews/scheduled-interviews';
import PastInterviews from '@/pages/interviews/past-interviews';
import Search from '@/assets/svgs/Search';
import SearchCross from '@/assets/svgs/SearchCross';

const Interviews = () => {
  const searchRef = useRef('');
  const { state } = useLocation();
  const [value, setValue] = useState(state?.recruiterId ? '2' : '1');
  const [tabValue, setTabValue] = useState('1');
  const [searchText, setSearchText] = useState('');
  const [activeCompanyId, setActiveCompanyId] = useState(
    state?.companyId ? state?.companyId : null
  );
  const [activeCompanyStatus, setActiveCompanyStatus] = useState('');
  const [activeCompanyEmployees, setActiveCompanyEmployees] = useState(null);
  const [activeRecruiterId, setActiveRecruiterId] = useState(
    state?.recruiterId ? state?.recruiterId : null
  );
  const [activeRecruiterEmail, setActiveRecruiterEmail] = useState(null);
  const [searchanchorEl, setSeachAnchorEl] = useState(null);
  const [addResponsiveClass, setAddResponsiveClass] = useState(false);
  const [activeRecruiterStatus, setActiveRecruiterStatus] = useState(null);

  const searchOpen = Boolean(searchanchorEl);
  const searchPopoverid = searchOpen ? 'simple-popover' : '';

  const handleChange = (event, newValue) => {
    setActiveCompanyId(null);
    setActiveRecruiterId(null);
    setActiveRecruiterEmail(null);
    setActiveRecruiterStatus(null);
    setSearchText(null);
    setActiveCompanyEmployees(null);
    setActiveCompanyStatus('');
    setValue(newValue);
  };

  const handleTabValueChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSeachClick = (event) => {
    setSeachAnchorEl(event.currentTarget);
  };

  const handleSearchClose = () => {
    setTabValue('1');
    searchRef.current.value = '';
    setSeachAnchorEl(null);
    setSearchText('');
  };

  const onCompanySelection = (companyId, employeeCount, status = 'active') => {
    setActiveCompanyId(companyId);
    setActiveCompanyEmployees(employeeCount);
    setActiveCompanyStatus(status);
    setActiveRecruiterId(null);
    setActiveRecruiterEmail(null);
    setActiveRecruiterStatus(null);
  };

  const onRecruiterSelection = (
    recruiterId,
    recruiterEmail,
    recruiterDeleteStatus = false
  ) => {
    setActiveRecruiterId(recruiterId);
    setActiveRecruiterEmail(recruiterEmail);
    setActiveRecruiterStatus(recruiterDeleteStatus);
    setActiveCompanyId(null);
    setActiveCompanyEmployees(null);
    setActiveCompanyStatus('');
  };

  const SearchCompanyOrRecruiter = useCallback(
    _.debounce((searchText) => setSearchText(searchText), 500),
    []
  );

  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid">
          <div className="layoutGrid__col">
            <div
              className={`listCol ${searchanchorEl ? 'listCol__search' : ''}`}
            >
              <div className="listCol__head listing__head">
                <h2 className="listCol__head--title">Interviews</h2>
                <div className="listCol__head--actions">
                  <div className="formField">
                    <button
                      className="btn btn__white p_0"
                      aria-describedby={searchPopoverid}
                      onClick={handleSeachClick}
                    >
                      <Search />
                    </button>
                  </div>
                </div>

                <div className="searchContainer">
                  <div className="formField">
                    <div className="formField__group sm">
                      <span className="formField__icon">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                            stroke="#9DA4AE"
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <input
                        type="text"
                        className="formField__input"
                        placeholder={`Search for a  ${
                          value == 1 ? 'company' : 'recruiter'
                        }`}
                        ref={searchRef}
                        onChange={(e) =>
                          SearchCompanyOrRecruiter(e?.target?.value)
                        }
                      />
                    </div>
                  </div>

                  <button
                    className="btn btn__transparent border_0 p_0"
                    onClick={handleSearchClose}
                  >
                    <SearchCross />
                  </button>
                </div>
              </div>

              <Box className="tabContainer">
                <TabContext value={value}>
                  <Box className="tabContainer__head">
                    <TabList onChange={handleChange}>
                      <Tab label="Customers" value="1" />
                      <Tab label="Recruiters" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1" className="tabContainer__item">
                    <InterviewCustomers
                      setAddResponsiveClass={setAddResponsiveClass}
                      onCompanySelection={onCompanySelection}
                      activeCompanyId={activeCompanyId}
                      searchText={searchText}
                      setTabValue={setTabValue}
                    />
                  </TabPanel>

                  <TabPanel value="2" className="tabContainer__item">
                    <InterviewRecruiters
                      setAddResponsiveClass={setAddResponsiveClass}
                      onRecruiterSelection={onRecruiterSelection}
                      activeRecruiterId={activeRecruiterId}
                      searchText={searchText}
                      setTabValue={setTabValue}
                    />
                  </TabPanel>
                </TabContext>
              </Box>
            </div>
          </div>

          <div
            className={`layoutGrid__col layoutGrid--responsive  ${
              addResponsiveClass ? 'layoutGrid--show' : ''
            }`}
          >
            {activeCompanyId || activeRecruiterId ? (
              <div className="dataCol">
                <div className="page page__full">
                  <div className="page__head">
                    <h2 className="page__head--title">
                      <button
                        className="btn btn__transparent btn__back border_0 p_0"
                        onClick={() => setAddResponsiveClass(false)}
                      >
                        <svg
                          width="23"
                          height="25"
                          viewBox="0 0 23 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.375 18.5L8.625 12.5L14.375 6.5"
                            stroke="#150823"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      Interviews
                    </h2>
                  </div>
                  <div className="page__body bg--white">
                    <Box className="tabContainer">
                      <TabContext value={tabValue}>
                        <Box className="tabContainer__head tabContainer__head--fixed m_0">
                          <TabList onChange={handleTabValueChange}>
                            <Tab label="Scheduled Interviews" value="1" />
                            <Tab label="Past Interviews" value="2" />
                          </TabList>
                        </Box>
                        <TabPanel value="1" className="tabContainer__item">
                          <ScheduledInterviews
                            activeCompanyId={activeCompanyId}
                            activeRecruiterId={activeRecruiterId}
                            originType="interview"
                            activeRecruiterEmail={activeRecruiterEmail}
                            activeCompanyEmployees={activeCompanyEmployees}
                            tabValue={value}
                            companyDeleteStatus={
                              activeCompanyStatus === 'delete' ? true : false
                            }
                            activeRecruiterStatus={activeRecruiterStatus}
                          />
                        </TabPanel>

                        <TabPanel value="2" className="tabContainer__item">
                          <PastInterviews
                            activeCompanyId={activeCompanyId}
                            activeRecruiterId={activeRecruiterId}
                            originType="interview"
                            tabValue={value}
                          />
                        </TabPanel>
                      </TabContext>
                    </Box>
                  </div>
                </div>
              </div>
            ) : (
              <div className="no-data">No Results Found!</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Interviews;
