import React from 'react';

export default function Pagination({
  startIndex = 0,
  endIndex = 3,
  currentPage = 0,
  recordsPerPage = 10,
  totalRecord = 20,
  setCurrentPage
}) {
  const nPages = Math.ceil(totalRecord / recordsPerPage);
  const records = totalRecord < recordsPerPage ? totalRecord : recordsPerPage;
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
  const nextPage = () => {
    if (currentPage !== nPages - 1) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 0) setCurrentPage(currentPage - 1);
  };

  const navigatePage = (page) => {
    if (currentPage !== page) setCurrentPage(page);
  };
  const higherlimit = (totalRecord) => {
    let limit = records * (currentPage + 1);
    if (limit >= totalRecord) {
      return totalRecord;
    } else {
      return limit;
    }
  };

  const goToFirstPage = () => {
    setCurrentPage(pageNumbers[0]);
  };

  const goTolastPage = () => {
    setCurrentPage(pageNumbers[pageNumbers.length - 1]);
  };

  return (
    <div className="pagination__flex">
      <div className="btn__group">
        <button className="pagination__button" onClick={goToFirstPage}>
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.83333 11.3332L4.5 7.99984L7.83333 4.6665M12.5 11.3332L9.16667 7.99984L12.5 4.6665"
              stroke="#150823"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <button
          className="pagination__button pagination__prev"
          onClick={prevPage}
        >
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5 12L6.5 8L10.5 4"
              stroke="#150823"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
      <ul className="pagination__list">
        {pageNumbers?.slice(startIndex, endIndex).map((pgNumber, i) => (
          <li
            className={`pagination__list--item ${
              currentPage === pgNumber ? 'page-active' : ''
            }`}
            onClick={() => navigatePage(pgNumber)}
          >
            {pgNumber}
          </li>
        ))}
        {pageNumbers.length > 3 ? (
          <li className="pagination__list--item">...</li>
        ) : (
          ''
        )}
        {pageNumbers.length > 3 ? (
          <li
            className={`pagination__list--item ${
              currentPage === pageNumbers[pageNumbers.length - 1]
                ? 'page-active'
                : ''
            }`}
            onClick={() => goTolastPage()}
          >
            {pageNumbers[pageNumbers.length - 1]}
          </li>
        ) : (
          ''
        )}
      </ul>
      <div className="btn__group">
        <button
          className="pagination__button pagination__next"
          onClick={nextPage}
        >
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.5 12L10.5 8L6.5 4"
              stroke="#150823"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <button className="pagination__button" onClick={goTolastPage}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.66667 11.3332L12 7.99984L8.66667 4.6665M4 11.3332L7.33333 7.99984L4 4.6665"
              stroke="#150823"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
