import ArrowDown from './SortingArrowDown';
import ArrowUp from './SortingArrowUp';

const SortColumn = ({ thName, sortByASC }) => {
  return (
    <div className="sortingDiv">
      {thName}
      <span className="arrowHoverHideShow">
        {sortByASC ? <ArrowUp /> : <ArrowDown />}
      </span>
    </div>
  );
};

export default SortColumn;
