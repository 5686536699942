import React, { useEffect, useState } from 'react';
import CloseIcon from '@/assets/svgs/CloseIcon';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import 'moment-timezone';
import {
  convertToTitleCase,
  convertDateToMMDDYYY,
  formatPhoneNumber
} from '@/helper';

const AuditTrailDetailModal = ({ openModal, closeModal, detailModalData }) => {
  const [DetailDataArray, setDetailDataArray] = useState([]);

  useEffect(() => {
    ViewDetailData(detailModalData);
  }, [openModal]);

  const jobDataModal = (jsonObject) => {
    const filteredObject = {
      ...(jsonObject.title && { title: jsonObject.title }),
      ...(jsonObject.location && { location: jsonObject.location }),
      ...(jsonObject.qualification && {
        qualification: jsonObject.qualification
      }),
      ...(jsonObject.experienceRange && {
        experience: `${jsonObject.experienceRange[0]?.value} years - ${jsonObject.experienceRange[1]?.value} years`
      }),
      ...(jsonObject?.noOfHires && { noOfHires: jsonObject?.noOfHires }),
      ...(jsonObject?.compensationRange &&
        jsonObject?.baseSalary && {
          baseSalary: getCompensationValue(jsonObject)
        }),
      ...(jsonObject?.department && { department: jsonObject?.department }),
      ...(jsonObject?.skillSet && { skillSet: jsonObject?.skillSet }),
      ...(jsonObject?.jobTeamMember && {
        jobTeamMember: jsonObject?.jobTeamMember?.map(
          (item) => `${item.firstName} ${item.lastName}`
        )
      }),
      ...(jsonObject?.hiringLead && {
        hiringLead: jsonObject?.hiringLead?.map(
          (item) => `${item.firstName} ${item.lastName}`
        )
      }),
      ...(jsonObject.employmentType && {
        employmentType: jsonObject.employmentType
      }),
      ...(jsonObject?.description && { description: jsonObject?.description })
    };
    return filteredObject;
  };

  const interviewDataModal = (jsonObject) => {
    const filteredObject = {
      ...(jsonObject?.action && { action: jsonObject?.action }),
      ...(jsonObject?.title && { title: jsonObject?.title }),
      ...(jsonObject?.createdAt && {
        date: moment(jsonObject?.createdAt).format('MM/DD/YYYY')
      }),
      ...(jsonObject?.meeting_start_time &&
        jsonObject?.meeting_end_time && {
          time:
            moment(jsonObject?.meeting_start_time).format('hh:mm A') +
            ' - ' +
            moment(jsonObject?.meeting_end_time).format('hh:mm A')
        }),
      ...(jsonObject?.meetingInfo?.location && {
        URL: jsonObject?.meetingInfo?.location
      })
    };
    return filteredObject;
  };

  const CustomerDataModal = (jsonObject) => {
    const filteredObject = {
      ...(jsonObject?.action && { action: jsonObject?.action }),
      ...(jsonObject?.companyName && { companyName: jsonObject?.companyName }),
      ...(jsonObject?.websiteLink && { websiteLink: jsonObject?.websiteLink }),
      ...(jsonObject?.linkedin && { linkedin: jsonObject?.linkedin }),
      ...(jsonObject?.industryType && {
        industryType: jsonObject?.industryType
      }),
      ...(jsonObject?.countryName && { countryName: jsonObject?.countryName }),
      ...(jsonObject?.zipcode && { zipcode: jsonObject?.zipcode }),
      ...(jsonObject?.stateName && { stateName: jsonObject?.stateName }),
      ...(jsonObject?.city && { city: jsonObject?.city }),
      ...(jsonObject?.address && { address: jsonObject?.address })
    };
    return filteredObject;
  };

  const EmployeeDataModal = (jsonObject) => {
    const filteredObject = {
      ...(jsonObject?.action && { action: jsonObject?.action }),
      ...(jsonObject?.email && { email: jsonObject?.email }),
      ...(jsonObject?.role && { role: jsonObject?.role }),
      ...(jsonObject?.firstName &&
        jsonObject?.lastName && {
          name: `${jsonObject.firstName} ${jsonObject.lastName}`
        }),
      ...(jsonObject?.phoneNumber && {
        phoneNumber: `${jsonObject.countryCode} ${formatPhoneNumber(jsonObject.phoneNumber)}`
      })
    };
    return filteredObject;
  };

  const CandidateDataModal = (jsonObject) => {
    const filteredObject = {
      ...(jsonObject?.action && { action: jsonObject?.action }),
      ...(jsonObject?.firstName &&
        jsonObject?.lastName && {
          name: `${jsonObject.firstName} ${jsonObject.lastName}`
        }),
      ...(jsonObject?.phoneNumber && {
        phoneNumber: `${jsonObject.countryCode} ${formatPhoneNumber(jsonObject.phoneNumber)}`
      }),
      ...(jsonObject?.email && { email: jsonObject?.email }),
      ...(jsonObject?.resumeUrl && { resume: jsonObject?.resumeUrl }),
      ...(jsonObject?.education && { higherEducation: jsonObject?.education }),
      ...(jsonObject?.institutionName && {
        institutionName: jsonObject?.institutionName
      }),
      ...(jsonObject?.graduationYear && {
        graduationYear: jsonObject?.graduationYear
      }),
      ...(jsonObject?.experience && {
        experience: `${jsonObject?.experience} years`
      }),
      ...(jsonObject?.linkedin && { linkedin: jsonObject?.linkedin }),
      ...(jsonObject?.role && { role: jsonObject?.role }),
      ...(jsonObject?.skillset && { skillset: jsonObject?.skillset })
    };
    return filteredObject;
  };
  const RecruiterDataModal = (jsonObject) => {
    const filteredObject = {
      ...(jsonObject?.action && { action: jsonObject?.action }),
      ...(jsonObject?.firstName &&
        jsonObject?.lastName && {
          name: `${jsonObject.firstName} ${jsonObject.lastName}`
        }),
      ...(jsonObject?.phoneNumber && {
        phoneNumber: `${jsonObject.countryCode} ${formatPhoneNumber(jsonObject.phoneNumber)}`
      }),
      ...(jsonObject?.email && { email: jsonObject?.email }),
      ...(jsonObject?.skillSet && {
        recruitingFields: jsonObject?.skillSet
      }),
      ...(jsonObject?.experience && {
        totalExperience: `${jsonObject?.experience} years`
      }),
      ...(jsonObject?.linkedin && {
        linkedin: jsonObject?.linkedin
      }),
      ...(jsonObject?.education && {
        highestQualification: jsonObject?.education
      }),
      ...(jsonObject?.institutionName && {
        institutionName: jsonObject?.institutionName
      }),
      ...(jsonObject?.graduationYear && {
        graduationYear: jsonObject?.graduationYear
      }),
      ...(jsonObject?.majorStudy && {
        majorFieldOfStudy: jsonObject?.majorStudy
      })
    };
    return filteredObject;
  };

  const ViewDetailData = (detailModalData) => {
    let dataArr = [];
    let jobData = {};
    if (
      detailModalData?.origin === 'Job' &&
      detailModalData?.activity === 'create'
    ) {
      jobData = jobDataModal(detailModalData?.detail);
      for (const key in jobData) {
        dataArr.push({ fieldName: key, value: jobData[key] });
      }
    } else if (detailModalData?.origin === 'Interview') {
      jobData = interviewDataModal(detailModalData?.detail);
      for (const key in jobData) {
        dataArr.push({ fieldName: key, value: jobData[key] });
      }
    } else if (detailModalData?.origin === 'Customer') {
      jobData = CustomerDataModal(detailModalData?.detail);
      for (const key in jobData) {
        dataArr.push({ fieldName: key, value: jobData[key] });
      }
    } else if (detailModalData?.origin === 'Employee') {
      jobData = EmployeeDataModal(detailModalData?.detail);
      for (const key in jobData) {
        dataArr.push({ fieldName: key, value: jobData[key] });
      }
    } else if (detailModalData?.origin === 'Candidate') {
      jobData = CandidateDataModal(detailModalData?.detail);
      for (const key in jobData) {
        dataArr.push({ fieldName: key, value: jobData[key] });
      }
    } else if (detailModalData?.origin === 'Recruiter') {
      jobData = RecruiterDataModal(detailModalData?.detail);
      for (const key in jobData) {
        dataArr.push({ fieldName: key, value: jobData[key] });
      }
    } else {
      for (const key in detailModalData?.detail) {
        if (key !== 'employeeId') {
          dataArr.push({ fieldName: key, value: detailModalData?.detail[key] });
        }
      }
    }
    setDetailDataArray(dataArr);
  };

  const CorrectingDataFormat = (data) => {
    if (data?.fieldName === 'applicationDeadline') {
      return convertDateToMMDDYYY(data?.value);
    } else if (data?.fieldName === 'description') {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: data?.value
          }}
        />
      );
    } else if (Array.isArray(data?.value)) {
      return data?.value?.join(', ');
    } else if (data?.fieldName === 'URL') {
      return <span onCopy={(e) => e.preventDefault()}>{data?.value}</span>;
    } else {
      return data?.value;
    }
  };

  const getCompensationValue = (jsonObject) => {
    if (jsonObject?.baseSalary === 'Range') {
      return `$ ${jsonObject?.compensationRange[0]?.value} - $ ${jsonObject?.compensationRange[1]?.value} ${jsonObject?.frequency}`;
    } else if (jsonObject?.baseSalary === 'Starting at') {
      return `$${jsonObject.compensationRange[0]?.value}  ${jsonObject?.frequency}`;
    } else if (jsonObject?.baseSalary === 'Up to') {
      return `$${jsonObject?.compensationRange[1]?.value} ${jsonObject?.frequency}`;
    } else if (jsonObject?.baseSalary === 'Exact Pay') {
      return `$${jsonObject?.compensationRange[1]?.value} ${jsonObject?.frequency}`;
    } else {
      return `${'--'}`;
    }
  };

  return (
    <>
      <div className={`modal modalPrompt ${openModal ? 'modal--open' : ''}`}>
        <div className="modal__dialog">
          <div className="modal__content">
            <div className="modal__header" style={{ alignItems: 'flex-start' }}>
              <h2 className="modal__header--title">View Details</h2>
              {detailModalData?.origin === 'Job' && (
                <p className="text__left">Job Name: {detailModalData?.name}</p>
              )}
              <button
                onClick={closeModal}
                className="btn btn__transparent btnClose"
              >
                <CloseIcon />
              </button>
            </div>
            <div className="modal__body">
              <TableContainer component={Paper}>
                {DetailDataArray?.length > 0 ? (
                  <Table sx={{ minWidth: 100 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          <b>Field Name</b>
                        </TableCell>
                        <TableCell align="left">
                          <b>Value</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {DetailDataArray?.map((data, index) => {
                        return (
                          <TableRow
                            key={index}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 }
                            }}
                          >
                            <TableCell align="left">
                              {convertToTitleCase(data?.fieldName)}
                            </TableCell>
                            <TableCell align="left">
                              {CorrectingDataFormat(data)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                ) : (
                  <div className="no-data">No Results Found!</div>
                )}
              </TableContainer>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
    </>
  );
};

export default AuditTrailDetailModal;
