// ******************* RECRUITER END POINTS**********************
export const ADD_RECRUITER = '/laila/recruiter/add';
export const UPDATE_RECRUITER = '/laila/recruiter/update';
export const RECRUITERS_JOB_ASSIGNED = '/laila/recruiter/job';
export const RECRUITER_STATUS_CHANGE = '/laila/recruiter/statuschange';
export const RECRUITERS_LIST = '/laila/recruiter/list';
export const DELETE_RECRUITER = '/laila/recruiter/delete';
export const ACTIVATE_RECRUITER = '/laila/recruiter/activate';
export const ADD_RECRUITER_EDUDETAILS =
  '/laila/recruiter/addqualificationfromadmin';
export const CHECK_RECRUITER_DEACTIVATION =
  '/laila/recruiter/checkfordeactivation/';
export const FETCH_RECRUITER_TAX_INFO = '/laila/tax/information/';
export const GET_RECRUITER_DETAILS = '/laila/customerjob/recruiterdetails/';
export const UPDATE_REVIEW_RATINGS = '/laila/rating/update';

// ******************* STATIC DATA END POINT*********************************
export const STATIC_DATA = '/laila/admin/staticdata';

/** ********************* Screening end points *******************************/
export const SCREENING_LIST = '/laila/screening/list';
export const CANDIDATE_STATUS_CHANGE = '/laila/screening/statuschange';
export const CANDIDATE_RECORDING_LIST = '/laila/screening/getrecordinglist';
export const CANDIDATE_FEEDBACK_LIST =
  '/laila/recruiterjob/feedbackquesiton/candidate';

/** ********************* Billing & Payments end points *******************************/
export const BILLING_CUSTOMER_LIST = '/laila/billing/companylist';
export const BILLING_JOB_LIST = 'laila/billing/joblist';
export const BILLING_CANDIDATE_LIST = '/laila/billing/candidatelist';
export const BILLING_ADD_AMOUNT = '/laila/billing/addamount';
export const BILLING_SEND_INVOICE = '/laila/billing/sendinvoice';
export const BILLING_RECRUITER_LIST = '/laila/recruiter/approvedrecruiterlist';
export const BILLING_RECRUITER_JOBS_LIST = '/laila/billing/recruiterjoblist';
export const BILLING_RECRUITER_CANDIDATES_LIST =
  '/laila/billing/candidaterecruiterlist';
export const APPROVE_CANDIDATES_BILLING =
  '/laila/billing/approvedcandidateforrecruiter';
export const RECRUITERS_OUTSTANDING_AMOUNT =
  '/laila/billing/recruiteroutstandingamountlist';
export const PAY_RECRUITERS_OUTSTANDING_AMOUNT = '/laila/billing/markaspaid';

/** ********************* Interview end points *******************************/
export const INTERVIEW_CUSTOMER_LIST = 'laila/company/list';
export const INTERVIEW_RECRUITER_LIST = 'laila/recruiter/list';
export const INTERVIEW_COMPANY_EMPLOYEE_LIST = 'laila/employee/list';
export const ADD_SCHEDULED_INTERVIEW_DATA = '/laila/interview/add';
export const GET_SCHEDULED_INTERVIEW_DATA = '/laila/interview/list';
export const INTERVIEW_CREATE_MEETING = '/laila/interview/createmeeting';
export const INTERVIEW_CHECK_MEETING = '/laila/interview/checkmeeting';
export const INTERVIEW_JOIN_MEETING = '/laila/interview/checkparticipant';
export const INTERVIEW_GET_RECORDINGS = '/laila/interview/fetchrecording';
export const DOWNLOAD_ACH_PAYPAL_TRANSFER_CSV =
  '/laila/billing/downloaddownloadingoutstandinglist';

/** ************************  Dashboard end points  **************************/
export const DASHBOARD_DATA = '/laila/dashboard/data';

/** ************************  Jobs end points  **************************/
export const JOBS_LIST = '/laila/job/list';
export const JOBS_DETAILS = '/laila/job/detail/';
export const RECRUITER_DETAILS = '/laila/recruiter/details/';
export const DELETE_RECRUITER_REVIEWS_RATINGS = '/laila/rating/delete';
export const UNHIDE_RECRUITER_REVIEWS_RATINGS = '/laila/rating/unhide';

// ******************CANDIDATE End points***********************************
export const GET_JOB_NOTES = '/laila/job/notes/';
export const ADD_JOB_NOTES = '/laila/job/addnotes';
export const EDIT_JOB_NOTES = '/laila/job/editnotes';
export const DELETE_JOB_NOTES = '/laila/job/deletenotes';
export const GET_JOB_QUESTIONNAIRES = '/laila/job/viewquestionaire/';
export const ADD_JOB_QUESTIONNAIRES = '/laila/job/addquestionaire';
/****************************************************************************/

// ******************CANDIDATE SERVICE***********************************

export const ADD_CANDIDATE = '/laila/candidate/add';
export const UPDATE_CANDIDATE = '/laila/candidate/update';
export const DELETE_CANDIDATE = '/laila/candidate/delete';
export const GET_CANDIDATE_LISTING = '/laila/candidate/list';
export const UPLOAD_CANDIDATE_RESUME = '/laila/common/uploadresume';

//* *********************** Customers End points *********************** */
export const GET_STATE_CITY = '/laila/admin/getstatecity';
export const FILE_UPLOAD = '/laila/admin/upload';
export const ADD_COMPANY = '/laila/company/add';
export const ADD_EMPLOYEE = '/laila/employee/add';
export const EDIT_EMPLOYEE = '/laila/employee/edit';
export const UPDATE_COMPANY = '/laila/company/update';
export const COMPANY_LIST = '/laila/company/list';
export const EMPLOYEE_DELETE = '/laila/employee/delete';
export const JOB_LIST = '/laila/job/list';
export const DEACTIVATE_COMPANY = '/laila/company/deactivate';
export const ACTIVATE_COMPANY = '/laila/company/activate';
export const CHECK_COMPANY_DEACTIVATION =
  '/laila/company/checkfordeactivation/';
export const ACTIVATE_EMPLOYEE = '/laila/employee/activate';

// ********************COMMON END POINT******************************
export const LOG_IN = '/laila/admin/login';
export const LOG_OUT = '/laila/admin/logout';
export const RESET_PASSWORD = '/laila/admin/reset-password';
export const FORGOT_PASSWORD = '/laila/admin/forgot-password';

// **********************AUDIT TRAIL END POINTS**************************
export const AUDIT_TRAIL_LISTING = '/laila/audit/list';

// *********************REVIEWS/RATINGS END POINTS************************
export const GET_NPS_REVIEWS_RATINGS = '/laila/rating/fetchnpsrating';
export const GET_NPS_SCORE = '/laila/rating/npsscore';

// **********************SETTINGS END POINTS**************************
export const ADD_TAGS = '/laila/tag/add';
export const UPDATE_TAGS = '/laila/tag/update';
export const TAG_LIST = '/laila/tag/list';
export const DELETE_TAG = '/laila/tag/delete';
export const CHANGE_STATUS_TAG = '/laila/tag/changestatus';
export const GET_COMPANY_LIST = '/laila/tag/customerlistwithemployee';
export const GET_CUSTOMER_TAG_LIST = '/laila/tag/customertag';
