import { createContext } from 'react';

export const LoaderContext = createContext();

export default function BackdropLoader({ show }) {
  return (
    <div className={`${show ? 'loader loader__show' : 'loader'}`}>
      <div className="loader__container"></div>
      <div className="loader__backdrop"></div>
    </div>
  );
}
