import React, { useState } from 'react';
import './App.scss';
import MainRoutes from './routes/MainRoutes';
import BackdropLoader, { LoaderContext } from './components/Loader/Loader';

function App() {
  const [loading, setLoading] = useState(false);
  return (
    <LoaderContext.Provider value={setLoading}>
      <BackdropLoader show={loading} />
      <MainRoutes />
    </LoaderContext.Provider>
  );
}

export default App;
