import { AUDIT_TRAIL_LISTING } from '@/services/end-points';
import httpService from '@/services/http-service';

export const auditTrailListing = async (data) => {
  try {
    const response = await httpService.post(AUDIT_TRAIL_LISTING, data);
    return response;
  } catch (error) {
    return error;
  }
};
