import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ConfirmationModal from '@/components/modals/confirmation-modal';
import LailaLogo from '@/assets/images/laila-logo.svg';
import { GetUserDetails, getInitial } from '@/helper';
import { LogoutUser } from '@/services/common-service/common-service';
import DotGreenIcon from '@/assets/svgs/DotGreenIcon';
import ArrowDown from '@/assets/svgs/ArrowDown';
import SettingsSvg from '@/assets/svgs/SettingsSvg';
import TagsSvg from '@/assets/svgs/Tags';

const SideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('individual');
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const loggedInUser = GetUserDetails();
  const [subActiveTab, setSubActiveTab] = useState('');

  useEffect(() => {
    const newarray = location.pathname.split('/');
    if (!!newarray.find((e) => e === 'customer-listing')) {
      setActiveTab('customer-listing');
    } else if (!!newarray.find((e) => e === 'recruiters')) {
      setActiveTab('recruiters');
    } else if (!!newarray.find((e) => e === 'candidate-listing')) {
      setActiveTab('candidate-listing');
    } else if (
      !!newarray.find((e) => e === 'jobs') ||
      !!newarray.find((e) => e === 'job-listing')
    ) {
      setActiveTab('jobs');
    } else if (
      !!newarray.find((e) => e === 'billing-payments') ||
      !!newarray.find((e) => e === 'jobs-billing')
    ) {
      setActiveTab('billing-payments');
    } else if (!!newarray.find((e) => e === 'interviews')) {
      setActiveTab('interviews');
    } else if (!!newarray.find((e) => e === 'audit-trail')) {
      setActiveTab('audit-trail');
    } else if (!!newarray.find((e) => e === 'reviews-ratings')) {
      setActiveTab('reviews-ratings');
    } else if (!!newarray.find((e) => e === 'tags')) {
      setActiveTab('settings');
      setSubActiveTab('tags');
      handleSubMenuToggle();
    } else {
      setActiveTab('dashboard');
    }
  }, [location.pathname]);

  const logout = async () => {
    const response = await LogoutUser();
    const { meta } = response;
    if (meta?.status) {
      localStorage.clear();
      navigate('/');
    }
  };

  const handleSubMenuToggle = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };
  return (
    <>
      <div className="layoutSidemenu">
        <div className="layoutSidemenu__logo">
          <img src={LailaLogo} alt="Laila" width="70" height="30" />
          {(process.env.REACT_APP_NODE_ENV === 'development' ||
            process.env.REACT_APP_NODE_ENV === 'staging') && (
            <div className="portalIconDot">
              <DotGreenIcon />
            </div>
          )}
        </div>
        <div className="layoutSidemenu__fullHeight">
          <ul className="layoutSidemenu__list">
            <li
              className={
                activeTab === 'dashboard'
                  ? 'layoutSidemenu__list--item layoutSidemenu__selected'
                  : 'layoutSidemenu__list--item'
              }
            >
              <Link to="/dashboard" className="layoutSidemenu__list--link">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.181 6.25763V5.06176H15.5185V7.56924V7.77921L15.6684 7.92623L18.4522 10.6561L18.2207 10.8832L17.7061 10.3786L16.856 9.54493V10.7355V17.8337H11.8372V12.4314V11.9314H11.3372H8.66216H8.16216V12.4314V17.8337H3.14335V10.7355V9.54493L2.29327 10.3786L1.77867 10.8832L1.54713 10.6561L9.99967 2.36729L14.3309 6.61462L15.181 7.44824V6.25763ZM10.3498 3.1647L9.99967 2.8214L9.64959 3.1647L3.63079 9.06694L3.48087 9.21396V9.42393V17.022V17.522H3.98087H7.32465H7.82465V17.022V11.6198H12.1747V17.022V17.522H12.6747H16.0185H16.5185V17.022V9.42393V9.21396L16.3686 9.06694L10.3498 3.1647Z"
                    stroke="#6C737F"
                  />
                </svg>
                <span>Home</span>
              </Link>
            </li>
            <li
              className={
                activeTab === 'customer-listing'
                  ? 'layoutSidemenu__list--item layoutSidemenu__selected'
                  : 'layoutSidemenu__list--item'
              }
            >
              <Link
                to="/customer-listing"
                className="layoutSidemenu__list--link"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.19645 11.6457L3.95741 11.2785L3.26934 10.7882C2.39607 10.1658 1.83333 9.16832 1.83333 8.05588C1.83333 6.20581 3.39558 4.66699 5.33333 4.66699C7.27108 4.66699 8.83333 6.20581 8.83333 8.05588C8.83333 9.16832 8.2706 10.1658 7.39733 10.7882L6.70906 11.2786L7.47033 11.6457C8.35599 12.0728 9.09306 12.7441 9.56606 13.5705L10 14.3286L10.4339 13.5705C10.9069 12.7441 11.644 12.0728 12.5297 11.6457L13.2909 11.2786L12.6027 10.7882C11.7294 10.1658 11.1667 9.16832 11.1667 8.05588C11.1667 6.20581 12.7289 4.66699 14.6667 4.66699C16.6044 4.66699 18.1667 6.20581 18.1667 8.05588C18.1667 9.16832 17.6039 10.1658 16.7307 10.7882L16.0426 11.2785L16.8036 11.6457C18.2553 12.3462 19.2928 13.7211 19.4723 15.3337H19.1381C18.8846 13.1297 16.9739 11.4448 14.6667 11.4448C12.3594 11.4448 10.4487 13.1297 10.1952 15.3337H9.80478C9.55126 13.1297 7.64061 11.4448 5.33333 11.4448C3.02606 11.4448 1.11541 13.1297 0.861884 15.3337H0.527678C0.707184 13.7211 1.74468 12.3462 3.19645 11.6457ZM8.5 8.05588C8.5 6.32594 7.07788 4.96329 5.33333 4.96329C3.58879 4.96329 2.16667 6.32594 2.16667 8.05588C2.16667 9.78582 3.58879 11.1485 5.33333 11.1485C7.07788 11.1485 8.5 9.78582 8.5 8.05588ZM17.8333 8.05588C17.8333 6.32594 16.4112 4.96329 14.6667 4.96329C12.9221 4.96329 11.5 6.32594 11.5 8.05588C11.5 9.78582 12.9221 11.1485 14.6667 11.1485C16.4112 11.1485 17.8333 9.78582 17.8333 8.05588Z"
                    stroke="#6C737F"
                  />
                </svg>
                <span>Customers</span>
              </Link>
            </li>
            <li
              className={
                activeTab === 'jobs'
                  ? 'layoutSidemenu__list--item layoutSidemenu__selected'
                  : 'layoutSidemenu__list--item'
              }
            >
              <Link to="/jobs" className="layoutSidemenu__list--link">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.3337 17.5V4.16667C13.3337 3.72464 13.1581 3.30072 12.8455 2.98816C12.5329 2.67559 12.109 2.5 11.667 2.5H8.33366C7.89163 2.5 7.46771 2.67559 7.15515 2.98816C6.84259 3.30072 6.66699 3.72464 6.66699 4.16667V17.5M3.33366 5.83333H16.667C17.5875 5.83333 18.3337 6.57953 18.3337 7.5V15.8333C18.3337 16.7538 17.5875 17.5 16.667 17.5H3.33366C2.41318 17.5 1.66699 16.7538 1.66699 15.8333V7.5C1.66699 6.57953 2.41318 5.83333 3.33366 5.83333Z"
                    stroke="#6C737F"
                    strokeWidth="1"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>Jobs</span>
              </Link>
            </li>
            <li
              className={
                activeTab === 'recruiters'
                  ? 'layoutSidemenu__list--item layoutSidemenu__selected'
                  : 'layoutSidemenu__list--item'
              }
            >
              <Link to="/recruiters" className="layoutSidemenu__list--link">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.333 17.5V15.8333C13.333 14.9493 12.9818 14.1014 12.3567 13.4763C11.7316 12.8512 10.8837 12.5 9.99967 12.5H4.16634C3.28229 12.5 2.43444 12.8512 1.80932 13.4763C1.1842 14.1014 0.833008 14.9493 0.833008 15.8333V17.5M16.6663 6.66667V11.6667M19.1663 9.16667H14.1663M10.4163 5.83333C10.4163 7.67428 8.92396 9.16667 7.08301 9.16667C5.24206 9.16667 3.74967 7.67428 3.74967 5.83333C3.74967 3.99238 5.24206 2.5 7.08301 2.5C8.92396 2.5 10.4163 3.99238 10.4163 5.83333Z"
                    stroke="#6C737F"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>Recruiters</span>
              </Link>
            </li>
            <li
              className={
                activeTab === 'candidate-listing'
                  ? 'layoutSidemenu__list--item layoutSidemenu__selected'
                  : 'layoutSidemenu__list--item'
              }
            >
              <Link
                to="/candidate-listing"
                className="layoutSidemenu__list--link"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.6663 17.5V15.8333C16.6663 14.9493 16.3152 14.1014 15.69 13.4763C15.0649 12.8512 14.2171 12.5 13.333 12.5H6.66634C5.78229 12.5 4.93444 12.8512 4.30932 13.4763C3.6842 14.1014 3.33301 14.9493 3.33301 15.8333V17.5M13.333 5.83333C13.333 7.67428 11.8406 9.16667 9.99967 9.16667C8.15873 9.16667 6.66634 7.67428 6.66634 5.83333C6.66634 3.99238 8.15873 2.5 9.99967 2.5C11.8406 2.5 13.333 3.99238 13.333 5.83333Z"
                    stroke="#6C737F"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>Candidates</span>
              </Link>
            </li>
            <li
              className={
                activeTab === 'interviews'
                  ? 'layoutSidemenu__list--item layoutSidemenu__selected'
                  : 'layoutSidemenu__list--item'
              }
            >
              <Link to="/interviews" className="layoutSidemenu__list--link">
                <svg
                  width="22"
                  height="16"
                  viewBox="0 0 22 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.0003 6.51515C1.0003 6.75894 1.10526 6.99274 1.29207 7.16512C1.47889 7.3375 1.73227 7.43434 1.99647 7.43434H7.97348L9.96582 9.27273V1.91919C9.96582 1.67541 9.86087 1.44161 9.67405 1.26923C9.48723 1.09684 9.23385 1 8.96965 1H1.99647C1.73227 1 1.47889 1.09684 1.29207 1.26923C1.10526 1.44161 1.0003 1.67541 1.0003 1.91919V6.51515Z"
                    stroke="#6C737F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21.0003 15.0004V13.7277C21.0003 13.0526 20.7097 12.4052 20.1923 11.9278C19.675 11.4504 18.9733 11.1823 18.2417 11.1823H12.7244C11.9928 11.1823 11.2911 11.4504 10.7738 11.9278C10.2565 12.4052 9.96582 13.0526 9.96582 13.7277V15.0004M18.2417 6.09135C18.2417 7.49717 17.0066 8.63681 15.4831 8.63681C13.9595 8.63681 12.7244 7.49717 12.7244 6.09135C12.7244 4.68554 13.9595 3.5459 15.4831 3.5459C17.0066 3.5459 18.2417 4.68554 18.2417 6.09135Z"
                    stroke="#6C737F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>Interviews</span>
              </Link>
            </li>
            <li
              className={
                activeTab === 'billing-payments'
                  ? 'layoutSidemenu__list--item layoutSidemenu__selected'
                  : 'layoutSidemenu__list--item'
              }
            >
              <Link
                to="/billing-payments"
                className="layoutSidemenu__list--link"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.6663 1.66699H4.99967C4.55765 1.66699 4.13372 1.84259 3.82116 2.15515C3.5086 2.46771 3.33301 2.89163 3.33301 3.33366V16.667C3.33301 17.109 3.5086 17.5329 3.82116 17.8455C4.13372 18.1581 4.55765 18.3337 4.99967 18.3337H14.9997C15.4417 18.3337 15.8656 18.1581 16.1782 17.8455C16.4907 17.5329 16.6663 17.109 16.6663 16.667V6.66699M11.6663 1.66699L16.6663 6.66699M11.6663 1.66699V6.66699H16.6663M13.333 10.8337H6.66634M13.333 14.167H6.66634M8.33301 7.50033H6.66634"
                    stroke="#6C737F"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>Billing & Payments</span>
              </Link>
            </li>
            <li
              className={
                activeTab === 'reviews-ratings'
                  ? 'layoutSidemenu__list--item layoutSidemenu__selected'
                  : 'layoutSidemenu__list--item'
              }
            >
              <Link
                to="/reviews-ratings"
                className="layoutSidemenu__list--link"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.68584 0.685738C8.79981 0.402931 9.20019 0.40293 9.31416 0.685739L11.249 5.48705C11.3945 5.848 11.7333 6.09416 12.1215 6.12096L17.2858 6.47746C17.59 6.49846 17.7137 6.87924 17.4799 7.07503L13.5115 10.3989C13.2132 10.6488 13.0838 11.0471 13.1783 11.4246L14.4351 16.4462C14.5091 16.742 14.1852 16.9774 13.9267 16.8155L9.53923 14.0685C9.2094 13.862 8.7906 13.862 8.46077 14.0685L4.07327 16.8155C3.81483 16.9774 3.49092 16.742 3.56495 16.4462L4.82173 11.4246C4.91621 11.0471 4.7868 10.6488 4.48847 10.3989L0.520059 7.07503C0.286311 6.87924 0.410034 6.49846 0.71422 6.47746L5.87846 6.12096C6.26668 6.09416 6.6055 5.84799 6.75095 5.48706L8.68584 0.685738Z"
                    stroke="#6C737F"
                    strokeWidth="0.677419"
                  />
                </svg>

                <span>Reviews & Ratings</span>
              </Link>
            </li>
            <li
              className={
                activeTab === 'audit-trail'
                  ? 'layoutSidemenu__list--item layoutSidemenu__selected'
                  : 'layoutSidemenu__list--item'
              }
            >
              <Link to="/audit-trail" className="layoutSidemenu__list--link">
                <svg
                  width="18"
                  height="16"
                  viewBox="0 0 18 16"
                  fill="none"
                  xmlns="
http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.66699 15.5H12.3337M9.00033 12.1667V15.5M2.33366 0.5H15.667C16.5875 0.5 17.3337 1.24619 17.3337 2.16667V10.5C17.3337 11.4205 16.5875 12.1667 15.667 12.1667H2.33366C1.41318 12.1667 0.666992 11.4205 0.666992 10.5V2.16667C0.666992 1.24619 1.41318 0.5 2.33366 0.5Z"
                    stroke="#6C737F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>Audit Trail</span>
              </Link>
            </li>
            <li
              className={
                activeTab === 'settings'
                  ? 'layoutSidemenu__list--item layoutSidemenu__selected'
                  : 'layoutSidemenu__list--item'
              }
            >
              <Link
                className={`layoutSidemenu__list--link ${
                  isSubMenuOpen ? 'open' : ''
                }`}
                onClick={handleSubMenuToggle}
              >
                <SettingsSvg />
                <span>Settings</span>
                <span className="arrowDown" onClick={handleSubMenuToggle}>
                  <ArrowDown />
                </span>
              </Link>
              <ul
                className={`layoutSidemenu__list layoutSidemenu__list--submenu border_0 ${
                  isSubMenuOpen ? 'open' : ''
                }`}
              >
                <li
                  className={
                    subActiveTab === 'tags'
                      ? 'layoutSidemenu__list--item layoutSidemenu__selected'
                      : 'layoutSidemenu__list--item'
                  }
                >
                  <Link
                    to="/settings/tags"
                    className="layoutSidemenu__list--link"
                    onClick={() => setSubActiveTab('tags')}
                  >
                    <TagsSvg />
                    <span>Tags</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <div className="layoutSidemenu__logout">
            <button type="button" onClick={() => setOpenLogoutModal(true)}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.3003 5.53366C16.3489 6.58265 17.063 7.91903 17.3522 9.37382C17.6414 10.8286 17.4927 12.3365 16.925 13.7068C16.3572 15.0771 15.3959 16.2483 14.1626 17.0723C12.9293 17.8963 11.4794 18.3361 9.9961 18.3361C8.51284 18.3361 7.06289 17.8963 5.82958 17.0723C4.59626 16.2483 3.63496 15.0771 3.06723 13.7068C2.49951 12.3365 2.35084 10.8286 2.64003 9.37382C2.92922 7.91903 3.64328 6.58265 4.69193 5.53366M10.0003 1.66699V10.0003"
                  stroke="#F04438"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>Logout</span>
            </button>
          </div>
        </div>
        <div className="layoutSidemenu__user">
          <div className="layoutSidemenu__user--initials">
            {getInitial(loggedInUser?.firstName)}
          </div>
          <h4 className="layoutSidemenu__user--name">
            {loggedInUser?.firstName + ' ' + loggedInUser?.lastName}
            {(process.env.REACT_APP_NODE_ENV === 'development' ||
              process.env.REACT_APP_NODE_ENV === 'staging') && (
              <span>Laila Admin</span>
            )}
          </h4>
        </div>
      </div>
      <ConfirmationModal
        openModal={openLogoutModal}
        closeModal={() => setOpenLogoutModal(false)}
        onSubmit={logout}
        heading={'Logout'}
        confirmationMessage={'Are you sure want to logout?'}
      />
    </>
  );
};

export default SideBar;
