import React from 'react';

function Dot() {
  return (
    <svg
      width="5"
      height="6"
      viewBox="0 0 5 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="5" height="5" rx="2.5" fill="#384250" />
    </svg>
  );
}

export default Dot;
