import React from 'react';

export default function Candidate() {
  return (
    <svg
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="80" height="80" rx="40" fill="#12B76A" />
      <path
        d="M21.7502 54.5836H17.5835V52.5003C17.5835 48.6173 20.2393 45.3546 23.8335 44.4295M28.0002 37.5614C25.5727 36.7034 23.8335 34.3883 23.8335 31.667C23.8335 28.9457 25.5727 26.6306 28.0002 25.7726M59.2502 54.5836H63.4168V52.5003C63.4168 48.6173 60.7611 45.3546 57.1668 44.4295M53.0002 25.7726C55.4276 26.6306 57.1668 28.9457 57.1668 31.667C57.1668 34.3883 55.4276 36.7034 53.0002 37.5614M36.3335 44.167H44.6668C49.2692 44.167 53.0002 47.898 53.0002 52.5003V54.5837H28.0002V52.5003C28.0002 47.898 31.7311 44.167 36.3335 44.167ZM46.7502 31.667C46.7502 35.1188 43.9519 37.917 40.5002 37.917C37.0484 37.917 34.2502 35.1188 34.2502 31.667C34.2502 28.2152 37.0484 25.417 40.5002 25.417C43.9519 25.417 46.7502 28.2152 46.7502 31.667Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
