import { React, useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { SuccessfullyModal } from '@/components/modals/successfully-modal';
import ErrorModal from '@/components/modals/error-modal';
import {
  GetUserDetails,
  formatPhoneNumber,
  firstLetterCapital
} from '@/helper';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {
  phoneNumberRegex,
  EmailRegex,
  LinkedInRegex,
  isValidEmployeeName
} from '@/regex';
import ArrowDown from '@/elements/ArrowDown';
import BackArrow from '@/assets/svgs/BackArrow';
import NameIcon from '@/assets/svgs/NameIcon';
import PhoneIcon from '@/assets/svgs/PhoneIcon';
import EmailIcon from '@/assets/svgs/EmailIcon';
import UploadFileIcon from '@/assets/svgs/UploadFileIcon';
import LinkedinIcon from '@/assets/svgs/LinkedinIcon';
import {
  addCandidate,
  updateCandidate,
  uploadCandidateResume
} from '@/services/candidate-service/candidate-service';
import { getStaticData } from '@/services/static-data-service';
import * as CONSTANTS from '@/utils/constants';
import * as MESSAGES from '@/utils/popupMessages';

export const AddCandidate = ({
  backToCandidateListing,
  SelectedCandidateData,
  EditCustomerMode,
  CandidateList,
  setAddResponsiveClass,
  addResponsiveClass
}) => {
  const [errors, setErrors] = useState({});
  const [Loading, setLoading] = useState(false);
  const [FileLoading, setFileLoading] = useState(false);
  const [FileUrl, setFileUrl] = useState(
    EditCustomerMode ? SelectedCandidateData?.resumeUrl?.split('/')[4] : null
  );
  const [CountryCodes, setCountryCodes] = useState([]);
  const [SkillSetArr, setSkillSetArr] = useState(
    EditCustomerMode ? SelectedCandidateData?.skillset : []
  );
  const [SkillSetData, setSkillSetData] = useState([]);
  const [openSuccessfullModal, setOpenSuccessfullModal] = useState(false);
  const [successfullMsg, setSuccessfullMsg] = useState('');
  const [SuccessfullHeading, setSuccessfullHeading] = useState('');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMsgs, setErrorMsgs] = useState('');
  const [ActiveCandidateData, setActiveCandidateData] = useState(
    SelectedCandidateData
  );
  const [fields, setFields] = useState({
    candidateFirstName: EditCustomerMode
      ? SelectedCandidateData?.firstName
      : null,
    candidateLastName: EditCustomerMode
      ? SelectedCandidateData?.lastName
      : null,
    candidateEmailAddress: EditCustomerMode
      ? SelectedCandidateData?.email
      : null,
    candidatePhoneno: EditCustomerMode
      ? SelectedCandidateData?.phoneNumber
      : null,
    higherEducation: EditCustomerMode ? SelectedCandidateData?.education : null,
    totalExperience: EditCustomerMode
      ? SelectedCandidateData?.experience?.toString()
      : null,
    candidateSkillset: EditCustomerMode ? SelectedCandidateData?.skillset : [],
    linkedInAccount: EditCustomerMode ? SelectedCandidateData?.linkedin : null,
    attachedResume: EditCustomerMode
      ? SelectedCandidateData?.resumeUrl?.split('/')[4]
      : null,
    countryCode: EditCustomerMode ? SelectedCandidateData?.countryCode : '+1',
    candidateRole: EditCustomerMode ? SelectedCandidateData?.role : null,
    institutionName: EditCustomerMode
      ? SelectedCandidateData?.institutionName
      : null,
    graduationYear: EditCustomerMode
      ? SelectedCandidateData?.graduationYear
      : null
  });

  const [HigherEducationArr, setHigherEducationArr] = useState([]);

  const closeSucessfullModal = () => {
    setOpenSuccessfullModal(false);
  };

  const closeErrorModal = () => {
    setOpenErrorModal(false);
  };

  useEffect(() => {
    getDropdownsData();
  }, []);

  const getDropdownsData = async () => {
    try {
      const response = await getStaticData();
      const { data, meta } = response;
      if (meta?.status) {
        setCountryCodes(data?.countryCode);
        setSkillSetData(data?.skillSet);
        setHigherEducationArr(data?.qualification);
      } else {
        setOpenErrorModal(true);
      }
    } catch (error) {
      setOpenErrorModal(true);
      console.log('axios error --->', error);
    }
  };

  const handleChange = (e, newValue, fieldName) => {
    let field = fields;
    handleValidation(e);
    const { name, value } = e?.target;

    if (fieldName === 'candidateSkillset') {
      const {
        target: { value }
      } = e;
      field[name] = value === '' ? null : value;

      setSkillSetArr(
        typeof newValue === 'string'
          ? newValue.split(',')
            ? newValue === ''
            : null
          : newValue
      );
      setFields({
        ...fields,
        candidateSkillset:
          typeof newValue === 'string'
            ? newValue.split(',')
              ? newValue === ''
              : null
            : newValue
      });
    } else if (fieldName === 'higherEducation') {
      setFields({ ...fields, higherEducation: newValue });
    } else {
      field[name] = value === '' ? null : value;

      setFields({ ...field });
    }
  };

  const handleValidation = (e) => {
    let errorsT = errors;
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    let fieldname = e ? (e.target ? e.target.name : e) : e;
    let updatedValue = fieldValue;
    const currentYear = new Date().getFullYear();
    const maxYear = currentYear + 10;
    const minYear = 1930;

    if (fieldname === undefined || fieldname === 'candidateFirstName') {
      if (fieldname === undefined) {
        fieldValue = fields['candidateFirstName']
          ? fields['candidateFirstName']
          : '';
        updatedValue = fieldValue;
      }
      delete errorsT['candidateFirstName'];
      delete errorsT['candidateFirstNameError'];
      if (!updatedValue || updatedValue.trim() === '') {
        formIsValid = false;
        errorsT['candidateFirstName'] =
          CONSTANTS?.FIRST_NAME?.validation?.required;
        errorsT['candidateFirstNameError'] = true;
      } else if (updatedValue && !isValidEmployeeName.test(updatedValue)) {
        formIsValid = false;
        errorsT['candidateFirstName'] =
          CONSTANTS?.FIRST_NAME?.validation?.valid;
        errorsT['candidateFirstNameError'] = true;
      }
    }

    if (fieldname === undefined || fieldname === 'candidateLastName') {
      if (fieldname === undefined) {
        fieldValue = fields['candidateLastName']
          ? fields['candidateLastName']
          : '';
        updatedValue = fieldValue;
      }
      delete errorsT['candidateLastName'];
      delete errorsT['candidateLastNameError'];
      if (!updatedValue || updatedValue.trim() === '') {
        formIsValid = false;
        errorsT['candidateLastName'] =
          CONSTANTS?.LAST_NAME?.validation?.required;
        errorsT['candidateLastNameError'] = true;
      } else if (updatedValue && !isValidEmployeeName.test(updatedValue)) {
        formIsValid = false;
        errorsT['candidateLastName'] = CONSTANTS?.LAST_NAME?.validation?.valid;
        errorsT['candidateLastNameError'] = true;
      }
    }

    if (fieldname === undefined || fieldname === 'candidateEmailAddress') {
      if (fieldname === undefined) {
        fieldValue = fields['candidateEmailAddress']
          ? fields['candidateEmailAddress']
          : '';
        updatedValue = fieldValue;
      }
      delete errorsT['candidateEmailAddress'];
      delete errorsT['candidateEmailAddressError'];
      if (!updatedValue || updatedValue.trim() === '') {
        formIsValid = false;
        errorsT['candidateEmailAddress'] =
          CONSTANTS?.EMAIL?.validation?.required;
        errorsT['candidateEmailAddressError'] = true;
      } else if (!EmailRegex.test(updatedValue)) {
        formIsValid = false;
        errorsT['candidateEmailAddress'] = CONSTANTS?.EMAIL?.validation?.valid;
        errorsT['candidateEmailAddressError'] = true;
      }
    }

    if (fieldname === undefined || fieldname === 'candidatePhoneno') {
      if (fieldname === undefined) {
        fieldValue = fields['candidatePhoneno']
          ? fields['candidatePhoneno']
          : '';
        updatedValue = fieldValue;
      }

      delete errorsT['candidatePhoneno'];
      delete errorsT['candidatePhonenoError'];
      if (!updatedValue || updatedValue.trim() === '') {
        formIsValid = false;
        errorsT['candidatePhoneno'] =
          CONSTANTS?.PHONE_NUMBER?.validation?.required;
        errorsT['candidatePhonenoError'] = true;
      } else if (updatedValue && updatedValue.trim().length < 10) {
        formIsValid = false;
        errorsT['candidatePhoneno'] =
          CONSTANTS?.PHONE_NUMBER?.validation?.valid;
        errorsT['candidatePhonenoError'] = true;
      } else if (!phoneNumberRegex.test(updatedValue)) {
        formIsValid = false;
        errorsT['candidatePhoneno'] =
          CONSTANTS?.PHONE_NUMBER?.validation?.valid;
        errorsT['candidatePhonenoError'] = true;
      }
    }

    if (fieldname === undefined || fieldname === 'higherEducation') {
      if (fieldname === undefined) {
        fieldValue = fields['higherEducation'] ? fields['higherEducation'] : '';
        updatedValue = fieldValue;
      }

      delete errorsT['higherEducation'];
      delete errorsT['higherEducationError'];
      if (!updatedValue) {
        formIsValid = false;
        errorsT['higherEducation'] =
          CONSTANTS?.HIGHER_EDUCATION?.validation?.required;
        errorsT['higherEducationError'] = true;
      }
    }

    if (fieldname === undefined || fieldname === 'totalExperience') {
      if (fieldname === undefined) {
        fieldValue = fields['totalExperience'] ? fields['totalExperience'] : '';
        updatedValue = fieldValue;
      }

      delete errorsT['totalExperience'];
      delete errorsT['totalExperienceError'];
      if (!updatedValue) {
        formIsValid = false;
        errorsT['totalExperience'] =
          CONSTANTS?.EXPERIENCE?.validation?.required;
        errorsT['totalExperienceError'] = true;
      } else if (updatedValue < 0 || updatedValue > 99) {
        formIsValid = false;
        errorsT['totalExperience'] = CONSTANTS?.EXPERIENCE?.validation?.valid;
        errorsT['totalExperienceError'] = true;
      }
    }

    if (fieldname === undefined || fieldname === 'graduationYear') {
      if (fieldname === undefined) {
        fieldValue = fields['graduationYear'] ? fields['graduationYear'] : '';
        updatedValue = fieldValue;
      }

      delete errorsT['graduationYear'];
      delete errorsT['graduationYearError'];
      const isValidYear =
        /^\d{4}$/.test(updatedValue) &&
        updatedValue >= minYear &&
        updatedValue <= maxYear;
      if (updatedValue && !isValidYear) {
        formIsValid = false;
        errorsT['graduationYear'] =
          CONSTANTS?.GRADUATION_YEAR?.validation?.maxminValid;
        errorsT['graduationYearError'] = true;
      }
    }

    if (fieldname === undefined || fieldname === 'candidateSkillset') {
      if (fieldname === undefined) {
        fieldValue = fields['candidateSkillset']
          ? fields['candidateSkillset']
          : '';
        updatedValue = fieldValue;
      }

      delete errorsT['candidateSkillset'];
      delete errorsT['candidateSkillsetError'];
      if (!updatedValue || updatedValue?.length === 0) {
        formIsValid = false;
        errorsT['candidateSkillset'] =
          CONSTANTS?.SKILL_SET?.validation?.required;
        errorsT['candidateSkillsetError'] = true;
      }
    }

    if (fieldname === undefined || fieldname === 'linkedInAccount') {
      if (fieldname === undefined) {
        fieldValue = fields['linkedInAccount'] ? fields['linkedInAccount'] : '';
        updatedValue = fieldValue;
      }

      delete errorsT['linkedInAccount'];
      delete errorsT['linkedInAccountError'];
      if (updatedValue && !LinkedInRegex.test(updatedValue)) {
        formIsValid = false;
        errorsT['linkedInAccount'] = CONSTANTS?.LINKEDIN?.validation?.valid;
        errorsT['linkedInAccountError'] = true;
      }
    }

    setErrors({
      ...errorsT
    });
    return {
      formIsValid,
      updatedValue
    };
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const userDetails = GetUserDetails();
    const { formIsValid } = handleValidation();
    if (formIsValid) {
      let Variables = {
        firstName: fields?.candidateFirstName ?? null,
        lastName: fields?.candidateLastName ?? null,
        email: fields?.candidateEmailAddress ?? null,
        countryCode: fields?.countryCode ?? null,
        phoneNumber: fields?.candidatePhoneno ?? null,
        education: fields?.higherEducation ?? null,
        resumeUrl: FileUrl ?? null,
        institutionName: fields?.institutionName ?? null,
        graduationYear: fields?.graduationYear ?? null,
        linkedin: fields?.linkedInAccount ?? null,
        experience: Number(fields?.totalExperience) ?? null,
        skillset: SkillSetArr ?? null,
        role: fields?.candidateRole ?? null,
        addedBy: userDetails?.adminId
      };
      const response = await addCandidate(Variables);
      const { data, meta } = response;
      if (meta?.status) {
        const { message } = MESSAGES?.addCandidate;
        setSuccessfullMsg(message?.successfull);
        setSuccessfullHeading(message?.heading);
        setOpenSuccessfullModal(true);
        setTimeout(() => {
          backToCandidateListing();
        }, 3000);
      } else {
        setErrorMsgs(response?.meta?.msg);
        setOpenErrorModal(true);
      }
    }
    setLoading(false);
  };

  const handleUpdateCandiate = async (e) => {
    setLoading(true);
    e.preventDefault();
    const { formIsValid } = handleValidation();
    if (formIsValid) {
      let Variables = {
        firstName: fields?.candidateFirstName ?? null,
        lastName: fields?.candidateLastName ?? null,
        email: fields?.candidateEmailAddress ?? null,
        countryCode: fields?.countryCode ?? null,
        phoneNumber: fields?.candidatePhoneno ?? null,
        education: fields?.higherEducation ?? null,
        experience: Number(fields?.totalExperience) ?? null,
        role: fields?.candidateRole ?? null,
        institutionName: fields?.institutionName ?? null,
        graduationYear: fields?.graduationYear ?? null,
        skillset: SkillSetArr ?? null,
        linkedin: fields?.linkedInAccount ?? null,
        resumeUrl: FileUrl ?? null,
        candidateId: ActiveCandidateData?.candidateId ?? null
      };
      const response = await updateCandidate(Variables);
      const { meta } = response;
      if (meta?.status) {
        const { message } = MESSAGES?.updateCandidate;
        setSuccessfullMsg(message?.successfull);
        setSuccessfullHeading(message?.heading);
        setOpenSuccessfullModal(true);
        setTimeout(() => {
          backToCandidateListing();
        }, 3000);
      } else {
        setErrorMsgs(response?.meta?.msg);
        setOpenErrorModal(true);
      }
    }
    setLoading(false);
  };

  const handleFileUpload = async (e) => {
    let file = e?.target?.files;
    let image = file[0]?.name;
    let fileExtension = file[0]?.name?.split('.');
    let imageExtention = fileExtension ? fileExtension[1] : '';

    if (
      imageExtention === 'jpg' ||
      imageExtention === 'png' ||
      imageExtention === 'jpeg' ||
      imageExtention === 'doc' ||
      imageExtention === 'pdf' ||
      imageExtention === 'docx' ||
      imageExtention === 'JPG' ||
      imageExtention === 'PNG' ||
      imageExtention === 'JPEG' ||
      imageExtention === 'DOC' ||
      imageExtention === 'PDF' ||
      imageExtention === 'DOCX'
    ) {
      setFileLoading(true);
      let formData = new FormData();
      formData.append('resume', file[0]);
      const response = await uploadCandidateResume(formData);
      const { meta } = response;
      if (meta?.status) {
        setFileUrl(response?.data?.url);
        setFields((prevData) => ({
          ...prevData,
          attachedResume: image
        }));
        let errorsT = errors;
        delete errorsT['attachedResume'];
        delete errorsT['attachedResumeError'];
      } else {
        setErrorMsgs(response?.meta?.msg);
        setOpenErrorModal(true);
      }
    } else {
      setErrors((prevData) => ({
        ...prevData,
        attachedResume: MESSAGES?.resume?.message?.allowed,
        attachedResumeError: true
      }));
      setFields((prevData) => ({
        ...prevData,
        attachedResume: ''
      }));
      setFileUrl(null);
    }
    setFileLoading(false);
  };

  return (
    <>
      {CandidateList.length ? (
        <div
          className={`layoutGrid__col layoutGrid--responsive  ${
            addResponsiveClass ? 'layoutGrid--show' : ''
          }`}
        >
          <div className="dataCol">
            <div className="page page__full">
              <div className="page__head">
                <h2 className="page__head--title">
                  <button
                    className="btn btn__transparent btn__back border_0 p_0"
                    onClick={() => setAddResponsiveClass(false)}
                  >
                    <BackArrow />
                  </button>
                  {EditCustomerMode
                    ? firstLetterCapital(SelectedCandidateData?.firstName) +
                      ' ' +
                      firstLetterCapital(SelectedCandidateData?.lastName)
                    : 'Add Candidate'}
                </h2>
                <div className="page__head--actions">
                  <button
                    type="button"
                    className="btn btn__white"
                    onClick={() => {
                      setAddResponsiveClass(false);
                      backToCandidateListing();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn__primary"
                    type="button"
                    disabled={Loading ? true : false}
                    onClick={(e) => {
                      EditCustomerMode
                        ? handleUpdateCandiate(e)
                        : handleSubmit(e);
                    }}
                  >
                    {EditCustomerMode ? 'Update' : 'Add'}
                  </button>
                </div>
              </div>

              <div className="page__body">
                <form className="form">
                  <section className="form__section">
                    <h4 className="form__section--title">Basic Details</h4>
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.candidateFirstNameError ? 'fieldError' : ''
                          }`}
                        >
                          <label className="formField__label">First Name</label>
                          <div className="formField__group sm">
                            <input
                              type="text"
                              className="formField__input sm"
                              placeholder="First Name"
                              name="candidateFirstName"
                              value={fields?.candidateFirstName ?? ''}
                              onChange={(e) => handleChange(e)}
                            />
                            <span className="formField__icon">
                              <NameIcon />
                            </span>
                          </div>
                          <span className="formField__error">
                            {errors?.candidateFirstName}
                          </span>
                        </div>
                      </div>
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.candidateLastNameError ? 'fieldError' : ''
                          }`}
                        >
                          <label className="formField__label">Last Name</label>
                          <div className="formField__group sm">
                            <input
                              type="text"
                              className="formField__input sm"
                              placeholder="Last Name"
                              name="candidateLastName"
                              value={fields?.candidateLastName ?? ''}
                              onChange={(e) => handleChange(e)}
                            />
                            <span className="formField__icon">
                              <NameIcon />
                            </span>
                          </div>
                          <span className="formField__error">
                            {errors?.candidateLastName}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.candidatePhonenoError ? 'fieldError' : ''
                          }`}
                        >
                          <label className="formField__label">
                            Phone Number
                          </label>
                          <div className="formField__group formField__phoneNumber sm">
                            <FormControl
                              fullWidth
                              size="small"
                              className="formField__select"
                            >
                              <Select
                                onChange={(e) => handleChange(e)}
                                IconComponent={() => <ArrowDown />}
                                name="countryCode"
                                value={fields?.countryCode ?? null}
                                displayEmpty
                              >
                                {CountryCodes?.map((item, index) => (
                                  <MenuItem key={index} value={item.value}>
                                    {item.value}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <input
                              type="text"
                              maxLength="10"
                              value={
                                formatPhoneNumber(fields?.candidatePhoneno) ??
                                ''
                              }
                              className="formField__input sm"
                              placeholder="Phone Number"
                              name="candidatePhoneno"
                              onChange={(e) => handleChange(e)}
                            />
                            <span className="formField__icon">
                              <PhoneIcon />
                            </span>
                          </div>
                          <span className="formField__error">
                            {errors?.candidatePhoneno}
                          </span>
                        </div>
                      </div>
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.candidateEmailAddressError
                              ? 'fieldError'
                              : ''
                          }`}
                        >
                          <label className="formField__label">
                            Email Address
                          </label>
                          <div className="formField__group sm">
                            <input
                              type="text"
                              className={`formField__input sm ${
                                EditCustomerMode ? 'formField__disabled' : ''
                              }`}
                              placeholder="Email Address"
                              name="candidateEmailAddress"
                              disabled={EditCustomerMode ? true : false}
                              value={fields?.candidateEmailAddress ?? ''}
                              onChange={(e) => handleChange(e)}
                            />
                            <span className="formField__icon">
                              <EmailIcon />
                            </span>
                          </div>
                          <span className="formField__error">
                            {errors?.candidateEmailAddress}
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="form__section">
                    <h4 className="form__section--title">
                      Educational Details
                    </h4>
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.attachedResumeError ? 'fieldError' : ''
                          }`}
                        >
                          <label className="formField__label">
                            Attach Resume
                          </label>
                          <label
                            className="formField__group sm"
                            id="attachResumeLabel"
                          >
                            {fields?.attachedResume
                              ? fields?.attachedResume
                              : 'Browse file'}
                            <input
                              type="file"
                              className="formField__input"
                              placeholder="Browse file"
                              id="attachResume"
                              name="attachedResume"
                              accept="image/*, .doc,.docx, .DOC, .DOCX, .pdf, .PDF"
                              onChange={(e) => handleFileUpload(e)}
                              disabled={FileLoading ? true : false}
                            />
                            <span className="formField__icon">
                              <UploadFileIcon />
                            </span>
                          </label>
                          <span className="formField__error">
                            {errors?.attachedResume}
                          </span>
                        </div>
                      </div>
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.higherEducationError ? 'fieldError' : ''
                          }`}
                        >
                          <label className="formField__label">
                            Highest Education
                          </label>
                          <Autocomplete
                            id="higherEducation"
                            options={HigherEducationArr}
                            value={fields?.higherEducation}
                            name="higherEducation"
                            onChange={(e, newValue) =>
                              handleChange(e, newValue, 'higherEducation')
                            }
                            freeSolo
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  variant="outlined"
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="filled"
                                placeholder="Select"
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    higherEducation: e?.target?.value
                                  });
                                }}
                                className="autoCompleteInput"
                              />
                            )}
                          />
                          <span className="formField__error">
                            {errors?.higherEducation}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.institutionNameError ? 'fieldError' : ''
                          }`}
                        >
                          <label className="formField__label">
                            Name of Institution
                          </label>
                          <div className="formField__group sm">
                            <input
                              type="text"
                              className="formField__input"
                              onChange={(e) => handleChange(e)}
                              name="institutionName"
                              value={fields?.institutionName ?? ''}
                              placeholder="Name of Institution"
                            />
                          </div>
                          <span className="formField__error">
                            {errors?.institutionName}
                          </span>
                        </div>
                      </div>
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.graduationYearError ? 'fieldError' : ''
                          }`}
                        >
                          <label className="formField__label">
                            Graduation Year
                          </label>
                          <input
                            type="text"
                            className="formField__input sm"
                            placeholder="Graduation Year"
                            name="graduationYear"
                            maxLength="4"
                            value={fields?.graduationYear ?? ''}
                            onChange={(e) => handleChange(e)}
                          />
                          <span className="formField__error">
                            {errors?.graduationYear}
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="form__section">
                    <h4 className="form__section--title">
                      Professional Details
                    </h4>
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.totalExperienceError ? 'fieldError' : ''
                          }`}
                        >
                          <label className="formField__label">Experience</label>
                          <input
                            type="text"
                            maxLength="2"
                            className="formField__input"
                            onChange={(e) => handleChange(e)}
                            name="totalExperience"
                            value={fields?.totalExperience ?? ''}
                            placeholder="Total experience"
                          />
                          <span className="formField__error">
                            {errors?.totalExperience}
                          </span>
                        </div>
                      </div>
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.linkedInAccountError ? 'fieldError' : ''
                          }`}
                        >
                          <label className="formField__label">
                            LinkedIn Account
                          </label>
                          <div className="formField__group sm">
                            <input
                              type="text"
                              className="formField__input sm"
                              placeholder="LinkedIn Account"
                              name="linkedInAccount"
                              value={fields?.linkedInAccount ?? ''}
                              onChange={(e) => handleChange(e)}
                            />
                            <span className="formField__icon">
                              <LinkedinIcon />
                            </span>
                          </div>
                          <span className="formField__error">
                            {errors?.linkedInAccount}
                          </span>
                        </div>
                      </div>
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.candidateRoleError ? 'fieldError' : ''
                          }`}
                        >
                          <label className="formField__label">Role</label>
                          <div className="formField__group sm">
                            <input
                              type="text"
                              className="formField__input sm"
                              placeholder="Role"
                              name="candidateRole"
                              value={fields?.candidateRole ?? ''}
                              onChange={(e) => handleChange(e)}
                            />
                            <span className="formField__icon">
                              <LinkedinIcon />
                            </span>
                          </div>
                          <span className="formField__error">
                            {errors?.candidateRole}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="formRow">
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.candidateSkillsetError ? 'fieldError' : ''
                          }`}
                        >
                          <label className="formField__label">Skill set</label>
                          <Autocomplete
                            multiple
                            id="tags-filled"
                            options={SkillSetData}
                            defaultValue={SkillSetArr}
                            name="candidateSkillset"
                            onChange={(e, newValue) =>
                              handleChange(e, newValue, 'candidateSkillset')
                            }
                            freeSolo
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  variant="outlined"
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="filled"
                                placeholder="Select"
                                className="autoCompleteInput"
                              />
                            )}
                          />
                          <span className="formField__error">
                            {errors?.candidateSkillset}
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>
                </form>
              </div>
            </div>
          </div>
          <SuccessfullyModal
            openModal={openSuccessfullModal}
            closeModal={closeSucessfullModal}
            Heading={SuccessfullHeading}
            sucessfullMsg={successfullMsg}
          />
          <ErrorModal
            openModal={openErrorModal}
            errorMessage={errorMsgs}
            closeModal={closeErrorModal}
          />
        </div>
      ) : (
        <div className="no-data">No Results Found!</div>
      )}
    </>
  );
};
