import React, { useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Radio } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import Autocomplete from '@mui/material/Autocomplete';
import { getCompanyList } from '../services/settings-service/tags/tags-service';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';

export default function CustomerTagFilter({
  setCompanyFilterValue,
  setCreatedByFilterValue,
  CompanyFilterValue,
  CreatedByFilterValue,
  handleApplyFilter,
  setCompanyIdArray,
  setCreatedByIdArray,
  setFilterAnchorEl,
  filteranchorEl,
  handleClearFilter,
  setSelectedRadioValue,
  SelectedRadioValue
}) {
  const [CreatedByFilterOptions, setCreatedByFilterOptions] = useState([]);
  const [CompanyFilterOptions, setCompanyFilterOptions] = useState([]);

  const filterOpen = Boolean(filteranchorEl);
  const filterPopoverid = filterOpen ? 'simple-popover' : undefined;

  useEffect(() => {
    getFilterCompanyList();
  }, []);

  useEffect(() => {}, []);

  const getFilterCompanyList = async () => {
    try {
      const response = await getCompanyList();
      const { data, meta } = response;
      if (meta?.status) {
        setCompanyFilterOptions(data);
      }
    } catch (error) {
      console.log('axios error --->', error);
    }
  };

  useEffect(() => {
    if (CompanyFilterValue.length > 0) {
      const filteredEmployees = CompanyFilterValue.flatMap((company) =>
        company.employees.map((employee) => ({
          firstName: employee.firstName,
          lastName: employee.lastName,
          employeeId: employee.employeeId
        }))
      );
      const employeeArray = CreatedByFilterValue?.map(
        (employee) => employee.employeeId
      );
      setCreatedByFilterOptions(filteredEmployees);
      setCreatedByIdArray(employeeArray);
    } else {
      setCreatedByFilterOptions([]);
      setCreatedByIdArray([]);
    }
  }, [CompanyFilterValue, CreatedByFilterValue]);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleRadioChange = (e) => {
    setSelectedRadioValue(e?.target?.value);
  };

  const handleCompanyFilterChange = (e, value) => {
    const companyArray = value?.map((company) => company.id);
    setCompanyFilterValue(value);
    setCompanyIdArray(companyArray);
  };

  const handleFilterCount = () => {
    let filterCount = 0;
    if (CompanyFilterValue?.length) {
      filterCount += 1;
    }
    if (CreatedByFilterValue.length) {
      filterCount += 1;
    }
    return filterCount > 0 ? filterCount : '';
  };

  return (
    <div className="customMultiSelect">
      <button
        className="btn btn__white p_0"
        aria-describedby={filterPopoverid}
        onClick={handleFilterClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M18.3337 2.5H1.66699L8.33366 10.3833V15.8333L11.667 17.5V10.3833L18.3337 2.5Z"
            stroke="#150823"
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        {CompanyFilterValue?.length || CreatedByFilterValue.length ? (
          <span className="tagCount">{handleFilterCount()}</span>
        ) : (
          ''
        )}
      </button>
      <Popover
        id={filterPopoverid}
        open={filterOpen}
        anchorEl={filteranchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        classes={{ root: 'filterPopover customerFilterWrap' }}
      >
        <div className="customerFilter">
          <div className="col">
            <h3 className="heading">Filter By</h3>
            <div className="customerFilter__body">
              <ul className="menuPopover__list">
                <li className="menuPopover__list--item">
                  <FormControl>
                    <RadioGroup>
                      <FormControlLabel
                        name="Company"
                        value="1"
                        checked={SelectedRadioValue === '1' ? true : false}
                        control={<Radio />}
                        label="Company"
                        className={SelectedRadioValue === '1' ? 'selected' : ''}
                        onChange={(e) => handleRadioChange(e)}
                      />
                      <FormControlLabel
                        name="Created By"
                        control={<Radio />}
                        value="2"
                        label="Created By"
                        className={SelectedRadioValue === '2' ? 'selected' : ''}
                        checked={SelectedRadioValue === '2' ? true : false}
                        onChange={(e) => handleRadioChange(e)}
                        disabled={CompanyFilterValue?.length === 0}
                      />
                    </RadioGroup>
                  </FormControl>
                </li>
              </ul>
            </div>
          </div>
          <div className="col">
            <h3 className="heading">
              {SelectedRadioValue === '1' ? 'Select Company' : 'Created By'}
            </h3>
            <div className="customerFilter__body">
              {SelectedRadioValue === '1' ? (
                <div className="formField">
                  <label className="formField__label">
                    <b>Filter</b>
                  </label>
                  <label className="formField__label">Company</label>
                  <Autocomplete
                    multiple
                    id="company"
                    options={CompanyFilterOptions ? CompanyFilterOptions : []}
                    getOptionLabel={(option) => option?.companyName || option}
                    value={CompanyFilterValue}
                    name="company"
                    onChange={(e, newValue) => {
                      handleCompanyFilterChange(e, newValue);
                    }}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option?.companyName}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        placeholder="Select"
                        className="autoCompleteInput"
                      />
                    )}
                  />
                </div>
              ) : (
                <div className="formField">
                  <label className="formField__label">
                    <b>Filter</b>
                  </label>
                  <label className="formField__label">Created By</label>
                  <Autocomplete
                    multiple
                    id="createdBy"
                    options={
                      CreatedByFilterOptions ? CreatedByFilterOptions : []
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.employeeId === value.employeeId
                    }
                    getOptionLabel={(option) =>
                      `${option.firstName} ${option.lastName}` || option
                    }
                    value={CreatedByFilterValue}
                    name="createdBy"
                    onChange={(e, newValue) => {
                      setCreatedByFilterValue(newValue);
                    }}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={`${option.firstName} ${option.lastName}`}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        placeholder="Select"
                        className="autoCompleteInput"
                      />
                    )}
                  />
                </div>
              )}

              <div className="btn__group">
                <button className="btn btn__white" onClick={handleClearFilter}>
                  Clear
                </button>
                <button
                  className="btn btn__primary"
                  disabled={
                    !CompanyFilterValue?.length && !CreatedByFilterValue?.length
                  }
                  onClick={handleApplyFilter}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
}
