import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { GetInterviewDetails, GetUserDetails } from '@/helper';
import { addScheduledInterviewData } from '@/services/interview-service/interview-service';
import ThumbUp from '@/assets/svgs/ThumbUp';

const ThankYou = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [companyId, setCompanyId] = useState(GetInterviewDetails()?.companyId);
  const [recruiterId, setRecruiterId] = useState(
    GetInterviewDetails()?.recruiterId
  );

  useEffect(() => {
    getMeetingData();
  }, []);

  const navigateToInterviews = () => {
    navigate('/interviews', {
      state: { companyId: companyId, recruiterId: recruiterId }
    });
  };

  const getMeetingData = async () => {
    let sdate = searchParams.get('start_time');
    let start = new Date(0);
    start.setUTCSeconds(sdate);

    let edate = searchParams.get('end_time');
    let end = new Date(0);
    end.setUTCSeconds(edate);

    let meetingInfo = {
      account_id: searchParams.get('account_id'),
      additional_values: JSON.parse(searchParams.get('additional_values')),
      calendar_id: searchParams.get('calendar_id'),
      edit_hash: searchParams.get('edit_hash'),
      email: searchParams.get('email'),
      end_time: new Date(end).toISOString(),
      event_id: searchParams.get('event_id'),
      location: searchParams.get('location'),
      name: searchParams.get('name'),
      page_slug: searchParams.get('page_slug'),
      start_time: new Date(start).toISOString(),
      tz: searchParams.get('tz')
    };

    let interviewObj = {
      jobId: null,
      candidateId: null,
      companyId: GetInterviewDetails()?.recruiterId
        ? null
        : GetInterviewDetails()?.companyId,
      recruiterId: GetInterviewDetails()?.recruiterId
        ? GetInterviewDetails()?.recruiterId
        : null,
      employeeId: GetInterviewDetails()?.recruiterId
        ? null
        : GetInterviewDetails()?.employeeId,
      adminId: GetUserDetails()?.adminId,
      meetingInfo: meetingInfo,
      meeting_start_time: new Date(start).toISOString(),
      meeting_end_time: new Date(end).toISOString(),
      meetingId: GetInterviewDetails()?.meetingId,
      title: GetInterviewDetails()?.meetingTitle
    };

    const response = await addScheduledInterviewData(interviewObj);
    const { meta, data } = response;
    if (meta?.status) {
      localStorage.removeItem('interview');
    }
  };

  return (
    <>
      <div className="container__fullScreen bg--light">
        <div className={`modal modalSuccessfull modal--open`}>
          <div className="modal__dialog">
            <div className="modal__content">
              <div className="modal__header">
                <div className="modal__header--logo">
                  <ThumbUp />
                </div>
                <h2 className="modal__header--title">Thank You</h2>
              </div>
              <div className="modal__body">
                <h4 className="promptMessage">
                  Thanks for scheduling the interview
                </h4>
              </div>
              <div className="modal__footer">
                <div className="btn__group">
                  <button
                    onClick={() => navigateToInterviews()}
                    className="btn btn__primary"
                  >
                    View meetings
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
