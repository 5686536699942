import React, { useEffect, useState, useCallback, useContext } from 'react';
import CustomPagination from '@/elements/pagination/customPagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getcandidateList } from '@/services/billing-service/billing-service';
import GoTOPage from '@/elements/gotopage/gotopage';
import { BillingAmountModal } from '@/components/modals/billingAmount-modal';
import { PreviewInvoiceModal } from '@/components/modals/previewInvoice-modal';
import MultiSelectFilter from '@/components/filter';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { firstLetterCapital } from '@/helper';
import { LoaderContext } from '@/components/Loader/Loader';
import BackArrow from '@/assets/svgs/BackArrow';
import Search from '@/assets/svgs/Search';
import Eye from '@/assets/images/eye.png';
import Edit from '@/assets/images/edit.png';

export const CandidateBilling = ({
  SelectedJobId,
  JobTitle,
  addResponsiveClass,
  setAddResponsiveClass,
  JobsListing = []
}) => {
  const setLoading = useContext(LoaderContext);
  const [BillingAmount, setBillingAmount] = useState('');
  const [filteranchorEl, setFilterAnchorEl] = useState(null);
  const [searchanchorEl, setSeachAnchorEl] = useState(null);
  const [CandidateList, setCandidateList] = useState([]);
  const [OpenAddAmountModal, setOpenAddAmountModal] = useState(false);
  const [SelectedBillingStatus, setSelectedBillingStatus] = useState('');
  const [sendInvoiceCall, setSendInvoiceCall] = useState(false);
  const [OpenPreviewInvoiceModal, setOpenPreviewInvoiceModal] = useState(false);
  const [BillingFilterStatus, setBillingFilterStatus] = useState([
    'Billed',
    'Unbilled'
  ]);
  const [ScreeningId, setScreeningId] = useState('');
  const [BillingInvoice, setBillingInvoice] = useState('');

  const [pagination, setPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0,
    search: '',
    status: ''
  });

  useEffect(() => {
    if (SelectedJobId) {
      getCandidateListing();
    }
  }, [SelectedJobId]);

  useEffect(() => {
    if (sendInvoiceCall) {
      getCandidateListing();
    }
  }, [sendInvoiceCall]);

  const changePage = (event, value) => {
    setPagination({ ...pagination, currentPage: value });
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
    getCandidateListing(
      pagination?.recordsPerPage,
      pagination?.currentPage,
      pagination?.search,
      [],
      SelectedJobId
    );
  };

  const handleStatusFilterChange = (selectedOption) => {
    setSelectedBillingStatus(selectedOption);
    getCandidateListing(
      pagination?.recordsPerPage,
      pagination?.currentPage,
      pagination?.search,
      selectedOption,
      SelectedJobId
    );
  };

  const getCandidateListing = async (
    recordsPerPage = pagination?.recordsPerPage,
    currentPage = pagination?.currentPage,
    search = pagination?.search,
    status = pagination?.status,
    jobId = SelectedJobId
  ) => {
    setLoading(true);
    const data = await getcandidateList(
      recordsPerPage,
      currentPage,
      search,
      status,
      jobId
    );
    setPagination((prev) => ({
      ...prev,
      totalRecord: data?.count ? data?.count : 0
    }));
    setCandidateList(data?.rows);
    setLoading(false);
  };

  const searchCandidate = useCallback(
    _.debounce(
      (searchText, jobId) =>
        getCandidateListing(
          pagination?.recordsPerPage,
          1,
          searchText,
          pagination?.status,
          jobId
        ),
      500
    ),
    []
  );

  const handleCloseAmountModal = () => {
    setOpenAddAmountModal(false);
  };

  return (
    <>
      {JobsListing?.length ? (
        <div
          className={`layoutGrid__col layoutGrid--responsive  ${
            addResponsiveClass ? 'layoutGrid--show' : ''
          }`}
        >
          <div className="dataCol">
            <div className="page page__full">
              <div className="page__head">
                <h2 className="page__head--title">
                  <button
                    className="btn btn__transparent btn__back border_0 p_0"
                    onClick={() => setAddResponsiveClass(false)}
                  >
                    <BackArrow />
                  </button>
                  {JobTitle}
                </h2>
              </div>

              <div className="page__body">
                <div className="tabContainer tabContainer--inner">
                  <div className="tabContainer__item bpContainer">
                    <div className="tabContainer__item--head">
                      <div className="listCol__head--actions w_100">
                        <div className="formField formFieldSearch">
                          <div className="formField__group sm">
                            <span className="formField__icon">
                              <Search />
                            </span>
                            <input
                              type="text"
                              className="formField__input"
                              placeholder="Search a candidate"
                              onChange={(event) =>
                                searchCandidate(
                                  event?.target?.value,
                                  SelectedJobId
                                )
                              }
                            />
                          </div>
                        </div>
                        <MultiSelectFilter
                          labels={BillingFilterStatus}
                          applyFilter={handleStatusFilterChange}
                          clearFilter={handleFilterClose}
                        />
                      </div>
                    </div>
                    <TableContainer
                      component={Paper}
                      className="tableContainer"
                    >
                      <Table className="table">
                        <TableHead>
                          <TableRow>
                            <TableCell>S.</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Email address</TableCell>
                            <TableCell>Approved on</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        {CandidateList?.length ? (
                          <TableBody>
                            {CandidateList?.map((row, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell>
                                    <div className="profile">
                                      {/* Profile Initials */}
                                      <div className="profile__initials">
                                        {row?.candidate?.firstName
                                          ?.charAt(0)
                                          .toUpperCase()}
                                      </div>
                                      <span className="profile__name">
                                        {firstLetterCapital(
                                          row?.candidate?.firstName
                                        ) +
                                          ' ' +
                                          firstLetterCapital(
                                            row?.candidate?.lastName
                                          )}
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="cellText">
                                      <span className="cellText__dark">
                                        {row?.candidate?.email
                                          ? row?.candidate?.email
                                          : '--'}
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="cellText">
                                      <span className="cellText__dark">
                                        {moment(row?.updatedAt).format(
                                          'D MMM , YYYY'
                                        )}
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="cellText">
                                      <span
                                        className={
                                          row?.billStatus === false
                                            ? 'cellText__dark text--danger'
                                            : 'cellText__dark text--primary'
                                        }
                                      >
                                        {row?.billStatus
                                          ? 'Billed'
                                          : 'Unbilled'}
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="actions flex--end">
                                      {/* edit icon */}
                                      {row?.invoiceLink &&
                                        !row?.invoiceSent && (
                                          <button
                                            className="btn btn__white btn--sm"
                                            onClick={() => {
                                              setOpenAddAmountModal(true);
                                              setScreeningId(row?.sreeningId);
                                            }}
                                          >
                                            {/* <EditIcon/> */}
                                            <img
                                              src={Edit}
                                              alt="Edit"
                                              width="18"
                                              height="18"
                                            />
                                          </button>
                                        )}
                                      {/* eye icon */}
                                      {row?.invoiceSent && row?.invoiceLink && (
                                        <Link
                                          className="btn btn__primary btn__outlined btn--sm"
                                          to={row?.invoiceLink}
                                          style={{ cursor: 'pointer' }}
                                          target="_blank"
                                        >
                                          {/* <EyeIcon/> */}
                                          <img
                                            src={Eye}
                                            alt="Eye"
                                            width="18"
                                            height="18"
                                          />
                                        </Link>
                                      )}
                                      {/* send sentbutton */}
                                      {row?.invoiceLink && (
                                        <button
                                          className={`btn btn__white w_fitContent ${
                                            row?.invoiceSent && row?.invoiceLink
                                              ? 'formField__disabled'
                                              : ''
                                          }`}
                                          onClick={() => {
                                            if (!row?.invoiceSent) {
                                              setOpenPreviewInvoiceModal(true);
                                              setScreeningId(row?.sreeningId);
                                              setBillingInvoice(
                                                row?.invoiceLink
                                              );
                                            }
                                          }}
                                        >
                                          {row?.invoiceSent ? 'Sent' : 'Send'}
                                        </button>
                                      )}
                                      {!row?.invoiceLink && (
                                        <button
                                          className="btn btn__white"
                                          onClick={() => {
                                            setOpenAddAmountModal(true);
                                            setScreeningId(row?.sreeningId);
                                          }}
                                        >
                                          Approve
                                        </button>
                                      )}
                                    </div>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        ) : (
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <div className="no-data floating">
                                  No Results Found!
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>

                    <div className="pagination">
                      <div className="pagination__result">{`${
                        pagination?.totalRecord >
                        pagination?.currentPage * pagination?.recordsPerPage
                          ? pagination?.recordsPerPage
                          : pagination?.totalRecord
                      } of ${pagination?.totalRecord} results`}</div>
                      {pagination?.totalRecord ? (
                        <CustomPagination
                          totalPageCount={Math.ceil(
                            pagination?.totalRecord / pagination.recordsPerPage
                          )}
                          page={pagination?.currentPage}
                          changePage={(event, value) =>
                            changePage(event, value)
                          }
                        />
                      ) : (
                        ''
                      )}
                      {pagination?.totalRecord ? (
                        <div className="pagination__actions">
                          <GoTOPage changePage={changePage} />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="no-data">No Results Found!</div>
      )}
      <BillingAmountModal
        openModal={OpenAddAmountModal}
        handleCloseAmountModal={handleCloseAmountModal}
        ScreeningId={ScreeningId}
        SelectedJobId={SelectedJobId}
        getCandidateListing={getCandidateListing}
        setBillingAmount={setBillingAmount}
        BillingAmount={BillingAmount}
      />
      <PreviewInvoiceModal
        openModal={OpenPreviewInvoiceModal}
        closeModal={() => setOpenPreviewInvoiceModal(false)}
        ScreeningId={ScreeningId}
        SelectedJobId={SelectedJobId}
        onSendClick={true}
        getCandidateListing={getCandidateListing}
        InvoiceUrl={BillingInvoice}
        setBillingAmount={setBillingAmount}
      />
    </>
  );
};
