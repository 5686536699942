import React, { useState, useEffect } from 'react';
import { SuccessfullyModal } from '@/components/modals/successfully-modal';
import ErrorModal from '@/components/modals/error-modal';
import ConfirmationModal from '@/components/modals/confirmation-modal';
import {
  getJobsQuestionaire,
  addJobsQuestionaire
} from '@/services/job-service/job-service';
import * as MESSAGES from '@/utils/popupMessages';

const JobQuestionnaire = ({ jobId }) => {
  const [questions, setquestions] = useState([]);
  const [isOpenIndex, setIsOpenIndex] = useState(0);
  const [newQuestion, setNewQuestion] = useState('');
  const [editQues, setEditQues] = useState('');
  const [editQuesIndex, setEditQuesIndex] = useState('');
  const [OpenSuccessfullModal, setOpenSuccessfullModal] = useState(false);
  const [OpenErrorModal, setOpenErrorModal] = useState(false);
  const [OpenConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [SuccessfullMsg, setSuccessfullMsg] = useState('');
  const [SuccessfullHeading, setSuccessfullHeading] = useState('');
  const [ErrorMsg, setErrorMsg] = useState('');
  const [DeleteQuestionIndex, setDeleteQuestionIndex] = useState(0);

  const closeSucessfullModal = () => {
    setOpenSuccessfullModal(false);
  };
  const closeErrorModal = () => {
    setOpenErrorModal(false);
  };
  const closeConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  useEffect(() => {
    getJobQuestions();
  }, [jobId]);

  const getJobQuestions = async () => {
    try {
      const response = await getJobsQuestionaire(jobId);
      const { data, meta } = response;
      if (meta?.status) {
        data?.addQuestionaire && setquestions(data?.addQuestionaire);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addOestion = async () => {
    const { message } = MESSAGES?.addQuestion;
    if (newQuestion.trim()) {
      let questionnaireArr = questions;
      questionnaireArr.push(newQuestion);
      try {
        const response = await addJobsQuestionaire(jobId, questionnaireArr);
        const { meta } = response;
        if (meta?.status) {
          setOpenSuccessfullModal(true);
          setSuccessfullHeading(message?.heading);
          setSuccessfullMsg(message?.successfull);
          setNewQuestion('');
          getJobQuestions();
        } else {
          setOpenErrorModal(true);
          setErrorMsg(response?.meta?.msg);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setOpenErrorModal(true);
      setErrorMsg(message?.errorMsg);
      setNewQuestion('');
    }
  };

  const editQuetion = async (index) => {
    let quesArr = questions;
    quesArr[editQuesIndex] = editQues;
    try {
      const response = await addJobsQuestionaire(jobId, quesArr);
      const { meta } = response;
      const { message } = MESSAGES?.updateQuestion;
      if (meta?.status) {
        setOpenSuccessfullModal(true);
        setSuccessfullHeading(message?.heading);
        setSuccessfullMsg(message?.successfull);
        setIsOpenIndex(0);
        getJobQuestions();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editQuestionnaire = (value, index) => {
    setEditQues(value);
    setEditQuesIndex(index);
  };

  const deleteQuestion = async (index) => {
    let quesArr = questions;
    quesArr.splice(DeleteQuestionIndex, 1);

    try {
      const response = await addJobsQuestionaire(jobId, quesArr);
      const { meta } = response;
      const { message } = MESSAGES?.deleteQuestion;
      if (meta?.status) {
        setIsOpenIndex(0);
        getJobQuestions();
        setOpenConfirmationModal(false);
        setOpenSuccessfullModal(true);
        setSuccessfullHeading(message?.heading);
        setSuccessfullMsg(message?.successfull);
      } else {
        setOpenErrorModal(true);
        setErrorMsg(response?.meta?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="page">
        {questions?.map((item, index) => (
          <React.Fragment key={`question-${index}`}>
            <div className="flexRow flexRow__list">
              <div className="formField__label">{item}</div>
              <button
                className="btn btn__white btn--sm"
                onClick={(e) => {
                  setIsOpenIndex(index + 1);
                  setEditQues(item);
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 16.6667H17.5M13.75 2.91669C14.0815 2.58517 14.5312 2.39893 15 2.39893C15.2321 2.39893 15.462 2.44465 15.6765 2.53349C15.891 2.62233 16.0858 2.75254 16.25 2.91669C16.4142 3.08085 16.5444 3.27572 16.6332 3.4902C16.722 3.70467 16.7678 3.93455 16.7678 4.16669C16.7678 4.39884 16.722 4.62871 16.6332 4.84319C16.5444 5.05766 16.4142 5.25254 16.25 5.41669L5.83333 15.8334L2.5 16.6667L3.33333 13.3334L13.75 2.91669Z"
                    stroke="#150823"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <button
                className="btn btn__danger btn--sm"
                onClick={() => {
                  setOpenConfirmationModal(true);
                  setDeleteQuestionIndex(index);
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.5 4.99984H4.16667M4.16667 4.99984H17.5M4.16667 4.99984V16.6665C4.16667 17.1085 4.34226 17.5325 4.65482 17.845C4.96738 18.1576 5.39131 18.3332 5.83333 18.3332H14.1667C14.6087 18.3332 15.0326 18.1576 15.3452 17.845C15.6577 17.5325 15.8333 17.1085 15.8333 16.6665V4.99984H4.16667ZM6.66667 4.99984V3.33317C6.66667 2.89114 6.84226 2.46722 7.15482 2.15466C7.46738 1.8421 7.89131 1.6665 8.33333 1.6665H11.6667C12.1087 1.6665 12.5326 1.8421 12.8452 2.15466C13.1577 2.46722 13.3333 2.89114 13.3333 3.33317V4.99984M8.33333 9.1665V14.1665M11.6667 9.1665V14.1665"
                    stroke="#F04438"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            {isOpenIndex == index + 1 ? (
              <div className="flexRow flexRow__list">
                <input
                  name={`editQues${index}`}
                  type="text"
                  className="formField__input sm"
                  placeholder={item}
                  value={editQues}
                  onChange={(e) =>
                    editQuestionnaire(e?.target?.value.trimStart(), index)
                  }
                />
                <button
                  className="btn btn__success btn--sm"
                  disabled={!editQues}
                  onClick={() => editQuetion(index)}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.6663 5L7.49967 14.1667L3.33301 10"
                      stroke="white"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            ) : (
              ''
            )}
          </React.Fragment>
        ))}
        <div className="flexRow flexRow__list">
          <input
            type="text"
            className="formField__input sm"
            placeholder="Enter your question here..."
            value={newQuestion}
            onChange={(e) => setNewQuestion(e?.target?.value.trimStart())}
          />
          {
            <button
              className="btn btn__primary btn--sm"
              onClick={() => addOestion()}
              disabled={newQuestion.trim() ? false : true}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.0003 4.1665V15.8332M4.16699 9.99984H15.8337"
                  stroke="white"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          }
        </div>
      </div>
      <SuccessfullyModal
        openModal={OpenSuccessfullModal}
        closeModal={closeSucessfullModal}
        Heading={SuccessfullHeading}
        sucessfullMsg={SuccessfullMsg}
      />
      <ErrorModal
        openModal={OpenErrorModal}
        closeModal={closeErrorModal}
        errorMessage={ErrorMsg}
      />
      <ConfirmationModal
        openModal={OpenConfirmationModal}
        closeModal={closeConfirmationModal}
        onSubmit={deleteQuestion}
        heading={MESSAGES?.deleteQuestion?.message?.confirmationHeading}
        confirmationMessage={MESSAGES?.deleteQuestion?.message?.confirmationMsg}
      />
    </>
  );
};

export default JobQuestionnaire;
