import React, { useState, useEffect, useContext } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { firstLetterCapital } from '@/helper';
import moment from 'moment';
import { getJobDetails } from '@/services/job-service/job-service';
import { Link } from 'react-router-dom';
import DetailModal from '@/components/modals/DetailModal';
import { LoaderContext } from '@/components/Loader/Loader';

const JobDescription = ({ jobId }) => {
  const [jobDetails, setJobDetails] = useState({});
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [data, setData] = useState({});
  const setLoading = useContext(LoaderContext);

  useEffect(() => {
    getJobDeatils();
  }, [jobId]);

  const getJobDeatils = async () => {
    setLoading(true);
    const response = await getJobDetails(jobId);
    if (response?.meta?.status) {
      setJobDetails(response?.data);
    }
    setLoading(false);
  };

  const YourComponent = ({ htmlContent }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };

  const getCompensationValue = (job) => {
    if (job?.baseSalary === 'Range') {
      return `$ ${job?.minCompensation} - $ ${job?.maxCompensation} ${job?.frequency}`;
    } else if (job?.baseSalary === 'Starting at') {
      return `$ ${job?.minCompensation} ${job?.frequency}`;
    } else if (job?.baseSalary === 'Up to') {
      return `$ ${job?.maxCompensation} ${job?.frequency}`;
    } else if (job?.baseSalary === 'Exact Pay') {
      return `$ ${job?.maxCompensation} ${job?.frequency}`;
    } else {
      return `${'--'}`;
    }
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%' }}>
          {}
          <div className="page">
            <div className="fieldBox flexRow">
              <div className="flexCol">
                <label className="flexCol__label">Job Title</label>
                <div className="flexCol__text">{jobDetails?.title}</div>
              </div>
              <div className="flexCol">
                <label className="flexCol__label">Location</label>
                <div className="flexCol__text">{jobDetails?.location}</div>
              </div>
            </div>
            <div className="fieldBox flexRow">
              <div className="flexCol">
                <label className="flexCol__label">Required Qualification</label>
                <div className="flexCol__text">
                  {jobDetails?.qualification
                    ? jobDetails?.qualification.join(', ')
                    : '--'}
                </div>
              </div>
              <div className="flexCol">
                <label className="flexCol__label">Experience</label>
                <div className="flexCol__text">
                  {jobDetails?.minExperience}{' '}
                  {jobDetails?.minExperience > 1 ? 'Years' : 'Year'} -{' '}
                  {jobDetails?.maxExperience}{' '}
                  {jobDetails?.maxExperience > 1 ? 'Years' : 'Year'}
                </div>
              </div>
            </div>
            <div className="fieldBox flexRow">
              <div className="flexCol">
                <label className="flexCol__label">Employment Type</label>
                <div className="flexCol__text">
                  {jobDetails?.employmentType
                    ? jobDetails?.employmentType.join(', ')
                    : '--'}
                </div>
              </div>
              <div className="flexCol">
                <label className="flexCol__label">Number of Hires</label>
                <div className="flexCol__text">{jobDetails?.noOfHires}</div>
              </div>
            </div>
            <div className="fieldBox flexRow">
              <div className="flexCol">
                <label className="flexCol__label">Base Salary</label>
                <div className="flexCol__text">
                  {getCompensationValue(jobDetails)}
                </div>
              </div>
              <div className="flexCol">
                <label className="flexCol__label">Recruiter Assigned</label>
                <div className="flexCol__text">
                  {jobDetails?.assignedStatus
                    ? firstLetterCapital(jobDetails?.recruiter?.firstName) +
                      ' ' +
                      firstLetterCapital(jobDetails?.recruiter?.lastName)
                    : '--'}
                </div>
              </div>
            </div>

            <div className="fieldBox flexRow">
              <div className="flexCol">
                <label className="flexCol__label">Application Deadline</label>
                <div className="flexCol__text">
                  {moment(jobDetails?.applicationDeadline).format(
                    'D MMM , YYYY'
                  )}
                </div>
              </div>
              <div className="flexCol">
                <label className="flexCol__label">Department</label>
                <div className="flexCol__text">
                  {jobDetails?.department ? jobDetails?.department : '--'}
                </div>
              </div>
            </div>
            <div className="fieldBox flexRow">
              <div className="flexCol">
                <label className="flexCol__label">Job Team Member</label>
                {jobDetails?.jobTeamMember?.map((jtm, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Link
                        className="link link__primary link__center"
                        onClick={(e) => {
                          setOpenDetailModal(true);
                          setData(jtm);
                        }}
                      >
                        {firstLetterCapital(jtm?.firstName) +
                          ' ' +
                          firstLetterCapital(jtm?.lastName)}
                      </Link>
                      {index < jobDetails.jobTeamMember.length - 1 && ', '}
                    </React.Fragment>
                  );
                })}
              </div>
              <div className="flexCol">
                <label className="flexCol__label">Hiring Lead</label>
                <Link
                  className="link link__primary link__center"
                  onClick={(e) => {
                    setOpenDetailModal(true);
                    setData(jobDetails?.hiringLead[0]);
                  }}
                >
                  {jobDetails?.hiringLead
                    ? firstLetterCapital(jobDetails?.hiringLead[0]?.firstName) +
                      ' ' +
                      firstLetterCapital(jobDetails?.hiringLead[0]?.lastName)
                    : '--'}
                </Link>
              </div>
            </div>

            <div className="fieldBox">
              <label className="flexCol__label">Skill set</label>
              <div className="flexCol__text">
                {jobDetails?.skillSet ? jobDetails?.skillSet?.join(', ') : '--'}
              </div>
            </div>

            <div className="fieldBox">
              <label className="flexCol__label">Job Description</label>
              <div className="showQuillTags">
                <YourComponent htmlContent={jobDetails?.description} />
              </div>
            </div>
          </div>
        </Paper>
      </Box>
      <DetailModal
        openModal={openDetailModal}
        closeModal={() => setOpenDetailModal(false)}
        data={data}
      />
    </>
  );
};

export default JobDescription;
