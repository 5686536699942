import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignIn from '@/pages/Signin/signin';
import ForgotPassword from '@/pages/Signin/forgot-password';
import CreatePassword from '@/pages/Signin/create-password';
import Customer from '@/pages/Customer/Customer';
import Dashboard from '@/pages/Dashboard/dashboard';
import Recruiters from '@/pages/recruiters/recruiters';
import JobListing from '@/pages/Jobs/job-listing';
import Jobs from '@/pages/Jobs/jobs';
import CandidateListing from '@/pages/Candidate/candidateListing';
import ProtectedRoute from '@/routes/ProtectedRoute';
import DashboardRoute from '@/routes/DashboardRoute';
import BillingPayments2 from '../pages/billing-payments/billing-payments2';
import { JobsBilling } from '../pages/billing-payments/Customer/jobs-billing';
import Interviews from '@/pages/interviews/interviews';
import ThankYou from '@/pages/interviews/thankyou';
import { ScheduleMeeting } from '@/pages/interviews/Meeting/meeting';
import AuditTrailListing from '@/pages/audit-trail/auditTrailListing';
import BillingpaymentsIndex from '@/pages/billing-payments/BillingpaymentsIndex';
import RecruiterJobsCandidateIndex from '@/pages/billing-payments/Recruiter/RecruiterJobsCandidateIndex';
import TotalOutstandingAmount from '@/pages/billing-payments/Recruiter/TotalOutstandingAmount';
import ReviewsAndRatingsDetailPage from '@/pages/reviews-ratings/ReviewsAndRatingsDetailPage';
import TagsIndex from '@/pages/Settings/Tags/';

export default function MainRoutes() {
  return (
    <Router>
      <Routes>
        <Route
          exact={true}
          path="/"
          element={
            <DashboardRoute>
              <SignIn />
            </DashboardRoute>
          }
        />
        <Route
          exact={true}
          path="/forgot-password"
          element={
            <DashboardRoute>
              <ForgotPassword />
            </DashboardRoute>
          }
        />
        <Route
          exact={true}
          path="/create-password"
          element={
            <DashboardRoute>
              <CreatePassword />
            </DashboardRoute>
          }
        />
        <Route
          exact={true}
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/customer-listing"
          element={
            <ProtectedRoute>
              <Customer />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/job-listing"
          element={
            <ProtectedRoute>
              <JobListing />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/recruiters"
          element={
            <ProtectedRoute>
              <Recruiters />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/candidate-listing"
          element={
            <ProtectedRoute>
              <CandidateListing />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/jobs"
          element={
            <ProtectedRoute>
              <Jobs />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/billing-payments"
          element={
            <ProtectedRoute>
              <BillingpaymentsIndex />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/reviews-ratings"
          element={
            <ProtectedRoute>
              <ReviewsAndRatingsDetailPage />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/recruiter-candidates-billing"
          element={
            <ProtectedRoute>
              <RecruiterJobsCandidateIndex />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/outstanding-amount"
          element={
            <ProtectedRoute>
              <TotalOutstandingAmount />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/billing-payments2"
          element={
            <ProtectedRoute>
              <BillingPayments2 />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/Jobs-billing"
          element={
            <ProtectedRoute>
              <JobsBilling />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/interviews"
          element={
            <ProtectedRoute>
              <Interviews />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/settings/tags"
          element={
            <ProtectedRoute>
              <TagsIndex />
            </ProtectedRoute>
          }
        />
        <Route
          // exact={true}
          path="/meeting/:meetingId"
          element={<ScheduleMeeting />}
        />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route
          exact={true}
          path="/audit-trail"
          element={
            <ProtectedRoute>
              <AuditTrailListing />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}
