import React, { useState, useEffect, useRef } from 'react';
import { CirclePicker, ChromePicker } from 'react-color';
import ColorPickerIcon from '@/assets/svgs/ColorPickerIcon';

const ColorPickersDropdown = ({
  open,
  handleColorChange,
  additionalClass = 'colorDiv',
  close,
  tagData = {},
  onSubmit = {}
}) => {
  const [color, setColor] = useState('#fff');
  const [showCustomColor, setShowCustomColor] = useState(false);

  const handleSelectedColor = (e) => {
    handleColorChange(e?.hex);
    setColor(e?.hex);
  };

  const handleChangeComplete = (color) => {
    handleColorChange(color.hex);
    setColor(color.hex);
  };
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      close();
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  return (
    <>
      <div
        ref={modalRef}
        className={`colorPickersDropdown ${open ? 'colorPickersDropdown--open' : ''}`}
      >
        <div className="colorPickersDropdown__row colorDiv">
          <div className="colorPickersDropdown__row__item">
            <div className="colorPickersDropdown__label">Select Colors:</div>
            <CirclePicker onChange={handleSelectedColor} />
            <div className="btnAddCustomColor">
              <button
                type="button"
                className="btnAddCustomColor__btn"
                onClick={() => setShowCustomColor(true)}
              >
                <ColorPickerIcon />+ Add Custom colors
              </button>
            </div>
          </div>
          <div
            className={`colorPickersDropdown__row__item AddCustomColorBox ${showCustomColor ? 'open' : ''}`}
          >
            <div className="colorPickersDropdown__label">
              Add Custom colors:
            </div>
            <ChromePicker
              color={color}
              onChangeComplete={handleChangeComplete}
            ></ChromePicker>
          </div>
        </div>
        <div className="btn__group">
          <button
            type="button"
            className="btn btn__white"
            onClick={() => close()}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn__primary"
            onClick={() => onSubmit({ ...tagData, colorCode: color })}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default ColorPickersDropdown;
