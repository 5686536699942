import React, { useState, useEffect, useContext } from 'react';
import {
  getCandidateFeedbackList11,
  changeCandidateStatus11
} from '@/services/screening-service/screening-service';
import { LoaderContext } from '@/components/Loader/Loader';
import { SuccessfullyModal } from '@/components/modals/successfully-modal';
import { GetUserDetails } from '@/helper';
import * as MESSAGES from '@/utils/popupMessages';

function JobSendFeedback({
  jobId,
  changeCandidateSendFeedback,
  candidateSreeningId
}) {
  const userDetails = GetUserDetails();
  const setLoading = useContext(LoaderContext);
  const [feedbackData, setFeedbackData] = useState([]);
  const [feedbackQusAns, setFeedbackQusAns] = useState([]);
  const [btnDisable, setBtnDisable] = useState(true);
  const [validationErrors, setValidationErrors] = useState({});
  const [openSuccessfullModal, setOpenSuccessfullModal] = useState(false);

  useEffect(() => {
    getFeedbackList();
  }, []);

  useEffect(() => {
    if (feedbackData?.length) {
      var newFeedbackArr = feedbackData.map(function (el) {
        var o = Object.assign({}, el);
        o.answer = '';
        return o;
      });
      setFeedbackQusAns(newFeedbackArr);
    }
  }, [feedbackData]);

  const getFeedbackList = async () => {
    setLoading(true);
    const response = await getCandidateFeedbackList11();
    const { meta, data } = response;
    if (meta?.status) {
      setFeedbackData(data);
    }
    setLoading(false);
  };

  const validateForm = (formData) => {
    const errors = {};
    formData.forEach((item, index) => {
      if (item?.answer?.trim() === '' && item.question !== 'Custom note?') {
        errors[index] = 'Answer is required.';
      } else {
        delete errors[index];
      }
    });
    setValidationErrors(errors);
    setBtnDisable(Object.keys(errors).length > 0);
  };

  const changeTextArea = (e, index) => {
    let quesArray = feedbackQusAns;
    quesArray[index]['answer'] = e?.target?.value;
    setFeedbackQusAns(quesArray);
    validateForm(quesArray);
  };

  const approveCandidate = async () => {
    const response = await changeCandidateStatus11(
      candidateSreeningId,
      jobId,
      'approved',
      [
        {
          id: userDetails?.adminId,
          firstName: userDetails?.firstName,
          lastName: userDetails?.lastName,
          role: 'Admin',
          status: 'Approved',
          feedBackData: feedbackQusAns
        }
      ]
    );
    const { meta, data } = response;
    if (meta?.status) {
      setOpenSuccessfullModal(true);
    }
  };

  return (
    <>
      <div className="dataCol__head feedback--head">
        <h4 className="details__section--title">
          {MESSAGES?.feedback?.message?.heading}
        </h4>
        <div className="formField__label">
          {MESSAGES?.feedback?.message?.text}
        </div>
      </div>
      <div className="p_2">
        <div className="formRow formRow__2">
          {feedbackData.map((item, index) => (
            <div className="formCol">
              <div className="formField">
                <label className="formField__label">{item?.question}</label>
                <textarea
                  className="formField__textarea"
                  rows={3}
                  onChange={(e) => changeTextArea(e, index)}
                ></textarea>
                {validationErrors[index] && (
                  <span className="formField__error">
                    {validationErrors[index]}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="btn__group flex--start">
          <button
            className="btn btn__white"
            onClick={() => changeCandidateSendFeedback(false)}
          >
            Back
          </button>
          <button
            className="btn btn__primary"
            disabled={btnDisable}
            onClick={() => {
              approveCandidate();
              setBtnDisable(true);
            }}
          >
            Approve
          </button>
        </div>
      </div>
      <SuccessfullyModal
        openModal={openSuccessfullModal}
        closeModal={() => {
          setOpenSuccessfullModal(false);
          changeCandidateSendFeedback(false);
        }}
        Heading={MESSAGES?.approveCandidate?.message?.heading}
        sucessfullMsg={MESSAGES?.approveCandidate?.message?.successfull}
      />
    </>
  );
}

export default JobSendFeedback;
