import React, { useRef, useState, useEffect, useContext } from 'react';
import SideBar from '@/components/layout/SideBar';
import { AddCandidate } from './add-candidate';
import { CandidateDetailPage } from './candidate-detailpage';
import { firstLetterCapital, formatPhoneNumber } from '@/helper';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import CustomPagination from '@/elements/pagination/customPagination';
import { LoaderContext } from '@/components/Loader/Loader';
import Search from '@/assets/svgs/Search';
import Filter from '@/assets/svgs/Filter';
import PlusIcon from '@/assets/svgs/PlusIcon';
import SearchCross from '@/assets/svgs/SearchCross';
import Dot from '@/assets/svgs/Dot';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { getCandidateListing } from '@/services/candidate-service/candidate-service';
import { getStaticData } from '@/services/static-data-service';

const CandidateListing = () => {
  const searchRef = useRef(null);
  const setLoading = useContext(LoaderContext);

  const [searchanchorEl, setSeachAnchorEl] = useState(null);
  const [filteranchorEl, setFilterAnchorEl] = useState(null);
  const [candidateList, setCandidateList] = useState([]);
  const [SelectedCandidateData, setSelectedCandidateData] = useState({});
  const [OpenAddCandiateScreen, setOpenAddCandiateScreen] = useState(false);
  const [skillSetData, setSkillSetData] = useState([]);
  const searchOpen = Boolean(searchanchorEl);
  const filterOpen = Boolean(filteranchorEl);
  const searchPopoverid = searchOpen ? 'simple-popover' : '';
  const filterPopoverid = filterOpen ? 'simple-popover' : '';
  const [EditCustomerMode, setEditCustomerMode] = useState(false);
  const [HigherEducationFilterValue, setHigherEducationFilterValue] =
    useState('');
  const [SkillsetFiltervalue, setSkillsetFiltervalue] = useState([]);
  const [HigherEducationArr, setHigherEducationArr] = useState([]);
  const [rangeValue, setRangeValue] = useState([0, 99]);
  const [rangeFilterApplied, setRangeFilterApplied] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [addResponsiveClass, setAddResponsiveClass] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0,
    search: '',
    skillSet: [],
    education: '',
    experience: [0, 99]
  });

  useEffect(() => {
    getComStaticData();
  }, []);

  useEffect(() => {
    getCandidateList();
  }, [pagination]);

  const changePage = (event, value) => {
    setPagination({ ...pagination, currentPage: value });
  };

  const handleSeachClick = (event) => {
    setSeachAnchorEl(event.currentTarget);
  };

  const handleSearchClose = () => {
    setPagination({ ...pagination, search: '', currentPage: 1 });
    searchRef.current.value = '';
    setSeachAnchorEl(null);
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const getCandidateList = async () => {
    let {
      recordsPerPage,
      currentPage,
      search,
      skillSet,
      education,
      experience
    } = pagination;
    setLoading(true);
    const response = await getCandidateListing({
      limit: recordsPerPage,
      page: currentPage,
      search,
      skillSet,
      education,
      experience
    });
    const { data, meta } = response;
    if (meta?.status) {
      setCandidateList(data?.rows);
      setTotalPageCount(Math.ceil(data?.count / pagination.recordsPerPage));
      if (data?.count) {
        onCandidateSelect(data?.rows[0], true);
      }
    }

    setLoading(false);
  };

  const searchCandidateListing = (e) => {
    setPagination({ ...pagination, search: e?.target?.value, currentPage: 1 });
  };

  const filterEduction = (e, newValue) => {
    setHigherEducationFilterValue(newValue);
  };

  const filterSkillset = (e, newValue) => {
    setSkillsetFiltervalue(
      typeof newValue === 'string'
        ? newValue.split(',')
          ? newValue === ''
          : null
        : newValue
    );
  };

  const filterExperience = (event, newValue) => {
    setRangeValue(newValue);
    setRangeFilterApplied(true);
  };

  const backToCandidateListing = () => {
    setOpenAddCandiateScreen(false);
    getCandidateList();
  };

  const getComStaticData = async () => {
    try {
      const response = await getStaticData();
      const { data, meta } = response;
      if (meta?.status) {
        setSkillSetData(data?.skillSet);
        setHigherEducationArr(data?.qualification);
      }
    } catch (error) {
      console.log('axios error --->', error);
    }
  };

  const onCandidateSelect = (candidate, fromListing) => {
    fromListing ? setAddResponsiveClass(false) : setAddResponsiveClass(true);
    setSelectedCandidateData(candidate);
    setEditCustomerMode(false);
    setOpenAddCandiateScreen(false);
  };

  const handleEditCustomerMode = () => {
    setEditCustomerMode(true);
    setOpenAddCandiateScreen(true);
  };

  const handleAddResponsiveClass = () => {
    setAddResponsiveClass(false);
  };

  const clearFilters = (e) => {
    setHigherEducationFilterValue('');
    setSkillsetFiltervalue([]);
    setRangeValue([0, 99]);
    setRangeFilterApplied(false);
    handleFilterClose();
    setPagination({
      currentPage: 1,
      recordsPerPage: 20,
      totalRecord: 0,
      search: '',
      skillSet: [],
      education: '',
      experience: [0, 99]
    });
  };

  const applyFilters = (e) => {
    setPagination({
      ...pagination,
      currentPage: 1,
      skillSet: SkillsetFiltervalue,
      education: HigherEducationFilterValue,
      experience: rangeValue
    });
    handleFilterClose();
  };

  const getFilterCount = () => {
    let count = 0;
    if (SkillsetFiltervalue?.length) {
      count++;
    }
    if (HigherEducationFilterValue) {
      count++;
    }
    if (rangeFilterApplied) {
      count++;
    }
    return count;
  };

  return (
    <div className="layoutContainer">
      <SideBar />

      <div className="layoutGrid">
        <div className="layoutGrid__col">
          <div
            className={`listCol ${
              filteranchorEl
                ? 'listCol__filter'
                : searchanchorEl
                  ? 'listCol__search'
                  : ''
            }`}
          >
            <div className="listCol__head listing__head">
              <h2 className="listCol__head--title">
                <span>Candidates</span>
              </h2>
              <div className="listCol__head--actions">
                <div className="formField">
                  <button
                    className="btn btn__white p_0"
                    aria-describedby={searchPopoverid}
                    onClick={handleSeachClick}
                  >
                    <Search />
                  </button>
                </div>

                <div>
                  <button
                    className="btn btn__white p_0"
                    aria-describedby={filterPopoverid}
                    onClick={handleFilterClick}
                  >
                    <Filter />
                  </button>
                </div>
                <button
                  className="btn btn__white p_0"
                  disabled={EditCustomerMode ? true : false}
                  onClick={() => {
                    setAddResponsiveClass(true);
                    setOpenAddCandiateScreen(true);
                  }}
                >
                  <PlusIcon />
                </button>
              </div>

              <div className="searchContainer">
                <div className="formField">
                  <div className="formField__group sm">
                    <span className="formField__icon">
                      <Search />
                    </span>
                    <input
                      type="text"
                      ref={searchRef}
                      className="formField__input"
                      placeholder="Search candidate"
                      onChange={(e) => searchCandidateListing(e)}
                    />
                  </div>
                </div>

                <button
                  className="btn btn__transparent border_0 p_0"
                  onClick={handleSearchClose}
                >
                  <SearchCross />
                </button>
              </div>
            </div>

            <div className="filter__head">
              <h2 className="listCol__head--title">Filter</h2>
              <button
                className="btn btn__transparent border_0 p_0"
                onClick={handleFilterClose}
              >
                <SearchCross />
              </button>
            </div>

            <div className="filterContainer">
              <ul className="listCol__list filter__list">
                <li>
                  <div className="formRow">
                    <div className="formCol">
                      <div className="formField">
                        <label className="formField__label">
                          Highest Education
                        </label>
                        <Autocomplete
                          id="higherEducation"
                          options={HigherEducationArr}
                          value={HigherEducationFilterValue}
                          name="higherEducation"
                          onChange={(e, newValue) =>
                            filterEduction(e, newValue)
                          }
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="filled"
                              placeholder="Select"
                              onChange={(e) =>
                                setHigherEducationFilterValue(e?.target?.value)
                              }
                              className="autoCompleteInput"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="formCol">
                      <div className="formField">
                        <label className="formField__label">Skill set</label>
                        <Autocomplete
                          multiple
                          id="tags-filled"
                          options={skillSetData}
                          defaultValue={SkillsetFiltervalue}
                          name="candidateSkillset"
                          onChange={(e, newValue) =>
                            filterSkillset(e, newValue)
                          }
                          freeSolo
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="filled"
                              placeholder="Select"
                              className="autoCompleteInput"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="formCol">
                      <div className="formField">
                        <label className="formField__label flex flex--between">
                          Experience (years)
                          <span>
                            {rangeValue[0] + '-' + rangeValue[1]} years
                          </span>
                        </label>
                        <Box
                        //fullWidth sx={{ padding: "0 4px" }}
                        >
                          <Slider
                            value={rangeValue}
                            onChange={filterExperience}
                            valueLabelDisplay="auto"
                            min={0}
                            max={99}
                          />
                        </Box>
                        <label
                          className="formField__label flex flex--between"
                          style={{ display: 'flex' }}
                        >
                          0<span>99</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

              <div className="filter__footer">
                <div className="btn__group flex--between">
                  <button
                    className="btn btn__transparent p_0"
                    onClick={clearFilters}
                  >
                    Clear
                  </button>
                  <button className="btn btn__primary" onClick={applyFilters}>
                    Apply
                  </button>
                </div>
              </div>
            </div>
            {SkillsetFiltervalue?.length ||
            HigherEducationFilterValue ||
            rangeFilterApplied ? (
              <div className="listColTitle filterClear">
                <p>Filters applied ({getFilterCount()})</p>
                <button className="filterClear__btn" onClick={clearFilters}>
                  Clear filter
                </button>
              </div>
            ) : (
              ''
            )}

            <ul className="listCol__list listing__list">
              {candidateList?.length ? (
                candidateList.map((candidate, index) => {
                  return (
                    <li
                      key={`candidateList${index}`}
                      className={
                        SelectedCandidateData?.candidateId ===
                        candidate?.candidateId
                          ? 'listCol__list--item listCol__item--selected'
                          : 'listCol__list--item'
                      }
                      onClick={() => onCandidateSelect(candidate)}
                    >
                      <div className="item item--recruiter">
                        <div className="initials initials__md">
                          {candidate?.firstName?.charAt(0).toUpperCase()}
                        </div>
                        <div className="item__info">
                          <h4 className="item__info--title">
                            {firstLetterCapital(candidate?.firstName) +
                              ' ' +
                              firstLetterCapital(candidate?.lastName)}
                          </h4>
                          <div className="item__info--subtitle">
                            <p>{candidate?.education}</p>
                            <span>
                              <Dot />
                              {candidate?.experience > 0
                                ? candidate?.experience + ' Yrs Exp'
                                : 'Fresher'}
                            </span>
                            {candidate?.role && (
                              <span>
                                <Dot />
                                {candidate?.role}
                              </span>
                            )}
                          </div>
                          <div className="item__info--subtitle">
                            <p>
                              <b>P:</b>
                              {candidate?.countryCode}{' '}
                              {formatPhoneNumber(candidate?.phoneNumber)}
                            </p>
                            <span>
                              <b>E:</b>
                              {candidate?.email}
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : (
                <div className="no-data">No Results Found!</div>
              )}
            </ul>
            <div className="pagination pagination__center">
              {candidateList?.length ? (
                <CustomPagination
                  page={pagination.currentPage}
                  totalPageCount={totalPageCount}
                  changePage={(event, value) => changePage(event, value)}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        {OpenAddCandiateScreen ? (
          <AddCandidate
            setAddResponsiveClass={setAddResponsiveClass}
            addResponsiveClass={addResponsiveClass}
            CandidateList={candidateList}
            backToCandidateListing={backToCandidateListing}
            SelectedCandidateData={SelectedCandidateData}
            EditCustomerMode={EditCustomerMode}
          />
        ) : (
          <CandidateDetailPage
            handleAddResponsiveClass={handleAddResponsiveClass}
            addResponsiveClass={addResponsiveClass}
            CandidateList={candidateList}
            SelectedCandidateData={SelectedCandidateData}
            handleEditCustomerMode={handleEditCustomerMode}
            getCandidateList={getCandidateList}
          />
        )}
      </div>
    </div>
  );
};

export default CandidateListing;
