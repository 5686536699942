import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import _ from 'lodash';
import Pagination from '@/components/pagination';
import CustomPagination from '@/elements/pagination/customPagination';
import GoTOPage from '@/elements/gotopage/gotopage';
import ArrowDown from '@/elements/ArrowDown';
import { Currency, firstLetterCapital } from '@/helper';
import { getBillingJobsList } from '@/services/billing-service/billing-service';
import moment from 'moment';
import { LoaderContext } from '@/components/Loader/Loader';
import Search from '@/assets/svgs/Search';

const JobsBilling = ({
  activeCompanyId,
  onRowClick,
  ActiveCompanyData,
  haveCustomersInSearch
}) => {
  const navigate = useNavigate();
  const setLoading = useContext(LoaderContext);

  const [billingJobs, setBillingJobs] = useState([]);
  const [TotalUnbilledJobsAmount, setTotalUnbilledJobsAmount] = useState(0);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0,
    search: '',
    status: ''
  });

  useEffect(() => {
    getBillingJobs();
  }, [activeCompanyId]);

  const getBillingJobs = async (
    recordsPerPage = pagination?.recordsPerPage,
    currentPage = pagination?.currentPage,
    search = pagination?.search,
    status = pagination?.status,
    companyId = activeCompanyId
  ) => {
    setLoading(true);
    const response = await getBillingJobsList(
      currentPage,
      recordsPerPage,
      search,
      status,
      companyId
    );
    const { data, meta } = response;
    if (meta?.status) {
      setPagination((prev) => ({
        ...prev,
        totalRecord: data?.count ? data?.count : 0
      }));
      setBillingJobs(data?.rows);
      setTotalUnbilledJobsAmount(data?.unBilledAmount);
    }
    setLoading(false);
  };

  const searchInBillingJobs = useCallback(
    _.debounce(
      (searchText) =>
        getBillingJobs(
          pagination?.recordsPerPage,
          pagination?.currentPage,
          searchText,
          pagination?.status
        ),
      500
    ),
    []
  );

  const handleChange = (e) => {
    setPagination({ ...pagination, status: e?.target?.value });
    getBillingJobs(
      pagination?.recordsPerPage,
      pagination?.currentPage,
      pagination?.search,
      e?.target?.value
    );
  };

  const changePage = (event, value) => {
    setPagination({ ...pagination, currentPage: value });
  };

  const CalculateUnbilledAmount = (obj) => {
    const sumFilteredValues = obj.screenings
      .filter((item) => !item.billStatus && item.status === 'approved')
      .reduce((sum, item) => sum + item.amount, 0);
    return Currency(sumFilteredValues);
  };

  return (
    <>
      {haveCustomersInSearch ? (
        <>
          <ul className="tags__list">
            {ActiveCompanyData?.lastBillAmount &&
              ActiveCompanyData?.lastBilledDate && (
                <li className="tags__list--item">
                  Last Billed /Date:
                  <b>
                    ${ActiveCompanyData?.lastBillAmount},{' '}
                    {moment(ActiveCompanyData?.lastBilledDate).format(
                      'D MMM,YYYY'
                    )}
                  </b>
                </li>
              )}
            {TotalUnbilledJobsAmount && (
              <li className="tags__list--item text--danger">
                Total Unbilled:
                <b>{Currency(TotalUnbilledJobsAmount)}</b>
              </li>
            )}
          </ul>
          <div className="tabContainer__item--head">
            <div className="listCol__head--actions w_100">
              <div className="formRow formRow__3 m_0 w_100">
                <div className="formCol">
                  <div className="formField">
                    <div className="formField__group sm">
                      <span className="formField__icon">
                        <Search />
                      </span>
                      <input
                        type="text"
                        className="formField__input"
                        placeholder="Search a job"
                        onChange={(e) => searchInBillingJobs(e?.target?.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="formCol">
                  <div className="formField">
                    <FormControl
                      fullWidth
                      size="small"
                      className="formField__select"
                      sx={{ minHeight: '42px' }}
                    >
                      <Select
                        onChange={(e) => handleChange(e)}
                        IconComponent={() => <ArrowDown />}
                        placeholder="Country"
                        name="country"
                        value={pagination?.status ?? null}
                        displayEmpty
                        renderValue={(selected) => {
                          if (!selected) {
                            return (
                              <span style={{ opacity: '0.8' }}>
                                Select status
                              </span>
                            );
                          }

                          return pagination?.status;
                        }}
                      >
                        <MenuItem key="Billed" value="Billed">
                          Billed
                        </MenuItem>
                        <MenuItem key="Unbilled" value="Unbilled">
                          Unbilled
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TableContainer
            component={Paper}
            className="tableContainer tableContainerBilling"
          >
            <Table className="table tableClickable">
              <TableHead>
                <TableRow>
                  <TableCell>S.</TableCell>
                  <TableCell>Job Title</TableCell>
                  <TableCell>Department</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Recruiter</TableCell>
                  <TableCell>Total Unbilled</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              {billingJobs.length ? (
                <TableBody>
                  {billingJobs.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        onClick={() => {
                          if (!ActiveCompanyData?.deleteStatus) {
                            onRowClick(
                              row?.companyId,
                              row?.jobId,
                              TotalUnbilledJobsAmount
                            );
                          }
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell>
                          <div className="cellText">
                            <span className="cellText__title">
                              {row?.title}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="cellText">
                            <span className="cellText__dark">
                              {row?.department}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="cellText">
                            <span className="cellText__dark">
                              {row?.location}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="cellText">
                            <span className="cellText__dark">
                              {row?.assignedStatus
                                ? firstLetterCapital(
                                    row?.recruiter?.firstName
                                  ) +
                                  ' ' +
                                  firstLetterCapital(row?.recruiter?.lastName)
                                : '--'}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="cellText">
                            <span className="cellText__dark">
                              {' '}
                              {CalculateUnbilledAmount(row)}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="cellText">
                            <span
                              className={
                                row?.billStatus
                                  ? 'cellText__dark text--primary'
                                  : 'cellText__dark text--danger'
                              }
                            >
                              {row?.billStatus ? 'Billed' : 'Unbilled'}
                            </span>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <div className="no-data floating">No Results Found!</div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <div className="pagination">
            <div className="pagination__result">{`${
              Pagination?.totalRecord >
              pagination?.currentPage * pagination?.recordsPerPage
                ? pagination?.recordsPerPage
                : pagination?.totalRecord
            } of ${pagination?.totalRecord} results`}</div>
            {pagination?.totalRecord ? (
              <CustomPagination
                totalPageCount={Math.ceil(
                  pagination?.totalRecord / pagination.recordsPerPage
                )}
                changePage={(event, value) => changePage(event, value)}
              />
            ) : (
              ''
            )}
            {pagination?.totalRecord ? (
              <div className="pagination__actions">
                <GoTOPage changePage={changePage} />
              </div>
            ) : (
              ''
            )}
          </div>
        </>
      ) : (
        <div className="no-data">No Results Found!</div>
      )}
    </>
  );
};

export default JobsBilling;
