import React, { useEffect, useContext, useState, useCallback } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowDownIcon from '@/assets/svgs/ArrowDownIcon';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import SideBar from '@/components/layout/SideBar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import BackArrow from '@/assets/svgs/BackArrow';
import Search from '@/assets/svgs/Search';
import DownloadFileSvg from '@/assets/svgs/DownloadFileSvg';
import { getRecruitersOutstandingAmount } from '@/services/billing-service/billing-service';
import { LoaderContext } from '@/components/Loader/Loader';
import { Currency, decryptData } from '@/helper';
import CustomPagination from '@/elements/pagination/customPagination';
import GoTOPage from '@/elements/gotopage/gotopage';
import _ from 'lodash';
import { payRecruitersOutstandingAmount } from '@/services/billing-service/billing-service';
import { SuccessfullyModal } from '@/components/modals/successfully-modal';
import { useNavigate } from 'react-router-dom';
import ErrorModal from '@/components/modals/error-modal';
import ConfirmationModal from '@/components/modals/confirmation-modal';
import { downloadACHPaypalTransferCsv } from '@/services/billing-service/billing-service';
import * as MESSAGES from '@/utils/popupMessages';

const TotalOutstandingAmount = () => {
  const setLoading = useContext(LoaderContext);
  const navigate = useNavigate();
  const [RecruitersOAList, setRecruitersOAList] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [SelectedItems, setSelectedItems] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState('All');
  const [openSuccessfullModal, setOpenSuccessfullModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [CSVData, setCSVData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [recruiterListCount, setRecruiterListCount] = useState();
  const [apiCalling, setApiCalling] = useState(false);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0,
    search: '',
    method: ''
  });

  useEffect(() => {
    RecruitersOutstandingAmountList();
  }, []);
  useEffect(() => {
    setSelectAllChecked(true);
    const updatedRecruitersOAList = RecruitersOAList.map((item) => ({
      ...item,
      checked: true
    }));
    const recruiterIds = RecruitersOAList?.map((item) => ({
      recruiterId: item?.recruiterId,
      paymentMethod: item?.paymentMethod
    }));
    setSelectedItems(recruiterIds);
    setRecruitersOAList(updatedRecruitersOAList);
  }, [recruiterListCount, apiCalling]);

  useEffect(() => {
    if (SelectedItems) {
      const xyz = SelectedItems.filter((item) =>
        item?.paymentMethod?.includes('Paypal')
      );
    }
  }, []);

  const RecruitersOutstandingAmountList = async (
    recordsPerPage = pagination?.recordsPerPage,
    currentPage = pagination?.currentPage,
    search = pagination?.search,
    method = pagination?.method
  ) => {
    setLoading(true);
    const response = await getRecruitersOutstandingAmount(
      recordsPerPage,
      currentPage,
      search,
      method
    );
    const { meta, data } = response;
    if (meta?.status) {
      setRecruitersOAList(data?.rows);
      setApiCalling(!apiCalling);
      setRecruiterListCount(data?.count);
      setPagination({ ...pagination, totalRecord: data?.count });
    }
    setLoading(false);
  };

  const changePage = (event, value) => {
    setPagination({ ...pagination, currentPage: value });
  };

  const searchInRecruiterListing = useCallback(
    _.debounce((searchText, method) => {
      setSelectedItems([]);
      setPagination({ ...pagination, search: searchText });
      setSearchText(searchText);
      RecruitersOutstandingAmountList(
        pagination?.recordsPerPage,
        searchText ? 1 : pagination?.currentPage,
        searchText,
        method
      );
    }, 500),
    []
  );

  useEffect(() => {
    if (SelectedItems.length === 0 || !RecruitersOAList?.length) {
      setSelectAllChecked(false);
    }
    if (
      RecruitersOAList?.length > 0 &&
      SelectedItems?.length > 0 &&
      RecruitersOAList?.length === SelectedItems?.length
    ) {
      setSelectAllChecked(true);
    }
  }, [RecruitersOAList?.length, SelectedItems]);

  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;
    setSelectAllChecked(checked);
    const updatedRecruitersOAList = RecruitersOAList.map((item) => ({
      ...item,
      checked: checked
    }));
    const recruiterIds = checked
      ? RecruitersOAList.map((item) => ({
          recruiterId: item?.recruiterId,
          paymentMethod: item?.paymentMethod
        }))
      : [];
    setSelectedItems(recruiterIds);
    setRecruitersOAList(updatedRecruitersOAList);
  };

  const handleSingleSelectChange = (e, item) => {
    const checked = e.target.checked;
    const updatedRecruitersOAList = RecruitersOAList.map((recruiter) => {
      if (recruiter === item) {
        return { ...recruiter, checked: checked };
      }
      return recruiter;
    });
    let updatedSelectedItems = [...SelectedItems];
    if (checked) {
      if (!updatedSelectedItems.includes(item)) {
        updatedSelectedItems.push({
          recruiterId: item?.recruiterId,
          paymentMethod: item?.paymentMethod
        });
      }
    } else {
      setSelectAllChecked(false);
      updatedSelectedItems = updatedSelectedItems.filter(
        (selectedItem) => selectedItem.recruiterId !== item.recruiterId
      );
    }
    setRecruitersOAList(updatedRecruitersOAList);
    setSelectedItems(updatedSelectedItems);
  };

  const onMarkAllpaidClick = async () => {
    setOpenConfirmationModal(false);
    setLoading(true);
    let body = { recruiterArray: SelectedItems };
    const response = await payRecruitersOutstandingAmount(body);
    const { meta } = response;
    if (meta?.status) {
      setOpenSuccessfullModal(true);
      RecruitersOutstandingAmountList();
    }
    setLoading(false);
  };

  const handlePaymentMethodFilterChange = (value) => {
    setPagination({ ...pagination, method: value });
    setPaymentMethod(value);
    RecruitersOutstandingAmountList(
      pagination?.recordsPerPage,
      pagination?.currentPage,
      searchText,
      value
    );
  };

  const onMarkAsPaidClick = async (item) => {
    setLoading(true);
    let body = {
      recruiterArray: [
        { recruiterId: item?.recruiterId, paymentMethod: item?.paymentMethod }
      ]
    };
    const response = await payRecruitersOutstandingAmount(body);
    const { meta } = response;
    if (meta?.status) {
      setOpenSuccessfullModal(true);
      RecruitersOutstandingAmountList();
    }
    setLoading(false);
  };

  const handleBackClick = () => {
    navigate('/billing-payments', {
      state: {
        value: '2'
      }
    });
  };

  const handleRowClick = (recruiter) => {
    navigate('/billing-payments', {
      state: {
        value: '2',
        recruiterDetails: recruiter,
        fromOutstandingScreen: true
      }
    });
  };

  const handleDownloadTransfers = async (type) => {
    if (!SelectedItems?.length) {
      setOpenErrorModal(true);
      setErrorMessage(
        `Please select at least one record to download the list.`
      );
    } else if (SelectedItems?.length) {
      setLoading(true);
      let body = {
        method: type,
        excludeArray: selectAllChecked ? [] : SelectedItems
      };
      const response = await downloadACHPaypalTransferCsv(body);
      const { data, meta } = response;
      if (Object.keys(data).length && meta?.status) {
        if (type === 'ACH') {
          let newData = [];
          data?.map((item) => {
            newData?.push({
              'Recruiter Name': item?.recruiterName,
              'Routing Number': decryptData(
                item?.accountDetail?.methodDetail?.routingNumber
              ),
              'Account Number': decryptData(
                item?.accountDetail?.methodDetail?.accountNumber
              ),
              'Account Type': item?.accountDetail?.methodDetail?.accountType,
              'Amount($)': Number(item?.amount).toFixed(2)
            });
          });
          downloadCSV(newData, 'Recruiter - ACH payment list');
        } else {
          let newData = [];
          data?.map((item) => {
            newData?.push({
              'Recipient Name': item?.recruiterName,
              'PayPal Email Address': item?.accountDetail?.methodDetail?.email,
              'Amount($)': Number(item?.amount).toFixed(2),
              'PayPal fee($)': Number(item?.fee),
              Currency: 'USD'
            });
          });
          downloadCSV(newData, 'Recruiter - PayPal payment list');
        }
      } else {
        setOpenErrorModal(true);
        setErrorMessage('No Data to download!');
      }
      setLoading(false);
    }
  };

  function convertArrayOfObjectsToCSV(data) {
    const header = Object.keys(data[0]).join(',');
    const rows = data.map((obj) => Object.values(obj).join(','));
    return [header, ...rows].join('\n');
  }

  function downloadCSV(data, filename) {
    const csv = convertArrayOfObjectsToCSV(data);
    const blob = new Blob([csv], {
      type: 'text/csv;charset=utf-8;'
    });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid__col">
          <div className="page page__full jobPage">
            <div className="page__head">
              <h2 className="listCol__head--title">
                <button
                  className="btn btn__transparent btn__back border_0 p_0"
                  onClick={handleBackClick}
                >
                  <BackArrow />
                </button>
                Total Outstanding Amount
              </h2>
            </div>
            <div className="page__body">
              <div className="tabContainer tabContainer--totalOutstandingAmount tabContainer--inner">
                <div className="tabContainer__item bpContainer">
                  <div className="tabContainer__item--head ">
                    <div className="listCol__head--actions billingPayment listCol__head--actions--totalOutstandingAmount w_100">
                      <div className="formRow formRow__2 m_0">
                        <div className="formCol">
                          <div className="formField">
                            <div className="formField__group sm">
                              <span className="formField__icon">
                                <Search />
                              </span>
                              <input
                                type="text"
                                className="formField__input"
                                placeholder="Search a  recruiter"
                                onChange={(e) =>
                                  searchInRecruiterListing(
                                    e?.target?.value,
                                    paymentMethod
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="formCol">
                          <div className="customMultiSelect">
                            <FormControl
                              sx={{ width: '100%' }}
                              size="small"
                              className="formField__select sm"
                            >
                              <Select
                                IconComponent={() => <ArrowDownIcon />}
                                name="paymentMethod"
                                displayEmpty
                                value={paymentMethod}
                                onChange={(e) =>
                                  handlePaymentMethodFilterChange(
                                    e?.target?.value
                                  )
                                }
                                renderValue={(selected) => {
                                  if (!selected) {
                                    return (
                                      <span style={{ opacity: '0.8' }}>
                                        Payment Method
                                      </span>
                                    );
                                  }

                                  return selected;
                                }}
                              >
                                <MenuItem value="All">All</MenuItem>
                                <MenuItem value="ACH">ACH</MenuItem>
                                <MenuItem value="Paypal">Paypal</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                      <div className="btn__group">
                        {(paymentMethod === 'ACH' ||
                          paymentMethod === 'All') && (
                          <button
                            onClick={() => handleDownloadTransfers('ACH')}
                            className="btn btn__white w_100"
                            disabled={
                              recruiterListCount &&
                              SelectedItems?.length &&
                              SelectedItems.filter((item) =>
                                item?.paymentMethod?.includes('ACH')
                              ).length
                                ? false
                                : true
                            }
                          >
                            <DownloadFileSvg /> Download ACH Transfer
                          </button>
                        )}
                        {(paymentMethod === 'Paypal' ||
                          paymentMethod === 'All') && (
                          <button
                            className="btn btn__white w_100"
                            onClick={() => handleDownloadTransfers('Paypal')}
                            disabled={
                              recruiterListCount &&
                              SelectedItems?.length &&
                              SelectedItems.filter((item) =>
                                item?.paymentMethod?.includes('Paypal')
                              ).length
                                ? false
                                : true
                            }
                          >
                            <DownloadFileSvg /> Download PayPal Transfer
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <TableContainer component={Paper} className="tableContainer">
                    <Table className="table tableClickable">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <div className="formField__checkbox">
                              <FormControlLabel
                                className="pb15"
                                control={<Checkbox />}
                                checked={selectAllChecked ? true : false}
                                onChange={handleSelectAllChange}
                                disabled={recruiterListCount ? false : true}
                              />
                            </div>
                          </TableCell>
                          <TableCell>Recruiters</TableCell>
                          <TableCell>Payment Method</TableCell>
                          <TableCell>Total Unpaid</TableCell>
                          <TableCell>
                            {' '}
                            <div className="actions flex--end">
                              <button
                                className="btn btn__white"
                                disabled={
                                  SelectedItems.length <= 0 ? true : false
                                }
                                onClick={() => setOpenConfirmationModal(true)}
                              >
                                Mark all as paid
                              </button>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {RecruitersOAList?.length > 0 ? (
                        <TableBody>
                          {RecruitersOAList?.map((item, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                  <div className="formField__checkbox">
                                    <FormControlLabel
                                      className="pb15"
                                      control={<Checkbox />}
                                      checked={item.checked || false}
                                      onChange={(e) =>
                                        handleSingleSelectChange(e, item, index)
                                      }
                                    />
                                  </div>
                                </TableCell>
                                <TableCell onClick={() => handleRowClick(item)}>
                                  <div className="cellText">
                                    <span className="cellText__title">
                                      {item?.recruiterName
                                        ? item?.recruiterName
                                        : '--'}
                                    </span>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="cellText">
                                    <span className="cellText__dark">
                                      {item?.paymentMethod
                                        ? item?.paymentMethod
                                        : '--'}
                                    </span>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="cellText">
                                    <span className="cellText__dark">
                                      {Currency(item?.amount)}
                                    </span>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="actions flex--end">
                                    <button
                                      className="btn btn__white"
                                      disabled={selectAllChecked ? true : false}
                                      onClick={() => onMarkAsPaidClick(item)}
                                    >
                                      Mark as paid
                                    </button>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      ) : (
                        <div className="no-data floating">
                          No Results Found!
                        </div>
                      )}
                    </Table>
                  </TableContainer>

                  <div className="pagination">
                    <div className="pagination__result">{`${
                      pagination?.totalRecord >
                      pagination?.currentPage * pagination?.recordsPerPage
                        ? pagination?.recordsPerPage
                        : pagination?.totalRecord
                    } of ${pagination?.totalRecord} results`}</div>

                    {pagination?.totalRecord ? (
                      <CustomPagination
                        totalPageCount={Math.ceil(
                          pagination?.totalRecord / pagination.recordsPerPage
                        )}
                        page={pagination?.currentPage}
                        changePage={(event, value) => changePage(event, value)}
                      />
                    ) : (
                      ''
                    )}
                    {pagination?.totalRecord ? (
                      <div className="pagination__actions">
                        <GoTOPage changePage={changePage} />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessfullyModal
        openModal={openSuccessfullModal}
        closeModal={() => setOpenSuccessfullModal(false)}
        sucessfullMsg={'Payment status updated successfully.'}
        Heading={'Payment status!'}
      />
      <ErrorModal
        openModal={openErrorModal}
        errorMessage={errorMessage}
        closeModal={() => setOpenErrorModal(false)}
      />
      <ConfirmationModal
        openModal={openConfirmationModal}
        closeModal={() => setOpenConfirmationModal(false)}
        onSubmit={onMarkAllpaidClick}
        confirmationMessage={MESSAGES?.markAspaid?.message?.confirmationMsg}
        heading={MESSAGES?.markAspaid?.message?.heading}
      />
    </>
  );
};

export default TotalOutstandingAmount;
