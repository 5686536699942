import httpService from '@/services/http-service';
import {
  SCREENING_LIST,
  CANDIDATE_STATUS_CHANGE,
  CANDIDATE_RECORDING_LIST,
  CANDIDATE_FEEDBACK_LIST
} from '@/services/end-points';

export const getScreeningList = async (
  page,
  limit,
  search,
  jobId,
  columnName,
  sort
) => {
  try {
    const response = await httpService.post(SCREENING_LIST, {
      page,
      limit,
      search,
      jobId,
      columnName,
      sort
    });
    const { data, meta } = response;
    if (meta?.status) {
      return data;
    }
  } catch (error) {
    return error;
  }
};

export const changeCandidateStatus11 = async (
  screeningId,
  jobId,
  status,
  feedback
) => {
  try {
    const response = await httpService.put(CANDIDATE_STATUS_CHANGE, {
      screeningId,
      jobId,
      status,
      feedback
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getScreeningRecordingList = async (
  page,
  limit,
  screeningId,
  addedFrom
) => {
  try {
    const response = await httpService.post(CANDIDATE_RECORDING_LIST, {
      page,
      limit,
      screeningId,
      addedFrom
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getCandidateFeedbackList11 = async () => {
  try {
    const response = await httpService.get(CANDIDATE_FEEDBACK_LIST, {});
    return response;
  } catch (error) {
    return error;
  }
};
