import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef
} from 'react';
import SideBar from '@/components/layout/SideBar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Search from '@/assets/svgs/Search';
import UploadFileIconWhite from '@/assets/svgs/UploadFileIconWhite';
import RefreshIcon from '@/assets/svgs/RefreshIcon';
import Paper from '@mui/material/Paper';
import { auditTrailListing } from '@/services/audit-trail-service/auditTrail-service';
import DatePickerIcon from '@/assets/svgs/DatePickerIcon';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import MultiSelectFilter from '@/components/filter';
import _ from 'lodash';
import GoTOPage from '@/elements/gotopage/gotopage';
import CustomPagination from '@/elements/pagination/customPagination';
import { firstLetterCapital, getInitial } from '@/helper';
import { LoaderContext } from '@/components/Loader/Loader';
import { getStaticData } from '@/services/static-data-service';
import dayjs from 'dayjs';
import { getTimeZone } from '@/helper';
import { Link } from 'react-router-dom';
import AuditTrailDetailModal from '@/components/modals/auditTrailDetail-modal';
import SortColumn from '@/elements/SortColumn';

const AuditTrailListing = () => {
  const searchRef = useRef();
  const setLoading = useContext(LoaderContext);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [auditTrailList, setAuditTrailList] = useState([]);
  const [refreshList, setRefreshList] = useState('');
  const [FilterOptions, setFilterOptions] = useState('');
  const [filterValue, setFilterValue] = useState([]);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [detailModalData, setDetailModalData] = useState({});
  const [ListCount, setListCount] = useState();
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [sortByASC, setSortByASC] = useState(true);
  const [sortByColoumn, setSortByColoumn] = useState('');

  const AuditStartDate = (val) => {
    let originalDateString = JSON.stringify(val);
    let date = new Date(originalDateString.split('T')[0]);
    let formatedDate = moment(date, 'YYYY-MM-DD')
      .add(1, 'days')
      .format('YYYY-MM-DD');
    setStartDate(formatedDate);
  };

  const AuditEndDate = (val) => {
    let originalDateString = JSON.stringify(val);
    let date = new Date(originalDateString.split('T')[0]);
    let formatedDate = moment(date, 'YYYY-MM-DD')
      .add(1, 'days')
      .format('YYYY-MM-DD');
    setEndDate(formatedDate);
  };

  const shouldDisableDate = (date) => {
    return startDate ? dayjs(date).isBefore(dayjs(startDate), 'day') : false;
  };

  const getStaticDataLocal = async () => {
    try {
      const response = await getStaticData();
      const { data, meta } = response;
      if (meta?.status) {
        const filterOptions = data?.auditFilter?.map((item) => {
          return item?.name;
        });
        setFilterOptions(filterOptions);
      }
    } catch (error) {
      console.log('axios error --->', error);
    }
  };

  const getAuditTrailListing = async (
    currentPage = page,
    filterStartDate = startDate,
    filterEndDate = endDate,
    filterValues = filterValue,
    auditSearch = search,
    columnName = sortByColoumn,
    sort = sortByASC ? 'ASC' : 'DESC'
  ) => {
    setLoading(true);
    const body = {
      page: currentPage,
      limit: 20,
      startDate: filterStartDate,
      endDate: filterEndDate,
      origin: filterValues,
      search: auditSearch,
      columnName,
      sort
    };
    const response = await auditTrailListing(body);
    const { meta, data } = response;
    if (meta?.status) {
      setAuditTrailList(data?.rows);
      setListCount(data?.count);
    }
    setLoading(false);
  };

  const handleStatusFilterChange = (selectedOption) => {
    getAuditTrailListing(page, startDate, endDate, selectedOption, search);
    setFilterValue(selectedOption);
  };

  const handleFilterClear = () => {
    getAuditTrailListing(
      page,
      startDate,
      endDate,
      [],
      search,
      sortByColoumn,
      sortByASC ? 'ASC' : 'DESC'
    );
    setFilterValue([]);
  };

  const handleSearch = useCallback(
    _.debounce((searchText, companyId) => setSearch(searchText), 500),
    []
  );

  const changePage = (event, value) => {
    setPage(value);
  };

  const handleRefresh = () => {
    setFilterValue([]);
    searchRef.current.value = '';
    setSearch('');
    setStartDate('');
    setEndDate('');
    setRefreshList(true);
  };

  const getSortByData = (columnName) => {
    setSortByColoumn(columnName);
    setSortByASC(!sortByASC);
    getAuditTrailListing(
      page,
      startDate,
      endDate,
      filterValue,
      search,
      columnName,
      sortByASC ? 'ASC' : 'DESC'
    );
  };

  useEffect(() => {
    getAuditTrailListing();
  }, [search, page, refreshList]);
  useEffect(() => {
    if (startDate && endDate) {
      getAuditTrailListing();
    }
  }, [startDate, endDate]);
  useEffect(() => {
    getStaticDataLocal();
  }, []);
  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid__col">
          <div className="page page__full">
            <div className="page__head">
              <div className="listCol__head--actions w_100">
                <div className="formRow formRow__3 m_0 w_100">
                  <div className="formCol">
                    <div className="formField">
                      <div className="formField__group sm">
                        <span className="formField__icon">
                          <Search />
                        </span>
                        <input
                          ref={searchRef}
                          type="text"
                          className="formField__input"
                          placeholder="Search"
                          onChange={(e) => handleSearch(e?.target?.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="formRow m_0">
                    <div className="formField flexRow--datepicker">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          onChange={(val) => AuditStartDate(val)}
                          className="formField__datepicker"
                          slots={{ openPickerIcon: DatePickerIcon }}
                          label="From"
                          value={startDate}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="formField__datepicker"
                          onChange={(val) => AuditEndDate(val)}
                          slots={{ openPickerIcon: DatePickerIcon }}
                          shouldDisableDate={shouldDisableDate}
                          label="To"
                          value={endDate}
                        />
                      </LocalizationProvider>
                      <MultiSelectFilter
                        labels={FilterOptions}
                        applyFilter={handleStatusFilterChange}
                        clearFilter={handleFilterClear}
                      />
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn__white p_0 w_fitContent"
                  onClick={() => handleRefresh()}
                >
                  <RefreshIcon />
                </button>
                {/* <button className="btn btn__primary w_fitContent">
                <UploadFileIconWhite />
                Export
              </button> */}
              </div>
            </div>
            <div className="page__body">
              <div className="tabContainer">
                <div className="tabContainer__item">
                  <TableContainer component={Paper} className="tableContainer">
                    {auditTrailList?.length ? (
                      <Table className="table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{ maxWidth: '150px' }}
                              onClick={() => getSortByData('createdAt')}
                            >
                              <SortColumn
                                thName={'Occured'}
                                sortByASC={sortByASC}
                              />
                            </TableCell>
                            <TableCell
                              onClick={() => getSortByData('employeeName')}
                            >
                              <SortColumn
                                thName={'Username'}
                                sortByASC={sortByASC}
                              />
                            </TableCell>
                            <TableCell
                              onClick={() => getSortByData('userRole')}
                            >
                              <SortColumn
                                thName={'User Role'}
                                sortByASC={sortByASC}
                              />
                            </TableCell>
                            <TableCell
                              onClick={() => getSortByData('actionPerformedBy')}
                            >
                              <SortColumn
                                thName={'Entity'}
                                sortByASC={sortByASC}
                              />
                            </TableCell>
                            <TableCell onClick={() => getSortByData('origin')}>
                              <SortColumn
                                thName={'Screen'}
                                sortByASC={sortByASC}
                              />
                            </TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {auditTrailList.length &&
                            auditTrailList?.map((row, index) => {
                              return (
                                <TableRow key={`audittrail${index}`}>
                                  <TableCell style={{ maxWidth: '150px' }}>
                                    {getTimeZone(row?.createdAt)}
                                  </TableCell>
                                  <TableCell>
                                    <div className="profile">
                                      <div className="profile__initials">
                                        {getInitial(row?.employeeName)}
                                      </div>
                                      <span className="profile__name">
                                        {row?.employeeName
                                          ? row?.employeeName
                                          : '--'}
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    {row?.userRole ? row?.userRole : '--'}
                                  </TableCell>
                                  <TableCell>
                                    {row?.actionPerformedBy === 'Company'
                                      ? firstLetterCapital(
                                          row?.company?.companyName
                                        )
                                      : row?.actionPerformedBy}
                                  </TableCell>
                                  <TableCell>{row?.origin}</TableCell>
                                  <TableCell>
                                    {row?.activity === 'update'
                                      ? 'Updated'
                                      : row?.activity === 'create'
                                        ? 'Created'
                                        : 'Deleted'}
                                    <div>
                                      <span>
                                        <Link
                                          onClick={() => {
                                            setOpenDetailModal(true);
                                            setDetailModalData(row);
                                          }}
                                          className="cellText__dark cell--link"
                                        >
                                          (View Details)
                                        </Link>
                                      </span>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    ) : (
                      <div className="no-data">No Results Found!</div>
                    )}
                  </TableContainer>
                  <div className="pagination">
                    <div className="pagination__result">
                      {ListCount > 0 &&
                        `${ListCount > page * recordsPerPage ? recordsPerPage : ListCount} of ${ListCount} results`}
                    </div>
                    {ListCount > 0 ? (
                      <CustomPagination
                        totalPageCount={Math.ceil(ListCount / recordsPerPage)}
                        changePage={(event, value) => changePage(event, value)}
                        page={page}
                      />
                    ) : (
                      ''
                    )}
                    {ListCount > 0 ? (
                      <div className="pagination__actions">
                        <GoTOPage changePage={changePage} />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AuditTrailDetailModal
        openModal={openDetailModal}
        closeModal={() => setOpenDetailModal(false)}
        detailModalData={detailModalData}
      />
    </>
  );
};
export default AuditTrailListing;
