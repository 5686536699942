import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import ForwardArrow from '@/assets/svgs/ForwardArrow';
import CustomPagination from '@/elements/pagination/customPagination';
import GoTOPage from '@/elements/gotopage/gotopage';
import { RecordingModal } from '@/components/modals/recording-modal';
import { LoaderContext } from '@/components/Loader/Loader';
import { getScreeningRecordingList } from '@/services/screening-service/screening-service';
import { firstLetterCapital, getUTCRecordingTimeSlot } from '@/helper';
import PlayIcon from '@/assets/svgs/PlayIcon';

const CandidateRecordingList = ({
  changeViewRecordingStatus,
  candidateData
}) => {
  const setLoading = useContext(LoaderContext);
  const [recordings, setRecordings] = useState([]);
  const [recordingLink, setRecordingLink] = useState('');
  const [selected, setSelected] = useState('');
  const [openRecordingModal, setOpenRecordingModal] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0,
    addedFrom: ''
  });

  useEffect(() => {
    getRecordingList();
  }, []);

  const getRecordingList = async (
    currentPage = pagination?.currentPage,
    recordsPerPage = pagination?.recordsPerPage,
    addedFrom = pagination?.addedFrom
  ) => {
    setLoading(true);
    const response = await getScreeningRecordingList(
      currentPage,
      recordsPerPage,
      candidateData?.sreeningId,
      addedFrom
    );
    const { meta, data } = response;
    if (meta?.status) {
      setPagination((prev) => ({
        ...prev,
        totalRecord: data?.count ? data?.count : 0
      }));
      setRecordings(data?.rows);
      setLoading(false);
    }
  };

  const changePage = (event, value) => {
    setPagination({ ...pagination, currentPage: value });
  };

  const handleChange = (e) => {
    if (e?.target?.value) {
      setSelected(e?.target?.value === 'All' ? '' : e?.target?.value);
      setPagination((prev) => ({
        ...prev,
        addedFrom: e?.target?.value === 'All' ? '' : e?.target?.value
      }));
      getRecordingList(
        pagination?.currentPage,
        pagination?.recordsPerPage,
        e?.target?.value === 'All' ? '' : e?.target?.value
      );
    }
  };

  return (
    <>
      <div className="candidateRecordings">
        <div className="details">
          <section className="details__section m_0">
            <button
              className="btn btn__white btn__back"
              onClick={() => changeViewRecordingStatus(false)}
            >
              Back
            </button>
            <div className="initials initials__lg">
              {candidateData?.candidate?.firstName?.charAt(0).toUpperCase()}
            </div>
            <div className="details__section--name">
              {firstLetterCapital(candidateData?.candidate?.firstName) +
                ' ' +
                firstLetterCapital(candidateData?.candidate?.lastName)}
            </div>
            <div className="details__row">
              <div className="details__row--item">
                <span>Email:</span>
                {candidateData?.candidate?.email}
              </div>
            </div>
            <ul className="tags__list">
              <li className="tags__list--item">
                <span>Recruiter Name:</span>
                {firstLetterCapital(candidateData?.recruiter?.firstName) +
                  ' ' +
                  firstLetterCapital(candidateData?.recruiter?.lastName)}
              </li>
              {candidateData?.candidate?.resumeUrl && (
                <li className="tags__list--item">
                  <Link
                    to={candidateData?.candidate?.resumeUrl}
                    target="_blank"
                    className="tag--link"
                  >
                    {candidateData?.candidate?.resumeUrl}
                    <ForwardArrow />
                  </Link>
                </li>
              )}
            </ul>
          </section>

          <section className="details__section pt_0">
            {/* <div className="formRow formRow__3 m_0">
              <div className="formCol">
                <div className="formField">
                  <FormControl
                    fullWidth
                    size="small"
                    className="formField__select"
                    sx={{ minHeight: "42px" }}
                  >
                    <Select
                      onChange={(e) => handleChange(e)}
                      IconComponent={() => <ArrowDown />}
                      placeholder="Country"
                      name="country"
                      value={selected}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span style={{ opacity: "0.8" }}>Select</span>;
                        }

                        return selected;
                      }}
                    >
                      <MenuItem key={"All"} value={"All"}>
                        All
                      </MenuItem>
                      <MenuItem key={"Recruiter"} value={"Recruiter"}>
                        Recruiter
                      </MenuItem>
                      <MenuItem key={"Customer"} value={"Customer"}>
                        Customer
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div> */}
            <TableContainer
              component={Paper}
              className="tableContainer p_0"
              style={{ height: 'calc(100dvh - 510px)' }}
            >
              <Table className="table">
                <TableHead>
                  <TableRow>
                    <TableCell>S.</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Interviewed By</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {recordings?.length ? (
                  <TableBody>
                    {recordings?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell>
                          <div className="cellText">
                            <span className="cellText__dark">
                              {moment(row?.createdAt).format('D MMM , YYYY')}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="cellText">
                            <span className="cellText__dark">
                              {getUTCRecordingTimeSlot(
                                row?.createdAt,
                                row?.timeSlot
                              )}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="cellText">
                            <span className="cellText__dark">
                              {row?.addedFrom}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <div className="actions flex--end">
                            <button
                              className="btn btn__white"
                              onClick={() => {
                                setOpenRecordingModal(true);
                                setRecordingLink(row?.link);
                              }}
                            >
                              View Rec.
                              <PlayIcon />
                            </button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <div className="no-data floating">No Results Found!</div>
                )}
              </Table>
            </TableContainer>
          </section>
        </div>

        <div className="pagination">
          <div className="pagination__result">{`${
            pagination?.totalRecord >
            pagination?.currentPage * pagination?.recordsPerPage
              ? pagination?.recordsPerPage
              : pagination?.totalRecord
          } of ${pagination?.totalRecord} results`}</div>
          {pagination?.totalRecord ? (
            <CustomPagination
              totalPageCount={Math.ceil(
                pagination?.totalRecord / pagination.recordsPerPage
              )}
              changePage={(event, value) => changePage(event, value)}
            />
          ) : (
            ''
          )}
          {pagination?.totalRecord ? (
            <div className="pagination__actions">
              <GoTOPage changePage={changePage} />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <RecordingModal
        openModal={openRecordingModal}
        closeModal={() => setOpenRecordingModal(false)}
        recordingLink={recordingLink}
      />
    </>
  );
};

export default CandidateRecordingList;
