import { React, useState, useEffect, useContext } from 'react';
import SideBar from '@/components/layout/SideBar';
import Company from '@/assets/svgs/Company';
import Recruiter from '@/assets/svgs/Recruiter';
import Candidate from '@/assets/svgs/Candidate';
import ActiveJobs from '@/assets/svgs/Jobs';
import ClosedJobs from '@/assets/svgs/ClosedJobs';
import AssignedJobs from '@/assets/svgs/AssignedJobs';
import UnassignedJobs from '@/assets/svgs/UnassignedJobs';
import { getDashboardData } from '@/services/dashboard-service/dashboard-service';
import { LoaderContext } from '@/components/Loader/Loader';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const setLoading = useContext(LoaderContext);
  const navigate = useNavigate();
  const [DashboardData, setDashboardData] = useState({});

  useEffect(() => {
    GetDashboardData();
  }, []);

  const GetDashboardData = async () => {
    try {
      setLoading(true);
      const dashboardData = await getDashboardData();
      const { data, meta } = dashboardData;
      if (meta?.status) {
        setDashboardData(data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div className="layoutContainer">
      <SideBar />
      <div className="layoutGrid layoutGrid--full">
        <div className="layoutGrid__col">
          <div className="page page__full">
            <div className="page__head">
              <h2 className="listCol__head--title">Home</h2>
            </div>
            <div className="page__body bg--white">
              <div className="cardContainer">
                <ul className="cardList">
                  <li
                    className="cardList__item"
                    onClick={() => navigate('/customer-listing')}
                  >
                    <div className="card--icon">
                      <Company />
                    </div>
                    <div className="card--title">Customers</div>
                    <div className="card--subtitle">
                      {DashboardData?.company}
                    </div>
                  </li>
                  <li
                    className="cardList__item"
                    onClick={() => navigate('/recruiters')}
                  >
                    <div className="card--icon">
                      <Recruiter />
                    </div>
                    <div className="card--title">Recruiters</div>
                    <div className="card--subtitle">
                      {DashboardData?.recruiter}
                    </div>
                  </li>
                  <li
                    className="cardList__item"
                    onClick={() => navigate('/candidate-listing')}
                  >
                    <div className="card--icon">
                      <Candidate />
                    </div>
                    <div className="card--title">Candidates</div>
                    <div className="card--subtitle">
                      {DashboardData?.candidate}
                    </div>
                  </li>
                  <li
                    className="cardList__item"
                    onClick={() => navigate('/jobs')}
                  >
                    <div className="card--icon">
                      <ActiveJobs />
                    </div>
                    <div className="card--title">Active Jobs</div>
                    <div className="card--subtitle">
                      {DashboardData?.activeJob}
                    </div>
                  </li>
                  <li
                    className="cardList__item"
                    onClick={() => navigate('/jobs')}
                  >
                    <div className="card--icon">
                      <ClosedJobs />
                    </div>
                    <div className="card--title">Closed Jobs</div>
                    <div className="card--subtitle">
                      {DashboardData?.closeJob}
                    </div>
                  </li>
                  <li
                    className="cardList__item"
                    onClick={() => navigate('/jobs')}
                  >
                    <div className="card--icon">
                      <AssignedJobs />
                    </div>
                    <div className="card--title">Assigned Jobs</div>
                    <div className="card--subtitle">
                      {DashboardData?.assignedJob}
                    </div>
                  </li>
                  <li
                    className="cardList__item"
                    onClick={() => navigate('/jobs')}
                  >
                    <div className="card--icon">
                      <UnassignedJobs />
                    </div>
                    <div className="card--title">Unassigned Jobs</div>
                    <div className="card--subtitle">
                      {DashboardData?.unassignedJob}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
