import React, {
  useState,
  useCallback,
  useRef,
  useContext,
  useEffect
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { firstLetterCapital } from '@/helper';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import PropTypes from 'prop-types';
import SideBar from '@/components/layout/SideBar';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import JobDescription from '@/pages/Jobs/job-description';
import JobRecruiter from '@/pages/Jobs/job-recruiter';
import JobQuestionnaire from '@/pages/Jobs/job-questionnaire';
import JobNotes from '@/pages/Jobs/job-notes';
import JobCandidate from '@/pages/Jobs/job-candidate';
import CustomPagination from '@/elements/pagination/customPagination';
import ArrowDown from '@/elements/ArrowDown';
import _ from 'lodash';
import { LoaderContext } from '@/components/Loader/Loader';
import JobFeedbackView from '@/pages/Jobs/job-feedback-view';
import JobSendFeedback from '@/pages/Jobs/job-feedback-send';
import { getJobsList } from '@/services/job-service/job-service';

const headCells = [
  {
    id: 'id',
    label: 'S.'
  },
  {
    id: 'name',
    label: 'Name'
  },
  {
    id: 'experience',
    label: 'Experience'
  },
  {
    id: 'emailAddress',
    label: 'Email Address'
  },
  {
    id: 'phoneNumber',
    label: 'Phone Number'
  },
  {
    id: 'acceptedJobs',
    label: 'Accepted Jobs'
  },
  {
    id: 'action',
    label: 'Action'
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const JobListing = () => {
  const searchRef = useRef(null);
  const setLoading = useContext(LoaderContext);
  const { state } = useLocation();
  const navigate = useNavigate();

  const [value, setValue] = useState('1');
  const [jobId, setJobId] = useState(state?.jobId);
  const [jobTitle, setJobTitle] = useState(state?.jobTitle);
  const [filteranchorEl, setFilterAnchorEl] = useState(null);
  const [searchanchorEl, setSeachAnchorEl] = useState(null);
  const [jobList, setJobList] = useState([]);
  const [SkillsetFiltervalue, setSkillsetFiltervalue] = useState([]);
  const [jobFilterList, setJobFilterList] = useState([
    'Assigned',
    'Unassigned'
  ]);
  const [CompanyName, setCompanyName] = useState('');
  const [pagination, setPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0,
    search: '',
    status: []
  });
  const [candidateSreeningId, setCandidateScreeningId] = useState('');
  const searchOpen = Boolean(searchanchorEl);
  const filterOpen = Boolean(filteranchorEl);
  const searchPopoverid = searchOpen ? 'simple-popover' : undefined;
  const filterPopoverid = filterOpen ? 'simple-popover' : undefined;
  const [addResponsiveClass, setAddResponsiveClass] = useState(
    state?.addResponsiveClass ?? false
  );
  const [candidateFeedbackView, setCandidateView] = useState(false);
  const [candidateSendFeedback, setCandidateSendFeedback] = useState(false);
  const [fromJobtab, setFromJobtab] = useState(state?.fromJobsTab);
  const [candidateFeedbackData, setCandidateFeedbackData] = useState({});
  const [selectedJobData, setSelectedJobData] = useState({});

  useEffect(() => {
    getJobData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSeachClick = (event) => {
    setSeachAnchorEl(event.currentTarget);
  };

  const handleSearchClose = () => {
    setSeachAnchorEl(null);
    searchRef.current.value = '';
    getJobData(pagination?.recordsPerPage, 1, '', pagination?.status);
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const searchInJobListing = useCallback(
    _.debounce(
      (searchText) =>
        getJobData(
          pagination?.recordsPerPage,
          pagination?.currentPage,
          searchText,
          pagination?.status
        ),
      500
    ),
    []
  );

  const getJobData = async (
    recordsPerPage = pagination?.recordsPerPage,
    currentPage = pagination?.currentPage,
    search = state?.jobTitle ? state?.jobTitle : pagination?.search,
    status = pagination?.status
  ) => {
    setLoading(true);
    const response = await getJobsList(
      currentPage,
      recordsPerPage,
      search,
      '',
      '',
      status,
      state?.companyId
    );
    const { data, meta } = response;
    if (meta?.status) {
      setPagination((prev) => ({
        ...prev,
        totalRecord: data?.count ? data?.count : 0
      }));
      setJobList(data?.rows);
      if (fromJobtab) {
        let filteredData = data?.rows?.filter((item) => item?.jobId === jobId);
        selectNewJob(filteredData[0]);
        setJobId(filteredData[0]?.jobId);
      } else {
        selectNewJob(data?.rows?.length ? data?.rows[0] : []);
        setJobId(data ? data?.rows[0]?.jobId : '');
      }
      setCompanyName(data?.rows[0]?.employee?.company?.companyName);
      setLoading(false);
    }
  };

  useEffect(() => {
    setFromJobtab(false);
  }, [pagination?.currentPage]);

  const filterJobset = (e) => {
    const {
      target: { value }
    } = e;
    setSkillsetFiltervalue(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  const changePage = (event, value) => {
    setPagination({ ...pagination, currentPage: value });
    getJobData(
      pagination?.recordsPerPage,
      value,
      pagination?.search,
      pagination?.status
    );
  };

  const selectNewJob = (selectedJob) => {
    setJobId(selectedJob?.jobId);
    setJobTitle(selectedJob?.title);
    setSelectedJobData(selectedJob);
    setCandidateView(false);
    setCandidateSendFeedback(false);
  };

  const changeCandidateFeedbackView = (status, data) => {
    setCandidateView(status);
    setCandidateFeedbackData(data);
  };

  const changeCandidateSendFeedback = (status, screeningId = '') => {
    setCandidateSendFeedback(status);
    setCandidateScreeningId(screeningId);
  };

  const handleBackButton = () => {
    if (state?.fromJobsTab) {
      navigate('/jobs');
    } else {
      navigate('/customer-listing');
    }
  };

  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid">
          <div className="layoutGrid__col">
            <div
              className={`listCol ${
                filteranchorEl
                  ? 'listCol__filter'
                  : searchanchorEl
                    ? 'listCol__search'
                    : ''
              }`}
            >
              <div className="listCol__head listing__head">
                <h2 className="listCol__head--title">
                  <button
                    className="btn border_0 p_0"
                    onClick={handleBackButton}
                  >
                    <svg
                      width="23"
                      height="25"
                      viewBox="0 0 23 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.375 18.5L8.625 12.5L14.375 6.5"
                        stroke="#150823"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <span>{firstLetterCapital(CompanyName)}</span>
                </h2>
                <div className="listCol__head--actions">
                  <div className="formField">
                    <button
                      className="btn btn__white p_0"
                      aria-describedby={searchPopoverid}
                      onClick={handleSeachClick}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                          stroke="#150823"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>

                  <div>
                    <button
                      className="btn btn__white p_0"
                      aria-describedby={filterPopoverid}
                      onClick={handleFilterClick}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M18.3337 2.5H1.66699L8.33366 10.3833V15.8333L11.667 17.5V10.3833L18.3337 2.5Z"
                          stroke="#150823"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="searchContainer">
                  <div className="formField">
                    <div className="formField__group sm">
                      <span className="formField__icon">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                            stroke="#9DA4AE"
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <input
                        type="text"
                        ref={searchRef}
                        className="formField__input"
                        placeholder="Search a job/recruiter"
                        onChange={(e) => searchInJobListing(e?.target?.value)}
                      />
                    </div>
                  </div>

                  <button
                    className="btn btn__transparent border_0 p_0"
                    onClick={handleSearchClose}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                        stroke="#150823"
                        strokeWidth="1.67"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <div className="filter__head">
                <h2 className="listCol__head--title">Filter</h2>
                <button
                  className="btn btn__transparent border_0 p_0"
                  onClick={handleFilterClose}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                      stroke="#150823"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>

              <div className="filterContainer">
                <ul className="listCol__list filter__list">
                  <li>
                    <div className="formRow">
                      <div className="formCol">
                        <div className="formField">
                          <label className="formField__label">Job Status</label>
                          <FormControl
                            sx={{ width: '100%' }}
                            size="small"
                            className="formField__select"
                          >
                            <Select
                              id="multiple-checkbox"
                              multiple
                              value={SkillsetFiltervalue}
                              onChange={filterJobset}
                              name="skillSet"
                              renderValue={(selected) => selected?.join(', ')}
                              MenuProps={MenuProps}
                              displayEmpty
                              IconComponent={() => <ArrowDown />}
                              classes={{ root: 'multiSelect' }}
                            >
                              {jobFilterList?.map((name) => (
                                <MenuItem key={name} value={name}>
                                  <Checkbox
                                    checked={
                                      SkillsetFiltervalue?.indexOf(name) > -1
                                    }
                                  />
                                  <ListItemText primary={name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>

                <div className="filter__footer">
                  <div className="btn__group flex--between">
                    <button
                      className="btn btn__transparent p_0"
                      onClick={(e) => {
                        getJobData(
                          pagination?.recordsPerPage,
                          pagination?.currentPage,
                          pagination?.search,
                          []
                        );
                        setSkillsetFiltervalue([]);
                        handleFilterClose();
                      }}
                    >
                      Clear
                    </button>
                    <button
                      className="btn btn__primary"
                      onClick={(e) => {
                        getJobData(
                          pagination?.recordsPerPage,
                          SkillsetFiltervalue?.length
                            ? 1
                            : pagination?.currentPage,
                          pagination?.search,
                          SkillsetFiltervalue
                        );
                        handleFilterClose();
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>

              <ul className="listCol__list">
                {jobList.map((row) => {
                  return (
                    <li
                      key={`jobListing${row?.jobId}`}
                      className={`listCol__list--item ${
                        jobId === row?.jobId
                          ? 'listCol__item--selected sm'
                          : 'sm'
                      }`}
                      onClick={() => {
                        setAddResponsiveClass(true);
                        selectNewJob(row);
                        setValue('1');
                      }}
                    >
                      <div className="item item--recruiter">
                        <div className="item__info">
                          <h4 className="item__info--title">
                            {firstLetterCapital(row?.title)}
                          </h4>
                          <div className="item__info--subtitle">
                            <p>{row?.location}</p>
                            <span>
                              <svg
                                width="5"
                                height="6"
                                viewBox="0 0 5 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  y="0.5"
                                  width="5"
                                  height="5"
                                  rx="2.5"
                                  fill="#384250"
                                />
                              </svg>
                              {row?.qualification?.join(',')}
                            </span>
                            <span>
                              <svg
                                width="5"
                                height="6"
                                viewBox="0 0 5 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  y="0.5"
                                  width="5"
                                  height="5"
                                  rx="2.5"
                                  fill="#384250"
                                />
                              </svg>
                              {row?.minExperience}-{row?.maxExperience} Years
                            </span>
                          </div>

                          <div className="item__info--subtitle">
                            {row?.assignedStatus ? (
                              <p>
                                Recruiter:
                                {firstLetterCapital(row?.recruiter?.firstName) +
                                  ' ' +
                                  firstLetterCapital(row?.recruiter?.lastName)}
                              </p>
                            ) : (
                              ''
                            )}
                            <span>
                              <svg
                                width="5"
                                height="6"
                                viewBox="0 0 5 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  y="0.5"
                                  width="5"
                                  height="5"
                                  rx="2.5"
                                  fill="#384250"
                                />
                              </svg>
                              Status: {firstLetterCapital(row?.status)} /
                              {row?.assignedStatus ? 'Assigned' : 'Unassigned'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}

                {jobList.length ? (
                  ' '
                ) : (
                  <div className="no-data">No Results Found!</div>
                )}
              </ul>

              <div className="pagination pagination__center">
                {pagination?.totalRecord ? (
                  <CustomPagination
                    totalPageCount={Math.ceil(
                      pagination?.totalRecord / pagination.recordsPerPage
                    )}
                    changePage={(event, value) => changePage(event, value)}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div
            className={`layoutGrid__col layoutGrid--responsive  ${
              addResponsiveClass ? 'layoutGrid--show' : ''
            }`}
          >
            <div className="dataCol">
              <div className="page page__full page__fullBody">
                <div className="page__body">
                  {jobList?.length > 0 ? (
                    <Box className="tabContainer tabContainer--inner">
                      <TabContext value={value}>
                        <Box className="tabContainer__head">
                          <button
                            className="btn btn__transparent btn__back border_0 p_0"
                            onClick={() => {
                              setAddResponsiveClass(false);
                            }}
                          >
                            <svg
                              width="23"
                              height="25"
                              viewBox="0 0 23 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.375 18.5L8.625 12.5L14.375 6.5"
                                stroke="#150823"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                          <TabList
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                          >
                            <Tab label="Job Details" value="1" />
                            <Tab label="Recruiters" value="2" />
                            <Tab label="Candidates" value="3" />
                            <Tab label="Questionnaire" value="4" />
                            <Tab label="Notes" value="5" />
                          </TabList>
                        </Box>

                        <TabPanel value="1" className="tabContainer__item">
                          <JobDescription jobId={jobId} />
                        </TabPanel>

                        <TabPanel value="2" className="tabContainer__item">
                          <JobRecruiter
                            getJobData={getJobData}
                            jobId={jobId}
                            jobTitle={jobTitle}
                            selectedJobData={selectedJobData}
                          />
                        </TabPanel>
                        <TabPanel value="3" className="tabContainer__item">
                          {candidateFeedbackView ? (
                            <JobFeedbackView
                              changeCandidateFeedbackView={
                                changeCandidateFeedbackView
                              }
                              jobData={candidateFeedbackData}
                            />
                          ) : candidateSendFeedback ? (
                            <JobSendFeedback
                              changeCandidateSendFeedback={
                                changeCandidateSendFeedback
                              }
                              jobId={jobId}
                              candidateSreeningId={candidateSreeningId}
                            />
                          ) : (
                            <JobCandidate
                              jobId={jobId}
                              changeCandidateFeedbackView={
                                changeCandidateFeedbackView
                              }
                              changeCandidateSendFeedback={
                                changeCandidateSendFeedback
                              }
                              selectedJobData={selectedJobData}
                            />
                          )}
                        </TabPanel>
                        <TabPanel value="4" className="tabContainer__item">
                          <JobQuestionnaire jobId={jobId} />
                        </TabPanel>
                        <TabPanel value="5" className="tabContainer__item">
                          <JobNotes jobId={jobId} />
                        </TabPanel>
                      </TabContext>
                    </Box>
                  ) : (
                    <div className="no-data">No Results Found!</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobListing;
