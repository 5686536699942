import { React, useState, useEffect, useRef } from 'react';
import { SuccessfullyModal } from '@/components/modals/successfully-modal';
import { firstLetterCapital, GetUserDetails } from '@/helper';
import ErrorModal from '@/components/modals/error-modal';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import { LinkedInRegex, URLRegex } from '@/regex';
import BackArrow from '@/assets/svgs/BackArrow';
import { getStaticData } from '@/services/static-data-service';
import DefaultImg from '@/assets/images/default-img.png';
import SearchCross from '@/assets/svgs/SearchCross';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ArrowDown from '@/elements/ArrowDown';
import {
  fileUpload,
  addCompany,
  updateCompany
} from '@/services/customers-service';
import * as CONSTANTS from '@/utils/constants';
import * as MESSAGES from '@/utils/popupMessages';

export const AddCustomer = (props) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const add3Ref = useRef();
  const add1DivRef = useRef();
  const add3DivRef = useRef();
  const [imageLoading, setImageLoading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [openSuccessfullModal, setOpenSuccessfullModal] = useState(false);
  const [successfullMsg, setSuccessfullMsg] = useState('');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMsgs, setErrorMsgs] = useState('');
  const [staticData, setStaticData] = useState({});
  const [showAddressFiels, setShowAddressFiels] = useState(
    props?.EditCustomerMode ? true : false
  );

  const [SelectedCompanyData, setSelectedCompanyData] = useState(
    props?.ActiveCompanyData
  );
  const [IndustryTypeArr, setIndustryTypeArr] = useState(
    props?.EditCustomerMode ? SelectedCompanyData?.industryType : []
  );
  const [ImageUrl, setImageUrl] = useState(
    props?.EditCustomerMode ? SelectedCompanyData?.companyLogo : null
  );
  const [fields, setFields] = useState({
    companyName: props?.EditCustomerMode
      ? SelectedCompanyData?.companyName
      : '',
    country: props?.EditCustomerMode
      ? {
          value: SelectedCompanyData?.country,
          name: SelectedCompanyData?.countryName
        }
      : null,
    state: props?.EditCustomerMode
      ? {
          value: SelectedCompanyData?.state,
          name: SelectedCompanyData?.stateName
        }
      : null,
    city: props?.EditCustomerMode ? SelectedCompanyData?.city : null,
    companyAddress: props?.EditCustomerMode
      ? SelectedCompanyData?.address
      : null,
    zipCode: props?.EditCustomerMode ? SelectedCompanyData?.zipcode : null,
    companyWebsite: props?.EditCustomerMode
      ? SelectedCompanyData?.websiteLink
      : null,
    companyLinkedIn: props?.EditCustomerMode
      ? SelectedCompanyData?.linkedin
      : null,
    industryType: props?.EditCustomerMode
      ? SelectedCompanyData?.industryType
      : []
  });

  useEffect(() => {
    if (add3DivRef.current) {
      add3DivRef.current.style.display = 'none';
    }
    const options = {
      componentRestrictions: {
        country: fields?.country?.value ? fields?.country?.value : 'US'
      }
    };
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener('place_changed', async function () {
      setShowAddressFiels(true);
      const place = await autoCompleteRef.current.getPlace();
      handleAddressChange(place);
    });
  }, [fields?.country]);

  const replaceLast = (str, pattern, replacement) => {
    const match =
      typeof pattern === 'string'
        ? pattern
        : (str.match(new RegExp(pattern.source, 'g')) || []).slice(-1)[0];
    if (!match) return str;
    const last = str.lastIndexOf(match);
    return last !== -1
      ? `${str.slice(0, last)}${replacement}${str.slice(last + match.length)}`
      : str;
  };

  const handleAddressChange = (address) => {
    const zipCode = extractComponent(address, 'postal_code')
      ? extractComponent(address, 'postal_code')
      : extractComponent(address, 'postal_code_prefix');
    const country = extractComponent(address, 'country');
    const countryCode = shortNameComponent(address, 'country');
    const state = extractComponent(address, 'administrative_area_level_1');
    const stateCode = shortNameComponent(
      address,
      'administrative_area_level_1'
    );
    const city = extractComponent(address, 'locality');

    const addressSplit = address.formatted_address.split(city);
    let modifiedAddress = '';
    if (addressSplit.length && addressSplit[0].length) {
      modifiedAddress = replaceLast(addressSplit[0], ', ', '');
    }

    const streetAddress = modifiedAddress.includes(address.name)
      ? modifiedAddress
      : `${address.name}`;
    let obj = {
      location: streetAddress,
      zipcode: zipCode.replace(/\s/g, ''),
      country: country,
      countryCode: countryCode,
      stateCode: stateCode,
      state: state,
      city: city
    };
    setFields({
      ...fields,
      companyAddress: obj?.location,
      country: {
        value: obj?.countryCode,
        name: obj?.country
      },
      state: {
        value: obj?.stateCode,
        name: obj?.state
      },
      city: obj?.city,
      zipCode: obj?.zipcode
    });
    handleValidation();
  };

  const handleAddressChangeDiv = (value, inputName) => {
    if (value?.length >= 3 && inputName === 'address3') {
      add3DivRef.current.style.display = 'none';
      add1DivRef.current.style.display = 'block';
      inputRef.current.focus();
    } else if (value?.length < 3 && inputName === 'address1') {
      add1DivRef.current.style.display = 'none';
      add3DivRef.current.style.display = 'block';
      add3Ref.current.focus();
    } else {
      if (value?.length < 3) {
        add1DivRef.current.style.display = 'none';
        add3DivRef.current.style.display = 'block';
        add3Ref.current.focus();
      } else {
        add3DivRef.current.style.display = 'none';
        add1DivRef.current.style.display = 'block';
        inputRef.current.focus();
      }
    }
  };

  const extractComponent = (address, type) => {
    const component = address.address_components.find((comp) =>
      comp.types.includes(type)
    );
    return component
      ? `${type === 'subpremise' ? '#' : ''}${component.long_name}`
      : '';
  };
  const shortNameComponent = (address, type) => {
    const component = address.address_components.find((comp) =>
      comp.types.includes(type)
    );
    return component
      ? `${type === 'subpremise' ? '#' : ''}${component.short_name}`
      : '';
  };

  useEffect(() => {
    callGetStaticData();
  }, []);

  const callGetStaticData = async () => {
    const data = await getStaticData();
    setStaticData(data?.data);
  };

  const closeSucessfullModal = () => {
    setOpenSuccessfullModal(false);
  };

  const closeErrorModal = () => {
    setOpenErrorModal(false);
  };

  const multiSelecthandler = (event) => {
    let field = fields;
    const {
      target: { value }
    } = event;
    field['industryType'] =
      typeof value === 'string' ? value.split(',') : value;
    setFields(field);
    setIndustryTypeArr(typeof value === 'string' ? value.split(',') : value);
    handleValidation(event);
  };

  const handleChange = (e) => {
    let field = fields;
    const { name, value } = e?.target;
    if (name === 'companyName' || name === 'companyAddress') {
      field[name] = value === '' ? null : value.trimStart();
      setFields(field);
    } else if (name === 'companyAddress3') {
      field['companyAddress'] = value === '' ? null : value.trimStart();
      setFields(field);
    } else if (name === 'country') {
      field['country'] = value === '' ? null : value;
      field['companyAddress'] = '';
      field['state'] = '';
      field['city'] = '';
      field['zipCode'] = '';
      setShowAddressFiels(false);
      setFields(field);
    } else {
      field[name] = value === '' ? null : value;
      setFields(field);
    }
    handleValidation(e);
  };

  const handleValidation = (e) => {
    let errorsT = errors;
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    let fieldname = e ? (e.target ? e.target.name : e) : e;
    let updatedValue = fieldValue;

    if (fieldname === undefined || fieldname === 'companyName') {
      if (fieldname === undefined) {
        fieldValue = fields['companyName'] ? fields['companyName'] : '';
        updatedValue = fieldValue;
      }
      delete errorsT['companyName'];
      delete errorsT['companyNameError'];
      if (!updatedValue || updatedValue.trim() === '') {
        formIsValid = false;
        errorsT['companyName'] = CONSTANTS?.COMPANY_NAME?.validation?.required;
        errorsT['companyNameError'] = true;
      }
    }

    // if (fieldname === undefined || fieldname === 'companyAddress') {
    //   if (fieldname === undefined) {
    //     fieldValue = fields['companyAddress'] ? fields['companyAddress'] : '';
    //     updatedValue = fieldValue;
    //   }
    //   delete errorsT['companyAddress'];
    //   delete errorsT['companyAddressError'];
    //   if (!updatedValue || updatedValue.trim() === '') {
    //     formIsValid = false;
    //     errorsT['companyAddress'] = 'Company address is required';
    //     errorsT['companyAddressError'] = true;
    //   }
    // }

    // if (fieldname === undefined || fieldname === 'country') {
    //   if (fieldname === undefined) {
    //     fieldValue = fields['country'] ? fields['country'] : '';
    //     updatedValue = fieldValue;
    //   }
    //   delete errorsT['country'];
    //   delete errorsT['countryError'];
    //   if (!updatedValue && updatedValue?.length == 0) {
    //     formIsValid = false;
    //     errorsT['country'] = CONSTANTS?.COUNTRY?.validation?.valid;
    //     errorsT['countryError'] = true;
    //   }
    // }

    // if (fieldname === undefined || fieldname === 'state') {
    //   if (fieldname === undefined) {
    //     fieldValue = fields['state'] ? fields['state'] : '';
    //     updatedValue = fieldValue;
    //   }
    //   delete errorsT['state'];
    //   delete errorsT['stateError'];
    //   if (!updatedValue && updatedValue?.length == 0) {
    //     formIsValid = false;
    //     errorsT['state'] = 'Please enter state';
    //     errorsT['stateError'] = true;
    //   }
    // }

    // if (fieldname === undefined || fieldname === 'city') {
    //   if (fieldname === undefined) {
    //     fieldValue = fields['city'] ? fields['city'] : '';
    //     updatedValue = fieldValue;
    //   }
    //   delete errorsT['city'];
    //   delete errorsT['cityError'];
    //   if (!updatedValue && updatedValue?.length == 0) {
    //     formIsValid = false;
    //     errorsT['city'] = 'Please enter state';
    //     errorsT['cityError'] = true;
    //   }
    // }

    if (fieldname === undefined || fieldname === 'zipCode') {
      if (fieldname === undefined) {
        fieldValue = fields['zipCode'] ? fields['zipCode'] : '';
        updatedValue = fieldValue;
      }
      delete errorsT['zipCode'];
      delete errorsT['zipCodeError'];
      if (
        fields['country'] !== null &&
        fields['country']['value'] == 'US' &&
        updatedValue?.length === 0
      ) {
        formIsValid = false;
        errorsT['zipCode'] = CONSTANTS?.ZIP_CODE?.validation?.valid;
        errorsT['zipCodeError'] = true;
      }
    }

    if (fieldname === undefined || fieldname === 'industryType') {
      if (fieldname === undefined) {
        fieldValue = fields['industryType'] ? fields['industryType'] : '';
        updatedValue = fieldValue;
      }
      delete errorsT['industryType'];
      delete errorsT['industryTypeError'];
      if (!updatedValue || updatedValue?.length === 0) {
        formIsValid = false;
        errorsT['industryType'] =
          CONSTANTS?.INDUSTRY_TYPE?.validation?.required;
        errorsT['industryTypeError'] = true;
      }
    }

    if (fieldname === undefined || fieldname === 'companyWebsite') {
      if (fieldname === undefined) {
        fieldValue = fields['companyWebsite'] ? fields['companyWebsite'] : '';
        updatedValue = fieldValue;
      }
      delete errorsT['companyWebsite'];
      delete errorsT['companyWebsiteError'];
      if (updatedValue && !URLRegex.test(updatedValue.toLowerCase())) {
        formIsValid = false;
        errorsT['companyWebsite'] = CONSTANTS?.WEBSITE_URL?.validation?.valid;
        errorsT['companyWebsiteError'] = true;
      }
    }

    if (fieldname === undefined || fieldname === 'companyLinkedIn') {
      if (fieldname === undefined) {
        fieldValue = fields['companyLinkedIn'] ? fields['companyLinkedIn'] : '';
        updatedValue = fieldValue;
      }
      delete errorsT['companyLinkedIn'];
      delete errorsT['companyLinkedInError'];
      if (updatedValue && !LinkedInRegex.test(updatedValue.toLowerCase())) {
        formIsValid = false;
        errorsT['companyLinkedIn'] = CONSTANTS?.LINKEDIN?.validation?.valid;
        errorsT['companyLinkedInError'] = true;
      }
    }

    setErrors({
      ...errorsT
    });
    return {
      formIsValid,
      updatedValue
    };
  };

  const handleFileUpload = async (e) => {
    let file = e?.target?.files;
    setImageLoading(true);
    let formData = new FormData();
    formData.append('imgFile', file[0]);
    const response = await fileUpload(formData);
    const { meta } = response;
    if (meta?.status) {
      setImageUrl(response?.data?.url);
    } else {
      setErrorMsgs(response?.meta?.msg);
      setOpenErrorModal(true);
    }
    setImageLoading(false);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const userDetails = GetUserDetails();
    const { formIsValid } = handleValidation();
    if (formIsValid) {
      let body = {
        companyName: fields?.companyName ?? null,
        industryType: IndustryTypeArr,
        country: fields?.country?.value ?? null,
        countryName: fields?.country?.name ?? null,
        stateName: fields?.state?.name ?? null,
        zipcode: fields?.zipCode ?? null,
        state: fields?.state?.value ?? null,
        city: fields?.city ?? null,
        address: fields?.companyAddress ?? null,
        companyLogo: ImageUrl ?? null,
        websiteLink: fields?.companyWebsite ?? null,
        linkedin: fields?.companyLinkedIn ?? null,
        addedBy: userDetails?.adminId
      };
      try {
        const response = await addCompany(body);
        const { meta } = response;
        if (meta?.status) {
          let { message } = MESSAGES?.addCompany;
          setSuccessfullMsg(message?.successfull);
          setOpenSuccessfullModal(true);
          setTimeout(() => {
            props?.backToCustomerListing(0);
          }, 3000);
        } else {
          setErrorMsgs(response?.meta?.msg);
          setOpenErrorModal(true);
        }
      } catch (error) {
        setErrorMsgs(error?.message);
        setOpenErrorModal(true);
        console.log('axios error --->', error);
      }
    }
    setLoading(false);
  };
  const handleUpdateCustomer = async (e) => {
    setLoading(true);
    e.preventDefault();
    const userDetails = GetUserDetails();
    const { formIsValid } = handleValidation();
    if (formIsValid) {
      let body = {
        companyId: SelectedCompanyData?.id
          ? SelectedCompanyData?.id
          : SelectedCompanyData?.companyId,
        industryType: IndustryTypeArr,
        country: fields?.country?.value ?? null,
        zipcode: fields?.zipCode ?? null,
        state: fields?.state?.value ?? null,
        stateName: fields?.state?.name ?? null,
        countryName: fields?.country?.name ?? null,
        companyName: fields?.companyName ?? null,
        city: fields?.city ?? null,
        address: fields?.companyAddress ?? null,
        companyLogo: ImageUrl ?? null,
        websiteLink: fields?.companyWebsite ?? null,
        linkedin: fields?.companyLinkedIn ?? null
      };
      try {
        const response = await updateCompany(body);
        const { meta } = response;
        if (meta?.status) {
          const { message } = MESSAGES?.updateCompany;
          setSuccessfullMsg(message?.successfull);
          setOpenSuccessfullModal(true);
          setTimeout(() => {
            props?.backToCustomerListing(props?.activeCompanyId, body);
          }, 3000);
        } else {
          setErrorMsgs(response?.meta?.msg);
          setOpenErrorModal(true);
        }
      } catch (error) {
        setErrorMsgs(error?.message);
        setOpenErrorModal(true);
        console.log('axios error --->', error);
      }
    }
    setLoading(false);
  };
  return (
    <>
      <div
        className={`layoutGrid__col layoutGrid--responsive  ${
          props?.addResponsiveClass ? 'layoutGrid--show' : ''
        }`}
      >
        <div className="dataCol">
          <div className="page page__full">
            <div className="page__head">
              <h2 className="page__head--title">
                <button
                  className="btn btn__transparent btn__back border_0 p_0"
                  onClick={() => {
                    props?.setAddResponsiveClass(false);
                  }}
                >
                  <BackArrow />
                </button>
                {props?.EditCustomerMode
                  ? firstLetterCapital(SelectedCompanyData?.companyName)
                  : 'Add Company'}
              </h2>
              <div className="page__head--actions">
                <button
                  type="button"
                  className="btn btn__white"
                  onClick={() => {
                    props?.backToCustomerListing(
                      props?.activeCompanyId,
                      props?.ActiveCompanyData
                    );
                    props?.setAddResponsiveClass(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn__primary"
                  type="button"
                  disabled={
                    Loading || Object.keys(errors)?.length ? true : false
                  }
                  onClick={(e) => {
                    props?.EditCustomerMode
                      ? handleUpdateCustomer(e)
                      : handleSubmit(e);
                  }}
                >
                  {props?.EditCustomerMode ? 'Update' : 'Add'}
                </button>
              </div>
            </div>

            <div className="page__body">
              <form className="form">
                <section className="form__section">
                  <h4 className="form__section--title">Company Information</h4>
                  <div className="formRow">
                    <div className="formCol">
                      <div className="formField">
                        <div className="uploadField">
                          <div className="uploadImage">
                            <img
                              src={ImageUrl ? ImageUrl : DefaultImg}
                              alt="Company"
                              width="140"
                              height="140"
                            />
                            {ImageUrl && (
                              <button
                                className="uploadImage__remove"
                                onClick={() => setImageUrl(null)}
                              >
                                <SearchCross />
                              </button>
                            )}
                          </div>

                          <div className="uploadAction">
                            <label htmlFor="uploadFile">
                              {ImageUrl ? 'Update logo' : 'Upload logo'}
                              <input
                                type="file"
                                id="uploadFile"
                                name="companyLogo"
                                accept=".jpg, .jpeg, .png, .PNG, .JPEG, .JPG"
                                onChange={(e) => handleFileUpload(e)}
                                disabled={imageLoading ? true : false}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="formRow formRow__2">
                    <div className="formCol">
                      <div
                        className={`formField ${
                          errors?.companyNameError ? 'fieldError' : ''
                        }`}
                      >
                        <label className="formField__label">Company Name</label>
                        <div className="formField__group sm">
                          <input
                            type="text"
                            className="formField__input sm"
                            placeholder="Company Name"
                            name="companyName"
                            value={fields?.companyName ?? ''}
                            onChange={(e) => handleChange(e)}
                          />
                          <span className="formField__icon">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.25 3.55556V4.30556H8H15.25V15.25H0.75V0.75H7.25V3.55556ZM3.2 14.9722H3.95V14.2222V12.4444V11.6944H3.2H1.6H0.85V12.4444V14.2222V14.9722H1.6H3.2ZM3.2 11.4167H3.95V10.6667V8.88889V8.13889H3.2H1.6H0.85V8.88889V10.6667V11.4167H1.6H3.2ZM3.2 7.86111H3.95V7.11111V5.33333V4.58333H3.2H1.6H0.85V5.33333V7.11111V7.86111H1.6H3.2ZM3.2 4.30556H3.95V3.55556V1.77778V1.02778H3.2H1.6H0.85V1.77778V3.55556V4.30556H1.6H3.2ZM6.4 14.9722H7.15V14.2222V12.4444V11.6944H6.4H4.8H4.05V12.4444V14.2222V14.9722H4.8H6.4ZM6.4 11.4167H7.15V10.6667V8.88889V8.13889H6.4H4.8H4.05V8.88889V10.6667V11.4167H4.8H6.4ZM6.4 7.86111H7.15V7.11111V5.33333V4.58333H6.4H4.8H4.05V5.33333V7.11111V7.86111H4.8H6.4ZM6.4 4.30556H7.15V3.55556V1.77778V1.02778H6.4H4.8H4.05V1.77778V3.55556V4.30556H4.8H6.4ZM14.4 14.9722H15.15V14.2222V5.33333V4.58333H14.4H8H7.25V5.33333V7.11111V7.86111H8H8.85V8.13889H8H7.25V8.88889V10.6667V11.4167H8H8.85V11.6944H8H7.25V12.4444V14.2222V14.9722H8H14.4ZM11.95 8.13889V7.86111H12.05V8.13889H11.95ZM11.95 11.6944V11.4167H12.05V11.6944H11.95Z"
                                fill="#384250"
                                stroke="black"
                                strokeWidth="1.5"
                              />
                            </svg>
                          </span>
                        </div>

                        <span className="formField__error">
                          {errors?.companyName}
                        </span>
                      </div>
                    </div>

                    <div className="formCol">
                      <div
                        className={`formField ${
                          errors?.companyWebsiteError ? 'fieldError' : ''
                        }`}
                      >
                        <label className="formField__label">
                          Company Website
                        </label>
                        <div className="formField__group sm">
                          <input
                            type="text"
                            className="formField__input sm"
                            placeholder="Company Website"
                            name="companyWebsite"
                            value={fields?.companyWebsite ?? ''}
                            onChange={(e) => handleChange(e)}
                          />
                          <span className="formField__icon">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_1867_9625)">
                                <path
                                  d="M6.66668 8.66697C6.95298 9.04972 7.31825 9.36642 7.73771 9.5956C8.15717 9.82477 8.62102 9.96105 9.09778 9.99519C9.57454 10.0293 10.0531 9.96055 10.5009 9.79349C10.9487 9.62643 11.3554 9.36502 11.6933 9.02697L13.6933 7.02697C14.3005 6.3983 14.6365 5.55629 14.6289 4.6823C14.6213 3.80831 14.2708 2.97227 13.6527 2.35424C13.0347 1.73621 12.1987 1.38565 11.3247 1.37806C10.4507 1.37046 9.60869 1.70644 8.98001 2.31364L7.83334 3.45364M9.33334 7.33364C9.04704 6.95088 8.68177 6.63418 8.26231 6.40501C7.84285 6.17584 7.37901 6.03956 6.90224 6.00541C6.42548 5.97127 5.94695 6.04006 5.49911 6.20711C5.05128 6.37417 4.6446 6.63559 4.30668 6.97364L2.30668 8.97364C1.69948 9.60231 1.3635 10.4443 1.3711 11.3183C1.37869 12.1923 1.72926 13.0283 2.34728 13.6464C2.96531 14.2644 3.80135 14.615 4.67534 14.6226C5.54933 14.6301 6.39134 14.2942 7.02001 13.687L8.16001 12.547"
                                  stroke="#384250"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1867_9625">
                                  <rect width="16" height="16" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                        </div>

                        <span className="formField__error">
                          {errors?.companyWebsite}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="formRow formRow__2">
                    <div className="formCol">
                      <div
                        className={`formField ${
                          errors?.companyLinkedInError ? 'fieldError' : ''
                        }`}
                      >
                        <label className="formField__label">
                          Company LinkedIn
                        </label>
                        <div className="formField__group sm">
                          <input
                            type="text"
                            className="formField__input sm"
                            placeholder="Company LinkedIn"
                            name="companyLinkedIn"
                            value={fields?.companyLinkedIn ?? ''}
                            onChange={(e) => handleChange(e)}
                          />
                          <span className="formField__icon">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_1867_10725)">
                                <path
                                  d="M6.66668 8.66648C6.95298 9.04923 7.31825 9.36594 7.73771 9.59511C8.15717 9.82428 8.62102 9.96056 9.09778 9.9947C9.57454 10.0288 10.0531 9.96006 10.5009 9.793C10.9487 9.62594 11.3554 9.36453 11.6933 9.02648L13.6933 7.02648C14.3005 6.39781 14.6365 5.5558 14.6289 4.68181C14.6213 3.80782 14.2708 2.97178 13.6527 2.35375C13.0347 1.73573 12.1987 1.38516 11.3247 1.37757C10.4507 1.36997 9.60869 1.70595 8.98001 2.31315L7.83334 3.45315M9.33334 7.33315C9.04704 6.9504 8.68177 6.63369 8.26231 6.40452C7.84285 6.17535 7.37901 6.03907 6.90224 6.00493C6.42548 5.97078 5.94695 6.03957 5.49911 6.20663C5.05128 6.37368 4.6446 6.6351 4.30668 6.97315L2.30668 8.97315C1.69948 9.60182 1.3635 10.4438 1.3711 11.3178C1.37869 12.1918 1.72926 13.0278 2.34728 13.6459C2.96531 14.2639 3.80135 14.6145 4.67534 14.6221C5.54933 14.6297 6.39134 14.2937 7.02001 13.6865L8.16001 12.5465"
                                  stroke="#384250"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1867_10725">
                                  <rect width="16" height="16" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                        </div>

                        <span className="formField__error">
                          {errors?.companyLinkedIn}
                        </span>
                      </div>
                    </div>

                    <div className="formCol">
                      <div
                        className={`formField ${
                          errors?.industryTypeError ? 'fieldError' : ''
                        }`}
                      >
                        <label className="formField__label">
                          Industry Type
                        </label>
                        <Autocomplete
                          id="tags-filled"
                          multiple
                          options={
                            staticData?.industryType?.length
                              ? staticData?.industryType
                              : []
                          }
                          defaultValue={IndustryTypeArr}
                          value={IndustryTypeArr}
                          name="industryType"
                          onChange={(event, newValue) => {
                            multiSelecthandler({
                              target: { name: 'industryType', value: newValue }
                            });
                          }}
                          freeSolo
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="filled"
                              placeholder="Select"
                              className="autoCompleteInput"
                            />
                          )}
                        />
                        <span className="formField__error">
                          {errors?.industryType}
                        </span>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="form__section">
                  <h4 className="form__section--title">Address</h4>
                  <div className="formRow">
                    <div className="formCol">
                      <div
                        className={`formField ${
                          errors?.countryError ? 'fieldError' : ''
                        }`}
                      >
                        <label className="formField__label">Country</label>
                        <Autocomplete
                          id="tags-filled"
                          options={staticData?.taxCountry}
                          getOptionLabel={(option) => option?.name || option}
                          defaultValue={fields?.country || ''}
                          value={fields?.country}
                          name="country"
                          onChange={(event, newValue) => {
                            handleChange({
                              target: { name: 'country', value: newValue }
                            });
                          }}
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="filled"
                              placeholder="Select"
                              className="autoCompleteInput"
                            />
                          )}
                        />
                        {/* <FormControl
                          fullWidth
                          size="small"
                          className="formField__select"
                        >
                          <Select
                            onChange={(e) => handleChange(e)}
                            IconComponent={() => <ArrowDown />}
                            placeholder="Country"
                            name="country"
                            value={fields?.country ?? ''}
                            displayEmpty
                            renderValue={(selected) => {
                              if (!selected) {
                                return (
                                  <span style={{ opacity: '0.8' }}>Select</span>
                                );
                              }

                              return selected.name;
                            }}
                          >
                            {staticData?.country?.map((item, index) => (
                              <MenuItem key={index} value={item}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl> */}
                        {/* <input
                          type="text"
                          className="formField__input sm"
                          placeholder="Country"
                          value={fields?.country?.name ?? ''}
                          name="country"
                          onChange={(e) => handleChange(e)}
                        /> */}
                        <span className="formField__error">
                          {errors?.country}
                        </span>
                      </div>
                    </div>
                  </div>
                  {fields?.country && (
                    <div className="formRow" ref={add1DivRef}>
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.companyAddressError ? 'fieldError' : ''
                          }`}
                        >
                          <label className="formField__label">
                            Company Address
                          </label>
                          <div className="formField__group sm">
                            <input
                              type="text"
                              className="formField__input sm"
                              placeholder="Company Address"
                              name="companyAddress"
                              value={fields?.companyAddress ?? ''}
                              onChange={(e) => {
                                handleAddressChangeDiv(
                                  e?.target?.value,
                                  'address'
                                );
                                handleChange(e);
                              }}
                              ref={inputRef}
                            />
                            <span className="formField__icon">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clipPath="url(#clip0_1867_10112)">
                                  <path
                                    d="M5.33329 12.0002L0.666626 14.6668V4.00016L5.33329 1.3335M5.33329 12.0002L10.6666 14.6668M5.33329 12.0002V1.3335M10.6666 14.6668L15.3333 12.0002V1.3335L10.6666 4.00016M10.6666 14.6668V4.00016M10.6666 4.00016L5.33329 1.3335"
                                    stroke="#384250"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_1867_10112">
                                    <rect width="16" height="16" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                          </div>

                          <span className="formField__error">
                            {errors?.companyAddress}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {fields?.country && (
                    <div className="formRow" ref={add3DivRef}>
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.companyAddressError ? 'fieldError' : ''
                          }`}
                        >
                          <label className="formField__label">
                            Company Address3
                          </label>
                          <div className="formField__group sm">
                            <input
                              type="text"
                              className="formField__input sm"
                              placeholder="Company Address3"
                              name="companyAddress3"
                              value={fields?.companyAddress ?? ''}
                              ref={add3Ref}
                              onChange={(e) => {
                                handleAddressChangeDiv(
                                  e?.target?.value,
                                  'address3'
                                );
                                handleChange(e);
                              }}
                            />
                            <span className="formField__icon">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clipPath="url(#clip0_1867_10112)">
                                  <path
                                    d="M5.33329 12.0002L0.666626 14.6668V4.00016L5.33329 1.3335M5.33329 12.0002L10.6666 14.6668M5.33329 12.0002V1.3335M10.6666 14.6668L15.3333 12.0002V1.3335L10.6666 4.00016M10.6666 14.6668V4.00016M10.6666 4.00016L5.33329 1.3335"
                                    stroke="#384250"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_1867_10112">
                                    <rect width="16" height="16" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                          </div>

                          <span className="formField__error">
                            {errors?.companyAddress}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {showAddressFiels && (
                    <div className="formRow formRow__2">
                      {/* STATE */}
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.stateError ? 'fieldError' : ''
                          }`}
                        >
                          <label className="formField__label">State</label>
                          {/* <FormControl
                          fullWidth
                          size="small"
                          className="formField__select"
                        >
                          <Select
                            onChange={(e) => handleChange(e)}
                            IconComponent={() => <ArrowDown />}
                            placeholder="State"
                            name="state"
                            value={fields?.state ?? ''}
                            displayEmpty
                            renderValue={(selected) => {
                              if (!selected) {
                                return (
                                  <span style={{ opacity: '0.8' }}>Select</span>
                                );
                              }

                              return selected.name;
                            }}
                          >
                            {States?.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl> */}
                          <input
                            type="text"
                            className="formField__input sm"
                            placeholder="State"
                            value={fields?.state?.name ?? ''}
                            name="state"
                            onChange={(e) => handleChange(e)}
                          />
                          <span className="formField__error">
                            {errors?.state}
                          </span>
                        </div>
                      </div>
                      {/* CITY */}
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.cityError ? 'fieldError' : ''
                          }`}
                        >
                          <label className="formField__label">City</label>
                          {/* <FormControl
                          fullWidth
                          size="small"
                          className="formField__select"
                        >
                          <Select
                            onChange={(e) => handleChange(e)}
                            IconComponent={() => <ArrowDown />}
                            placeholder="City"
                            name="city"
                            value={fields?.city ?? ''}
                            displayEmpty
                            renderValue={(selected) => {
                              if (!selected) {
                                return (
                                  <span style={{ opacity: '0.8' }}>Select</span>
                                );
                              }

                              return selected;
                            }}
                          >
                            {Cities?.map((item, index) => (
                              <MenuItem key={index} value={item.name}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl> */}
                          <input
                            type="text"
                            className="formField__input sm"
                            placeholder="City"
                            value={fields?.city ?? ''}
                            name="city"
                            onChange={(e) => handleChange(e)}
                          />
                          <span className="formField__error">
                            {errors?.city}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {showAddressFiels && (
                    <div className="formRow formRow__2">
                      {/* ZIPCODE */}
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.zipCodeError ? 'fieldError' : ''
                          }`}
                        >
                          <label className="formField__label">Zipcode</label>
                          <div className="formField__group sm">
                            <input
                              type="text"
                              className="formField__input sm"
                              placeholder="12345"
                              value={fields?.zipCode ?? ''}
                              name="zipCode"
                              onChange={(e) => handleChange(e)}
                            />
                            <span className="formField__icon">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clipPath="url(#clip0_1867_8496)">
                                  <path
                                    d="M14 6.6665C14 11.3332 8 15.3332 8 15.3332C8 15.3332 2 11.3332 2 6.6665C2 5.0752 2.63214 3.54908 3.75736 2.42386C4.88258 1.29864 6.4087 0.666504 8 0.666504C9.5913 0.666504 11.1174 1.29864 12.2426 2.42386C13.3679 3.54908 14 5.0752 14 6.6665Z"
                                    stroke="#384250"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M8 8.6665C9.10457 8.6665 10 7.77107 10 6.6665C10 5.56193 9.10457 4.6665 8 4.6665C6.89543 4.6665 6 5.56193 6 6.6665C6 7.77107 6.89543 8.6665 8 8.6665Z"
                                    stroke="#384250"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_1867_8496">
                                    <rect width="16" height="16" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                          </div>

                          <span className="formField__error">
                            {errors?.zipCode}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </section>
              </form>
            </div>
          </div>
        </div>
      </div>

      <SuccessfullyModal
        openModal={openSuccessfullModal}
        closeModal={closeSucessfullModal}
        Heading={
          props?.EditCustomerMode
            ? MESSAGES?.updateCompany?.message?.heading
            : MESSAGES?.addCompany?.message?.heading
        }
        sucessfullMsg={successfullMsg}
      />
      <ErrorModal
        openModal={openErrorModal}
        errorMessage={errorMsgs}
        closeModal={closeErrorModal}
      />
    </>
  );
};

export default AddCustomer;
