import React, { useRef, useState, useEffect, useContext } from 'react';
import CustomPagination from '@/elements/pagination/customPagination';
import PlusIcon from '@/assets/svgs/PlusIcon';
import FollowersCount from '@/assets/svgs/FollowersCount';
import { LoaderContext } from '@/components/Loader/Loader';
import Search from '@/assets/svgs/Search';
import Filter from '@/assets/svgs/Filter';
import SearchCross from '@/assets/svgs/SearchCross';
import { firstLetterCapital } from '@/helper';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import { companyList } from '@/services/customers-service';
import { getStaticData } from '@/services/static-data-service';

const CompanyListing = ({
  activeCompanyId,
  setValue,
  onCompanySelection,
  setAddCustomerScreen,
  refreshCompanyListing,
  setAddResponsiveClass,
  EditCustomerMode,
  checkHaveCustomersInSearch,
  setActiveCompanyId
}) => {
  const searchRef = useRef('');
  const setLoading = useContext(LoaderContext);

  const [searchanchorEl, setSeachAnchorEl] = useState(null);
  const [customerList, setCustomerList] = useState([]);
  const [cstomerFilterList, setCustomerFilterList] = useState([]);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [SkillsetFiltervalue, setSkillsetFiltervalue] = useState([]);
  const [filteranchorEl, setFilterAnchorEl] = useState(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0,
    search: '',
    industryType: []
  });

  const searchOpen = Boolean(searchanchorEl);
  const filterOpen = Boolean(filteranchorEl);
  const searchPopoverid = searchOpen ? 'simple-popover' : '';
  const filterPopoverid = filterOpen ? 'simple-popover' : '';

  useEffect(() => {
    getStaticDataLocal();
  }, []);

  useEffect(() => {
    getCompanyData();
  }, [refreshCompanyListing, pagination]);

  const getStaticDataLocal = async () => {
    try {
      const response = await getStaticData();
      const { data, meta } = response;
      if (meta?.status) {
        setCustomerFilterList(data?.industryType);
      }
    } catch (error) {
      console.log('axios error --->', error);
    }
  };

  const changePage = (event, value) => {
    setPagination({ ...pagination, currentPage: value });
  };

  const searchInCompanyListing = (e) => {
    setPagination({ ...pagination, currentPage: 1, search: e?.target?.value });
  };

  const getCompanyData = async (
    recordsPerPage = pagination?.recordsPerPage,
    currentPage = pagination?.currentPage,
    search = pagination?.search,
    industryType = pagination?.industryType
  ) => {
    setLoading(true);
    try {
      const response = await companyList({
        limit: recordsPerPage,
        page: currentPage,
        search: search,
        industryType: industryType
      });
      const { data, meta } = response;
      if (meta?.status) {
        checkHaveCustomersInSearch(true);
        setTotalPageCount(Math.ceil(data?.count / pagination.recordsPerPage));
        setCustomerList(data?.rows);
        setActiveCompanyId(data?.rows[0]['id']);
        onCompanySelection(data?.rows[0]['id'], data?.rows[0], true);
        if (data?.count && activeCompanyId === 0) {
          onCompanySelection(data?.rows[0]['id'], data?.rows[0], true);
        } else if (data?.count === 0) {
          checkHaveCustomersInSearch(false);
        }
      }
    } catch (error) {
      console.log('axios error --->', error);
    }
    setLoading(false);
  };
  const handleSeachClick = (event) => {
    setSeachAnchorEl(event.currentTarget);
  };

  const handleSearchClose = () => {
    setPagination({ ...pagination, search: '', currentPage: 1 });
    searchRef.current.value = '';
    setSeachAnchorEl(null);
    checkHaveCustomersInSearch(true);
  };

  const handleBackToEmpListing = () => {
    setAddCustomerScreen(true);
    setAddResponsiveClass(true);
  };

  const applyFilterSelection = () => {
    setPagination({
      ...pagination,
      currentPage: 1,
      industryType: SkillsetFiltervalue
    });
  };

  const filterSkillset = (e) => {
    const {
      target: { value }
    } = e;
    setSkillsetFiltervalue(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const clearFilters = () => {
    setSkillsetFiltervalue([]);
    setPagination({
      currentPage: 1,
      recordsPerPage: 20,
      totalRecord: 0,
      search: '',
      industryType: []
    });
    handleFilterClose();
  };

  return (
    <div className="layoutGrid__col">
      <div
        className={`listCol ${
          filteranchorEl
            ? 'listCol__filter'
            : searchanchorEl
              ? 'listCol__search'
              : ''
        }`}
      >
        <div className="listCol__head listing__head">
          <h2 className="listCol__head--title">Customers</h2>
          <div className="listCol__head--actions">
            <div className="formField">
              <button
                className="btn btn__white p_0"
                aria-describedby={searchPopoverid}
                onClick={handleSeachClick}
              >
                <Search />
              </button>
            </div>

            <div>
              <button
                className="btn btn__white p_0"
                aria-describedby={filterPopoverid}
                onClick={handleFilterClick}
              >
                <Filter />
              </button>
            </div>
            <button
              disabled={EditCustomerMode ? true : false}
              className="btn btn__white p_0"
              onClick={handleBackToEmpListing}
            >
              <PlusIcon />
            </button>
          </div>

          <div className="searchContainer">
            <div className="formField">
              <div className="formField__group sm">
                <span className="formField__icon">
                  <Search />
                </span>
                <input
                  type="text"
                  ref={searchRef}
                  className="formField__input"
                  placeholder="Search for a company"
                  onChange={(e) => searchInCompanyListing(e)}
                />
              </div>
            </div>

            <button
              className="btn btn__transparent border_0 p_0"
              onClick={handleSearchClose}
            >
              <SearchCross />
            </button>
          </div>
        </div>

        <div className="filter__head">
          <h2 className="listCol__head--title">Filter</h2>
          <button
            className="btn btn__transparent border_0 p_0"
            onClick={handleFilterClose}
          >
            <SearchCross />
          </button>
        </div>

        <div className="filterContainer">
          <ul className="listCol__list filter__list">
            <li>
              <div className="formRow">
                <div className="formCol">
                  <div className="formField">
                    <label className="formField__label">Industry Type</label>
                    <Autocomplete
                      id="tags-filled"
                      multiple
                      options={cstomerFilterList}
                      defaultValue={SkillsetFiltervalue}
                      value={SkillsetFiltervalue}
                      name="industryType"
                      onChange={(event, newValue) => {
                        filterSkillset({
                          target: { name: 'industryType', value: newValue }
                        });
                      }}
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          placeholder="Select"
                          className="autoCompleteInput"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <div className="filter__footer">
            <div className="btn__group flex--between">
              <button
                className="btn btn__transparent p_0"
                onClick={clearFilters}
              >
                Clear
              </button>
              <button
                className="btn btn__primary"
                onClick={(e) => {
                  applyFilterSelection();
                  handleFilterClose();
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
        <ul className="listCol__list listing__list">
          {customerList.map((row, index) => {
            const valuesArray = [
              row?.address,
              row?.city,
              row?.stateName,
              row?.zipcode,
              row?.country
            ].filter((value) => value);
            const formattedAddress = valuesArray.join(', ');
            let liClassName = 'listCol__list--item';
            liClassName =
              activeCompanyId === row?.id
                ? liClassName + ' ' + 'listCol__item--selected'
                : liClassName;
            liClassName =
              row?.status === 'delete'
                ? liClassName + ' ' + 'listCol__item--alerterror'
                : liClassName;
            return (
              <li
                key={`companyListing${row?.id}`}
                className={liClassName}
                onClick={() => {
                  onCompanySelection(row?.id, row);
                  setValue('1');
                }}
              >
                <div className="item">
                  {row?.companyLogo ? (
                    <div className="item__logo">
                      <img src={row?.companyLogo} alt="Logo" />
                    </div>
                  ) : (
                    <div className="initials initials__lg">
                      {row?.companyName?.charAt(0).toUpperCase()}
                    </div>
                  )}
                  <div className="item__info">
                    <h4 className="item__info--title">
                      {firstLetterCapital(row?.companyName)}
                    </h4>
                    <div className="item__info--subtitle">
                      <p>{row?.industryType.join(', ')}</p>
                      <span>
                        <FollowersCount />
                        {row?.employees.length ? row?.employees.length : 0}
                      </span>
                    </div>
                    <p className="item__info--desc">{formattedAddress}</p>
                    {row?.status === 'delete' && (
                      <div className="item__info--subtitle item__info--subtitle--error">
                        Account Deactivated
                      </div>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
          {customerList.length ? (
            ''
          ) : (
            <div className="no-data">No Results Found!</div>
          )}
        </ul>
        <div className="pagination pagination__center">
          {customerList.length ? (
            <CustomPagination
              page={pagination.currentPage}
              totalPageCount={totalPageCount}
              changePage={(event, value) => changePage(event, value)}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyListing;
