import React from 'react';

export default function RefreshIcon() {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.833496 2.33331V7.33332M0.833496 7.33332H5.8335M0.833496 7.33332L4.70016 3.69998C5.59579 2.80391 6.70381 2.14932 7.92084 1.79729C9.13787 1.44527 10.4242 1.40727 11.6599 1.68686C12.8956 1.96645 14.0403 2.55451 14.9873 3.39616C15.9342 4.23782 16.6525 5.30564 17.0752 6.49998M19.1668 15.6666V10.6666M19.1668 10.6666H14.1668M19.1668 10.6666L15.3002 14.3C14.4045 15.1961 13.2965 15.8506 12.0795 16.2027C10.8625 16.5547 9.57608 16.5927 8.3404 16.3131C7.10472 16.0335 5.96 15.4455 5.01305 14.6038C4.06611 13.7621 3.3478 12.6943 2.92516 11.5"
        stroke="#150823"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
