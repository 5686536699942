import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { firstLetterCapital, GetUserDetails } from '@/helper';

function JobFeedbackView({ changeCandidateFeedbackView, jobData }) {
  const user = GetUserDetails();
  const role = user?.customer?.role;
  const [SelectedRadioValue, setSelectedRadioValue] = useState('1');
  const [QuestionsData, setQuestionsData] = useState([]);

  useEffect(() => {
    handleRadioClick();
  }, [SelectedRadioValue]);

  const handleRadioClick = () => {
    if (SelectedRadioValue === '1') {
      setQuestionsData(filterFeedbackData(jobData?.feedback, 'Customer'));
    } else if (SelectedRadioValue === '2') {
      setQuestionsData(filterFeedbackData(jobData?.feedback, 'Recruiter'));
    } else if (SelectedRadioValue === '3') {
      setQuestionsData(filterFeedbackData(jobData?.feedback, 'Admin'));
    }
  };

  const filterFeedbackData = (obj, role) => {
    let filterData = obj.filter((a) => a.role == role);
    return filterData;
  };

  const handleRadioChange = (e) => {
    setSelectedRadioValue(e.target.value);
  };

  return (
    <>
      <div className="dataCol__head feedback--head">
        <div className="notes__head w_100">
          <FormControl>
            <RadioGroup>
              <FormControlLabel
                name="Customer"
                value="1"
                checked={SelectedRadioValue === '1' ? true : false}
                control={<Radio />}
                label="Customer"
                onChange={(e) => handleRadioChange(e)}
              />
              <FormControlLabel
                name="Recruiter"
                control={<Radio />}
                value="2"
                label="Recruiter"
                checked={SelectedRadioValue === '2' ? true : false}
                onChange={(e) => handleRadioChange(e)}
              />
              <FormControlLabel
                name="Admin"
                value="3"
                control={<Radio />}
                label="Laila"
                checked={SelectedRadioValue === '3' ? true : false}
                onChange={(e) => handleRadioChange(e)}
              />
            </RadioGroup>
          </FormControl>
          <button
            className="btn btn__white"
            type="button"
            onClick={() => {
              changeCandidateFeedbackView(false);
            }}
          >
            Back
          </button>
        </div>
        <div className="formField__label mt_1">
          This feedback will be shared with <b>Laila</b>
        </div>
      </div>

      {QuestionsData?.map((row, index) => (
        <>
          <div key={index} className="dataCol__head feedback--head">
            <div className="profile">
              <div className="profile__initials">
                {row?.firstName.charAt(0).toUpperCase()}
              </div>
              <span className="profile__name">
                {firstLetterCapital(row?.firstName)}{' '}
                {firstLetterCapital(row?.lastName)}
              </span>
              <div
                className={`profile__alert ${
                  row?.status === 'Approved' || row?.status === 'approved'
                    ? 'alert--success'
                    : 'alert--danger'
                }`}
              >
                {firstLetterCapital(row?.status)}
              </div>
            </div>
          </div>
          <form key={`form-${index}`} className="form">
            <div className="formRow formRow__2">
              {row?.feedBackData?.map((item, itemIndex) => (
                <div key={`row-${index}-item-${itemIndex}`} className="formCol">
                  <div className="formField">
                    <label className="formField__label">{item?.question}</label>
                    <textarea
                      className="formField__textarea"
                      value={item?.answer}
                      rows={3}
                    ></textarea>
                  </div>
                </div>
              ))}
            </div>
          </form>
        </>
      ))}
      {!QuestionsData?.length && (
        <div className="tableContainer">
          <div className="no-data floating">No Results Found!</div>
        </div>
      )}
    </>
  );
}

export default JobFeedbackView;
