import { React, useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { SuccessfullyModal } from '@/components/modals/successfully-modal';
import { EmailRegex, isValidEmployeeName, phoneNumberRegex } from '@/regex';
import { formatPhoneNumber, GetUserDetails } from '@/helper';
import ErrorModal from '@/components/modals/error-modal';
import CompanyLogo1 from '@/assets/images/company-img-1.svg';
import ArrowDown from '@/elements/ArrowDown';
import BackArrow from '@/assets/svgs/BackArrow';
import { getStaticData } from '@/services/static-data-service';
import { addEmployee, editEmployee } from '@/services/customers-service';
import * as CONSTANTS from '@/utils/constants';
import * as MESSAGES from '@/utils/popupMessages';

export const AddEmployee = (props) => {
  const [EditedEmployeeData, setEditedEmployeeData] = useState(
    props?.EditEmployeeData
  );
  const [fields, setFields] = useState({
    countryCode: props?.EditEmployeeMode
      ? EditedEmployeeData?.countryCode
      : '+1',
    businessEmail: props?.EditEmployeeMode ? EditedEmployeeData?.email : null,
    designation: props?.EditEmployeeMode ? EditedEmployeeData?.role : null,
    employeeFirstName: props?.EditEmployeeMode
      ? EditedEmployeeData?.firstName
      : null,
    employeeLastName: props?.EditEmployeeMode
      ? EditedEmployeeData?.lastName
      : null,
    phoneno: props?.EditEmployeeMode ? EditedEmployeeData?.phoneNumber : null
  });
  const [errors, setErrors] = useState({});
  const [Loading, setLoading] = useState(false);
  const [SelectedCompanyId, setSelectedCompanyId] = useState(
    props?.activeCompanyId
  );
  const [SelectedCompanyData, setSelectedCompanyData] = useState(
    props?.ActiveCompanyData
  );
  const [openSuccessfullModal, setOpenSuccessfullModal] = useState(false);
  const [successfullMsg, setSuccessfullMsg] = useState('fgf');

  const [EmployeeDesignation, setEmployeeDesignation] = useState([]);
  const [CountryCodes, setCountryCodes] = useState([]);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [ErrorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    getDropdownsData();
  }, []);

  const closeSucessfullModal = () => {
    setOpenSuccessfullModal(false);
  };

  const getDropdownsData = async () => {
    try {
      const response = await getStaticData();
      const { data, meta } = response;
      if (meta?.status) {
        setEmployeeDesignation(data?.role);
        setCountryCodes(data?.countryCode);
      } else {
        setErrorMsg(response?.meta?.msg);
        setOpenErrorModal(true);
      }
    } catch (error) {
      setErrorMsg(error?.message ? error?.message : '');
      setOpenErrorModal(true);
      console.log('axios error --->', error);
    }
  };

  const handleChange = (e) => {
    let field = fields;
    handleValidation(e);
    const { name, value } = e?.target;
    field[name] = value === '' ? null : value.trimStart();
    setFields({ ...field });
  };

  const handleValidation = (e) => {
    let errorsT = errors;
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    let fieldname = e ? (e.target ? e.target.name : e) : e;
    let updatedValue = fieldValue;

    if (fieldname === undefined || fieldname === 'businessEmail') {
      if (fieldname === undefined) {
        fieldValue = fields['businessEmail'] ? fields['businessEmail'] : '';
        updatedValue = fieldValue;
      }
      delete errorsT['businessEmail'];
      delete errorsT['businessEmailError'];
      if (!updatedValue || updatedValue.trim() === '') {
        formIsValid = false;
        errorsT['businessEmail'] = CONSTANTS?.EMAIL?.validation?.required;
        errorsT['businessEmailError'] = true;
      } else if (!EmailRegex.test(updatedValue)) {
        formIsValid = false;
        errorsT['businessEmail'] = CONSTANTS?.EMAIL?.validation?.valid;
        errorsT['businessEmailError'] = true;
      }
    }

    if (fieldname === undefined || fieldname === 'designation') {
      if (fieldname === undefined) {
        fieldValue = fields['designation'] ? fields['designation'] : '';
        updatedValue = fieldValue;
      }
      delete errorsT['designation'];
      delete errorsT['designationError'];
      if (!updatedValue) {
        formIsValid = false;
        errorsT['designation'] = CONSTANTS?.ROLE?.validation?.required;
        errorsT['designationError'] = true;
      }
    }

    if (fieldname === undefined || fieldname === 'employeeFirstName') {
      if (fieldname === undefined) {
        fieldValue = fields['employeeFirstName']
          ? fields['employeeFirstName']
          : '';
        updatedValue = fieldValue;
      }
      delete errorsT['employeeFirstName'];
      delete errorsT['employeeFirstNameError'];
      if (!updatedValue) {
        formIsValid = false;
        errorsT['employeeFirstName'] =
          CONSTANTS?.FIRST_NAME?.validation?.required;
        errorsT['employeeFirstNameError'] = true;
      } else if (updatedValue && !isValidEmployeeName.test(updatedValue)) {
        formIsValid = false;
        errorsT['employeeFirstName'] = CONSTANTS?.FIRST_NAME?.validation?.valid;
        errorsT['employeeFirstNameError'] = true;
      }
    }

    if (fieldname === undefined || fieldname === 'employeeLastName') {
      if (fieldname === undefined) {
        fieldValue = fields['employeeLastName']
          ? fields['employeeLastName']
          : '';
        updatedValue = fieldValue;
      }
      delete errorsT['employeeLastName'];
      delete errorsT['employeeLastNameError'];
      if (!updatedValue) {
        formIsValid = false;
        errorsT['employeeLastName'] =
          CONSTANTS?.LAST_NAME?.validation?.required;
        errorsT['employeeLastNameError'] = true;
      } else if (updatedValue && !isValidEmployeeName.test(updatedValue)) {
        formIsValid = false;
        errorsT['employeeLastName'] = CONSTANTS?.LAST_NAME?.validation?.valid;
        errorsT['employeeLastNameError'] = true;
      }
    }

    if (fieldname === undefined || fieldname === 'phoneno') {
      if (fieldname === undefined) {
        fieldValue = fields['phoneno'] ? fields['phoneno'] : '';
        updatedValue = fieldValue;
      }

      delete errorsT['phoneno'];
      delete errorsT['phonenoError'];
      if (updatedValue && !phoneNumberRegex.test(updatedValue)) {
        formIsValid = false;
        errorsT['phoneno'] = CONSTANTS?.PHONE_NUMBER?.validation?.valid;
        errorsT['phonenoError'] = true;
      }
    }

    setErrors({
      ...errorsT
    });
    return {
      formIsValid,
      updatedValue
    };
  };

  const closeErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const userDetails = GetUserDetails();
    const { formIsValid } = handleValidation();
    if (formIsValid) {
      let Variables = {
        email: fields?.businessEmail ?? '',
        role: fields?.designation ?? '',
        firstName: fields?.employeeFirstName ?? '',
        lastName: fields?.employeeLastName ?? '',
        phoneNumber: fields?.phoneno?.replace(/[^0-9.]/g, '') ?? '',
        countryCode: fields?.countryCode ?? '',
        addedBy: userDetails?.adminId,
        companyId: SelectedCompanyId
      };
      try {
        const response = await addEmployee(Variables);
        const { meta } = response;
        if (meta?.status) {
          const { message } = MESSAGES?.addEmployee;
          setSuccessfullMsg(message?.successfull);
          setOpenSuccessfullModal(true);
          props?.getEmployeeList(SelectedCompanyId);
          setTimeout(() => {
            props?.backToEmployeeListing();
          }, 3000);
        } else {
          setErrorMsg(response?.meta?.msg);
          setOpenErrorModal(true);
        }
      } catch (error) {
        console.log('axios error --->', error);
        setErrorMsg(error?.message ? error?.message : '');
        setOpenErrorModal(true);
      }
    }
    setLoading(false);
  };

  const handleUpdateEmployee = async (e) => {
    setLoading(true);
    e.preventDefault();
    const userDetails = GetUserDetails();
    const { formIsValid } = handleValidation();
    if (formIsValid) {
      let Variables = {
        firstName: fields?.employeeFirstName ?? '',
        lastName: fields?.employeeLastName ?? '',
        phoneNumber: fields?.phoneno?.replace(/[^0-9.]/g, '') ?? '',
        countryCode: fields?.countryCode ?? '',
        updatedBy: userDetails?.adminId,
        employeeId: EditedEmployeeData?.employeeId,
        role: fields?.designation ?? ''
      };
      try {
        const response = await editEmployee(Variables);
        const { meta } = response;
        const { message } = MESSAGES?.updateEmployee;
        if (meta?.status) {
          setSuccessfullMsg(message?.successfull);
          setOpenSuccessfullModal(true);
          props?.getEmployeeList(SelectedCompanyId);
          setTimeout(() => {
            props?.backToEmployeeListing();
          }, 3000);
        } else {
          setErrorMsg(response?.meta?.msg);
          setOpenErrorModal(true);
        }
      } catch (error) {
        console.log('axios error --->', error);
        setErrorMsg(error?.message ? error?.message : '');
        setOpenErrorModal(true);
      }
    }
    setLoading(false);
  };
  const valuesArray = [
    SelectedCompanyData?.address,
    SelectedCompanyData?.city,
    SelectedCompanyData?.state,
    SelectedCompanyData?.zipcode,
    SelectedCompanyData?.country
  ].filter((value) => value);
  const formattedAddress = valuesArray.join(', ');

  return (
    <>
      <div
        className={`layoutGrid__col layoutGrid--responsive  ${
          props?.addResponsiveClass ? 'layoutGrid--show' : ''
        }`}
      >
        <div className="dataCol">
          <div className="dataCol__head head--responsive">
            <button
              className="btn btn__transparent btn__back border_0 p_0"
              onClick={() => props?.setAddResponsiveClass(false)}
            >
              <BackArrow />
            </button>
            <div className="item">
              {SelectedCompanyData?.companyLogo ? (
                <div className="item__logo">
                  <img
                    src={SelectedCompanyData?.companyLogo ?? CompanyLogo1}
                    alt="Logo"
                  />
                </div>
              ) : (
                <div className="initials initials__lg">
                  {SelectedCompanyData?.companyName?.charAt(0).toUpperCase()}
                </div>
              )}
              <div className="item__info">
                <h4 className="item__info--title">
                  {SelectedCompanyData?.companyName}
                </h4>
                <div className="item__info--subtitle">
                  {SelectedCompanyData?.industryType?.join(' , ')}
                </div>
                <p className="item__info--desc">{formattedAddress}</p>
              </div>
            </div>

            <div className="dataCol__head--actions">
              <button
                className="btn btn__white w_fitContent"
                type="button"
                onClick={() => props?.backToEmployeeListing()}
              >
                Cancel
              </button>
              <button
                className="btn btn__primary w_fitContent"
                type="button"
                disabled={Loading ? true : false}
                onClick={(e) => {
                  props?.EditEmployeeMode
                    ? handleUpdateEmployee(e)
                    : handleSubmit(e);
                }}
              >
                {props?.EditEmployeeMode ? 'Update' : 'Add'}
              </button>
            </div>
          </div>
          <div className="page page__full">
            <div className="page__head">
              <h2 className="page__head--title">
                {props?.EditEmployeeMode
                  ? 'Update Employee Details'
                  : 'Add Employee Details'}
              </h2>
            </div>

            <div className="page__body">
              <form className="form">
                <section className="form__section">
                  <h4 className="form__section--title">Employee Details</h4>
                  <div className="formRow formRow__2">
                    <div className="formCol">
                      <div
                        className={`formField ${
                          errors?.businessEmailError ? 'fieldError' : ''
                        }`}
                      >
                        <label className="formField__label">
                          Business Email ID{' '}
                        </label>
                        <div className="formField__group sm">
                          <input
                            type="email"
                            className={`formField__input sm ${
                              props?.EditEmployeeMode
                                ? 'formField__disabled'
                                : ''
                            }`}
                            placeholder="Business Email Address"
                            disabled={props?.EditEmployeeMode ? true : false}
                            name="businessEmail"
                            value={fields?.businessEmail ?? ''}
                            onChange={(e) => handleChange(e)}
                          />
                          <span className="formField__icon">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.6666 4.00033C14.6666 3.26699 14.0666 2.66699 13.3333 2.66699H2.66665C1.93331 2.66699 1.33331 3.26699 1.33331 4.00033M14.6666 4.00033V12.0003C14.6666 12.7337 14.0666 13.3337 13.3333 13.3337H2.66665C1.93331 13.3337 1.33331 12.7337 1.33331 12.0003V4.00033M14.6666 4.00033L7.99998 8.66699L1.33331 4.00033"
                                stroke="#384250"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        </div>

                        <span className="formField__error">
                          {errors?.businessEmail}
                        </span>
                      </div>
                    </div>

                    <div className="formCol">
                      <div
                        className={`formField ${
                          errors?.designationError ? 'fieldError' : ''
                        }`}
                      >
                        <label className="formField__label">Role</label>
                        <FormControl
                          fullWidth
                          size="small"
                          className="formField__select"
                        >
                          <Select
                            onChange={(e) => handleChange(e)}
                            IconComponent={() => <ArrowDown />}
                            placeholder="Designation"
                            name="designation"
                            value={fields?.designation ?? ''}
                            displayEmpty
                            renderValue={(selected) => {
                              if (!selected) {
                                return (
                                  <span style={{ opacity: '0.8' }}>Select</span>
                                );
                              }

                              return selected;
                            }}
                          >
                            {EmployeeDesignation?.map((item, index) => (
                              <MenuItem key={index} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <span className="formField__error">
                          {errors?.designation}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="formRow formRow__2">
                    <div className="formCol">
                      <div
                        className={`formField ${
                          errors?.employeeFirstNameError ? 'fieldError' : ''
                        }`}
                      >
                        <label className="formField__label">
                          Employee First Name
                        </label>
                        <div className="formField__group sm">
                          <input
                            type="text"
                            value={fields?.employeeFirstName ?? ''}
                            className="formField__input sm"
                            placeholder="Employee First Name"
                            name="employeeFirstName"
                            onChange={(e) => handleChange(e)}
                          />
                          <span className="formField__icon">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.3333 14V12.6667C13.3333 11.9594 13.0524 11.2811 12.5523 10.781C12.0522 10.281 11.3739 10 10.6667 10H5.33332C4.62608 10 3.9478 10.281 3.4477 10.781C2.94761 11.2811 2.66666 11.9594 2.66666 12.6667V14M10.6667 4.66667C10.6667 6.13943 9.47275 7.33333 7.99999 7.33333C6.52723 7.33333 5.33332 6.13943 5.33332 4.66667C5.33332 3.19391 6.52723 2 7.99999 2C9.47275 2 10.6667 3.19391 10.6667 4.66667Z"
                                stroke="#384250"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        </div>

                        <span className="formField__error">
                          {errors?.employeeFirstName}
                        </span>
                      </div>
                    </div>
                    <div className="formCol">
                      <div
                        className={`formField ${
                          errors?.employeeLastNameError ? 'fieldError' : ''
                        }`}
                      >
                        <label className="formField__label">
                          Employee Last Name
                        </label>
                        <div className="formField__group sm">
                          <input
                            type="text"
                            value={fields?.employeeLastName ?? ''}
                            className="formField__input sm"
                            placeholder="Employee Last Name"
                            name="employeeLastName"
                            onChange={(e) => handleChange(e)}
                          />
                          <span className="formField__icon">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.3333 14V12.6667C13.3333 11.9594 13.0524 11.2811 12.5523 10.781C12.0522 10.281 11.3739 10 10.6667 10H5.33332C4.62608 10 3.9478 10.281 3.4477 10.781C2.94761 11.2811 2.66666 11.9594 2.66666 12.6667V14M10.6667 4.66667C10.6667 6.13943 9.47275 7.33333 7.99999 7.33333C6.52723 7.33333 5.33332 6.13943 5.33332 4.66667C5.33332 3.19391 6.52723 2 7.99999 2C9.47275 2 10.6667 3.19391 10.6667 4.66667Z"
                                stroke="#384250"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        </div>

                        <span className="formField__error">
                          {errors?.employeeLastName}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="formRow formRow__2">
                    <div className="formCol">
                      <div
                        className={`formField ${
                          errors?.phonenoError ? 'fieldError' : ''
                        }`}
                      >
                        <label className="formField__label">Phone Number</label>
                        <div className="formField__group formField__phoneNumber sm">
                          <FormControl
                            fullWidth
                            size="small"
                            className="formField__select"
                          >
                            <Select
                              onChange={(e) => handleChange(e)}
                              IconComponent={() => <ArrowDown />}
                              name="countryCode"
                              value={fields?.countryCode ?? null}
                              displayEmpty
                            >
                              {CountryCodes?.map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                  {item.value}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <input
                            type="text"
                            maxLength="10"
                            value={formatPhoneNumber(fields?.phoneno) ?? ''}
                            className="formField__input sm"
                            placeholder="Phone Number"
                            name="phoneno"
                            onChange={(e) => handleChange(e)}
                          />
                          <span className="formField__icon">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_1867_7550)">
                                <path
                                  d="M14.6667 11.2797V13.2797C14.6674 13.4654 14.6294 13.6492 14.555 13.8193C14.4806 13.9894 14.3715 14.1421 14.2347 14.2676C14.0979 14.3932 13.9364 14.4887 13.7605 14.5482C13.5846 14.6077 13.3983 14.6298 13.2133 14.6131C11.1619 14.3902 9.19134 13.6892 7.46001 12.5664C5.84923 11.5428 4.48356 10.1772 3.46001 8.56641C2.33333 6.82721 1.63217 4.84707 1.41334 2.78641C1.39668 2.60205 1.41859 2.41625 1.47767 2.24082C1.53676 2.0654 1.63172 1.9042 1.75652 1.76749C1.88131 1.63077 2.03321 1.52155 2.20253 1.44675C2.37186 1.37196 2.5549 1.33325 2.74001 1.33307H4.74001C5.06354 1.32989 5.3772 1.44446 5.62251 1.65543C5.86783 1.8664 6.02806 2.15937 6.07334 2.47974C6.15775 3.11978 6.31431 3.74822 6.54001 4.35307C6.6297 4.59169 6.64911 4.85102 6.59594 5.10033C6.54277 5.34964 6.41925 5.57848 6.24001 5.75974L5.39334 6.60641C6.34238 8.27544 7.72431 9.65737 9.39334 10.6064L10.24 9.75974C10.4213 9.5805 10.6501 9.45697 10.8994 9.4038C11.1487 9.35063 11.4081 9.37004 11.6467 9.45974C12.2515 9.68544 12.88 9.84199 13.52 9.92641C13.8439 9.97209 14.1396 10.1352 14.351 10.3847C14.5624 10.6343 14.6748 10.9528 14.6667 11.2797Z"
                                  stroke="#384250"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1867_7550">
                                  <rect width="16" height="16" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                        </div>
                      </div>
                      <span className="formField__error">
                        {errors?.phoneno}
                      </span>
                    </div>
                  </div>
                </section>
              </form>
            </div>
          </div>
        </div>
      </div>
      <SuccessfullyModal
        openModal={openSuccessfullModal}
        closeModal={closeSucessfullModal}
        Heading={
          props?.EditEmployeeMode
            ? MESSAGES?.updateEmployee?.message?.heading
            : MESSAGES?.addEmployee?.message?.heading
        }
        sucessfullMsg={successfullMsg}
      />
      <ErrorModal
        openModal={openErrorModal}
        errorMessage={ErrorMsg}
        closeModal={closeErrorModal}
      />
    </>
  );
};
