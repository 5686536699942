import React from 'react';

export default function CopyToClipboardIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1078_3543)">
        <path
          d="M3.3335 10.0002H2.66683C2.31321 10.0002 1.97407 9.85969 1.72402 9.60964C1.47397 9.35959 1.3335 9.02045 1.3335 8.66683V2.66683C1.3335 2.31321 1.47397 1.97407 1.72402 1.72402C1.97407 1.47397 2.31321 1.3335 2.66683 1.3335H8.66683C9.02045 1.3335 9.35959 1.47397 9.60964 1.72402C9.85969 1.97407 10.0002 2.31321 10.0002 2.66683V3.3335M7.3335 6.00016H13.3335C14.0699 6.00016 14.6668 6.59712 14.6668 7.3335V13.3335C14.6668 14.0699 14.0699 14.6668 13.3335 14.6668H7.3335C6.59712 14.6668 6.00016 14.0699 6.00016 13.3335V7.3335C6.00016 6.59712 6.59712 6.00016 7.3335 6.00016Z"
          stroke="#384250"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1078_3543">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
