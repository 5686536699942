import React from 'react';

export default function AssignedJobs() {
  return (
    <svg
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="80" height="80" rx="40" fill="#150823" />
      <path
        d="M48.833 58.75V54.5833C48.833 52.3732 47.955 50.2536 46.3922 48.6908C44.8294 47.128 42.7098 46.25 40.4997 46.25H25.9163C23.7062 46.25 21.5866 47.128 20.0238 48.6908C18.461 50.2536 17.583 52.3732 17.583 54.5833V58.75M50.9163 37.9167L55.083 42.0833L63.4163 33.75M41.5413 29.5833C41.5413 34.1857 37.8104 37.9167 33.208 37.9167C28.6056 37.9167 24.8747 34.1857 24.8747 29.5833C24.8747 24.981 28.6056 21.25 33.208 21.25C37.8104 21.25 41.5413 24.981 41.5413 29.5833Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
