export const EmailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
export const ValidPhoneNumberRegex = /^\(\d{3}\)\s?\d{3}-\d{4}$/;
export const phoneNumberRegex = /^\d{10}$/;
export const isValidName = /^[a-zA-Z !@#$%^&*(),.?":{}|<>]*$/;
export const isValidEmployeeName = /^[A-Za-z._\s]+$/;
export const isValidCompanyName = /^[A-Za-z0-9.@_\s]+$/;
export const isValidGraduationYear = /^\d{4}$/;
export const isValidNumberOnly = /^[0-9]*$/;
export const URLRegex =
  /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
export const LinkedInRegex = /^https:\/\/www\.linkedin\.com\/.*$/;
export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
