import httpService from '@/services/http-service';
import {
  LOG_OUT,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  LOG_IN
} from '@/services/end-points';

export const LogInUser = async (body) => {
  try {
    const response = await httpService.post(LOG_IN, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const LogoutUser = async () => {
  try {
    const response = await httpService.post(LOG_OUT, {});
    return response;
  } catch (error) {
    return error;
  }
};

export const resetPassword = async (body) => {
  try {
    const response = await httpService.put(RESET_PASSWORD, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const forgotPassword = async (body) => {
  try {
    const response = await httpService.post(FORGOT_PASSWORD, body);
    return response;
  } catch (error) {
    return error;
  }
};
