import React from 'react';

function StarIcon() {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2449 0L13.7162 7.60589H21.7135L15.2436 12.3066L17.7149 19.9125L11.2449 15.2118L4.77494 19.9125L7.24625 12.3066L0.776287 7.60589H8.7736L11.2449 0Z"
        fill="#28A0E8"
      />
    </svg>
  );
}

export default StarIcon;
