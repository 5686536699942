import React from 'react';
import CloseIcon from '@/assets/svgs/CloseIcon';
import { firstLetterCapital, formatPhoneNumber } from '@/helper';

const DetailModal = ({ openModal, closeModal, data }) => {
  return (
    <>
      <div className={`modal modalPrompt ${openModal ? 'modal--open' : ''}`}>
        <div className="modal__dialog">
          <div className="modal__content">
            <div className="modal__header">
              <h2 className="modal__header--title">Details</h2>
              <button
                onClick={closeModal}
                className="btn btn__transparent btnClose"
              >
                <CloseIcon />
              </button>
            </div>
            <div className="modal__body">
              <h4 className="promptMessage">
                <span>
                  <strong>Name:</strong>{' '}
                  {data?.firstName && data?.lastName
                    ? firstLetterCapital(data?.firstName) +
                      ' ' +
                      firstLetterCapital(data?.lastName)
                    : '--'}
                </span>
                <span>
                  <strong>Email:</strong> {data?.email ? data?.email : '--'}
                </span>
                <span>
                  <strong>Phone no:</strong>{' '}
                  {data?.countryCode && data?.phoneNumber
                    ? data?.countryCode
                    : ''}
                  {data?.phoneNumber
                    ? formatPhoneNumber(data?.phoneNumber)
                    : '--'}
                </span>
              </h4>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
    </>
  );
};

export default DetailModal;
