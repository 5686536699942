import { EmailRegex } from './regex';
import moment from 'moment';
import 'moment-timezone';
import * as CONSTANTS from './utils/constants';
import CryptoJS from 'crypto-js';
import _ from 'lodash';

const handleValidation = (e, errors, fields) => {
  const errorsT = errors;
  let formIsValid = true;
  let fieldValue = e ? (e.target ? e.target.value : e) : e;
  const fieldname = e ? (e.target ? e.target.name : e) : e;
  let updatedValue = fieldValue;

  if (fieldname === undefined || fieldname === 'email') {
    if (fieldname === undefined) {
      fieldValue = fields.email ? fields.email : '';
      updatedValue = fieldValue;
    }
    delete errorsT.email;
    delete errorsT.emailErr;
    if (!updatedValue || updatedValue.trim() === '') {
      formIsValid = false;
      errorsT.email = CONSTANTS?.EMAIL?.validation?.required;
      errorsT.emailErr = true;
    } else if (!EmailRegex.test(updatedValue)) {
      formIsValid = false;
      errorsT.email = CONSTANTS?.EMAIL?.validation?.valid;
      errorsT.emailErr = true;
    }
  }

  if (fieldname === undefined || fieldname === 'password') {
    if (fieldname === undefined) {
      fieldValue = fields.password ? fields.password : '';
      updatedValue = fieldValue;
    }
    delete errorsT.password;
    delete errorsT.passwordErr;
    if (!updatedValue || updatedValue.trim() === '') {
      formIsValid = false;
      errorsT.password = CONSTANTS?.PASSWORD?.validation?.required;
      errorsT.passwordErr = true;
    } else if (updatedValue.length < 8) {
      formIsValid = false;
      errorsT.password = CONSTANTS?.PASSWORD?.validation?.maxChar;
      errorsT.passwordErr = true;
    }
  }

  if (fieldname === undefined || fieldname === 'signinPassword') {
    if (fieldname === undefined) {
      fieldValue = fields.signinPassword ? fields.signinPassword : '';
      updatedValue = fieldValue;
    }
    delete errorsT.signinPassword;
    delete errorsT.signinPasswordErr;
    if (!updatedValue || updatedValue.trim() === '') {
      formIsValid = false;
      errorsT.signinPassword = CONSTANTS?.PASSWORD?.validation?.required;
      errorsT.signinPasswordErr = true;
    }
  }

  return {
    formIsValid,
    updatedValue,
    errorsT
  };
};

const SaveUserDetails = (data) => {
  localStorage.setItem('user', JSON.stringify(data));
};

const SaveInterviewDetails = (data) => {
  localStorage.setItem('interview', JSON.stringify(data));
};

const GetInterviewDetails = () => {
  return JSON.parse(localStorage.getItem('interview'));
};

const GetUserDetails = () => {
  return JSON.parse(localStorage.getItem('user'));
};

const SaveAdminDetails = (data) => {
  localStorage.setItem('admin', JSON.stringify(data));
};

const GetAdminDetails = () => {
  return JSON.parse(localStorage.getItem('admin'));
};

export function GetSessionId() {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user?.sessionid) {
    return true;
  } else {
    return false;
  }
}
// get phone number in +1 (876) 543-2123 format
const formatPhoneNumber = (str) => {
  const cleaned = ('' + str).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  } else {
    return ('' + str).replace(/\D/g, '');
  }
};
// eg: get "john cena" as "John Cena"
const firstLetterCapital = (str) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
};
// get first intital character eg: Comapny- C
const getInitial = (str) => {
  if (str) {
    return str.charAt(0).toUpperCase();
  }
};

const Currency = (amount) => {
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2 // Set maximum fraction digits to 2
  });
  return currencyFormatter.format(amount);
};

const getUTCRecordingTimeSlot = (meetingStartTime, recTimeSlot) => {
  const meetingDate = meetingStartTime.split('T')[0];
  const timeSlot1 = recTimeSlot.split('-')[0].split(' ')[0];
  const timeSlotFormat1 = recTimeSlot.split('-')[0].split(' ')[1];
  const timeSlot2 = recTimeSlot.split('-')[1].split(' ')[1];
  const timeSlotFormat2 = recTimeSlot.split('-')[1].split(' ')[2];
  const UTCDate1 =
    meetingDate + ' ' + timeSlot1 + ':00' + ' ' + timeSlotFormat1 + ' ' + 'UTC';
  const UTCDate2 =
    meetingDate + ' ' + timeSlot2 + ':00' + ' ' + timeSlotFormat2 + ' ' + 'UTC';
  return (
    moment(new Date(UTCDate1)).format('hh:mm A') +
    ' - ' +
    moment(new Date(UTCDate2)).format('hh:mm A')
  );
};
// convert created date to mm/dd/yyyy PM UTC i.e. with timezone
const getTimeZone = (createdAt) => {
  const createdDate = new Date(createdAt);
  const userTimeZone = moment.tz.guess();

  const formattedDate = moment(createdDate)
    .tz(userTimeZone)
    .format('MM/DD/YYYY, hh:mm:ss A z');

  return formattedDate;
};
// eg: convert "applicationDeadline" to "Application Deadline"
const convertToTitleCase = (str) => {
  const formattedSting =
    str &&
    str
      ?.replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/\b\w/g, (match) => match.toUpperCase());
  return formattedSting;
};

const convertDateToMMDDYYY = (date) => {
  if (date) {
    return moment(date).format('MM/DD/YYYY');
  }
};
function toTitleCase(text) {
  return text.replace(/\w\S*/g, function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
}

const formatAmountInUSFormat = (value) => {
  if (value === '') return '';

  value = value.replace(/^0+/, '');

  value = value.replace(/\D/g, (_, index) =>
    index < value.length - 1 ? '' : _
  );
  if (value.length === 1) {
    return `00.0${value}`;
  }

  const dollars = value.slice(0, -2);
  const cents = value.slice(-2);
  return `${dollars?.length === 1 ? '0' + dollars : dollars}.${cents}`;
};

const ConvertAmountToUSCurrencyFormat = (amount) => {
  const numberAmount = parseFloat(amount);
  if (isNaN(numberAmount) || numberAmount === 0) return '';
  const formattedAmount = numberAmount.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  const [dollars, cents] = formattedAmount.split('.');
  const paddedDollars = dollars.padStart(2, '0');
  return `${paddedDollars}.${cents}`;
};
const isNumber = (number) => {
  return /^\d+$/.test(number);
};

const decryptData = (data) => {
  if (isNumber(data)) {
    return data;
  } else {
    const key = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const bytes = CryptoJS.AES.decrypt(data, key);
    const text = bytes.toString(CryptoJS.enc.Utf8);
    return text;
  }
};

const isButtonDisabled = (error, value, initialValues) => {
  let status = false;
  if (Object.keys(error)?.length != 0) {
    status = true;
  } else if (
    Object.keys(error)?.length == 0 &&
    _.isEqual(value, initialValues)
  ) {
    status = true;
  }
  return status;
};

export {
  handleValidation,
  SaveUserDetails,
  GetUserDetails,
  formatPhoneNumber,
  firstLetterCapital,
  SaveInterviewDetails,
  GetInterviewDetails,
  SaveAdminDetails,
  GetAdminDetails,
  Currency,
  getUTCRecordingTimeSlot,
  getInitial,
  getTimeZone,
  convertToTitleCase,
  convertDateToMMDDYYY,
  toTitleCase,
  formatAmountInUSFormat,
  ConvertAmountToUSCurrencyFormat,
  decryptData,
  isNumber,
  isButtonDisabled
};
