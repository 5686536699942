import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LoaderContext } from '@/components/Loader/Loader';
import _ from 'lodash';
import SideBar from '@/components/layout/SideBar';
import BackArrow from '@/assets/svgs/BackArrow';
import Search from '@/assets/svgs/Search';
import Filter from '@/assets/svgs/Filter';
import SearchCross from '@/assets/svgs/SearchCross';
import { firstLetterCapital } from '@/helper';
import JobsListing from './JobsListing';
import RecruiterCandidateListing from './RecruiterCandidateListing';
import {
  getBillingRecruiterJobsList,
  getBillingRecruiterCandidates
} from '@/services/billing-service/billing-service';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowDown from '@/elements/ArrowDown';

const RecruiterJobsCandidateIndex = () => {
  const [searchanchorEl, setSeachAnchorEl] = useState(null);
  const [filteranchorEl, setFilterAnchorEl] = useState(null);
  const filterOpen = Boolean(filteranchorEl);
  const searchOpen = Boolean(searchanchorEl);
  const searchPopoverid = searchOpen ? 'simple-popover' : '';
  const filterPopoverid = filterOpen ? 'simple-popover' : '';
  // COMMON STATES
  const searchRef = useRef('');
  const location = useLocation();
  const navigate = useNavigate();
  const setLoading = useContext(LoaderContext);
  const [addResponsiveClass, setAddResponsiveClass] = useState(false);
  const [FilterApplied, setFilterApplied] = useState(false);
  // JOBS LIST STATES
  const [activeJobId, setActiveJobId] = useState(location?.state?.jobid);
  const [totalUnpaidAmount, setTotalUnpaidAmount] = useState('');
  const [activeJobData, setActiveJobData] = useState({});
  const [recruitersJobsList, setRecruitersJobsList] = useState([]);
  const [recruiterJobsCount, setRecruiterJobsCount] = useState();
  const [selectedStatus, setSelectedStatus] = useState('');
  const [BillingStatus, setBillingStatus] = useState(['All', 'Paid', 'Unpaid']);
  const [recruiterJobsPagination, setRecruiterJobsPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0,
    search: '',
    status: []
  });
  // CANDIDATE STATES
  const [candidatesPagination, setCandidatesPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0,
    search: '',
    status: []
  });
  const [candidateTotalCount, setCandidateTotalCount] = useState();
  const [candidateList, setCandidateList] = useState([]);

  const handleSeachClick = (event) => {
    setSeachAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    getRecruiterJobsList();
  }, []);

  const handleBackClick = () => {
    navigate('/billing-payments', {
      state: {
        value: '2'
      }
    });
  };

  const getRecruiterJobsList = async (
    recordsPerPage = recruiterJobsPagination?.recordsPerPage,
    currentPage = recruiterJobsPagination?.currentPage,
    search = recruiterJobsPagination?.search,
    status = recruiterJobsPagination?.status,
    recruiterId = location?.state?.activeRecruiter?.recruiterId
  ) => {
    setLoading(true);
    const statusArray = Array.isArray(status) ? status : [status];
    const response = await getBillingRecruiterJobsList(
      recordsPerPage,
      currentPage,
      search,
      statusArray,
      recruiterId
    );
    const { meta, data } = response;
    if (meta?.status) {
      setRecruiterJobsPagination((prev) => ({
        ...prev,
        totalRecord: data?.count ? data?.count : 0
      }));
      setRecruiterJobsCount(data?.count);
      setRecruitersJobsList(data?.rows);
      setTotalUnpaidAmount(data?.unpaidAmount);
    }
    setLoading(false);
  };
  const JobsPageChange = (event, value) => {
    setRecruiterJobsPagination({
      ...recruiterJobsPagination,
      currentPage: value
    });
  };

  const onJobSelect = (selectedJob) => {
    setActiveJobId(selectedJob?.jobId);
    setActiveJobData(selectedJob);
  };

  useEffect(() => {
    getCandidateList();
  }, [activeJobId]);

  const getCandidateList = async (
    recordsPerPage = candidatesPagination?.recordsPerPage,
    currentPage = candidatesPagination?.currentPage,
    search = candidatesPagination?.search,
    status = candidatesPagination?.status,
    jobId = activeJobId,
    recruiterId = location?.state?.activeRecruiter?.recruiterId
  ) => {
    setLoading(true);
    const response = await getBillingRecruiterCandidates(
      recordsPerPage,
      currentPage,
      search,
      status,
      jobId,
      recruiterId
    );
    const { meta, data } = response;
    if (meta?.status) {
      setCandidatesPagination((prev) => ({
        ...prev,
        totalRecord: data?.count ? data?.count : 0
      }));
      setCandidateTotalCount(data?.count);
      setCandidateList(data?.rows);
    }
    setLoading(false);
  };

  const changeCandidatesPage = (event, value) => {
    setCandidatesPagination({ ...candidatesPagination, currentPage: value });
  };

  const searchInJobListing = useCallback(
    _.debounce(
      (searchText) =>
        getRecruiterJobsList(
          recruiterJobsPagination?.recordsPerPage,
          searchText ? 1 : recruiterJobsPagination?.currentPage,
          searchText,
          recruiterJobsPagination?.status,
          location?.state?.activeRecruiter?.recruiterId
        ),
      500
    ),
    []
  );
  const searchInCandidateListing = useCallback(
    _.debounce(
      (searchText) =>
        getCandidateList(
          candidatesPagination?.recordsPerPage,
          searchText ? 1 : candidatesPagination?.currentPage,
          searchText,
          candidatesPagination?.status,
          activeJobId,
          location?.state?.activeRecruiter?.recruiterId
        ),
      500
    ),
    []
  );

  const handleSearchClose = () => {
    searchRef.current.value = '';
    setSeachAnchorEl(null);
    getRecruiterJobsList(
      recruiterJobsPagination?.recordsPerPage,
      recruiterJobsPagination?.currentPage,
      recruiterJobsPagination?.search,
      recruiterJobsPagination?.status,
      location?.state?.activeRecruiter?.recruiterId
    );
  };

  const handleStatusFilterChange = (event) => {
    setSelectedStatus(event?.target?.value);
  };

  const clearFilter = () => {
    setSelectedStatus([]);
    getRecruiterJobsList(
      recruiterJobsPagination?.recordsPerPage,
      recruiterJobsPagination?.currentPage,
      recruiterJobsPagination?.search,
      '',
      location?.state?.activeRecruiter?.recruiterId
    );
  };

  const applyFilterSelection = () => {
    if (selectedStatus.length) {
      getRecruiterJobsList(
        recruiterJobsPagination?.recordsPerPage,
        recruiterJobsPagination?.currentPage,
        recruiterJobsPagination?.search,
        selectedStatus,
        location?.state?.activeRecruiter?.recruiterId
      );
    }
  };

  const applyCandidateFilter = (selectedOption) => {
    setCandidatesPagination({
      ...candidatesPagination,
      status: selectedOption
    });
    getCandidateList(
      candidatesPagination?.recordsPerPage,
      candidatesPagination?.currentPage,
      candidatesPagination?.search,
      selectedOption,
      activeJobId,
      location?.state?.activeRecruiter?.recruiterId
    );
  };

  const clearCandidateFilter = () => {
    setCandidatesPagination({ ...candidatesPagination, status: [] });

    getCandidateList(
      candidatesPagination?.recordsPerPage,
      candidatesPagination?.currentPage,
      candidatesPagination?.search,
      [],
      activeJobId,
      location?.state?.activeRecruiter?.recruiterId
    );
  };
  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid">
          <div className="layoutGrid__col">
            <div
              className={`listCol ${FilterApplied ? 'filterApplied' : ''} ${
                filteranchorEl
                  ? 'listCol__filter'
                  : searchanchorEl
                    ? 'listCol__search'
                    : ''
              }`}
            >
              <div className="listCol__head listing__head">
                <h2 className="listCol__head--title">
                  <button
                    className="btn border_0 p_0"
                    onClick={handleBackClick}
                  >
                    <BackArrow />
                  </button>
                  {`${firstLetterCapital(location?.state?.activeRecruiter?.firstName)} ${firstLetterCapital(location?.state?.activeRecruiter?.lastName)}`}
                </h2>
                <div className="listCol__head--actions">
                  <div className="formField">
                    <button
                      className="btn btn__white p_0"
                      aria-describedby={searchPopoverid}
                      onClick={handleSeachClick}
                    >
                      <Search />
                    </button>
                  </div>
                  <div className="formField">
                    <button
                      className="btn btn__white p_0"
                      type="button"
                      aria-describedby={filterPopoverid}
                      onClick={handleFilterClick}
                    >
                      <Filter />
                    </button>
                  </div>
                </div>

                <div className="searchContainer">
                  <div className="formField">
                    <div className="formField__group sm">
                      <span className="formField__icon">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                            stroke="#9DA4AE"
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <input
                        type="text"
                        className="formField__input"
                        placeholder="Search job"
                        ref={searchRef}
                        onChange={(e) => searchInJobListing(e?.target?.value)}
                      />
                    </div>
                  </div>

                  <button
                    className="btn btn__transparent border_0 p_0"
                    onClick={handleSearchClose}
                  >
                    <SearchCross />
                  </button>
                </div>
              </div>
              <div className="filter__head">
                <h2 className="listCol__head--title">Filter</h2>
                <button
                  className="btn btn__transparent border_0 p_0"
                  onClick={handleFilterClose}
                >
                  <SearchCross />
                </button>
              </div>

              <div className="filterContainer">
                <ul className="listCol__list filter__list">
                  <li>
                    <div className="formRow">
                      <div className="formCol">
                        <div className="formField">
                          <label className="formField__label">Status</label>
                          <FormControl
                            fullWidth
                            size="small"
                            className="formField__select"
                          >
                            <Select
                              onChange={(e) => handleStatusFilterChange(e)}
                              IconComponent={() => <ArrowDown />}
                              name="Status"
                              displayEmpty
                              value={
                                selectedStatus?.length ? selectedStatus : ''
                              }
                              renderValue={(selected) => {
                                if (!selected) {
                                  return (
                                    <span style={{ opacity: '0.8' }}>
                                      Select status
                                    </span>
                                  );
                                }

                                return selected;
                              }}
                            >
                              {BillingStatus?.map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item}>
                                    {item}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>

                <div className="filter__footer">
                  <div className="btn__group flex--between">
                    <button
                      className="btn btn__transparent p_0"
                      onClick={(e) => {
                        clearFilter();
                        handleFilterClose();
                        setFilterApplied(false);
                      }}
                    >
                      Clear
                    </button>
                    <button
                      className="btn btn__primary"
                      onClick={(e) => {
                        handleFilterClose();
                        applyFilterSelection();
                        setFilterApplied(true);
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
              {selectedStatus.length ? (
                <div className="listColTitle filterClear">
                  <p>Filters applied ({selectedStatus.length ? '1' : '0'})</p>
                  <button
                    className="filterClear__btn"
                    onClick={() => {
                      clearFilter();
                      setFilterApplied(false);
                    }}
                  >
                    Clear filter
                  </button>
                </div>
              ) : (
                ''
              )}
              <JobsListing
                jobList={recruitersJobsList}
                activeJobId={activeJobId}
                onJobSelect={onJobSelect}
                recruiterJobsPagination={recruiterJobsPagination}
                JobsPageChange={(event, value) => JobsPageChange(event, value)}
                totalUnpaidAmount={totalUnpaidAmount}
                setAddResponsiveClass={setAddResponsiveClass}
              />
            </div>
          </div>
          <RecruiterCandidateListing
            addResponsiveClass={addResponsiveClass}
            setAddResponsiveClass={setAddResponsiveClass}
            candidateList={candidateList}
            activeRecruiter={location?.state?.activeRecruiter}
            candidatesPagination={candidatesPagination}
            changeCandidatesPage={(event, value) =>
              changeCandidatesPage(event, value)
            }
            recruitersJobsList={recruitersJobsList}
            activeJobId={activeJobId}
            recruiterId={location?.state?.activeRecruiter?.recruiterId}
            searchInCandidateListing={(searchText) =>
              searchInCandidateListing(searchText)
            }
            applyCandidateFilter={(selectedOption) =>
              applyCandidateFilter(selectedOption)
            }
            clearCandidateFilter={clearCandidateFilter}
            getCandidateList={getCandidateList}
            getRecruiterJobsList={getRecruiterJobsList}
          />
        </div>
      </div>
    </>
  );
};

export default RecruiterJobsCandidateIndex;
