import React from 'react';

export default function FileIconBlue() {
  return (
    <svg
      width="11"
      height="13"
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.40441 1H2.08088C1.79421 1 1.51929 1.11713 1.31658 1.32563C1.11388 1.53412 1 1.81691 1 2.11176V11.0059C1 11.3007 1.11388 11.5835 1.31658 11.792C1.51929 12.0005 1.79421 12.1176 2.08088 12.1176H8.56618C8.85284 12.1176 9.12777 12.0005 9.33047 11.792C9.53318 11.5835 9.64706 11.3007 9.64706 11.0059V4.33529L6.40441 1Z"
        fill="white"
      />
      <path d="M6.40441 1V4.33529H9.64706" fill="white" />
      <path d="M4.24265 4.89118H3.16176H4.24265Z" fill="white" />
      <path
        d="M6.40441 1H2.08088C1.79421 1 1.51929 1.11713 1.31658 1.32563C1.11388 1.53412 1 1.81691 1 2.11176V11.0059C1 11.3007 1.11388 11.5835 1.31658 11.792C1.51929 12.0005 1.79421 12.1176 2.08088 12.1176H8.56618C8.85284 12.1176 9.12777 12.0005 9.33048 11.792C9.53318 11.5835 9.64706 11.3007 9.64706 11.0059V4.33529M6.40441 1L9.64706 4.33529M6.40441 1V4.33529H9.64706M7.48529 7.11471H3.16176M7.48529 9.33824H3.16176M4.24265 4.89118H3.16176"
        stroke="#28A0E8"
        strokeWidth="1.23529"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
