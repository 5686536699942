import React from 'react';
import CloseIcon from '@/assets/svgs/CloseIcon';

const FeedbackModal = ({
  openModal,
  approveWithoutFeedback,
  approveWithFeedback,
  closeBtnAction
}) => {
  return (
    <>
      <div className={`modal modalPrompt ${openModal ? 'modal--open' : ''}`}>
        <div className="modal__dialog">
          <div className="modal__content">
            <div className="modal__header">
              <h2 className="modal__header--title">Feedback</h2>
              <button
                onClick={closeBtnAction}
                className="btn btn__transparent btnClose"
              >
                <CloseIcon />
              </button>
            </div>
            <div className="modal__body">
              <h4 className="promptMessage">Want to add a feedback?</h4>
            </div>
            <div className="modal__footer">
              <div className="btn__group">
                <button
                  className="btn btn__white"
                  onClick={() => approveWithoutFeedback()}
                >
                  No, Approve
                </button>
                <button
                  className="btn btn__primary"
                  onClick={() => approveWithFeedback()}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
    </>
  );
};

export default FeedbackModal;
