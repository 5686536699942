import httpService from '@/services/http-service';
import { DASHBOARD_DATA } from '@/services/end-points';

export const getDashboardData = async () => {
  try {
    const response = await httpService.get(DASHBOARD_DATA);
    return response;
  } catch (error) {
    return error;
  }
};
