import React from 'react';
import moment from 'moment';
import { Currency } from '@/helper';
import CustomPagination from '@/elements/pagination/customPagination';

const CustomerBilling = ({
  customerLengthCount,
  UnbilledAmount,
  activeCompanyData,
  customerList,
  onCompanySelection,
  changePage,
  pagination
}) => {
  return (
    <>
      {customerList?.length > 0 && (
        <div className="listColTitle totalBilled">
          <p>Total Unbilled</p>
          <h4>{UnbilledAmount ? Currency(UnbilledAmount) : Currency(0)}</h4>
        </div>
      )}
      <ul
        className="listCol__list listing__list listing__billingPayments"
        style={{ height: 'calc(100vh - 242px)' }}
      >
        {customerList?.length > 0 ? (
          customerList.map((row, index) => {
            let liClassName = 'listCol__list--item';
            liClassName =
              activeCompanyData?.id === row?.id
                ? liClassName + ' ' + 'listCol__item--selected'
                : liClassName;
            liClassName =
              row?.status === 'delete'
                ? liClassName + ' ' + 'listCol__item--alerterror'
                : liClassName;
            return (
              <li
                disabled={row?.status === 'delete'}
                key={`billingCustomers${row?.id}`}
                className={liClassName}
                onClick={() => {
                  onCompanySelection(row);
                }}
              >
                <div className="item item--billingPayments">
                  <div className="item__logo">
                    {row?.companyLogo ? (
                      <img src={row?.companyLogo} alt="Logo" />
                    ) : (
                      <div className="initials initials__lg">
                        {row?.companyName?.charAt(0).toUpperCase()}
                      </div>
                    )}
                  </div>
                  <div className="item__info">
                    <h4 className="item__info--title">
                      <span>{row?.companyName}</span>
                      {[row?.state, row?.country].join(', ')}
                    </h4>

                    {row?.BillingStatus && (
                      <div className="item__info--subtitle gap--sm">
                        <div className="date">
                          <span>Last Billed Amount /Date:</span>
                          {Currency(
                            row?.lastBillAmount ? row?.lastBillAmount : 0
                          )}
                          , {moment(row?.updatedAt).format('D MMM , YYYY')}
                        </div>

                        {/* <span className="status">
                          <Dot />
                          Unbilled: <span className="text--normal">$136</span>
                        </span> */}
                      </div>
                    )}
                    <div className="item__info--subtitle">
                      <span className="status">
                        Status:{' '}
                        <span
                          className={
                            row?.BillingStatus
                              ? 'text--primary'
                              : 'text--danger'
                          }
                        >
                          {row?.BillingStatus ? 'Billed' : 'Unbilled'}
                        </span>
                      </span>
                    </div>
                    {row?.status === 'delete' && (
                      <div className="item__info--subtitle item__info--subtitle--error">
                        Account Deactivated
                      </div>
                    )}
                  </div>
                </div>
              </li>
            );
          })
        ) : (
          <div className="no-data">No Results Found!</div>
        )}
      </ul>
      {customerList.length > 0 ? (
        <div className="pagination pagination__center">
          <CustomPagination
            totalPageCount={Math.ceil(
              customerLengthCount / pagination.recordsPerPage
            )}
            changePage={(event, value) => changePage(event, value)}
            page={pagination?.currentPage}
          />
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default CustomerBilling;
