import React, { useState } from 'react';
import CustomPagination from '@/elements/pagination/customPagination';
import GoTOPage from '@/elements/gotopage/gotopage';

const TagsListPagination = ({ pagination, changePage }) => {
  return (
    <div className="pagination">
      <div className="pagination__result">{`${
        pagination?.totalRecord >
        pagination?.currentPage * pagination?.recordsPerPage
          ? pagination?.currentPage * pagination?.recordsPerPage
          : pagination?.totalRecord
      } of ${pagination?.totalRecord} results`}</div>
      {pagination?.totalRecord ? (
        <CustomPagination
          totalPageCount={Math.ceil(
            pagination?.totalRecord / pagination.recordsPerPage
          )}
          changePage={(event, value) => changePage(event, value)}
          page={pagination?.currentPage}
        />
      ) : (
        ''
      )}
      {pagination?.totalRecord ? (
        <div className="pagination__actions">
          <GoTOPage changePage={changePage} />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default TagsListPagination;
