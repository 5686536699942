import React, { useRef, useState, useEffect, useContext } from 'react';
import { formatPhoneNumber } from '@/helper';
import CopyToClipboard from '@/assets/svgs/CopyToClipboard';
import { GetUserDetails, toTitleCase } from '@/helper';
import Dot from '@/assets/svgs/Dot';
import UniversityLogoDefault from '@/assets/images/university.svg';
import PlusIcon from '@/assets/svgs/PlusIcon';
import EditIconNew from '@/assets/svgs/EditIconNew';
import AddEducationalDetailModal from '@/components/modals/AddEducationalDetailModal';
import { addRecruiterEducationalDetails } from '@/services/recruiters-service/recruiters-service';
import { INSTITUTION_LOGO } from '@/config';
import { SuccessfullyModal } from '@/components/modals/successfully-modal';
import moment from 'moment';
import WishIcon from '@/assets/svgs/WishIcon';
import Rating from '@mui/material/Rating';
import {
  deleteRecruiterReviewsRatings,
  unhideRecruiterReviewsRatings
} from '@/services/reviews-ratings-service/get-nps-ratings';
import { getRecruiterDetails } from '@/services/recruiters-service/recruiters-service';
import { LoaderContext } from '@/components/Loader/Loader';
import OpenEyeBlue from '@/assets/svgs/OpenEyeBlue';
import CloseEyeBlue from '@/assets/svgs/CloseEyeBlue';
import AddEditReviewsRatingsModal from '@/components/modals/AddEditReviewsRatingsModal';
import StarIcon from '@/assets/svgs/StarIcon';
import { getInitial } from '@/helper';

function RecruiterDetails({ activeRecruiterData, refreshDetailPage }) {
  const user = GetUserDetails();
  const setLoading = useContext(LoaderContext);

  const [OpenEduModal, setOpenEduModal] = useState(false);
  const [OpenAddEditReviewModal, setOpenAddEditReviewModal] = useState(false);
  const [refreshRecruiterDetails, setRefreshRecruiterDetails] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const copyLinkedinText = useRef(null);
  const [showFullNotes, setShowFullNotes] = useState({});
  const [SelectedRating, setSelectedRating] = useState({});
  const [ActiveRecruiterDetails, setActiveRecruiterDetails] =
    useState(activeRecruiterData);

  function copyToClipboard() {
    copyLinkedinText.current.select();
    document.execCommand('copy');
  }

  useEffect(() => {
    if (activeRecruiterData?.recruiterId) {
      getActiveRecruiterDetails(activeRecruiterData?.recruiterId);
    }
  }, [activeRecruiterData?.recruiterId, refreshRecruiterDetails]);

  const getGraduationYear = (qualification) => {
    const gradYear = `${toTitleCase(qualification?.startMonth)} ${qualification?.startYear} - ${toTitleCase(qualification?.endMonth)} ${qualification?.endYear}`;
    return gradYear;
  };

  const AddEducationalDeatils = async (values) => {
    let body = {
      recruiterId: activeRecruiterData?.recruiterId,
      degree: values?.degree,
      institutionName: values?.institutionName,
      logoName: values?.institutionNameLogo,
      startMonth: values?.startMonth,
      startYear: values?.startYear,
      endMonth: values?.endMonth,
      endYear: values?.endYear,
      fieldOfStudy: values?.fieldOfStudy,
      addedBy: user?.adminId
    };
    await addRecruiterEducationalDetails(body);
    setOpenSuccessModal(true);
    setOpenEduModal(false);
    setTimeout(() => {
      setOpenSuccessModal(false);
      refreshDetailPage();
    }, 3000);
  };

  const toggleReadMore = (index) => {
    setShowFullNotes((prev) => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const getActiveRecruiterDetails = async (recruiterId) => {
    try {
      setLoading(true);
      const response = await getRecruiterDetails(recruiterId);
      const { data, meta } = response;
      if (meta?.status) {
        setActiveRecruiterDetails(data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const HideReviewsRatings = async (deleteId, recruiterId) => {
    let body = {
      ratingId: deleteId
    };
    const response = await deleteRecruiterReviewsRatings(body);
    const { meta } = response;
    if (meta?.status) {
      getActiveRecruiterDetails(recruiterId);
      setSelectedRating({});
    }
  };

  const UnhideReviewsRatings = async (deleteId, recruiterId) => {
    let body = {
      ratingId: deleteId
    };
    const response = await unhideRecruiterReviewsRatings(body);
    const { meta } = response;
    if (meta?.status) {
      getActiveRecruiterDetails(recruiterId);
      setSelectedRating({});
    }
  };

  const handleEditReviewRatings = (rating) => {
    setOpenAddEditReviewModal(true);
    setSelectedRating(rating);
  };
  const filteredRatings = ActiveRecruiterDetails?.ratings?.filter(
    (rating) => !rating?.hide
  );

  return (
    <>
      <div className="details">
        <section className="details__section">
          {ActiveRecruiterDetails?.firstName && (
            <div className="initials initials__lg">
              {ActiveRecruiterDetails?.firstName?.length
                ? ActiveRecruiterDetails?.firstName[0].toUpperCase()
                : ''}
            </div>
          )}
          {ActiveRecruiterDetails?.firstName &&
            ActiveRecruiterDetails?.lastName && (
              <div className="details__section--name details__section--name--assignRecruiter d-flex item-align">
                {`${`${ActiveRecruiterDetails?.firstName[0].toUpperCase()}${ActiveRecruiterDetails?.firstName.slice(
                  1
                )}`} ${`${ActiveRecruiterDetails?.lastName[0].toUpperCase()}${ActiveRecruiterDetails?.lastName.slice(
                  1
                )}`}`}
                {filteredRatings?.length ? (
                  <div className="reviewCountInfo">
                    <span className="reviewCountInfo__totalRating">
                      {ActiveRecruiterDetails?.ratingCount?.toFixed(1)} Rating
                    </span>
                    <StarIcon />{' '}
                    <span>{ActiveRecruiterDetails?.reviewCount} Reviews</span>
                  </div>
                ) : (
                  ''
                )}
                {ActiveRecruiterDetails?.deleteStatus && (
                  <span className="deactivateAccount">Account Deactivated</span>
                )}
              </div>
            )}
          <div className="details__row">
            {ActiveRecruiterDetails?.phoneNumber && (
              <div className="details__row--item">
                <span>Phone:</span>
                {ActiveRecruiterDetails?.countryCode}{' '}
                {formatPhoneNumber(ActiveRecruiterDetails.phoneNumber)}
              </div>
            )}
            <div className="details__row--item">
              <span>Email:</span>
              {`${ActiveRecruiterDetails?.email ?? ''}`}
            </div>
          </div>
          <ul className="tags__list">
            <li key="tag1" className="tags__list--item">
              {ActiveRecruiterDetails?.experience === 0
                ? 'Fresher'
                : ActiveRecruiterDetails?.experience > 1
                  ? `${ActiveRecruiterDetails?.experience} Years`
                  : `${ActiveRecruiterDetails?.experience} Year`}
            </li>
            <li
              key="tag2"
              className="tags__list--item"
            >{`${ActiveRecruiterDetails?.jobs?.length} Assigned Jobs`}</li>
          </ul>
        </section>
        <section className="details__section">
          <h4 className="details__section--title">Professional Details</h4>
          <div className="formRow">
            <div className="formCol">
              <label className="formField__label">Recruiting Field</label>
              <ul className="tags__list">
                {ActiveRecruiterDetails?.skillSet?.length
                  ? ActiveRecruiterDetails?.skillSet.map((item, index) => {
                      return (
                        <li
                          key={`recruiterSkillSet${index}`}
                          className="tags__list--item"
                        >
                          {item}
                        </li>
                      );
                    })
                  : ''}
              </ul>
            </div>
          </div>

          <div className="formRow">
            <div className="formCol">
              <div className="formField">
                <label className="formField__label">LinkedIn Account</label>
                <div className="formField__group sm">
                  <input
                    type="text"
                    className={
                      'formField__input formField__disabled disabledHighlighted'
                    }
                    placeholder=""
                    ref={copyLinkedinText}
                    value={`${ActiveRecruiterDetails?.linkedin ?? ''}`}
                    readOnly={true}
                  />
                  <span
                    onClick={copyToClipboard}
                    className={`formField__icon ${
                      ActiveRecruiterDetails?.linkedin ? '' : 'iconDisabled'
                    }`}
                  >
                    <CopyToClipboard />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        {ActiveRecruiterDetails?.qualificationArray[0]?.degree !== '' && (
          <section className="details__section">
            <h4 className="details__section--title">
              Educational Details
              <button
                type="button"
                className="btn btn__white btn--sm"
                onClick={() => {
                  setOpenEduModal(true);
                }}
              >
                <PlusIcon />
              </button>
            </h4>
            <div className="fieldBox flexRow flexRow_3 w__100">
              {ActiveRecruiterDetails?.qualificationArray?.map(
                (qualification, index) => {
                  return (
                    <div className="flexCol" key={`recruiterdiv${index}`}>
                      <div className="educationInfobox">
                        {UniversityLogoDefault ? (
                          <div className="educationInfobox__educationLogo">
                            <img
                              src={
                                qualification?.logoName
                                  ? INSTITUTION_LOGO + qualification?.logoName
                                  : UniversityLogoDefault
                              }
                              alt=""
                            />
                          </div>
                        ) : (
                          <div className="initials initials__lg">
                            {qualification?.institutionName
                              ?.charAt(0)
                              .toUpperCase()}
                          </div>
                        )}

                        <div className="educationInfobox__detail">
                          <h3 className="title">
                            {qualification?.institutionName}
                          </h3>
                          <div className="subtitle">
                            <span>{qualification?.degree}</span>
                            <span>
                              <Dot />
                              {qualification?.fieldOfStudy}
                            </span>
                          </div>
                          <div className="subtitle">
                            <span>{getGraduationYear(qualification)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </section>
        )}
        {ActiveRecruiterDetails?.ratings?.length ? (
          <section className="details__section">
            <h4 className="details__section--title">Reviews and Ratings</h4>
            <div className="reviewSec">
              <h5 className="viewAllReview">All Reviews</h5>
              <div className="reviews">
                {ActiveRecruiterDetails?.ratings?.map((rating, index) => {
                  const isLongNote = rating?.notes?.length > 250;
                  const shouldShowFullNote = showFullNotes[index];
                  const displayedNote =
                    isLongNote && !shouldShowFullNote
                      ? `${rating?.notes.substring(0, 250)}...`
                      : `${rating?.notes} `;
                  return (
                    <div
                      className={`reviews__row ${
                        rating?.hide ? 'reviews__row--alerterror' : ''
                      }`}
                    >
                      <div className="reviewDetails">
                        <div
                          className={`pic ${rating?.company?.companyLogo ? '' : 'pic__rounded'}`}
                        >
                          {rating?.company?.companyLogo ? (
                            <>
                              <img
                                className="pic__img"
                                src={rating?.company?.companyLogo}
                                alt=""
                              />
                              <span className="wishIcon">
                                <WishIcon />
                              </span>
                            </>
                          ) : (
                            <span className="initials">
                              {getInitial(rating?.company?.companyName)}
                            </span>
                          )}
                        </div>
                        <div className="details details--widthAuto">
                          <h5 className="userName">
                            <span>
                              {rating?.company?.companyName}{' '}
                              <span className="reviewDate">
                                {moment(rating?.updatedAt).format(
                                  'D MMM, YYYY'
                                )}
                              </span>
                            </span>
                            <div className="btn__group">
                              <button
                                className="btn btn__white btn__edit btnHideReview"
                                onClick={() => handleEditReviewRatings(rating)}
                              >
                                <EditIconNew />
                              </button>
                              {!rating?.hide ? (
                                <button
                                  className="btn btn__primary btn__outlined btnHideReview"
                                  onClick={() =>
                                    HideReviewsRatings(
                                      rating?.ratingId,
                                      rating?.recruiterId
                                    )
                                  }
                                >
                                  <CloseEyeBlue />
                                  Hide
                                </button>
                              ) : (
                                <button
                                  className="btn btn__primary btn__outlined btnHideReview"
                                  onClick={() =>
                                    UnhideReviewsRatings(
                                      rating?.ratingId,
                                      rating?.recruiterId
                                    )
                                  }
                                >
                                  <OpenEyeBlue />
                                  Unhide
                                </button>
                              )}
                            </div>
                          </h5>
                          <div className="ratingsInfo">
                            <span className="ratings">
                              <Rating
                                precision={0.5}
                                name="read-only"
                                value={rating?.averageRating}
                                readOnly
                                sx={{
                                  '& .MuiRating-iconFilled': {
                                    color: '#28A0E8'
                                  }
                                }}
                              />
                            </span>
                            {rating?.averageRating} Rating
                          </div>
                          <p className="recrutingCunsultationText">
                            <strong>Recruiting:</strong> {rating?.job?.title}
                          </p>
                        </div>
                      </div>
                      <p>
                        {displayedNote}
                        {isLongNote && (
                          <button
                            onClick={() => toggleReadMore(index)}
                            className="readMoreButton"
                          >
                            {shouldShowFullNote ? 'Read Less' : 'Read More'}
                          </button>
                        )}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        ) : (
          ''
        )}
      </div>
      <AddEducationalDetailModal
        openModal={OpenEduModal}
        closeModal={() => setOpenEduModal(false)}
        onSubmit={AddEducationalDeatils}
      />
      <SuccessfullyModal
        openModal={openSuccessModal}
        closeModal={() => setOpenSuccessModal(false)}
        sucessfullMsg={'Educational Details added successfuly.'}
        Heading={'Educational Details Added!'}
      />
      <AddEditReviewsRatingsModal
        openModal={OpenAddEditReviewModal}
        closeModal={() => {
          setOpenAddEditReviewModal(false);
          setSelectedRating({});
        }}
        SelectedRating={SelectedRating}
        setOpenAddEditReviewModal={setOpenAddEditReviewModal}
        setRefreshRecruiterDetails={setRefreshRecruiterDetails}
        refreshRecruiterDetails={refreshRecruiterDetails}
        setSelectedRating={setSelectedRating}
      />
    </>
  );
}

export default RecruiterDetails;
