import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import _ from 'lodash';
import moment from 'moment';
import {
  getCompanyEmployeeList,
  getCompanyInterviews,
  createMeeting
} from '@/services/interview-service/interview-service';
import CustomPagination from '@/elements/pagination/customPagination';
import GoTOPage from '@/elements/gotopage/gotopage';
import { LoaderContext } from '@/components/Loader/Loader';
import ArrowDown from '@/elements/ArrowDown';
import { SaveInterviewDetails, GetAdminDetails } from '@/helper';
import PlusIcon from '@/assets/svgs/PlusIcon';
import { LAILA_LOGO, APP_URL } from '@/config';

const ScheduledInterviews = ({
  activeCompanyId = null,
  originType = null,
  changeInterviewType,
  activeRecruiterId = null,
  activeRecruiterEmail = null,
  activeCompanyEmployees = null,
  activeRecruiterStatus = false,
  companyDeleteStatus = false,
  tabValue
}) => {
  const setLoading = useContext(LoaderContext);
  const [openModal, setOpenModal] = useState(false);
  const [interviews, setInterviews] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(
    activeRecruiterId ? activeRecruiterEmail : ''
  );
  const [selectedEmployeeId, setSelectedEmployeeId] = useState('');
  const [pagination, setPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0,
    search: '',
    tab: 'schedule',
    type: activeRecruiterId ? 'adminToRecruiter' : 'adminToEmployee'
  });

  useEffect(() => {
    getInterviews();
  }, [activeCompanyId, activeRecruiterId, tabValue]);

  const getInterviews = async (
    currentPage = pagination?.currentPage,
    recordsPerPage = pagination?.recordsPerPage,
    search = pagination?.search,
    tab = pagination?.tab,
    type = activeRecruiterId ? 'adminToRecruiter' : 'adminToEmployee'
  ) => {
    setLoading(true);
    const response = await getCompanyInterviews(
      currentPage,
      recordsPerPage,
      search,
      tab,
      type,
      activeCompanyId,
      activeRecruiterId
    );
    const { meta, data } = response;
    if (meta?.status) {
      setPagination((prev) => ({
        ...prev,
        totalRecord: data?.count ? data?.count : 0
      }));
      setInterviews(data?.rows);
      setEmployees([]);
      setSelectedEmail('');
    }
    setLoading(false);
  };

  const openScheduleModal = async () => {
    if (activeRecruiterId) {
      setEmployees([
        {
          email: activeRecruiterEmail,
          employeeId: activeRecruiterId
        }
      ]);
      setSelectedEmail(activeRecruiterEmail);
      setSelectedEmployeeId(activeRecruiterId);
      scheduleMeeting();
    } else {
      if (!employees?.length) {
        const response = await getCompanyEmployeeList(
          1,
          100,
          '',
          activeCompanyId
        );
        const { meta, data } = response;
        if (meta?.status) {
          setEmployees(data?.rows);
        }
        setSelectedEmail('');
      }
      setOpenModal(true);
    }
  };

  const scheduleMeeting = async () => {
    setLoading(true);
    setOpenModal(false);
    const response = await createMeeting();
    const { meta, data } = response;
    if (meta.status) {
      SaveInterviewDetails({
        companyId: activeCompanyId,
        employeeId: selectedEmployeeId,
        meetingTitle: data?.title,
        meetingId: data?.id,
        recruiterId: activeRecruiterId
      });
      setTimeout(() => {
        setLoading(false);
        window.nylas.scheduler.show({
          pageDomain: '',
          auth: {
            accessToken: GetAdminDetails()?.nylasAccessToken
          },

          style: {
            tintColor: false ? '#FF0000' : '#32325d',
            backgroundColor: false ? '#424242' : 'white',
            modalTitle: 'Laila Interview'
          },
          defaults: {
            event: {
              title: 'Interview',
              location: `${APP_URL}meeting/${data?.id}`,
              duration: 60
            },
            appearance: {
              thank_you_redirect: `${APP_URL}thankyou`,
              company_name: 'Laila',
              logo: LAILA_LOGO
            },
            booking: {
              confirmation_emails_to_host: true,
              confirmation_emails_to_guests: true,
              calendar_invite_to_guests: true,
              min_booking_notice: 120,
              min_buffer: 0,
              additional_guests_hidden: true,
              available_days_in_future: 360
            }
          }
        });
      }, 1000);
    }
  };

  const handleChange = (e) => {
    setSelectedEmail(e?.target?.value?.split('/')[0]);
    setSelectedEmployeeId(e?.target?.value?.split('/')[1]);
  };

  const changePage = (event, value) => {
    setPagination({ ...pagination, currentPage: value });
  };

  const handleSelectChange = (e) => {
    changeInterviewType(e?.target?.value);
  };

  const searchInInterviewListing = useCallback(
    _.debounce(
      (searchText) =>
        getInterviews(
          searchText ? 1 : pagination?.currentPage,
          pagination?.recordsPerPage,
          searchText,
          pagination?.tab,
          pagination?.type
        ),
      500
    ),
    []
  );

  const navigateToRescheduleUrl = (data) => {
    SaveInterviewDetails({
      companyId: activeRecruiterId ? null : activeCompanyId,
      employeeId: activeRecruiterId ? null : data?.employeeId,
      recruiterId: activeRecruiterId
    });
    window.open(
      `https://schedule.nylas.com/${data?.meetingInfo?.page_slug}/reschedule/${data?.meetingInfo?.edit_hash}?`,
      '_blank'
    );
  };

  return (
    <>
      <div className="tabContainer__item--head head--responsive bg--white">
        <div className="listCol__head--actions flex--between w_100">
          <div className="formRow formRow__3 m_0 w_100">
            <div className="formCol">
              <div
                className={`formField ${
                  originType == 'interview' ? 'formFieldSearch' : ''
                }`}
              >
                <div className="formField__group sm">
                  <span className="formField__icon">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                        stroke="#6C737F"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="formField__input"
                    placeholder="Search an interview"
                    onChange={(e) => searchInInterviewListing(e?.target?.value)}
                  />
                </div>
              </div>
            </div>
            {originType == 'interview' ? (
              ''
            ) : (
              <div className="formCol">
                <div className="formField">
                  <FormControl
                    fullWidth
                    size="small"
                    className="formField__select"
                    sx={{ minHeight: '42px' }}
                  >
                    <Select
                      onChange={(e) => handleSelectChange(e)}
                      IconComponent={() => <ArrowDown />}
                      placeholder="Country"
                      name="country"
                      value={''}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return (
                            <span style={{ opacity: '0.8' }}>
                              Scheduled interviews
                            </span>
                          );
                        }

                        return 'Scheduled Interviews';
                      }}
                    >
                      <MenuItem key="schedule" value="schedule">
                        Scheduled Interviews
                      </MenuItem>
                      <MenuItem key="past" value="past">
                        Past Interviews
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            )}
          </div>
          {activeCompanyEmployees || activeRecruiterId ? (
            <button
              className="btn btn__white w_fitContent"
              onClick={() => openScheduleModal()}
              disabled={activeRecruiterStatus || companyDeleteStatus}
            >
              <PlusIcon />
              Schedule a meeting
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
      <TableContainer
        component={Paper}
        className="tableContainer tableScheduledInterviews"
        style={{ height: 'calc(100dvh - 249px)' }}
      >
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell>S.</TableCell>
              <TableCell>Interview Title</TableCell>
              <TableCell>Interview Date</TableCell>
              <TableCell>Interview Time</TableCell>
              <TableCell>Interview URL</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(activeCompanyId || activeRecruiterId) &&
              interviews?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>
                    <div className="cellText">
                      <span className="cellText__dark">
                        {row?.title ? row?.title : '--'}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="cellText">
                      <span className="cellText__dark">
                        {moment(row?.meeting_start_time).format('D MMM , YYYY')}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="cellText">
                      <span className="cellText__dark">
                        {moment(row?.meeting_start_time).format('hh:mm A') +
                          '-' +
                          moment(row?.meeting_end_time).format('hh:mm A')}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="cellText">
                      <span className="cellText__dark">
                        <Link
                          to={
                            row?.meetingInfo?.location
                              ? row?.meetingInfo?.location
                              : '#'
                          }
                          onCopy={(e) => {
                            if (
                              row?.status === 'cancelled' ||
                              activeRecruiterStatus ||
                              companyDeleteStatus
                            ) {
                              e.preventDefault();
                              return false;
                            }
                          }}
                          target="_blank"
                          className={`flexCol__text text--primary  ${
                            row?.status == 'cancelled' ||
                            activeRecruiterStatus ||
                            companyDeleteStatus
                              ? 'formField__disabled'
                              : ''
                          }`}
                        >
                          {row?.meetingInfo?.location}
                        </Link>
                      </span>
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    <div className="actions flex--end">
                      {row?.status == 'cancelled' ? (
                        'Cancelled'
                      ) : (
                        <button
                          onClick={() => navigateToRescheduleUrl(row)}
                          className="btn btn__white"
                          disabled={
                            activeRecruiterStatus || companyDeleteStatus
                          }
                        >
                          Reschedule
                        </button>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {interviews?.length ? (
          ''
        ) : (
          <div className="no-data">No Results Found!</div>
        )}
      </TableContainer>

      <div className="pagination">
        <div className="pagination__result">{`${
          pagination?.totalRecord >
          pagination?.currentPage * pagination?.recordsPerPage
            ? pagination?.recordsPerPage
            : pagination?.totalRecord
        } of ${pagination?.totalRecord} results`}</div>
        {pagination?.totalRecord ? (
          <CustomPagination
            totalPageCount={Math.ceil(
              pagination?.totalRecord / pagination.recordsPerPage
            )}
            changePage={(event, value) => changePage(event, value)}
          />
        ) : (
          ''
        )}
        {pagination?.totalRecord ? (
          <div className="pagination__actions">
            <GoTOPage changePage={changePage} />
          </div>
        ) : (
          ''
        )}
      </div>
      <div
        className={`modal modalPrompt modalForm ${
          openModal ? 'modal--open' : ''
        }`}
      >
        <div className="modal__dialog sm">
          <div className="modal__content">
            <div className="modal__header">
              <h2 className="modal__header--title">Enter Email Address</h2>
            </div>
            <div className="modal__body">
              <div className="formField">
                <label className="formField__label">Select Email</label>
                <FormControl
                  fullWidth
                  size="small"
                  className="formField__select"
                  sx={{ minHeight: '42px' }}
                >
                  <Select
                    onChange={(e) => handleChange(e)}
                    IconComponent={() => <ArrowDown />}
                    placeholder="Candidates email"
                    name="candidate"
                    displayEmpty
                    value={selectedEmail}
                    renderValue={(selected) => {
                      if (!selected) {
                        return (
                          <span style={{ opacity: '0.8' }}>Select email</span>
                        );
                      }
                      return selectedEmail ? selectedEmail : 'Select email';
                    }}
                  >
                    {employees.map((item, index) => (
                      <MenuItem
                        key={`employee${index}`}
                        value={item?.email + '/' + item?.employeeId}
                      >
                        {item?.email}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="modal__footer">
              <div className="btn__group">
                <button
                  className="btn btn__white"
                  onClick={() => setOpenModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn btn__primary"
                  disabled={!selectedEmail}
                  onClick={() => scheduleMeeting()}
                >
                  Schedule
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
    </>
  );
};

export default ScheduledInterviews;
