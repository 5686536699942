import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef
} from 'react';
import _ from 'lodash';
import { LoaderContext } from '@/components/Loader/Loader';
import SideBar from '@/components/layout/SideBar';
import Search from '@/assets/svgs/Search';
import PlusIcon from '@/assets/svgs/PlusIcon';
import AddNewTagsModal from '@/components/modals/AddNewTagsModal';
import TagsList from '@/pages/Settings/Tags/tagsList';
import TagsListPagination from '@/pages/Settings/Tags/tagsListPagination';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ArrowDown from '@/elements/ArrowDown';
import {
  getAllTagList,
  changeTagStatus
} from '@/services/settings-service/tags/tags-service';
import ErrorModal from '@/components/modals/error-modal';
import ConfirmationModal from '@/components/modals/confirmation-modal';
import { SuccessfullyModal } from '@/components/modals/successfully-modal';
import { GetUserDetails } from '@/helper';
import CustomerTagsList from './CustomerTagsList';
import CustomerTagsFilter from '@/components/CustomerTagsFilter';
import { getCustomerTagsList } from '@/services/settings-service/tags/tags-service';
import CustomPagination from '@/elements/pagination/customPagination';
import GoTOPage from '@/elements/gotopage/gotopage';

const TagsIndex = () => {
  const user = GetUserDetails();
  const searchRef = useRef();
  const employeeId = user?.adminId;
  const setLoading = useContext(LoaderContext);
  const [OpenAddTagsModal, setOpenAddTagsModal] = useState(false);
  const [tagsData, setTagsData] = useState([]);
  const [selectedTagData, setSelectedTagData] = useState({});
  const [openRemoveConfModal, setOpenRemoveConfModal] = useState(false);
  const [openSuccessfullModal, setOpenSuccessfullModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [LailaTagStatus, setLailaTagStatus] = useState('');
  const [LailaSearchText, setLailaSearchText] = useState('');
  // ****************************************************************
  const [OpenCustomerTagsList, setOpenCustomerTagsList] = useState(false);
  const [TagsListOpened, setTagsListOpened] = useState('LailaTags');
  const [CompanyFilterValue, setCompanyFilterValue] = useState([]);
  const [CreatedByFilterValue, setCreatedByFilterValue] = useState([]);
  const [CustomerTagsData, setCustomerTagsData] = useState([]);
  const [CustomerPage, setCustomerPage] = useState(1);
  const [CompanyIdArray, setCompanyIdArray] = useState([]);
  const [CreatedByIdArray, setCreatedByIdArray] = useState([]);
  const [filteranchorEl, setFilterAnchorEl] = useState(null);
  const [SelectedRadioValue, setSelectedRadioValue] = useState('1');
  const [CustomerTagSearchText, setCustomerTagSearchText] = useState('');
  const [CustomerTagStatus, setCustomerTagStatus] = useState('');

  // ******************************************************************
  const [pagination, setPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0
  });
  const [CustomerPagination, setCustomerPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0
  });

  const getTagsList = async (
    limit = pagination?.recordsPerPage,
    page = pagination?.currentPage,
    tagName = LailaSearchText,
    status = LailaTagStatus
  ) => {
    setLoading(true);
    const allTags = await getAllTagList(limit, page, tagName, status);
    if (allTags?.meta?.status) {
      setPagination({ ...pagination, totalRecord: allTags?.data?.count });
      setTagsData(allTags?.data?.rows);
    }
    setLoading(false);
  };

  const changePage = (event, value) => {
    setPagination({ ...pagination, currentPage: value });
    getTagsList(
      pagination?.recordsPerPage,
      value,
      LailaSearchText,
      LailaTagStatus
    );
  };

  const serachTags = useCallback(
    _.debounce((searchText, LailaTagStatus) => {
      setLailaSearchText(searchText);
      getTagsList(
        pagination?.recordsPerPage,
        pagination?.currentPage,
        searchText,
        LailaTagStatus
      );
    }, 500),
    []
  );

  const handleStatusChange = (status) => {
    setLailaTagStatus(status);
    getTagsList(
      pagination?.recordsPerPage,
      pagination?.currentPage,
      LailaSearchText,
      status
    );
  };

  const openEditTagModal = (data) => {
    setSelectedTagData(data);
    setOpenAddTagsModal(true);
  };

  const openRemoveTagModal = (data) => {
    setSelectedTagData(data);
    setOpenRemoveConfModal(true);
  };

  const removeTag = async () => {
    const status = selectedTagData?.activate ? 'deactivate' : 'activate';
    const deleteData = await changeTagStatus(
      selectedTagData?.id,
      status,
      employeeId
    );
    if (deleteData?.meta?.status) {
      setSuccessMessage(deleteData?.meta?.msg);
      setOpenSuccessfullModal(true);
    } else {
      setOpenErrorModal(true);
      setErrorMessage(deleteData?.meta?.msg);
    }
  };

  const closeSuccessModal = () => {
    setOpenSuccessfullModal(false);
    setOpenRemoveConfModal(false);
    if (OpenCustomerTagsList) {
      refreshCustomerTagsList();
    } else {
      refreshPage();
    }
  };

  const refreshPage = () => {
    getTagsList();
  };

  // *************** CUSTOMER TAGS LIST CODE ****************************

  useEffect(() => {
    if (OpenCustomerTagsList) {
      setTagsListOpened('CustomerTags');
      searchRef.current.value = '';
      setCustomerPagination({
        ...CustomerPagination,
        currentPage: 1,
        recordsPerPage: 20
      });
      setCustomerTagSearchText('');
      setCustomerTagStatus('');
      setCustomerPage(1);
      setCompanyFilterValue([]);
      setCreatedByFilterValue([]);
      setCompanyIdArray([]);
      setCreatedByIdArray([]);
      fetchCustomerTagsList(20, 1, '', '', [], []);
    } else {
      setTagsListOpened('LailaTags');
      searchRef.current.value = '';
      setPagination({
        ...pagination,
        currentPage: 1,
        recordsPerPage: 20
      });
      setLailaSearchText('');
      setLailaTagStatus('');
      getTagsList(20, 1, '', '');
    }
  }, [OpenCustomerTagsList]);

  const handleSwitchTagsList = () => {
    setOpenCustomerTagsList(!OpenCustomerTagsList);
    setTagsListOpened('CustomerTags');
  };

  const openEditCustomerTagModal = (data) => {
    setSelectedTagData(data);
    setOpenAddTagsModal(true);
  };

  const fetchCustomerTagsList = async (
    limit = CustomerPagination.recordsPerPage,
    page = CustomerPage,
    tagName = CustomerTagSearchText,
    status = CustomerTagStatus,
    companyArray = CompanyIdArray,
    employeeArray = CreatedByIdArray
  ) => {
    setLoading(true);
    const tagsRes = await getCustomerTagsList(
      limit,
      page,
      tagName,
      status,
      companyArray,
      employeeArray
    );
    if (tagsRes?.meta?.status) {
      setCustomerPagination({
        ...CustomerPagination,
        totalRecord: tagsRes?.data?.count
      });
      setCustomerTagsData(tagsRes?.data?.rows);
    }
    setLoading(false);
  };

  const customerPageChange = (event, value) => {
    setCustomerPagination({ ...CustomerPagination, currentPage: value });
    setCustomerPage(value);
    fetchCustomerTagsList(
      CustomerPagination?.recordsPerPage,
      value,
      CustomerTagSearchText,
      CustomerTagStatus,
      CompanyIdArray,
      CreatedByIdArray
    );
  };

  const handleApplyFilter = () => {
    setSelectedRadioValue('1');
    setFilterAnchorEl(null);
    const companyArray = CompanyFilterValue.map((company) => company.id);
    const employeeArray = CreatedByFilterValue.map(
      (employee) => employee.employeeId
    );
    fetchCustomerTagsList(
      CustomerPagination.recordsPerPage,
      1,
      CustomerTagSearchText,
      CustomerTagStatus,
      companyArray,
      employeeArray
    );
  };

  const handleClearFilter = () => {
    setSelectedRadioValue('1');
    setCompanyFilterValue([]);
    setCreatedByFilterValue([]);
    setCompanyIdArray([]);
    setCreatedByIdArray([]);
    setFilterAnchorEl(null);
    fetchCustomerTagsList(
      CustomerPagination.recordsPerPage,
      1,
      CustomerTagSearchText,
      CustomerTagStatus,
      [],
      []
    );
  };

  const serachCustomerTags = useCallback(
    _.debounce((searchText, status, CompanyIdArray, CreatedByIdArray) => {
      setPagination({ ...CustomerPagination, search: searchText });
      setCustomerTagSearchText(searchText);
      fetchCustomerTagsList(
        CustomerPagination?.recordsPerPage,
        1,
        searchText,
        status,
        CompanyIdArray,
        CreatedByIdArray
      );
    }, 500),
    []
  );

  const handleCustomerTagsStatusChange = (
    status,
    CompanyIdArr,
    CreatedByIdArr
  ) => {
    setCustomerTagStatus(status);
    fetchCustomerTagsList(
      pagination?.recordsPerPage,
      CustomerPage,
      CustomerTagSearchText,
      status,
      CompanyIdArr,
      CreatedByIdArr
    );
  };

  const refreshCustomerTagsList = () => {
    fetchCustomerTagsList();
  };

  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid layoutGrid--full">
          <div className="layoutGrid__col">
            <div className="dataCol">
              <div className="page page__full">
                <div className="page__head">
                  <h2 className="page__head--title">Tags</h2>
                </div>
                <div className="page__body bg--white">
                  <div className="details">
                    <div className="listCol__head--actions w_100">
                      <div className="formRow formRow__3 m_0 w_100 formRow--flexEnd">
                        <div className="formCol">
                          <div className="formField">
                            <div className="formField__group sm">
                              <span className="formField__icon">
                                <Search />
                              </span>
                              <input
                                type="text"
                                ref={searchRef}
                                className="formField__input"
                                placeholder="Search a Tag"
                                onChange={(e) => {
                                  OpenCustomerTagsList
                                    ? serachCustomerTags(
                                        e?.target?.value,
                                        CustomerTagStatus,
                                        CompanyIdArray,
                                        CreatedByIdArray
                                      )
                                    : serachTags(
                                        e?.target?.value,
                                        LailaTagStatus
                                      );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="formCol">
                          <div className="formField">
                            {!OpenCustomerTagsList ? (
                              <FormControl
                                fullWidth
                                size="small"
                                className="formField__select"
                              >
                                <Select
                                  onChange={(e) =>
                                    handleStatusChange(e?.target?.value)
                                  }
                                  IconComponent={() => <ArrowDown />}
                                  name="Status"
                                  value={LailaTagStatus}
                                  displayEmpty
                                  renderValue={(selected) => {
                                    if (!selected) {
                                      return (
                                        <span style={{ opacity: '0.8' }}>
                                          Select Status
                                        </span>
                                      );
                                    }

                                    return selected === 'all'
                                      ? 'All tags'
                                      : selected === 'activate'
                                        ? ' Active tags'
                                        : ' Deactivated tags';
                                  }}
                                >
                                  <MenuItem key={'all'} value={'all'}>
                                    All tags
                                  </MenuItem>
                                  <MenuItem key={'activate'} value={'activate'}>
                                    Active tags
                                  </MenuItem>
                                  <MenuItem
                                    key={'deactivate'}
                                    value={'deactivate'}
                                  >
                                    Deactivated tags
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            ) : (
                              <FormControl
                                fullWidth
                                size="small"
                                className="formField__select"
                              >
                                <Select
                                  onChange={(e) =>
                                    handleCustomerTagsStatusChange(
                                      e?.target?.value,
                                      CompanyIdArray,
                                      CreatedByIdArray
                                    )
                                  }
                                  IconComponent={() => <ArrowDown />}
                                  name="Status"
                                  value={CustomerTagStatus}
                                  displayEmpty
                                  renderValue={(selected) => {
                                    if (!selected) {
                                      return (
                                        <span style={{ opacity: '0.8' }}>
                                          Select Status
                                        </span>
                                      );
                                    }

                                    return selected === 'all'
                                      ? 'All tags'
                                      : selected === 'activate'
                                        ? ' Active tags'
                                        : ' Deactivated tags';
                                  }}
                                >
                                  <MenuItem key={'all'} value={'all'}>
                                    All tags
                                  </MenuItem>
                                  <MenuItem key={'activate'} value={'activate'}>
                                    Active tags
                                  </MenuItem>
                                  <MenuItem
                                    key={'deactivate'}
                                    value={'deactivate'}
                                  >
                                    Deactivated tags
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            )}
                          </div>
                        </div>
                        {OpenCustomerTagsList && (
                          <CustomerTagsFilter
                            setCompanyFilterValue={setCompanyFilterValue}
                            setCreatedByFilterValue={setCreatedByFilterValue}
                            CompanyFilterValue={CompanyFilterValue}
                            CreatedByFilterValue={CreatedByFilterValue}
                            handleApplyFilter={handleApplyFilter}
                            setCompanyIdArray={setCompanyIdArray}
                            setCreatedByIdArray={setCreatedByIdArray}
                            setFilterAnchorEl={setFilterAnchorEl}
                            filteranchorEl={filteranchorEl}
                            handleClearFilter={handleClearFilter}
                            setSelectedRadioValue={setSelectedRadioValue}
                            SelectedRadioValue={SelectedRadioValue}
                          />
                        )}
                      </div>

                      <button className="btn btn__white btn__white--hoverNone w_fitContent">
                        Total Tags:{' '}
                        <strong>
                          {OpenCustomerTagsList
                            ? CustomerPagination?.totalRecord
                            : pagination?.totalRecord}
                        </strong>
                      </button>
                      {!OpenCustomerTagsList ? (
                        <button
                          className="btn btn__white w_fitContent"
                          onClick={() => {
                            setSelectedTagData();
                            setOpenAddTagsModal(true);
                          }}
                        >
                          <PlusIcon /> Add Tag
                        </button>
                      ) : (
                        ''
                      )}
                      <button
                        className="btn btn__white w_fitContent"
                        onClick={handleSwitchTagsList}
                      >
                        {OpenCustomerTagsList ? 'Laila Tags' : 'Customer Tags'}
                      </button>
                    </div>
                    {OpenCustomerTagsList ? (
                      <CustomerTagsList
                        openEditCustomerTagModal={openEditCustomerTagModal}
                        CustomerTagsData={CustomerTagsData}
                        CustomerPagination={CustomerPagination}
                        CustomerPage={CustomerPage}
                        refreshCustomerTagsList={refreshCustomerTagsList}
                        deleteTag={openRemoveTagModal}
                      />
                    ) : (
                      <TagsList
                        tagsData={tagsData}
                        editTag={openEditTagModal}
                        deleteTag={openRemoveTagModal}
                        refreshPage={refreshPage}
                      />
                    )}
                  </div>
                </div>
                {OpenCustomerTagsList ? (
                  <div className="pagination">
                    <div className="pagination__result">{`${
                      CustomerPagination?.totalRecord >
                      CustomerPage * CustomerPagination?.recordsPerPage
                        ? CustomerPage * CustomerPagination?.recordsPerPage
                        : CustomerPagination?.totalRecord
                    } of ${CustomerPagination?.totalRecord} results`}</div>
                    {CustomerPagination?.totalRecord ? (
                      <CustomPagination
                        totalPageCount={Math.ceil(
                          CustomerPagination?.totalRecord /
                            CustomerPagination.recordsPerPage
                        )}
                        changePage={(event, value) =>
                          customerPageChange(event, value)
                        }
                        page={CustomerPage}
                      />
                    ) : (
                      ''
                    )}
                    {CustomerPagination?.totalRecord ? (
                      <div className="pagination__actions">
                        <GoTOPage changePage={customerPageChange} />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  <TagsListPagination
                    pagination={pagination}
                    changePage={changePage}
                    refreshPage={refreshPage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {OpenAddTagsModal && (
        <AddNewTagsModal
          openModal={OpenAddTagsModal}
          closeModal={() => setOpenAddTagsModal(false)}
          refreshPage={refreshPage}
          tagData={selectedTagData}
          OpenCustomerTagsList={OpenCustomerTagsList}
          refreshCustomerTagsList={refreshCustomerTagsList}
        />
      )}
      {openRemoveConfModal && (
        <ConfirmationModal
          openModal={openRemoveConfModal}
          closeModal={() => setOpenRemoveConfModal(false)}
          onSubmit={removeTag}
          heading={`${selectedTagData?.activate ? 'Deactivate' : 'Activate'} Tag!`}
          confirmationMessage={`Are you sure want to ${selectedTagData?.activate ? 'deactivate' : 'activate'} tag?`}
        />
      )}
      {openSuccessfullModal && (
        <SuccessfullyModal
          openModal={openSuccessfullModal}
          closeModal={() => closeSuccessModal()}
          sucessfullMsg={successMessage}
          Heading={`${selectedTagData?.activate ? 'Deactivate' : 'Activate'} Tag!`}
        />
      )}
      {openErrorModal && (
        <ErrorModal
          openModal={openErrorModal}
          errorMessage={errorMessage}
          closeModal={() => setOpenErrorModal(false)}
        />
      )}
    </>
  );
};

export default TagsIndex;
