import React, { useState, useEffect } from 'react';
import CloseIconGray from '@/assets/svgs/CloseIconGray';
import ArrowDown from '@/elements/ArrowDown';
import { Formik, Form, Field } from 'formik';
import ColorPickersDropdown from '@/elements/colorPicker/colorPickersDropdown';
import { addTagSchema } from '@/components/schema/schema';
import { isButtonDisabled } from '@/helper';
import {
  addTagsDetails,
  updateTagsDetails
} from '@/services/settings-service/tags/tags-service';
import { SuccessfullyModal } from '@/components/modals/successfully-modal';
import ErrorModal from '@/components/modals/error-modal';
import { GetUserDetails } from '@/helper';

const AddNewTagsModal = ({
  openModal,
  closeModal,
  refreshPage,
  tagData,
  OpenCustomerTagsList,
  refreshCustomerTagsList
}) => {
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [openSuccessfullModal, setOpenSuccessfullModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [tagAddMessage, setTagAddMessage] = useState('');
  const [tagAddHeading, setTagAddHeading] = useState('');
  const user = GetUserDetails();
  const employeeId = user?.adminId;

  const initialValues = {
    tagName: tagData?.tagName ? tagData?.tagName : '',
    colorCode: tagData?.colorCode ? tagData?.colorCode : ''
  };

  const clickToOpenColorPicker = () => {
    setOpenColorPicker(!openColorPicker);
  };

  const handleFormSubmit = async (values, resetForm) => {
    let tagRes = '';
    if (tagData?.id) {
      setTagAddHeading('Tag Updated!');
      tagRes = await updateTagsDetails(
        tagData?.id,
        values?.tagName,
        values?.colorCode,
        'Admin',
        employeeId,
        OpenCustomerTagsList ? tagData?.company?.id : null
      );
    } else {
      setTagAddHeading('Tag Added!');
      tagRes = await addTagsDetails(
        values?.tagName,
        values?.colorCode,
        'Admin',
        employeeId
      );
    }

    if (tagRes?.meta?.status) {
      setOpenSuccessfullModal(true);
    } else {
      setOpenErrorModal(true);
    }
    setTagAddMessage(tagRes?.meta?.msg);
    resetForm();
  };

  const closeSuccessModal = () => {
    setOpenSuccessfullModal(false);
    closeModal();
    if (OpenCustomerTagsList) {
      refreshCustomerTagsList();
    } else {
      refreshPage();
    }
  };

  return (
    <>
      <div
        className={`modal modalAddNewTags ${openModal ? 'modal--open' : ''}`}
      >
        <div className="modal__dialog">
          <div className="modal__content modal__content--xxxl">
            <div className="modal__header">
              <h2 className="modal__header--title">
                {tagData?.id ? 'Update' : 'Add'} Tag
              </h2>
              <button
                onClick={closeModal}
                className="btn btn__transparent btnClose"
              >
                <CloseIconGray />
              </button>
            </div>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={addTagSchema}
              onSubmit={(values, { resetForm }) =>
                handleFormSubmit(values, resetForm)
              }
            >
              {({ errors, values, handleChange, setFieldValue, resetForm }) => (
                <Form className="modal__body modal-body-left">
                  <div className="formRow formRow__2 w_100">
                    <div className="formCol">
                      <div className="formField">
                        <label className="formField__label">Tag Name</label>
                        <div className="formField__group sm">
                          <Field
                            type="text"
                            className="formField__input sm"
                            placeholder="Tag Name"
                            name="tagName"
                            onChange={(e) => {
                              const tagValue = e?.target?.value?.replace(
                                /^\s+/g,
                                ''
                              );
                              if (tagValue?.length <= 30) {
                                setFieldValue('tagName', tagValue);
                              }
                            }}
                          />
                        </div>
                        {/* <span className="formField__error">
                      Customer Support name is required.
                    </span> */}
                      </div>
                    </div>
                    <div className="formCol">
                      <div className="formField">
                        <label className="formField__label">Tag Color</label>
                        <div className="formField__formFieldTags">
                          <div className="formField__group sm">
                            <span
                              className="colorFill"
                              style={{
                                backgroundColor: values.colorCode
                                  ? values.colorCode
                                  : '#28a0e8'
                              }}
                            ></span>
                            <div className="colorCodeValueRow">
                              {/* <span className="colorCodeValue"># 28A0E8</span> */}
                              <Field
                                type="text"
                                className="formField__input sm colorCodeValue"
                                placeholder="Color Code"
                                name="colorCode"
                              />
                              <div onClick={() => clickToOpenColorPicker()}>
                                <ArrowDown />
                              </div>
                            </div>
                          </div>
                          <ColorPickersDropdown
                            open={openColorPicker}
                            handleColorChange={(color) =>
                              setFieldValue('colorCode', color)
                            }
                            close={() => setOpenColorPicker(false)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn__group">
                    <button
                      className="btn btn__white"
                      onClick={() => closeModal()}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn__primary"
                      disabled={isButtonDisabled(errors, values, initialValues)}
                    >
                      {tagData?.id ? 'Update' : 'Add'} Tag
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
      {openSuccessfullModal && (
        <SuccessfullyModal
          openModal={openSuccessfullModal}
          closeModal={() => closeSuccessModal()}
          sucessfullMsg={tagAddMessage}
          Heading={tagAddHeading}
        />
      )}
      {openErrorModal && (
        <ErrorModal
          openModal={openErrorModal}
          errorMessage={tagAddMessage}
          closeModal={() => setOpenErrorModal(false)}
        />
      )}
    </>
  );
};

export default AddNewTagsModal;
