import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { getStaticData } from '@/services/static-data-service';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ArrowDown from '@/elements/ArrowDown';
import { addEducationalFormSchema } from '@/components/schema/schema';
import { INSTITUTION_LOGO } from '@/config';
import UniversitySvg from '@/assets/svgs/UniversitySvg';

const AddEducationalDetailModal = ({ openModal, closeModal, onSubmit }) => {
  const [staticDetails, setStaticDetails] = useState({});
  const [Years, setYears] = useState([]);
  const [Months, setMonths] = useState([]);
  const [staticStartYear, setstaticStartYear] = useState();

  let initialValues = {
    degree: '',
    institutionName: '',
    startMonth: '',
    startYear: '',
    endMonth: '',
    endYear: '',
    fieldOfStudy: ''
  };

  const getStaticDataLocal = async () => {
    try {
      const response = await getStaticData();
      const { data, meta } = response;
      if (meta?.status) {
        setStaticDetails(data);
        setMonths(data?.yearMonth?.month);
        setstaticStartYear(data?.yearMonth?.startYear);
      }
    } catch (error) {
      console.log('axios error --->', error);
    }
  };

  const getYears = () => {
    const startYear = 1930;
    const currentYear = new Date().getFullYear();
    const yearsArray = Array.from(
      { length: currentYear - startYear + 1 },
      (_, index) => (startYear + index).toString()
    );
    setYears(yearsArray);
  };

  const handleFormSubmit = async (values, resetForm) => {
    onSubmit(values);
    resetForm();
  };

  const handleCancel = (resetForm) => {
    resetForm();
    closeModal();
  };

  useEffect(() => {
    getStaticDataLocal();
    getYears();
  }, []);

  const getUnivercityLogo = (univercityName) => {
    let logo =
      staticDetails?.nameOfInstitutionObject?.length &&
      staticDetails?.nameOfInstitutionObject.find(
        (company) => company.value === univercityName
      )?.logoName;
    return logo;
  };
  return (
    <>
      <div className={`modal modalEducation ${openModal ? 'modal--open' : ''}`}>
        <div className="modal__dialog">
          <Formik
            initialValues={initialValues}
            validationSchema={addEducationalFormSchema}
            onSubmit={(values, { resetForm }) =>
              handleFormSubmit(values, resetForm)
            }
          >
            {({ errors, values, handleChange, setFieldValue, resetForm }) => (
              <Form className="form">
                <div className="modal__content modal__content--lg">
                  <div className="modal__header">
                    <h2 className="modal__header--title">
                      Add Educational Details
                    </h2>
                  </div>
                  <div className="modal__body">
                    <div className="fieldBox fieldBox--row">
                      {/* EDUCATION */}
                      <div className="flexCol">
                        <div
                          className={`formField ${
                            errors?.degree ? 'fieldError' : ''
                          }`}
                        >
                          <label className="formField__label">Education</label>
                          <div className="formField__autoComplete">
                            <Autocomplete
                              id="tags-filled"
                              options={staticDetails?.qualification || []}
                              value={values?.degree}
                              name="degree"
                              onChange={(event, newValue) => {
                                setFieldValue('degree', newValue);
                              }}
                              freeSolo
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  placeholder="Select"
                                  className="autoCompleteInput"
                                  onBlur={(e) => {
                                    if (params?.inputProps?.value) {
                                      setFieldValue(
                                        'degree',
                                        params.inputProps.value
                                      );
                                    }
                                  }}
                                />
                              )}
                            />
                            {/* <span className="formField__icon">
                            <EducationSvg />
                          </span> */}
                          </div>

                          <span className="formField__error">
                            {errors?.degree}
                          </span>
                        </div>
                      </div>
                      {/* INSTITUTION NAME */}
                      <div className="flexCol">
                        <div
                          className={`formField ${
                            errors?.institutionName ? 'fieldError' : ''
                          }`}
                        >
                          <label className="formField__label">
                            Name of Institution
                          </label>
                          <div className="formField__autoComplete formField__autoComplete--withLogo">
                            <Autocomplete
                              id="tags-filled"
                              options={
                                staticDetails?.nameOfInstitutionObject || []
                              }
                              getOptionLabel={(option) => option?.value || ''}
                              defaultValue={values?.institutionName || ''}
                              name="institutionName"
                              onChange={(event, newValue) => {
                                setFieldValue(
                                  'institutionName',
                                  newValue?.value
                                );
                                setFieldValue(
                                  'institutionNameLogo',
                                  newValue?.logoName
                                );
                                setFieldValue(
                                  'institutionName',
                                  newValue?.value
                                );
                              }}
                              freeSolo
                              renderOption={(props, option) => (
                                <li {...props}>
                                  {option.logoName ? (
                                    <img
                                      src={INSTITUTION_LOGO + option.logoName}
                                      alt={option.value}
                                      style={{
                                        width: 25,
                                        marginRight: 5
                                      }}
                                    />
                                  ) : (
                                    <div className="autocompletelogo">
                                      <UniversitySvg />
                                    </div>
                                  )}
                                  {option?.value || ''}{' '}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  placeholder="Select"
                                  className="autoCompleteInput"
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                      <>
                                        {params.InputProps.startAdornment}
                                        {params.inputProps.value &&
                                        getUnivercityLogo(
                                          params.inputProps.value
                                        ) ? (
                                          <img
                                            className="autocompletelogo"
                                            src={
                                              params.inputProps.value &&
                                              INSTITUTION_LOGO +
                                                getUnivercityLogo(
                                                  params.inputProps.value
                                                )
                                            }
                                            alt="Selected"
                                          />
                                        ) : (
                                          <div className="autocompletelogo">
                                            <UniversitySvg />
                                          </div>
                                        )}
                                      </>
                                    )
                                  }}
                                  onBlur={(e) => {
                                    if (params?.inputProps?.value) {
                                      setFieldValue(
                                        'institutionName',
                                        params.inputProps.value
                                      );
                                    }
                                  }}
                                />
                              )}
                            />
                          </div>
                          {/*
                          <span className="formField__icon">
                            <InstitutionSvg />
                          </span> */}
                          <span className="formField__error">
                            {errors?.institutionName}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="fieldBox fieldBox--row">
                      {/* START MONTH */}
                      <div className="flexCol">
                        <div
                          className={`formField ${
                            errors?.startMonth ? 'fieldError' : ''
                          }`}
                        >
                          <label className="formField__label">
                            Start Month
                          </label>
                          <FormControl
                            sx={{ width: '100%' }}
                            size="small"
                            className="formField__select"
                          >
                            <Select
                              IconComponent={() => <ArrowDown />}
                              name="startMonth"
                              displayEmpty
                              value={values?.startMonth}
                              onChange={handleChange}
                              renderValue={(selected) => {
                                if (!selected) {
                                  return (
                                    <span style={{ opacity: '0.8' }}>
                                      Select Month
                                    </span>
                                  );
                                }

                                return selected;
                              }}
                            >
                              {Months?.map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item}>
                                    {item}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          <span className="formField__error">
                            {errors?.startMonth}
                          </span>
                        </div>
                      </div>
                      {/* START YEAR */}
                      <div className="flexCol">
                        <div
                          className={`formField ${
                            errors?.startYear ? 'fieldError' : ''
                          }`}
                        >
                          <label className="formField__label">Start Year</label>
                          <FormControl
                            sx={{ width: '100%' }}
                            size="small"
                            className="formField__select"
                          >
                            <Select
                              IconComponent={() => <ArrowDown />}
                              name="startYear"
                              displayEmpty
                              value={values?.startYear}
                              onChange={handleChange}
                              renderValue={(selected) => {
                                if (!selected) {
                                  return (
                                    <span style={{ opacity: '0.8' }}>
                                      Select Year
                                    </span>
                                  );
                                }

                                return selected;
                              }}
                            >
                              {Years?.map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item}>
                                    {item}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          <span className="formField__error">
                            {errors?.startYear}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="fieldBox fieldBox--row">
                      {/* END MONTH */}
                      <div className="flexCol">
                        <div
                          className={`formField ${
                            errors?.endMonth ? 'fieldError' : ''
                          }`}
                        >
                          <label className="formField__label">End Month</label>
                          <FormControl
                            sx={{ width: '100%' }}
                            size="small"
                            className="formField__select"
                          >
                            <Select
                              IconComponent={() => <ArrowDown />}
                              name="endMonth"
                              displayEmpty
                              value={values?.endMonth}
                              onChange={handleChange}
                              renderValue={(selected) => {
                                if (!selected) {
                                  return (
                                    <span style={{ opacity: '0.8' }}>
                                      Select Month
                                    </span>
                                  );
                                }

                                return selected;
                              }}
                            >
                              {Months?.map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item}>
                                    {item}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          <span className="formField__error">
                            {errors?.endMonth}
                          </span>
                        </div>
                      </div>
                      {/* END YEAR */}
                      <div className="flexCol">
                        <div
                          className={`formField ${
                            errors?.endYear ? 'fieldError' : ''
                          }`}
                        >
                          <label className="formField__label">End Year</label>
                          <FormControl
                            sx={{ width: '100%' }}
                            size="small"
                            className="formField__select"
                          >
                            <Select
                              IconComponent={() => <ArrowDown />}
                              name="endYear"
                              displayEmpty
                              value={values?.endYear}
                              onChange={handleChange}
                              renderValue={(selected) => {
                                if (!selected) {
                                  return (
                                    <span style={{ opacity: '0.8' }}>
                                      Select Year
                                    </span>
                                  );
                                }

                                return selected;
                              }}
                            >
                              {Years?.map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item}>
                                    {item}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          <span className="formField__error">
                            {errors?.endYear}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* MAJOR FIELD OF STUDY */}
                    <div className="fieldBox w_100 m_0">
                      <div
                        className={`formField ${
                          errors?.fieldOfStudy ? 'fieldError' : ''
                        }`}
                      >
                        <label className="formField__label">
                          Major/Field of Study
                        </label>
                        <div className="formField__autoComplete">
                          <Autocomplete
                            id="tags-filled"
                            options={staticDetails?.majorFieldOfStudy || []}
                            value={values?.fieldOfStudy}
                            name="fieldOfStudy"
                            onChange={(event, newValue) => {
                              setFieldValue('fieldOfStudy', newValue);
                            }}
                            freeSolo
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="filled"
                                placeholder="Select"
                                className="autoCompleteInput"
                                onBlur={(e) => {
                                  if (params?.inputProps?.value) {
                                    setFieldValue(
                                      'fieldOfStudy',
                                      params.inputProps.value
                                    );
                                  }
                                }}
                              />
                            )}
                          />
                        </div>

                        {/* <span className="formField__icon">
                          <EducationSvg />
                        </span> */}
                        <span className="formField__error">
                          {errors?.fieldOfStudy}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="modal__footer">
                    <div className="btn__group">
                      <button className="btn btn__primary" type="submit">
                        Add
                      </button>
                      <button
                        className="btn btn__white"
                        type="button"
                        onClick={() => handleCancel(resetForm)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="modal__backdrop"></div>
      </div>
    </>
  );
};

export default AddEducationalDetailModal;
