import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import RecruiterListing from '@/pages/recruiters/recruiterListing';
import SideBar from '@/components/layout/SideBar';
import AddEditRecruiter from './AddEditRecruiter';
import { Menu, MenuItem } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import ConfirmationModal from '@/components/modals/confirmation-modal';
import { SuccessfullyModal } from '@/components/modals/successfully-modal';
import JobAssigned from '@/pages/recruiters/JobAssigned';
import RecruiterDetails from '@/pages/recruiters/RecruiterDetails';
import ArrowDownIcon from '@/assets/svgs/ArrowDownIcon';
import {
  changeRecruiterStatus,
  deleteRecruiter,
  getRecruiterList,
  activateRecruiter,
  checkForRecruiterDeactivation
} from '@/services/recruiters-service/recruiters-service';
import BackArrow from '@/assets/svgs/BackArrow';
import EditFormIcon from '@/assets/svgs//EditFormIcon';
import ActivateIcon from '@/assets/svgs/ActivateIcon';
import DeactivateIcon from '@/assets/svgs/DeactivateIcon';
import RejectIcon from '@/assets/svgs/RejectIcon';
import Approveicon from '@/assets/svgs/Approveicon';
import ScheduledInterviews from '@/pages/interviews/scheduled-interviews';
import PastInterviews from '@/pages/interviews/past-interviews';
import * as MESSAGES from '@/utils/popupMessages';
import InformationModal from '@/components/modals/InformationModal';
import ErrorModal from '@/components/modals/error-modal';
import { fetchRecruiterTaxInfo } from '@/services/recruiters-service/recruiters-service';
import { firstLetterCapital, decryptData } from '@/helper';

const Recruiters = () => {
  const [value, setValue] = useState('1');
  const [addRecruiterScreen, setAddRecruiterScreen] = useState(false);
  const [editRecruiterMode, setEditRecruiterMode] = useState(false);
  const [activeRecruiterId, setActiveRecruiterId] = useState('');
  const [activeRecruiterData, setActiveRecruiterData] = useState({});
  const [OpenConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [OpenSuccessfullModal, setOpenSuccessfullModal] = useState(false);
  const [RecruiterTotalLength, setRecruiterTotalLength] = useState();
  const [refreshRecruitersListing, setRefreshRecruitersListing] =
    useState(false);
  const [addResponsiveClass, setAddResponsiveClass] = useState(false);
  const [RefreshJobAssignedData, setRefreshJobAssignedData] = useState(false);
  const [interviewType, setInterviewType] = useState('schedule');
  const [OpenInfoModal, setOpenInfoModal] = useState(false);
  const [successModalHeading, setSuccessModalHeading] = useState('');
  const [successModalMessage, setSuccessModalMessage] = useState('');
  const [InfoMessage, setInfoMessage] = useState('');
  const [activateConModal, setActivateConModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMsgs, setErrorMsgs] = useState('');
  const [RecruiterSettingsData, setRecruiterSettingsData] = useState({});

  useEffect(() => {
    getFirstRecruiter();
  }, [refreshRecruitersListing]);

  useEffect(() => {
    if (activeRecruiterId) {
      fetchRecruitertaxInformation(activeRecruiterId);
    }
  }, [activeRecruiterId]);

  const rejectRectuiter = async () => {
    setRefreshRecruitersListing(false);
    await changeRecruiterStatus({
      status: 'rejected',
      recruiterId: activeRecruiterData?.recruiterId
    });
    setRefreshRecruitersListing(true);
    getFirstRecruiter();
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleMenuItemClick = (value) => {
    setValue(value);
    handleMenuClose();
  };

  const approveRectuiter = async () => {
    setRefreshRecruitersListing(false);
    await changeRecruiterStatus({
      status: 'approved',
      recruiterId: activeRecruiterData?.recruiterId
    });
    setRefreshRecruitersListing(true);
    let activeRecruiter = activeRecruiterData;
    activeRecruiter.status = 'approved';
    setActiveRecruiterData(activeRecruiter);
  };

  const openEditForm = () => {
    setEditRecruiterMode(true);
    setAddRecruiterScreen(true);
  };

  const getFirstRecruiter = async () => {
    const response = await getRecruiterList({
      page: '1',
      limit: '1',
      search: '',
      skillSet: []
    });
    const { data, meta } = response;
    if (meta?.status) {
      setActiveRecruiterId(data?.rows[0].recruiterId);
      setActiveRecruiterData(data?.rows[0]);
      setValue('1');
    }
  };

  const handleTabValueChange = (event, newValue) => {
    if (newValue !== '4') {
      setValue(newValue);
    }
  };

  const deleteRecruiterr = async () => {
    try {
      const response = await deleteRecruiter({
        recruiterId: activeRecruiterData.recruiterId,
        status: true
      });
      const { meta } = response;
      if (meta?.status) {
        setOpenSuccessfullModal(true);
        setRefreshRecruitersListing(!refreshRecruitersListing);
      } else {
        setErrorMsgs(meta?.msg);
        setOpenErrorModal(true);
      }
      setOpenConfirmationModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onRecruiterSelection = (
    recruiterId,
    data,
    RecruiterListLength,
    responsiveClass
  ) => {
    setRecruiterTotalLength(RecruiterListLength);
    setRefreshJobAssignedData(!RefreshJobAssignedData);
    responsiveClass
      ? setAddResponsiveClass(false)
      : setAddResponsiveClass(true);
    setAddRecruiterScreen(false);
    setActiveRecruiterId(recruiterId);
    setActiveRecruiterData(data);
  };

  const backToRecruiterListing = () => {
    setEditRecruiterMode(false);
    setAddRecruiterScreen(false);
    setRefreshRecruitersListing(!refreshRecruitersListing);
    getFirstRecruiter();
  };

  const changeInterviewType = (interviewType) => {
    setInterviewType(interviewType);
  };

  const checkForDeactivate = async (data) => {
    setSuccessModalHeading('');
    setSuccessModalMessage('');
    try {
      const response = await checkForRecruiterDeactivation(
        activeRecruiterData.recruiterId
      );
      const { meta } = response;
      if (meta?.status) {
        setOpenConfirmationModal(true);
      } else {
        setInfoMessage(meta?.msg);
        setOpenInfoModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmReactivateRecruiter = () => {
    setActivateConModal(true);
  };

  const reactivateRecruiter = async () => {
    setRefreshRecruitersListing(false);
    try {
      const response = await activateRecruiter({
        recruiterId: activeRecruiterData.recruiterId
      });
      const { meta } = response;
      if (meta?.status) {
        setSuccessModalHeading('Account Activated!');
        setSuccessModalMessage(meta?.msg);
        setOpenSuccessfullModal(true);
        setRefreshRecruitersListing(true);
      }
      setActivateConModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  // Tax Functions

  const fetchRecruitertaxInformation = async (recruiterId) => {
    try {
      const response = await fetchRecruiterTaxInfo(recruiterId);
      const { data, meta } = response;
      if (meta?.status) {
        setRecruiterSettingsData(data);
      }
    } catch (error) {
      console.log(error, '---->axios error');
    }
  };

  const maskSSNnumber = (ssn, type) => {
    if (type === 'SSN') {
      return `XXX-XX-${ssn.slice(-4)}`;
    } else if (type === 'EIN') {
      return `XX-XX${ssn.slice(-5)}`;
    } else if (type === 'TIN') {
      return `XXXXXX${ssn.slice(-4)}`;
    } else {
      return ssn;
    }
  };

  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid">
          <RecruiterListing
            addResponsiveClass={addResponsiveClass}
            setValue={setValue}
            setAddResponsiveClass={setAddResponsiveClass}
            activeRecruiterId={activeRecruiterId}
            onRecruiterSelection={(
              recruiterId,
              data,
              RecruiterListLength,
              responsiveClass
            ) =>
              onRecruiterSelection(
                recruiterId,
                data,
                RecruiterListLength,
                responsiveClass
              )
            }
            setAddRecruiterScreen={setAddRecruiterScreen}
            editRecruiterMode={setEditRecruiterMode}
            editMode={editRecruiterMode}
            ActiveRecruiterData={activeRecruiterData}
            refreshRecruitersListing={refreshRecruitersListing}
          />
          {addRecruiterScreen ? (
            <AddEditRecruiter
              addResponsiveClass={addResponsiveClass}
              setAddResponsiveClass={setAddResponsiveClass}
              backToRecruiterListing={backToRecruiterListing}
              editRecruiterMode={editRecruiterMode}
              activeRecruiterData={activeRecruiterData}
            />
          ) : (
            <div
              className={`layoutGrid__col layoutGrid--responsive  ${
                addResponsiveClass ? 'layoutGrid--show' : ''
              }`}
            >
              {RecruiterTotalLength ? (
                <div className="dataCol">
                  <div className="page page__full">
                    <div className="page__head">
                      <button
                        className="btn btn__transparent btn__back border_0 p_0"
                        onClick={() => setAddResponsiveClass(false)}
                      >
                        <BackArrow />
                      </button>
                      {activeRecruiterData?.firstName &&
                        activeRecruiterData?.lastName && (
                          <h2 className="page__head--title">
                            {`${`${activeRecruiterData?.firstName[0].toUpperCase()}${activeRecruiterData?.firstName.slice(
                              1
                            )}`} ${`${activeRecruiterData?.lastName[0].toUpperCase()}${activeRecruiterData?.lastName.slice(
                              1
                            )}`}`}
                          </h2>
                        )}
                      <div className="listCol__head--actions ml_auto">
                        {activeRecruiterData?.status === 'approved' ? (
                          <>
                            <Tooltip title="Edit" placement="bottom">
                              <span>
                                <button
                                  onClick={openEditForm}
                                  className="btn btn__white btn__edit p_0"
                                  disabled={activeRecruiterData?.deleteStatus}
                                >
                                  <EditFormIcon />
                                </button>
                              </span>
                            </Tooltip>
                            {!activeRecruiterData?.deleteStatus ? (
                              <Tooltip title="Disable User" placement="bottom">
                                <button
                                  onClick={() =>
                                    checkForDeactivate(
                                      activeRecruiterData?.jobs
                                    )
                                  }
                                  className="btn btn__danger p_0"
                                >
                                  <DeactivateIcon />
                                </button>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Enable User" placement="bottom">
                                <button
                                  onClick={() => confirmReactivateRecruiter()}
                                  className="btn btn__successDark p_0"
                                >
                                  <ActivateIcon />
                                </button>
                              </Tooltip>
                            )}
                            <Tooltip title="Reject" placement="bottom">
                              <span>
                                <button
                                  onClick={rejectRectuiter}
                                  disabled={activeRecruiterData?.deleteStatus}
                                  className="btn btn__danger btn__filled w_fitContent"
                                >
                                  <RejectIcon />
                                  Reject
                                </button>
                              </span>
                            </Tooltip>
                          </>
                        ) : activeRecruiterData?.status === 'pending' ? (
                          <>
                            <Tooltip title="Reject" placement="bottom">
                              <button
                                onClick={rejectRectuiter}
                                className="btn btn__danger btn__filled w_fitContent"
                              >
                                <RejectIcon />
                                Reject
                              </button>
                            </Tooltip>
                            <Tooltip title="Approve" placement="bottom">
                              <button
                                onClick={approveRectuiter}
                                className="btn btn__success btn__filled w_fitContent"
                              >
                                <Approveicon />
                                Approve
                              </button>
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            {activeRecruiterData?.deleteStatus ? (
                              <Tooltip title="Activate" placement="bottom">
                                <button
                                  onClick={() => confirmReactivateRecruiter()}
                                  className="btn btn__successDark p_0"
                                >
                                  <ActivateIcon />
                                </button>
                              </Tooltip>
                            ) : (
                              ''
                            )}
                            <Tooltip title="Approve" placement="bottom">
                              <span>
                                <button
                                  disabled={activeRecruiterData?.deleteStatus}
                                  onClick={approveRectuiter}
                                  className="btn btn__success btn__filled w_fitContent"
                                >
                                  <Approveicon />
                                  Approve
                                </button>
                              </span>
                            </Tooltip>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="page__body">
                      <Box className="tabContainer">
                        <TabContext value={value}>
                          <Box className="tabContainer__head tabContainer__head--fixed m_0">
                            <TabList onChange={handleTabValueChange}>
                              <Tab label="Recruiter’s Details" value="1" />
                              {activeRecruiterData?.status === 'approved' && (
                                <Tab label="Job Assigned" value="2" />
                              )}
                              <Tab label="Interviews" value="3" />
                              <Tab
                                label={
                                  <div className="tabArrowDown">
                                    Settings
                                    <ArrowDownIcon />
                                  </div>
                                }
                                value="4"
                                onClick={(event) => {
                                  handleMenuOpen(event);
                                }}
                              />

                              <Menu
                                id="menu-dropdown"
                                anchorEl={anchorEl}
                                open={menuOpen}
                                onClose={handleMenuClose}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left'
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left'
                                }}
                              >
                                <MenuItem
                                  onClick={() => handleMenuItemClick('4')}
                                >
                                  Tax Info
                                </MenuItem>
                              </Menu>
                            </TabList>
                          </Box>

                          <TabPanel value="1" className="tabContainer__item">
                            <RecruiterDetails
                              activeRecruiterData={activeRecruiterData}
                              refreshDetailPage={backToRecruiterListing}
                            />
                          </TabPanel>

                          {activeRecruiterData?.status === 'approved' && (
                            <TabPanel value="2" className="tabContainer__item">
                              <JobAssigned
                                RefreshJobAssignedData={RefreshJobAssignedData}
                                recruiterId={activeRecruiterData?.recruiterId}
                              />
                            </TabPanel>
                          )}

                          <TabPanel value="3" className="tabContainer__item">
                            {interviewType === 'schedule' ? (
                              <ScheduledInterviews
                                activeRecruiterId={
                                  activeRecruiterData?.recruiterId
                                }
                                activeRecruiterStatus={
                                  activeRecruiterData?.deleteStatus
                                }
                                changeInterviewType={changeInterviewType}
                                activeRecruiterEmail={
                                  activeRecruiterData?.email
                                }
                              />
                            ) : (
                              <PastInterviews
                                activeRecruiterId={
                                  activeRecruiterData?.recruiterId
                                }
                                changeInterviewType={changeInterviewType}
                              />
                            )}
                          </TabPanel>

                          <TabPanel value="4" className="tabContainer__item">
                            <div className="details details--taxDetails">
                              <section className="details__section">
                                <h4 className="details__section--title">
                                  <span className="titleWithMessage">
                                    Tax Residence
                                    <span
                                      className={`${
                                        Object.keys(
                                          RecruiterSettingsData?.taxResidence ??
                                            {}
                                        ).length > 0
                                          ? 'successMessage'
                                          : 'errorMessage'
                                      }`}
                                    >
                                      {Object.keys(
                                        RecruiterSettingsData?.taxResidence ??
                                          {}
                                      ).length > 0
                                        ? 'Completed'
                                        : 'Not Completed'}
                                    </span>
                                  </span>
                                </h4>
                                {Object.keys(
                                  RecruiterSettingsData?.taxResidence ?? {}
                                ).length > 0 && (
                                  <>
                                    <div className="typerinfolist">
                                      <div className="typerinfolist__item">
                                        <h5>Address</h5>
                                        <p>
                                          {
                                            RecruiterSettingsData?.taxResidence
                                              ?.addressLine1
                                          }
                                          <br></br>{' '}
                                          {
                                            RecruiterSettingsData?.taxResidence
                                              ?.city
                                          }
                                          ,
                                          {
                                            RecruiterSettingsData?.taxResidence
                                              ?.stateName
                                          }
                                          <br></br>{' '}
                                          {
                                            RecruiterSettingsData?.taxResidence
                                              ?.zipCode
                                          }
                                          <br></br>
                                          {
                                            RecruiterSettingsData?.taxResidence
                                              ?.countryName
                                          }
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </section>
                              <section className="details__section">
                                <h4 className="details__section--title">
                                  <span className="titleWithMessage">
                                    Taxpayer Identification
                                    <span
                                      className={`${
                                        Object.keys(
                                          RecruiterSettingsData?.taxPayerIdentification ??
                                            {}
                                        ).length > 0
                                          ? 'successMessage'
                                          : 'errorMessage'
                                      }`}
                                    >
                                      {Object.keys(
                                        RecruiterSettingsData?.taxPayerIdentification ??
                                          {}
                                      ).length > 0
                                        ? 'Completed'
                                        : 'Not Completed'}
                                    </span>
                                  </span>
                                </h4>
                                {Object.keys(
                                  RecruiterSettingsData?.taxPayerIdentification ??
                                    {}
                                ).length > 0 && (
                                  <>
                                    <div className="typerinfolist">
                                      <div className="typerinfolist__item">
                                        <h5>Name of entity/individual</h5>
                                        <p>
                                          {RecruiterSettingsData
                                            ?.taxPayerIdentification
                                            ?.taxPayerName
                                            ? firstLetterCapital(
                                                RecruiterSettingsData
                                                  ?.taxPayerIdentification
                                                  ?.taxPayerName
                                              )
                                            : '--'}
                                        </p>
                                      </div>
                                      <div className="typerinfolist__item">
                                        <h5>Business Name</h5>
                                        <p>
                                          {
                                            RecruiterSettingsData
                                              ?.taxPayerIdentification
                                              ?.businessName
                                          }
                                        </p>
                                      </div>
                                      <div className="typerinfolist__item">
                                        <h5>Federal tax classification</h5>
                                        <p>
                                          {RecruiterSettingsData
                                            ?.taxPayerIdentification
                                            ?.taxClassification
                                            ? RecruiterSettingsData
                                                ?.taxPayerIdentification
                                                ?.taxClassification?.value
                                            : '--'}
                                        </p>
                                      </div>
                                      {RecruiterSettingsData?.usCitizen ===
                                        true && (
                                        <div className="typerinfolist__item">
                                          <h5>SSN/EIN #</h5>
                                          <div className="typerinfolist__item__passwordText">
                                            <p>
                                              {RecruiterSettingsData
                                                ?.taxPayerIdentification
                                                ?.idenficationNumber
                                                ? maskSSNnumber(
                                                    decryptData(
                                                      RecruiterSettingsData
                                                        ?.taxPayerIdentification
                                                        ?.idenficationNumber
                                                    ),
                                                    RecruiterSettingsData
                                                      ?.taxPayerIdentification
                                                      ?.idenficationNumberType
                                                  )
                                                : '--'}
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                      {RecruiterSettingsData?.usCitizen ===
                                        false && (
                                        <>
                                          {RecruiterSettingsData
                                            ?.taxPayerIdentification
                                            ?.taxClassification?.value ===
                                            'Individual' && (
                                            <div className="typerinfolist__item">
                                              <h5>Country of citizenship</h5>
                                              <p>
                                                {RecruiterSettingsData
                                                  ?.taxPayerIdentification
                                                  ?.citizenShipCountry
                                                  ? firstLetterCapital(
                                                      RecruiterSettingsData
                                                        ?.taxPayerIdentification
                                                        ?.citizenShipCountry
                                                        ?.name
                                                    )
                                                  : '--'}
                                              </p>
                                            </div>
                                          )}
                                          {(RecruiterSettingsData
                                            ?.taxPayerIdentification
                                            ?.taxClassification?.value ===
                                            'Other' ||
                                            RecruiterSettingsData
                                              ?.taxPayerIdentification
                                              ?.taxClassification?.value ===
                                              'Corporation') && (
                                            <div className="typerinfolist__item">
                                              <h5>Country of incorporation</h5>
                                              <p>
                                                {RecruiterSettingsData
                                                  ?.taxPayerIdentification
                                                  ?.citizenShipCountry
                                                  ? firstLetterCapital(
                                                      RecruiterSettingsData
                                                        ?.taxPayerIdentification
                                                        ?.citizenShipCountry
                                                        ?.name
                                                    )
                                                  : '--'}
                                              </p>
                                            </div>
                                          )}
                                          {RecruiterSettingsData
                                            ?.taxPayerIdentification
                                            ?.taxClassification?.value ===
                                            'Individual' && (
                                            <div className="typerinfolist__item">
                                              <h5>Date of Birth</h5>
                                              <p>
                                                {RecruiterSettingsData
                                                  ?.taxPayerIdentification?.dob
                                                  ? RecruiterSettingsData
                                                      ?.taxPayerIdentification
                                                      ?.dob
                                                  : '--'}
                                              </p>
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </>
                                )}
                              </section>
                              {Object.keys(
                                RecruiterSettingsData?.taxPayerIdentification ??
                                  {}
                              ).length > 0 &&
                                RecruiterSettingsData?.usCitizen === false && (
                                  <section className="details__section">
                                    <h4 className="details__section--title">
                                      <span className="titleWithMessage">
                                        Taxpayer Identification Number
                                        <span
                                          className={`${
                                            Object.keys(
                                              RecruiterSettingsData?.taxPayerIdentification ??
                                                {}
                                            ).includes('tinNumber')
                                              ? 'successMessage'
                                              : 'errorMessage'
                                          }`}
                                        >
                                          {Object.keys(
                                            RecruiterSettingsData?.taxPayerIdentification ??
                                              {}
                                          ).includes('tinNumber')
                                            ? 'Completed'
                                            : 'Not Completed'}
                                        </span>
                                      </span>
                                    </h4>
                                    {Object.keys(
                                      RecruiterSettingsData?.taxPayerIdentification ??
                                        {}
                                    ).includes('tinNumber') && (
                                      <div className="typerinfolist">
                                        <div className="typerinfolist__item">
                                          <h5>Tax identification number</h5>
                                          <div className="typerinfolist__item__passwordText">
                                            <p>
                                              {RecruiterSettingsData
                                                ?.taxPayerIdentification
                                                ?.tinNumber
                                                ? maskSSNnumber(
                                                    decryptData(
                                                      RecruiterSettingsData
                                                        ?.taxPayerIdentification
                                                        ?.tinNumber
                                                    ),
                                                    'TIN'
                                                  )
                                                : '--'}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </section>
                                )}
                            </div>
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="no-data">No Results Found!</div>
              )}
            </div>
          )}
        </div>
        <div className="loader">
          <div className="loader__container"></div>
          <div className="loader__backdrop"></div>
        </div>
      </div>
      <ConfirmationModal
        openModal={OpenConfirmationModal}
        closeModal={() => setOpenConfirmationModal(false)}
        onSubmit={deleteRecruiterr}
        confirmationMessage={
          MESSAGES?.deleteRecruiter?.message?.confirmationMsg
        }
        heading={MESSAGES?.deleteRecruiter?.message?.confirmationHeading}
      />
      <SuccessfullyModal
        openModal={OpenSuccessfullModal}
        closeModal={() => setOpenSuccessfullModal(false)}
        Heading={
          successModalHeading
            ? successModalHeading
            : MESSAGES?.deleteRecruiter?.message?.heading
        }
        sucessfullMsg={
          successModalMessage
            ? successModalMessage
            : MESSAGES?.deleteRecruiter?.message?.successfull
        }
      />
      {OpenInfoModal && (
        <InformationModal
          closeModal={() => setOpenInfoModal(false)}
          info={InfoMessage}
          heading={'Deactivate Recruiter!'}
        />
      )}
      {activateConModal && (
        <ConfirmationModal
          openModal={activateConModal}
          closeModal={() => setActivateConModal(false)}
          onSubmit={reactivateRecruiter}
          confirmationMessage={
            MESSAGES?.activateRecruiter?.message?.confirmationMsg
          }
          heading={MESSAGES?.activateRecruiter?.message?.heading}
        />
      )}
      {openErrorModal && (
        <ErrorModal
          openModal={openErrorModal}
          errorMessage={errorMsgs}
          closeModal={() => setOpenErrorModal(false)}
        />
      )}
    </>
  );
};

export default Recruiters;
