import React, { useState, useEffect, useContext } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import httpService from '@/services/http-service';
import Popover from '@mui/material/Popover';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { SuccessfullyModal } from '@/components/modals/successfully-modal';
import ErrorModal from '@/components/modals/error-modal';
import { formatPhoneNumber, firstLetterCapital } from '@/helper';
import CustomPagination from '@/elements/pagination/customPagination';
import GoTOPage from '@/elements/gotopage/gotopage';
import { LoaderContext } from '@/components/Loader/Loader';
import SortColumn from '@/elements/SortColumn';
import { getStaticData } from '@/services/static-data-service';
import {
  getJobDetails,
  getRecruiterDetails
} from '@/services/job-service/job-service';

const JobRecruiter = ({ jobId, jobTitle, getJobData, selectedJobData }) => {
  const setLoading = useContext(LoaderContext);
  const [filteranchorEl, setFilterAnchorEl] = useState(null);
  const [sortByASC, setSortByASC] = useState(true);
  const [sortByColoumn, setSortByColoumn] = useState('');
  const [recruiterList, setRecruiterList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [skillSets, setSkillSets] = useState([]);
  const [filterSeacrhText, setFilterSeacrhText] = useState('');
  const [recruiterFilterList, setRecruiterFilterList] = useState([]);
  const [recruiterFilterSelection, setRecruiterFilterSelection] = useState([]);
  const [openAssignRecruiter, setOpenAssignRecruiter] = useState(false);
  const [selectedRecruiterData, setSelectedRecruiterData] = useState('');
  const [openSuccessfullModal, setOpenSuccessfullModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [recruiterData, setRecruiterData] = useState('');
  const [recruiterAction, setRecruiterAction] = useState('assigned');
  const [errorMsg, setErrorMsg] = useState('');
  const filterOpen = Boolean(filteranchorEl);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0,
    search: '',
    skillSets: []
  });
  const filterPopoverid = filterOpen ? 'simple-popover' : undefined;

  useEffect(() => {
    getJobsDetails();
  }, [jobId]);

  const getJobsDetails = async () => {
    const response = await getJobDetails(jobId);
    const { data, meta } = response;
    if (meta?.status) {
      if (data?.assignedStatus) {
        const recruiterId = data?.assignedId;
        const responseR = await getRecruiterDetails(recruiterId);
        const dataR = responseR?.data;
        if (responseR?.meta?.status) {
          setRecruiterData(dataR);
        }
      } else {
        setRecruiterData('');
        getRecruiterList();
        getSkillSet();
      }
    }
  };

  const getSkillSet = async () => {
    try {
      const response = await getStaticData();
      const { data, meta } = response;
      if (meta?.status) {
        setSkillSets(data?.skillSet);
        setRecruiterFilterList(data?.skillSet);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRecruiterList = async (
    recordsPerPage = pagination?.recordsPerPage,
    currentPage = pagination?.currentPage,
    search = pagination?.search,
    skillSets = pagination?.skillSets,
    columnName = sortByColoumn,
    sort = sortByASC ? 'ASC' : 'DESC'
  ) => {
    try {
      setLoading(true);
      const response = await httpService.post('/laila/recruiter/list', {
        limit: recordsPerPage,
        page: currentPage,
        search: search,
        skillSet: skillSets,
        status: 'approved',
        from: 'assignedRecruiter',
        columnName: columnName,
        sort: sort
      });
      const { data, meta } = response;
      if (meta?.status) {
        setPagination((prev) => ({
          ...prev,
          totalRecord: data?.count ? data?.count : 0
        }));
        setRecruiterList(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const searchRecruterList = (e) => {
    setSearchText(e?.target?.value);
    getRecruiterList(
      pagination?.recordsPerPage,
      pagination?.currentPage,
      e?.target?.value,
      pagination?.skillSets
    );
  };

  const searchInFilterList = (searchText = '') => {
    setFilterSeacrhText(searchText);
    if (searchText) {
      let rgxp = new RegExp(searchText.toLowerCase(), 'g');

      function findFilterNames() {
        return skillSets.filter((x) => x.toLowerCase().match(rgxp));
      }
      setRecruiterFilterList(findFilterNames());
    } else {
      setRecruiterFilterList(skillSets);
    }
  };

  const filterInRecruiterListing = (e) => {
    let newArray = recruiterFilterSelection;
    if (newArray.includes(e?.target?.value)) {
      const arrIndex = newArray.indexOf(e?.target?.value);
      if (arrIndex > -1) {
        newArray.splice(arrIndex, 1);
      }
    } else {
      newArray.push(e?.target?.value);
    }
  };

  const openAssignRecruiterModal = (recruiterId = '', action) => {
    setRecruiterAction(action);
    setOpenAssignRecruiter(true);
    setSelectedRecruiterData({ recruiterId });
  };

  const assignRecruiter = async () => {
    let url =
      recruiterAction == 'assigned'
        ? '/laila/job/assigned'
        : '/laila/job/unassigned';
    let obj =
      recruiterAction == 'assigned'
        ? { jobId: jobId, assignedId: selectedRecruiterData?.recruiterId }
        : { jobId: jobId };
    try {
      const response = await httpService.put(url, obj);
      const { data, meta } = response;
      if (meta.status) {
        setOpenAssignRecruiter(false);
        setOpenSuccessfullModal(true);
        setTimeout(() => {
          setRecruiterData('');
          getJobsDetails();
          getJobData();
        }, 500);
      } else {
        setOpenAssignRecruiter(false);
        setOpenErrorModal(true);
        setErrorMsg(meta?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changePage = (event, value) => {
    setPagination({ ...pagination, currentPage: value });
    getRecruiterList(
      pagination?.recordsPerPage,
      value,
      pagination?.search,
      pagination?.skillSets
    );
  };

  const getAcceptedJobsCount = (jobs) => {
    let count = jobs?.filter((x) => x?.recruiterStatus === 'accepted');
    return count?.length;
  };

  const getSortByData = (columnName) => {
    getRecruiterList(
      pagination?.recordsPerPage,
      pagination?.currentPage,
      pagination?.search,
      pagination?.skillSets,
      columnName,
      sortByASC ? 'ASC' : 'DESC'
    );
    setSortByColoumn(columnName);
    setSortByASC(!sortByASC);
  };

  return (
    <>
      {recruiterData !== '' ? (
        <>
          <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%' }}>
              <div className="page">
                <div className="page__title">Assigned Recruiter Details</div>
                <div className="fieldBox flexRow">
                  <div className="flexCol">
                    <label className="flexCol__label">Name</label>
                    <div className="flexCol__text">
                      {firstLetterCapital(recruiterData?.firstName) +
                        ' ' +
                        firstLetterCapital(recruiterData?.lastName)}
                    </div>
                  </div>
                  <div className="flexCol">
                    <label className="flexCol__label">Phone Number</label>
                    <div className="flexCol__text">
                      {recruiterData?.phoneNumber ? '+1 ' : ''}
                      {recruiterData?.phoneNumber
                        ? formatPhoneNumber(recruiterData?.phoneNumber)
                        : '--'}
                    </div>
                  </div>
                </div>
                <div className="fieldBox flexRow">
                  <div className="flexCol">
                    <label className="flexCol__label">Email Address</label>
                    <div className="flexCol__text">{recruiterData?.email}</div>
                  </div>
                  <div className="flexCol">
                    <label className="flexCol__label">Assigned jobs</label>
                    <div className="flexCol__text">
                      {recruiterData?.assignedJob
                        ? recruiterData?.assignedJob
                        : '--'}
                    </div>
                  </div>
                </div>
                <div className="fieldBox flexRow">
                  <div className="flexCol">
                    <label className="flexCol__label">Experience</label>
                    <div className="flexCol__text">
                      {recruiterData?.experience}
                    </div>
                  </div>
                  <div className="flexCol">
                    <label className="flexCol__label">Accepted Jobs</label>
                    <div className="flexCol__text">
                      {recruiterData?.acceptedJob || '--'}
                    </div>
                  </div>
                </div>
                <div className="fieldBox">
                  <label className="flexCol__label">Skill set</label>
                  <div className="flexCol__text">
                    {recruiterData?.skillSet?.length
                      ? recruiterData?.skillSet.join(', ')
                      : ''}
                  </div>
                </div>

                <div className="fieldBox">
                  <button
                    className={`btn btn__danger btn__filled ${
                      selectedJobData?.status === 'Closed' ||
                      selectedJobData?.status === 'paused' ||
                      selectedJobData?.status === 'delete'
                        ? 'disabled'
                        : ''
                    }`}
                    onClick={() => {
                      openAssignRecruiterModal('', 'unassigned');
                    }}
                    disabled={
                      selectedJobData?.status === 'Closed' ||
                      selectedJobData?.status === 'paused' ||
                      selectedJobData?.status === 'delete'
                        ? true
                        : false
                    }
                  >
                    Unassign Recruiter
                  </button>
                </div>
              </div>
            </Paper>
          </Box>
        </>
      ) : (
        <>
          <div className="tabContainer__item--head">
            <div className="listCol__head--actions">
              <div className="formField formFieldSearch">
                <div className="formField__group sm">
                  <span className="formField__icon">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                        stroke="#6C737F"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="formField__input"
                    placeholder="Search a recruiter"
                    value={searchText}
                    onChange={(e) => searchRecruterList(e)}
                  />
                </div>
              </div>
              <div className="customMultiSelect">
                <input
                  type="text"
                  className="formField__input sm"
                  aria-describedby={filterPopoverid}
                  value={recruiterFilterSelection}
                  onClick={handleFilterClick}
                  placeholder="Select Skill set"
                  readOnly
                />
                <Popover
                  id={filterPopoverid}
                  open={filterOpen}
                  anchorEl={filteranchorEl}
                  onClose={handleFilterClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  classes={{ root: 'filterPopover multiSelectPopover' }}
                  onChange={(e) => filterInRecruiterListing(e)}
                >
                  <div className="formField">
                    <div className="formField__group sm">
                      <input
                        type="text"
                        className="formField__input"
                        placeholder="Search Skill set"
                        onChange={(e) => searchInFilterList(e?.target?.value)}
                        value={filterSeacrhText}
                      />
                    </div>
                  </div>
                  <ul className="menuPopover__list">
                    {recruiterFilterList.map((row, index) => (
                      <li
                        key={`jobRecruiterList${index}`}
                        className="menuPopover__list--item"
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={row}
                              defaultChecked={recruiterFilterSelection.includes(
                                row
                              )}
                            />
                          }
                          label={row}
                        />
                      </li>
                    ))}
                  </ul>
                  <div className="formField__select--actions">
                    <button
                      className="btn btn__transparent"
                      onClick={() => {
                        setRecruiterFilterList(skillSets);
                        setFilterSeacrhText('');
                        handleFilterClose();
                        setRecruiterFilterSelection([]);
                        getRecruiterList(
                          pagination?.recordsPerPage,
                          pagination?.currentPage,
                          pagination?.search,
                          []
                        );
                      }}
                    >
                      Clear
                    </button>
                    <button
                      className="btn btn__primary"
                      onClick={(e) => {
                        getRecruiterList(
                          pagination?.recordsPerPage,
                          pagination?.currentPage,
                          pagination?.search,
                          recruiterFilterSelection
                        );
                        handleFilterClose();
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </Popover>
              </div>
            </div>
          </div>
          <TableContainer component={Paper} className="tableContainer">
            <Table className="table">
              <TableHead>
                <TableRow>
                  <TableCell>S.</TableCell>
                  <TableCell onClick={() => getSortByData('firstName')}>
                    <SortColumn thName={'Name'} sortByASC={sortByASC} />
                  </TableCell>
                  <TableCell>Experience</TableCell>
                  <TableCell>Email Address</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Accepted Jobs</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recruiterList.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {pagination?.currentPage === 1
                        ? index + 1
                        : (pagination?.currentPage - 1) *
                            pagination?.recordsPerPage +
                          (index + 1)}
                    </TableCell>
                    <TableCell>
                      <div className="profile">
                        <div className="profile__initials">
                          {row?.firstName.charAt(0).toUpperCase()}
                        </div>
                        <span className="profile__name">
                          {firstLetterCapital(row?.firstName) +
                            ' ' +
                            firstLetterCapital(row?.lastName)}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="cellText">
                        <span className="cellText__dark">
                          {row?.experience}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="cellText">
                        <span className="cellText__dark">{row?.email}</span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="cellText">
                        <span className="cellText__dark">
                          {row?.phoneNumber
                            ? row?.countryCode +
                              ' ' +
                              formatPhoneNumber(row?.phoneNumber)
                            : '--'}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="cellText">
                        <span className="cellText__dark">
                          {getAcceptedJobsCount(row?.jobs)}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="actions">
                        <button
                          // className="btn btn__white"
                          onClick={() =>
                            openAssignRecruiterModal(
                              row?.recruiterId,
                              'assigned'
                            )
                          }
                          className={`btn btn__white ${
                            selectedJobData?.status === 'Closed' ||
                            selectedJobData?.status === 'paused' ||
                            selectedJobData?.status === 'delete'
                              ? 'disabled'
                              : ''
                          }`}
                          disabled={
                            selectedJobData?.status === 'Closed' ||
                            selectedJobData?.status === 'paused' ||
                            selectedJobData?.status === 'delete'
                              ? true
                              : false
                          }
                        >
                          Assign
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {recruiterList.length == 0 ? (
              <div className="no-data">No Results Found!</div>
            ) : (
              ''
            )}
          </TableContainer>

          <div className="pagination">
            <div className="pagination__result">{`${
              pagination?.totalRecord >
              pagination?.currentPage * pagination?.recordsPerPage
                ? pagination?.recordsPerPage
                : pagination?.totalRecord
            } of ${pagination?.totalRecord} results`}</div>
            {pagination?.totalRecord ? (
              <CustomPagination
                totalPageCount={Math.ceil(
                  pagination?.totalRecord / pagination.recordsPerPage
                )}
                changePage={(event, value) => changePage(event, value)}
              />
            ) : (
              ''
            )}
            {pagination?.totalRecord ? (
              <div className="pagination__actions">
                <GoTOPage changePage={changePage} />
              </div>
            ) : (
              ''
            )}
          </div>
        </>
      )}
      <div
        className={`modal modalPrompt ${
          openAssignRecruiter ? 'modal--open' : ''
        }`}
      >
        <div className="modal__dialog">
          <div className="modal__content">
            <div className="modal__header">
              <h2 className="modal__header--title">
                {recruiterAction === 'assigned' ? 'Assign' : 'Unassign'}{' '}
                Recruiter!
              </h2>
            </div>
            <div className="modal__body">
              <h4 className="promptMessage">
                Are you sure you want to{' '}
                {recruiterAction === 'assigned' ? 'assign' : 'unassign'} this
                recruiter to the {jobTitle} position?
              </h4>
            </div>
            <div className="modal__footer">
              <div className="btn__group">
                <button
                  className="btn btn__white"
                  onClick={() => setOpenAssignRecruiter(false)}
                >
                  No
                </button>
                <button
                  className="btn btn__primary"
                  onClick={() => {
                    assignRecruiter();
                    getJobData();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
      <SuccessfullyModal
        openModal={openSuccessfullModal}
        closeModal={() => setOpenSuccessfullModal(false)}
        Heading={
          recruiterAction === 'assigned'
            ? 'Recruiter Assigned!'
            : 'Recruiter Unassigned!'
        }
        sucessfullMsg={`Recruiter has been ${recruiterAction} to the ${jobTitle} position.`}
      />
      <ErrorModal
        openModal={openErrorModal}
        errorMessage={errorMsg}
        closeModal={() => setOpenErrorModal(false)}
      />
    </>
  );
};

export default JobRecruiter;
