import React, { useState } from 'react';
import { addBillingAmount } from '@/services/billing-service/billing-service';
import { PreviewInvoiceModal } from '@/components/modals/previewInvoice-modal';
import {
  formatAmountInUSFormat,
  ConvertAmountToUSCurrencyFormat
} from '@/helper';
export const BillingAmountModal = ({
  openModal,
  handleCloseAmountModal,
  ScreeningId,
  SelectedJobId,
  getCandidateListing,
  setBillingAmount,
  BillingAmount
}) => {
  const [OpenInvoiceModal, setOpenInvoiceModal] = useState(false);
  const [InvoiceUrl, setInvoiceUrl] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setBillingAmount('');
    handleCloseAmountModal();
  };

  const handleChange = (e) => {
    const value = formatAmountInUSFormat(e.target.value);
    // const sanitizedValue = e.target.value.replace(/[^0-9.]/g, '');
    // const dotCount = sanitizedValue.split('.').length - 1;
    // const finalValue = dotCount > 1 ? BillingAmount : sanitizedValue;
    setBillingAmount(value);
  };

  const handleAddBillingAmount = async () => {
    let screeningId = ScreeningId;
    let amount = Number(BillingAmount);
    try {
      if (amount) {
        setErrors((prevErrors) => {
          delete prevErrors['amountErrMsg'];
          return { ...prevErrors };
        });
        setLoading(true);
        const response = await addBillingAmount(screeningId, amount);
        const { meta, data } = response;
        if (meta?.status) {
          handleCloseAmountModal(response?.data?.invoiceUrl);
          setOpenInvoiceModal(true);
          setInvoiceUrl(response?.data?.invoiceUrl);
        }
      } else {
        setErrors({ amountErrMsg: 'Amount is required' });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <>
      <div
        className={`modal modalPrompt modalForm ${
          openModal ? 'modal--open' : ''
        }`}
      >
        <div className="modal__dialog sm">
          <div className="modal__content">
            <div className="modal__header">
              <h2 className="modal__header--title" required>
                Enter Amount
              </h2>
            </div>
            <div className="modal__body">
              <div
                className={`formField ${
                  errors?.amountErrMsg ? 'fieldError' : ''
                }`}
              >
                <label className="formField__label">Enter Amount</label>
                <input
                  type="text"
                  className="formField__input sm"
                  placeholder="Amount"
                  value={ConvertAmountToUSCurrencyFormat(BillingAmount)}
                  onChange={(e) => handleChange(e)}
                />
                <span className="formField__error">
                  {errors['amountErrMsg']}
                </span>
              </div>
            </div>
            <div className="modal__footer">
              <div className="btn__group">
                <button className="btn btn__white" onClick={closeModal}>
                  Cancel
                </button>
                <button
                  className="btn btn__primary"
                  onClick={handleAddBillingAmount}
                  disabled={loading ? true : false}
                >
                  Create Invoice
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
      <PreviewInvoiceModal
        openModal={OpenInvoiceModal}
        closeModal={() => setOpenInvoiceModal(false)}
        ScreeningId={ScreeningId}
        SelectedJobId={SelectedJobId}
        getCandidateListing={getCandidateListing}
        InvoiceUrl={InvoiceUrl}
        setBillingAmount={setBillingAmount}
      />
    </>
  );
};
