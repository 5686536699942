import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CustomPagination from '@/elements/pagination/customPagination';
import GoTOPage from '@/elements/gotopage/gotopage';
import MultiSelectFilter from '@/components/filter';
import { firstLetterCapital } from '@/helper';
import { LoaderContext } from '@/components/Loader/Loader';
import Search from '@/assets/svgs/Search';
import SortColumn from '@/elements/SortColumn';
import { jobList } from '@/services/customers-service';

const JobPost = ({ activeCompanyId }) => {
  const navigate = useNavigate();
  const setLoading = useContext(LoaderContext);

  const [jobPosts, setJobPosts] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [jobPostsFilter, setJobPostsFilter] = useState([
    'Assigned',
    'Unassigned'
  ]);
  const [sortByASC, setSortByASC] = useState(true);
  const [sortByColoumn, setSortByColoumn] = useState('');
  const [pagination, setPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0,
    search: '',
    status: []
  });

  useEffect(() => {
    getJobPosts();
  }, [activeCompanyId]);

  const getJobPosts = async (
    recordsPerPage = pagination?.recordsPerPage,
    currentPage = pagination?.currentPage,
    search = pagination?.search,
    status = pagination?.status,
    columnName = sortByColoumn,
    sort = sortByASC ? 'ASC' : 'DESC'
  ) => {
    try {
      setLoading(true);
      const response = await jobList({
        companyId: activeCompanyId,
        limit: recordsPerPage,
        page: currentPage,
        search: search,
        status: status,
        columnName: columnName,
        sort: sort
      });
      const { data, meta } = response;
      if (meta?.status) {
        setPagination((prev) => ({
          ...prev,
          totalRecord: data?.count ? data?.count : 0
        }));
        setJobPosts(data?.rows);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const goToJobDetails = (jobId, jobTitle) => {
    navigate('/job-listing', {
      state: {
        companyId: activeCompanyId,
        jobId: jobId,
        jobTitle: jobTitle,
        addResponsiveClass: true,
        fromCustomerTab: true
      }
    });
  };

  const searchInJobPosts = (e) => {
    setSearchText(e?.target?.value);
    getJobPosts(
      pagination?.recordsPerPage,
      pagination?.currentPage,
      e?.target?.value,
      pagination?.status
    );
  };

  const applyFilterSelection = (selectedOptions) => {
    getJobPosts(
      pagination?.recordsPerPage,
      pagination?.currentPage,
      pagination?.search,
      selectedOptions
    );
  };

  const clearFilterSelection = () => {
    getJobPosts(
      pagination?.recordsPerPage,
      pagination?.currentPage,
      pagination?.search,
      []
    );
  };

  const changePage = (event, value) => {
    setPagination({ ...pagination, currentPage: value });
    getJobPosts(
      pagination?.recordsPerPage,
      value,
      pagination?.search,
      pagination?.status
    );
  };

  const getSortByData = (columnName) => {
    setPagination({ ...pagination, search: searchText });
    getJobPosts(
      pagination?.recordsPerPage,
      pagination?.currentPage,
      searchText,
      pagination?.status,
      columnName,
      sortByASC ? 'ASC' : 'DESC'
    );
    setSortByColoumn(columnName);
    setSortByASC(!sortByASC);
  };

  return (
    <>
      <div className="tabContainer__item--head">
        <div className="listCol__head--actions jobPosted">
          <div className="formField formFieldSearch">
            <div className="formField__group sm">
              <span className="formField__icon">
                <Search />
              </span>
              <input
                type="text"
                className="formField__input"
                placeholder="Search a job title"
                onChange={(e) => searchInJobPosts(e)}
              />
            </div>
          </div>
          <MultiSelectFilter
            labels={jobPostsFilter}
            applyFilter={applyFilterSelection}
            clearFilter={clearFilterSelection}
          />
        </div>
      </div>
      <TableContainer component={Paper} className="tableContainer">
        <Table className="table tableClickable">
          <TableHead>
            <TableRow>
              <TableCell>S.</TableCell>
              <TableCell onClick={() => getSortByData('title')}>
                <SortColumn thName={'Job Title'} sortByASC={sortByASC} />
              </TableCell>
              <TableCell>Department/ Location</TableCell>
              <TableCell>Qualification / Exp.</TableCell>
              <TableCell>Recruiter</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activeCompanyId &&
              jobPosts &&
              jobPosts.map((row, index) => (
                <TableRow
                  key={`jobPostedTable${index}`}
                  onClick={() => goToJobDetails(row?.jobId, row?.title)}
                >
                  <TableCell component="th" scope="row">
                    {pagination?.currentPage === 1
                      ? index + 1
                      : (pagination?.currentPage - 1) *
                          pagination?.recordsPerPage +
                        (index + 1)}
                  </TableCell>
                  <TableCell>
                    <div className="cellText">
                      <span className="cellText__title">{row?.title}</span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="cellText">
                      <span className="cellText__dark">{row?.department}</span>
                      <span className="cellText__light">{row?.location}</span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="cellText">
                      <span className="cellText__dark">
                        {row?.qualification?.join(', ')}
                      </span>
                      <span className="cellText__light">
                        {row?.minExperience}-{row?.maxExperience} Years
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    {row?.assignedStatus
                      ? row?.recruiter?.firstName +
                        ' ' +
                        row?.recruiter?.lastName
                      : '--'}
                  </TableCell>
                  <TableCell>
                    {firstLetterCapital(row?.status)} /
                    {row?.assignedStatus ? 'Assigned' : 'Unassigned'}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {jobPosts.length ? (
          ''
        ) : (
          <div className="no-data">No Results Found!</div>
        )}
      </TableContainer>
      <div className="pagination">
        <div className="pagination__result">{`${
          pagination?.totalRecord >
          pagination?.currentPage * pagination?.recordsPerPage
            ? pagination?.currentPage * pagination?.recordsPerPage
            : pagination?.totalRecord
        } of ${pagination?.totalRecord} results`}</div>
        {pagination?.totalRecord ? (
          <CustomPagination
            totalPageCount={Math.ceil(
              pagination?.totalRecord / pagination.recordsPerPage
            )}
            changePage={(event, value) => changePage(event, value)}
          />
        ) : (
          ''
        )}
        {pagination?.totalRecord ? (
          <div className="pagination__actions">
            <GoTOPage changePage={changePage} />
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default JobPost;
