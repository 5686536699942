import axios from 'axios';
import { API_URL } from '@/config';
import { setupInterceptorsTo } from '@/services/axios-config';

const httpService = setupInterceptorsTo(
  axios.create({
    baseURL: API_URL
    // headers: {
    //   "Content-Type": "multipart/form-data",
    // },
  })
);

export default httpService;
