export const API_URL =
  process.env.REACT_APP_NODE_ENV === 'development'
    ? process.env.REACT_APP_API_URL_DEV
    : process.env.REACT_APP_NODE_ENV === 'staging'
      ? process.env.REACT_APP_API_URL_STAGING
      : process.env.REACT_APP_API_URL_PRODUCTION;

export const APP_URL =
  process.env.REACT_APP_NODE_ENV === 'development'
    ? process.env.REACT_APP_APP_URL_DEV
    : process.env.REACT_APP_NODE_ENV === 'staging'
      ? process.env.REACT_APP_APP_URL_STAGING
      : process.env.REACT_APP_APP_URL_PRODUCTION;

export const LAILA_LOGO = `https://storage.googleapis.com/laila-${process.env.REACT_APP_BUCKET_ENV}-assets/Laila_logo.png`;
export const INSTITUTION_LOGO = process.env.REACT_APP_INSTITUTION_BUCKET;
