import httpService from '@/services/http-service';
import {
  FILE_UPLOAD,
  GET_STATE_CITY,
  ADD_COMPANY,
  UPDATE_COMPANY,
  ADD_EMPLOYEE,
  EDIT_EMPLOYEE,
  COMPANY_LIST,
  EMPLOYEE_DELETE,
  JOB_LIST,
  DEACTIVATE_COMPANY,
  ACTIVATE_COMPANY,
  CHECK_COMPANY_DEACTIVATION,
  ACTIVATE_EMPLOYEE
} from '@/services/end-points';

export const getStateCityData = async (type, countryCode, stateCode) => {
  try {
    const response = await httpService.post(GET_STATE_CITY, {
      type,
      countryCode,
      stateCode
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const fileUpload = async (formData) => {
  try {
    const response = await httpService.post(FILE_UPLOAD, formData);
    return response;
  } catch (error) {
    return error;
  }
};

export const addCompany = async (body) => {
  try {
    const response = await httpService.post(ADD_COMPANY, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const addEmployee = async (body) => {
  try {
    const response = await httpService.post(ADD_EMPLOYEE, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const editEmployee = async (body) => {
  try {
    const response = await httpService.put(EDIT_EMPLOYEE, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateCompany = async (body) => {
  try {
    const response = await httpService.put(UPDATE_COMPANY, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const companyList = async (body) => {
  try {
    const response = await httpService.post(COMPANY_LIST, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const jobList = async (body) => {
  try {
    const response = await httpService.post(JOB_LIST, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const employeeDelete = async (body) => {
  try {
    const response = await httpService.put(EMPLOYEE_DELETE, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const deactivateCompany = async (body) => {
  try {
    const response = await httpService.put(DEACTIVATE_COMPANY, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const activateCompany = async (body) => {
  try {
    const response = await httpService.put(ACTIVATE_COMPANY, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const companyDeactivationCheck = async (companyId) => {
  try {
    const response = await httpService.get(
      `${CHECK_COMPANY_DEACTIVATION}${companyId}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const activateEmployee = async (body) => {
  try {
    const response = await httpService.put(ACTIVATE_EMPLOYEE, body);
    return response;
  } catch (error) {
    return error;
  }
};
