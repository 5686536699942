import React from 'react';

export default function StarYellowIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.31501 1.14368C7.54832 0.674106 8.21818 0.674105 8.45149 1.14368L10.3569 4.97872C10.4493 5.16467 10.6269 5.29365 10.8323 5.32406L15.0684 5.95116C15.5871 6.02795 15.7941 6.66502 15.4196 7.03202L12.3611 10.0293C12.2128 10.1746 12.145 10.3833 12.1795 10.5881L12.8922 14.8107C12.9794 15.3277 12.4375 15.7215 11.9727 15.4787L8.17702 13.4961C7.99297 13.4 7.77353 13.4 7.58948 13.4961L3.79377 15.4787C3.329 15.7215 2.78707 15.3277 2.87433 14.8107L3.58696 10.5881C3.62152 10.3833 3.55371 10.1746 3.40541 10.0293L0.346889 7.03202C-0.0276098 6.66502 0.179388 6.02795 0.698082 5.95116L4.93423 5.32406C5.13963 5.29365 5.31716 5.16467 5.40956 4.97872L7.31501 1.14368Z"
        fill="#FEA500"
      />
    </svg>
  );
}
