import React, { useState, useCallback } from 'react';
import BackArrow from '@/assets/svgs/BackArrow';
import Search from '@/assets/svgs/Search';
import MultiSelectFilter from '@/components/filter';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { firstLetterCapital } from '@/helper';
import { Currency } from '@/helper';
import { useNavigate } from 'react-router-dom';
import CustomPagination from '@/elements/pagination/customPagination';
import GoTOPage from '@/elements/gotopage/gotopage';

const CustomerJobsListing = ({
  addResponsiveClass,
  setAddResponsiveClass,
  activeCompanyData,
  searchJobPost,
  handleStatusFilterChange,
  handleCustomerJobFilterClose,
  jobList,
  changePage,
  customerLengthCount,
  pagination
}) => {
  const navigate = useNavigate();

  const [BillingFilterStatus, setBillingFilterStatus] = useState([
    'Billed',
    'Unbilled'
  ]);

  const navigateToJobsCandidateScreen = (activeCompanyData, jobid, title) => {
    navigate('/jobs-billing', {
      state: {
        activeCompanyData: activeCompanyData,
        jobid: jobid,
        addResponsiveClass: true,
        jobTitle: title
      }
    });
  };

  const CalculateUnbilledAmount = (obj) => {
    const sumFilteredValues = obj.screenings
      .filter((item) => !item.billStatus && item.status === 'approved')
      .reduce((sum, item) => sum + item.amount, 0);
    return Currency(sumFilteredValues);
  };

  const getBilledUnbilled = (screenings) => {
    const getApprovedScreenings =
      screenings.filter(
        (item) => !item.billStatus && item.status === 'approved'
      ).length > 0
        ? 'Unbilled'
        : 'Billed';
    return getApprovedScreenings;
  };

  return (
    <>
      {customerLengthCount ? (
        <div
          className={`layoutGrid__col layoutGrid--responsive  ${
            addResponsiveClass ? 'layoutGrid--show' : ''
          }`}
        >
          <div className="dataCol">
            <div className="page page__full">
              <div className="page__head">
                <h2 className="page__head--title">
                  <button
                    className="btn btn__transparent btn__back border_0 p_0"
                    onClick={() => setAddResponsiveClass(false)}
                  >
                    <BackArrow />
                  </button>
                  {activeCompanyData?.companyName}
                </h2>
              </div>
              {activeCompanyData?.status !== 'delete' && (
                <div className="page__body">
                  <div className="tabContainer tabContainer--inner">
                    <div className="tabContainer__item bpContainer">
                      <div className="tabContainer__item--head">
                        <div className="listCol__head--actions w_100">
                          <div className="formField formFieldSearch">
                            <div className="formField__group sm">
                              <span className="formField__icon">
                                <Search />
                              </span>
                              <input
                                type="text"
                                className="formField__input"
                                placeholder="Search a job post"
                                onChange={(e) =>
                                  searchJobPost(e?.target?.value)
                                }
                              />
                            </div>
                          </div>
                          <MultiSelectFilter
                            labels={BillingFilterStatus}
                            applyFilter={handleStatusFilterChange}
                            clearFilter={handleCustomerJobFilterClose}
                          />
                        </div>
                      </div>
                      <TableContainer
                        component={Paper}
                        className="tableContainer"
                      >
                        <Table className="table tableClickable">
                          <TableHead>
                            <TableRow>
                              <TableCell>S.</TableCell>
                              <TableCell>Job Title</TableCell>
                              <TableCell>Department</TableCell>
                              <TableCell>Location</TableCell>
                              <TableCell>Recruiter</TableCell>
                              <TableCell>Total Unbilled</TableCell>
                              <TableCell>Status</TableCell>
                            </TableRow>
                          </TableHead>
                          {jobList?.length ? (
                            <TableBody>
                              {jobList?.map((row, index) => {
                                return (
                                  <TableRow
                                    key={`billingJobs${index}`}
                                    onClick={() =>
                                      navigateToJobsCandidateScreen(
                                        activeCompanyData,
                                        row?.jobId,
                                        row?.title
                                      )
                                    }
                                  >
                                    <TableCell component="th" scope="row">
                                      {pagination?.currentPage === 1
                                        ? index + 1
                                        : (pagination?.currentPage - 1) * 20 +
                                          (index + 1)}
                                    </TableCell>
                                    <TableCell>
                                      <div className="cellText">
                                        <span className="cellText__title">
                                          {row?.title}
                                        </span>
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <div className="cellText">
                                        <span className="cellText__dark">
                                          {row?.department}
                                        </span>
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <div className="cellText">
                                        <span className="cellText__dark">
                                          {row?.location}
                                        </span>
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <div className="cellText">
                                        <span className="cellText__dark">
                                          {row?.assignedStatus
                                            ? firstLetterCapital(
                                                row?.recruiter?.firstName
                                              ) +
                                              ' ' +
                                              firstLetterCapital(
                                                row?.recruiter?.lastName
                                              )
                                            : '--'}
                                        </span>
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <div className="cellText">
                                        <span className="cellText__dark">
                                          {CalculateUnbilledAmount(row)}
                                        </span>
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <div className="cellText">
                                        <span
                                          className={
                                            getBilledUnbilled(
                                              row?.screenings
                                            ) === 'Billed'
                                              ? 'cellText__dark text--primary'
                                              : 'cellText__dark text--danger'
                                          }
                                        >
                                          {getBilledUnbilled(row?.screenings)}
                                        </span>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          ) : (
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <div className="no-data floating">
                                    No Results Found!
                                  </div>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>

                      <div className="pagination">
                        <div className="pagination__result">{`${
                          pagination?.totalRecord >
                          pagination?.currentPage * pagination?.recordsPerPage
                            ? pagination?.recordsPerPage
                            : pagination?.totalRecord
                        } of ${pagination?.totalRecord} results`}</div>

                        {pagination?.totalRecord ? (
                          <CustomPagination
                            totalPageCount={Math.ceil(
                              pagination?.totalRecord /
                                pagination.recordsPerPage
                            )}
                            page={pagination?.currentPage}
                            changePage={(event, value) =>
                              changePage(event, value)
                            }
                          />
                        ) : (
                          ''
                        )}
                        {pagination?.totalRecord ? (
                          <div className="pagination__actions">
                            <GoTOPage changePage={changePage} />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="no-data">No Results Found!</div>
      )}
    </>
  );
};

export default CustomerJobsListing;
