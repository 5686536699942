import React from 'react';

export default function PhoneIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_827_4122)">
        <path
          d="M14.6665 11.2802V13.2802C14.6672 13.4659 14.6292 13.6497 14.5548 13.8198C14.4804 13.9899 14.3713 14.1426 14.2345 14.2681C14.0977 14.3937 13.9362 14.4892 13.7603 14.5487C13.5844 14.6082 13.398 14.6303 13.2131 14.6136C11.1617 14.3907 9.19113 13.6897 7.45979 12.5669C5.84901 11.5433 4.48335 10.1777 3.45979 8.56689C2.33311 6.8277 1.63195 4.84756 1.41313 2.78689C1.39647 2.60254 1.41838 2.41673 1.47746 2.24131C1.53654 2.06589 1.63151 1.90469 1.7563 1.76797C1.8811 1.63126 2.033 1.52203 2.20232 1.44724C2.37164 1.37245 2.55469 1.33374 2.73979 1.33356H4.73979C5.06333 1.33038 5.37699 1.44495 5.6223 1.65592C5.86761 1.86689 6.02784 2.15986 6.07313 2.48023C6.15754 3.12027 6.31409 3.74871 6.53979 4.35356C6.62949 4.59218 6.6489 4.8515 6.59573 5.10081C6.54256 5.35012 6.41903 5.57897 6.23979 5.76023L5.39313 6.60689C6.34216 8.27592 7.7241 9.65786 9.39313 10.6069L10.2398 9.76023C10.4211 9.58099 10.6499 9.45746 10.8992 9.40429C11.1485 9.35112 11.4078 9.37053 11.6465 9.46023C12.2513 9.68593 12.8797 9.84248 13.5198 9.92689C13.8436 9.97258 14.1394 10.1357 14.3508 10.3852C14.5622 10.6348 14.6746 10.9533 14.6665 11.2802Z"
          stroke="#384250"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_827_4122">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
