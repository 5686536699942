import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext
} from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CustomPagination from '@/elements/pagination/customPagination';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import ArrowDown from '@/elements/ArrowDown';
import { firstLetterCapital } from '@/helper';
import Filter from '@/assets/svgs/Filter';
import SearchCross from '@/assets/svgs/SearchCross';
import BackArrow from '@/assets/svgs/BackArrow';
import _ from 'lodash';
import { getJobsList } from '@/services/billing-service/billing-service';
import { LoaderContext } from '@/components/Loader/Loader';
import Search from '@/assets/svgs/Search';
import Dot from '@/assets/svgs/Dot';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const JobsListing = ({
  activeCompanyId,
  activeJobId,
  setAddResponsiveClass,
  ActiveCompanyData,
  setBillingCompanyId,
  setJobsLengthCount,
  selectNewJob
}) => {
  const searchRef = useRef(null);
  const setLoading = useContext(LoaderContext);

  const [filteranchorEl, setFilterAnchorEl] = useState(null);
  const [searchanchorEl, setSeachAnchorEl] = useState(null);
  const searchOpen = Boolean(searchanchorEl);
  const filterOpen = Boolean(filteranchorEl);
  const searchPopoverid = searchOpen ? 'simple-popover' : '';
  const filterPopoverid = filterOpen ? 'simple-popover' : '';

  const [jobList, setJobList] = useState([]);
  const [CompanyName, setCompanyName] = useState(
    ActiveCompanyData?.companyName
  );
  const [FilterJobStatus, setFilterJobStatus] = useState([]);
  const [jobFilterList, setJobFilterList] = useState(['Billed', 'Unbilled']);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0,
    search: '',
    status: []
  });

  useEffect(() => {
    getJobData();
  }, []);

  const changePage = (event, value) => {
    setPagination({ ...pagination, currentPage: value });
  };

  const searchInJobListing = useCallback(
    _.debounce(
      (searchText) =>
        getJobData(
          pagination?.recordsPerPage,
          pagination?.currentPage,
          searchText,
          pagination?.status
        ),
      500
    ),
    []
  );

  const handleSeachClick = (event) => {
    setSeachAnchorEl(event.currentTarget);
  };

  const handleSearchClose = () => {
    setSeachAnchorEl(null);
    searchRef.current.value = '';
    getJobData(pagination?.recordsPerPage, 1, '', pagination?.status);
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const filterJobset = (e) => {
    const {
      target: { value }
    } = e;
    setFilterJobStatus(typeof value === 'string' ? value.split(',') : value);
  };

  const getJobData = async (
    recordsPerPage = pagination?.recordsPerPage,
    currentPage = pagination?.currentPage,
    search = pagination?.search,
    status = pagination?.status,
    companyId = activeCompanyId
  ) => {
    setLoading(true);
    const data = await getJobsList(
      recordsPerPage,
      currentPage,
      search,
      status,
      companyId
    );
    setPagination((prev) => ({
      ...prev,
      totalRecord: data?.count ? data?.count : 0
    }));
    setJobList(data?.rows);
    setJobsLengthCount(data?.count);
    setLoading(false);
  };

  return (
    <div className="layoutGrid__col">
      <div
        className={`listCol ${
          filteranchorEl
            ? 'listCol__filter'
            : searchanchorEl
              ? 'listCol__search'
              : ''
        }`}
      >
        <div className="listCol__head listing__head">
          <h2 className="listCol__head--title">
            <button
              className="btn border_0 p_0"
              onClick={() => setBillingCompanyId('')}
            >
              <BackArrow />
            </button>
            <span>{CompanyName}</span>
          </h2>
          <div className="listCol__head--actions">
            <div className="formField">
              <button
                className="btn btn__white p_0"
                aria-describedby={searchPopoverid}
                onClick={handleSeachClick}
              >
                <Search />
              </button>
            </div>

            <div>
              <button
                className="btn btn__white p_0"
                aria-describedby={filterPopoverid}
                onClick={handleFilterClick}
              >
                <Filter />
              </button>
            </div>
          </div>
          <div className="searchContainer">
            <div className="formField">
              <div className="formField__group sm">
                <span className="formField__icon">
                  <Search />
                </span>
                <input
                  type="text"
                  ref={searchRef}
                  className="formField__input"
                  placeholder="Search a job/recruiter"
                  onChange={(e) => searchInJobListing(e?.target?.value)}
                />
              </div>
            </div>

            <button
              className="btn btn__transparent border_0 p_0"
              onClick={handleSearchClose}
            >
              <SearchCross />
            </button>
          </div>
        </div>

        <div className="filter__head">
          <h2 className="listCol__head--title">Filter</h2>
          <button
            className="btn btn__transparent border_0 p_0"
            onClick={handleFilterClose}
          >
            <SearchCross />
          </button>
        </div>

        <div className="filterContainer">
          <ul className="listCol__list filter__list">
            <li>
              <div className="formRow">
                <div className="formCol">
                  <div className="formField">
                    <label className="formField__label">Job Status</label>
                    <FormControl
                      sx={{ width: '100%' }}
                      size="small"
                      className="formField__select"
                    >
                      <Select
                        id="multiple-checkbox"
                        value={FilterJobStatus}
                        onChange={filterJobset}
                        name="status"
                        renderValue={(selected) => {
                          if (!selected) {
                            return (
                              <span style={{ opacity: '0.8' }}>Select</span>
                            );
                          }

                          return selected;
                        }}
                        MenuProps={MenuProps}
                        displayEmpty
                        IconComponent={() => <ArrowDown />}
                        classes={{ root: 'multiSelect' }}
                      >
                        {jobFilterList?.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={FilterJobStatus?.indexOf(name) > -1}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <div className="filter__footer">
            <div className="btn__group flex--between">
              <button
                className="btn btn__transparent p_0"
                onClick={(e) => {
                  getJobData(
                    pagination?.recordsPerPage,
                    pagination?.currentPage,
                    pagination?.search,
                    []
                  );
                  setFilterJobStatus([]);
                  handleFilterClose();
                }}
              >
                Clear
              </button>
              <button
                className="btn btn__primary"
                onClick={(e) => {
                  getJobData(
                    pagination?.recordsPerPage,
                    FilterJobStatus?.length ? 1 : pagination?.currentPage,
                    pagination?.search,
                    FilterJobStatus
                  );
                  handleFilterClose();
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </div>

        {jobList.length ? (
          <ul className="listCol__list">
            {jobList.map((row, index) => {
              return (
                <li
                  className={`listCol__list--item ${
                    activeJobId == row?.jobId
                      ? 'listCol__item--selected sm'
                      : 'sm'
                  }`}
                  onClick={() => {
                    setAddResponsiveClass(true);
                    selectNewJob(row?.jobId, row?.title);
                  }}
                >
                  <div className="item item--recruiter item--jobs">
                    <div className="item__info">
                      <h4 className="item__info--title">
                        {row?.title ? row?.title : ''}
                      </h4>
                      <div className="item__info--subtitle">
                        <p>
                          <span>Department:</span>{' '}
                          {row?.department ? row?.department : ''}
                        </p>
                        <span className="status">
                          <Filter />
                          Status:{' '}
                          <span
                            className={
                              row?.billStatus === false
                                ? 'text--danger'
                                : 'text--primary'
                            }
                          >
                            {row?.billStatus === false ? 'Unbilled' : 'Billed'}
                          </span>
                        </span>
                      </div>
                      <div className="item__info--subtitle">
                        {row?.recruiter ? (
                          <p>
                            <span>Recruiter:</span>{' '}
                            {firstLetterCapital(row?.recruiter?.firstName) +
                              ' ' +
                              firstLetterCapital(row?.recruiter?.lastName)}
                          </p>
                        ) : (
                          ''
                        )}
                        <span>
                          <Dot />
                          {row?.location}
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <div className="no-data">No Results Found!</div>
        )}

        <div className="pagination pagination__center">
          {pagination?.totalRecord ? (
            <CustomPagination
              totalPageCount={Math.ceil(
                pagination?.totalRecord / pagination.recordsPerPage
              )}
              changePage={(event, value) => changePage(event, value)}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default JobsListing;
