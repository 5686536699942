import React from 'react';

export default function Company() {
  return (
    <svg
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="80" height="80" rx="40" fill="#28A0E8" />
      <path
        d="M20.5 60H22.7222M60.5 60H58.2778M22.7222 60V24.3009C22.7222 22.8432 22.7222 22.1144 23.0263 21.6279C23.2926 21.2019 23.7095 20.8917 24.1941 20.759C24.7474 20.6076 25.4455 20.817 26.8417 21.2359L42.664 25.9825C43.4822 26.228 43.8913 26.3507 44.1946 26.5936C44.4624 26.8081 44.6705 27.0878 44.7989 27.4058C44.9444 27.7661 44.9444 28.1933 44.9444 29.0476V35.5556M22.7222 60H33.8333M44.9444 60V35.5556M44.9444 60H58.2778M44.9444 60H33.8333M44.9444 35.5556L56.1718 31.8131C56.8828 31.5761 57.2383 31.4576 57.5213 31.5286C57.769 31.5908 57.9835 31.7455 58.1209 31.9608C58.2778 32.2068 58.2778 32.5815 58.2778 33.331V60M33.8333 33.3333V33.3556M33.8333 39.9778V40M51.6111 42.2V42.2222M51.6111 48.8889V48.9111M33.8333 60V48.8889"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
