import React from 'react';
import Dot from '@/assets/svgs/Dot';
import { firstLetterCapital, formatPhoneNumber, getInitial } from '@/helper';
import CustomPagination from '@/elements/pagination/customPagination';
import { useNavigate } from 'react-router-dom';
import ArrowDownBlueIcon from '@/assets/svgs/ArrowDownBlueIcon';
const RecruiterBilling = ({
  recruitersList,
  onRecruiterSelect,
  activeRecruiter,
  recruiterPagination,
  recruiterListPageChange,
  getJobPaidUnpaidStatus,
  setAddRecruiterResponsiveClass
}) => {
  const navigate = useNavigate();
  const getEducationAndExperience = (education, experience) => {
    if (education && !experience) {
      return `${education}`;
    } else if (experience && !education) {
      if (experience > 1) {
        return `${experience} years experience`;
      } else {
        return `${experience} year experience`;
      }
    } else if (education && experience) {
      if (experience > 1) {
        return `${education} / ${experience} years experience`;
      } else {
        return `${education} / ${experience} year experience`;
      }
    }
  };

  const onClickTotalOutstanding = () => {
    navigate('/outstanding-amount');
  };

  const getPaymentDate = () => {
    const moment = require('moment');
    let currentDate = moment();
    let lastDayOfMonth = moment(currentDate).endOf('month');
    while (lastDayOfMonth.day() !== 3) {
      lastDayOfMonth.subtract(1, 'day');
    }
    if (lastDayOfMonth.isBefore(currentDate)) {
      lastDayOfMonth = moment(currentDate).add(1, 'month').endOf('month');
      while (lastDayOfMonth.day() !== 3) {
        lastDayOfMonth.subtract(1, 'day');
      }
    }
    return lastDayOfMonth.format('D MMM, YYYY');
  };
  return (
    <>
      {recruitersList?.length > 0 && (
        <div className="veiwTotalAmoutLink">
          <button
            className="veiwTotalAmoutLink__link"
            onClick={onClickTotalOutstanding}
          >
            View total outstanding amount <ArrowDownBlueIcon />
          </button>
        </div>
      )}
      <ul
        className="listCol__list listing__list"
        style={{ height: 'calc(100vh - 262px)' }}
      >
        {recruitersList?.length > 0 ? (
          recruitersList?.map((recruiter, index) => {
            return (
              <>
                <li
                  className={`listCol__list--item ${activeRecruiter?.recruiterId === recruiter?.recruiterId ? 'listCol__item--selected' : ''}`}
                  key={recruiter?.recruiterId}
                  onClick={() => {
                    onRecruiterSelect(recruiter);
                    setAddRecruiterResponsiveClass(true);
                  }}
                >
                  <div className="item item--recruiter">
                    <div className="initials initials__md circle circle__approve">
                      {getInitial(recruiter?.firstName)}
                    </div>
                    <div className="item__info">
                      <h4 className="item__info--title">{`${firstLetterCapital(recruiter?.firstName)} ${firstLetterCapital(recruiter?.lastName)}`}</h4>
                      <div className="item__info--subtitle">
                        <p>
                          {getEducationAndExperience(
                            recruiter?.education,
                            recruiter?.experience
                          )}
                        </p>
                        <span className="status">
                          <Dot />
                          <span className="fw500">Status:</span>
                          <span
                            className={
                              getJobPaidUnpaidStatus(recruiter?.screenings) ===
                              'Paid'
                                ? 'text--primary'
                                : 'text--danger'
                            }
                          >
                            {getJobPaidUnpaidStatus(recruiter?.screenings)}
                          </span>
                        </span>
                      </div>
                      <div className="item__info--subtitle">
                        <p>
                          <b>P:</b>
                          {formatPhoneNumber(recruiter?.phoneNumber)}
                        </p>
                        <span>
                          <b>E:</b>
                          {recruiter?.email}
                        </span>
                      </div>
                      <div className="item__info--subtitle">
                        <p>
                          <b>Payment date: </b>
                          {getPaymentDate()}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              </>
            );
          })
        ) : (
          <div className="no-data">No Results Found!</div>
        )}
      </ul>

      {recruitersList?.length > 0 && (
        <div className="pagination pagination__center">
          {recruitersList.length ? (
            <CustomPagination
              totalPageCount={Math.ceil(
                recruiterPagination?.totalRecord /
                  recruiterPagination.recordsPerPage
              )}
              changePage={(event, value) =>
                recruiterListPageChange(event, value)
              }
              page={recruiterPagination?.currentPage}
            />
          ) : (
            ''
          )}
        </div>
      )}
    </>
  );
};

export default RecruiterBilling;
