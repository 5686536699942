import React, { useEffect, useState } from 'react';
import CloseIconGray from '@/assets/svgs/CloseIconGray';
import GaugeComponent from 'react-gauge-component';
import { getNPSScore } from '@/services/reviews-ratings-service/get-nps-ratings';

const NPSScoreGraphModal = ({ openModal, closeModal }) => {
  const [NPSScore, setNPSScore] = useState(false);
  useEffect(() => {
    getRecruitersNPSScore();
  }, [openModal]);

  const getRecruitersNPSScore = async () => {
    const response = await getNPSScore();
    const { meta, data } = response;
    if (meta?.status) {
      let npsScore = Math.round(data?.npsScore);
      setNPSScore(npsScore);
    }
  };
  return (
    <>
      <div className={`modal npsModal ${openModal ? 'modal--open' : ''}`}>
        <div className="modal__dialog">
          <div className="modal__content modal__content--xxxl">
            <div className="modal__header">
              <h2 className="modal__header--title">NPS Score</h2>
              <button
                onClick={closeModal}
                className="btn btn__transparent btnClose"
              >
                <CloseIconGray />
              </button>
            </div>
            <div className="modal__body">
              <div>
                <GaugeComponent
                  value={NPSScore}
                  minValue={-100}
                  maxValue={100}
                  type="radial"
                  labels={{
                    tickLabels: {
                      type: 'outer',
                      ticks: [{ value: 0 }]
                    }
                  }}
                  arc={{
                    colorArray: ['#d9534f', '#f0ad4e', '#5cb85c'],
                    subArcs: [{ limit: -35 }, { limit: 35 }, { limit: 40 }],
                    width: 0.4,
                    padding: 0.008,
                    cornerRadius: 1
                  }}
                  pointer={{ type: 'arrow', elastic: true }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
    </>
  );
};

export default NPSScoreGraphModal;
