import React, { useState } from 'react';
import BackArrow from '@/assets/svgs/BackArrow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Search from '@/assets/svgs/Search';
import { firstLetterCapital } from '@/helper';
import ArrowDownBlueIcon from '@/assets/svgs/ArrowDownBlueIcon';
import FileIconBlue from '@/assets/svgs/FileIconBlue';
import { useNavigate } from 'react-router';
import { Currency } from '@/helper';
import CustomPagination from '@/elements/pagination/customPagination';
import GoTOPage from '@/elements/gotopage/gotopage';
import MultiSelectFilter from '@/components/filter';

const RecruiterJobsListing = ({
  setAddRecruiterResponsiveClass,
  addRecruiterResponsiveClass,
  recruiterJobsList,
  recruitersList,
  recruiterJobsCount,
  activeRecruiter,
  recruiterJobsPagination,
  searchRecruitersJobs,
  getJobPaidUnpaidStatus,
  RecruiterJobsPageChange,
  handleJobsStatusFilterChange,
  handleJobsFilterClose,
  RecruiterFilterStatus,
  TotalUnbilledAmount
}) => {
  const navigate = useNavigate();

  const navigateToJobsCandidateScreen = (job) => {
    navigate('/recruiter-candidates-billing', {
      state: {
        activeRecruiter: activeRecruiter,
        jobData: job,
        jobid: job?.jobId
      }
    });
  };
  const CalculateTotalPaidAmount = (obj) => {
    const totalAmountPaid = obj?.screenings.reduce((acc, recruiter) => {
      if (recruiter.recruiterAmountPaid) {
        return acc + recruiter.recruiterAmount;
      }
      return acc;
    }, 0);
    return Currency(totalAmountPaid);
  };

  const CalculateTotalUnPaidAmount = (obj) => {
    const totalUnpaid = obj?.screenings
      .filter((recruiter) => !recruiter.recruiterAmountPaid)
      .reduce((acc, recruiter) => acc + recruiter.recruiterAmount, 0);
    return Currency(totalUnpaid);
  };
  return (
    <>
      {recruitersList?.length > 0 ? (
        <div
          className={`layoutGrid__col layoutGrid--responsive  ${
            addRecruiterResponsiveClass ? 'layoutGrid--show' : ''
          }`}
        >
          <div className="dataCol">
            <div className="page page__full">
              <div className="page__head">
                <h2 className="page__head--title">
                  <button
                    className="btn btn__transparent btn__back border_0 p_0"
                    onClick={() => setAddRecruiterResponsiveClass(false)}
                  >
                    <BackArrow />
                  </button>
                  {`${firstLetterCapital(activeRecruiter?.firstName)} ${firstLetterCapital(activeRecruiter?.lastName)}`}
                </h2>
                <div className="page__head__actions recuiterbilling">
                  <span className="errorMessage">
                    Total Unpaid Bill
                    <strong className="errorMessage__price">
                      {Currency(TotalUnbilledAmount)}
                    </strong>
                  </span>
                </div>
              </div>

              <div className="page__body">
                <div className="tabContainer tabContainer--inner">
                  <div className="tabContainer__item bpContainer">
                    <div className="tabContainer__item--head ">
                      <div className="listCol__head--actions w_100">
                        <div className="formField formFieldSearch">
                          <div className="formField__group sm">
                            <span className="formField__icon">
                              <Search />
                            </span>
                            <input
                              type="text"
                              className="formField__input"
                              placeholder="Search a Job"
                              onChange={(e) =>
                                searchRecruitersJobs(
                                  e?.target?.value,
                                  activeRecruiter?.recruiterId
                                )
                              }
                            />
                          </div>
                        </div>
                        <MultiSelectFilter
                          labels={RecruiterFilterStatus}
                          applyFilter={handleJobsStatusFilterChange}
                          clearFilter={handleJobsFilterClose}
                        />
                        {/* <div className="col">
                          <button className="paymentlink">
                            <FileIconBlue />
                            View Bill
                            <ArrowDownBlueIcon />
                          </button>
                        </div> */}
                      </div>
                    </div>
                    <TableContainer
                      component={Paper}
                      className="tableContainer"
                    >
                      <Table className="table tableClickable">
                        <TableHead>
                          <TableRow>
                            <TableCell>S.</TableCell>
                            <TableCell>Job Title</TableCell>
                            <TableCell>Department</TableCell>
                            <TableCell>Customer</TableCell>
                            <TableCell>Total Paid</TableCell>
                            <TableCell>Total Unpaid</TableCell>
                            <TableCell>Status</TableCell>
                            {/* <TableCell>Bills</TableCell> */}
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        {recruiterJobsCount ? (
                          <TableBody>
                            {recruiterJobsList?.map((job, index) => {
                              return (
                                <TableRow
                                  key={`billingJobs${index}`}
                                  onClick={() =>
                                    navigateToJobsCandidateScreen(job)
                                  }
                                >
                                  <TableCell component="th" scope="row">
                                    {recruiterJobsPagination?.currentPage > 1
                                      ? (recruiterJobsPagination?.currentPage -
                                          1) *
                                          20 +
                                        (index + 1)
                                      : index + 1}
                                  </TableCell>
                                  <TableCell>
                                    <div className="cellText">
                                      <span className="cellText__title">
                                        {job?.title ? job?.title : '--'}
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="cellText">
                                      <span className="cellText__dark">
                                        {job?.department
                                          ? job?.department
                                          : '--'}
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="cellText">
                                      <span className="cellText__dark">
                                        {job?.company?.companyName
                                          ? job?.company?.companyName
                                          : '--'}
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="cellText">
                                      <span className="cellText__dark">
                                        {CalculateTotalPaidAmount(job)}
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="cellText">
                                      <span className="cellText__dark">
                                        {CalculateTotalUnPaidAmount(job)}
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="cellText">
                                      <span
                                        className={
                                          getJobPaidUnpaidStatus(
                                            job?.screenings
                                          ) === 'Paid'
                                            ? 'cellText__dark text--primary'
                                            : 'cellText__dark text--danger'
                                        }
                                      >
                                        {getJobPaidUnpaidStatus(
                                          job?.screenings
                                        )}
                                      </span>
                                    </div>
                                  </TableCell>
                                  {/* <TableCell>
                                    <div className="cellText">
                                      <span className="cellText__primary cellText__primary--icon">
                                        <FileIconBlue />
                                        View Bill
                                      </span>
                                    </div>
                                  </TableCell> */}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        ) : (
                          <div className="no-data floating">
                            No Results Found!
                          </div>
                        )}
                      </Table>
                    </TableContainer>

                    <div className="pagination">
                      <div className="pagination__result">{`${
                        recruiterJobsPagination?.totalRecord >
                        recruiterJobsPagination?.currentPage *
                          recruiterJobsPagination?.recordsPerPage
                          ? recruiterJobsPagination?.recordsPerPage
                          : recruiterJobsPagination?.totalRecord
                      } of ${recruiterJobsPagination?.totalRecord} results`}</div>

                      {recruiterJobsPagination?.totalRecord ? (
                        <CustomPagination
                          totalPageCount={Math.ceil(
                            recruiterJobsPagination?.totalRecord /
                              recruiterJobsPagination.recordsPerPage
                          )}
                          page={recruiterJobsPagination?.currentPage}
                          changePage={(event, value) =>
                            RecruiterJobsPageChange(event, value)
                          }
                        />
                      ) : (
                        ''
                      )}
                      {recruiterJobsPagination?.totalRecord ? (
                        <div className="pagination__actions">
                          <GoTOPage changePage={RecruiterJobsPageChange} />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="no-data">No Results Found!</div>
      )}
    </>
  );
};

export default RecruiterJobsListing;
