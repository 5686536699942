import React from 'react';

function FollowersCount() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33333 4.16699C3.13281 4.16699 1.33333 5.91649 1.33333 8.05588C1.33333 9.34205 1.98438 10.4864 2.97917 11.1953C1.22135 12.0435 0 13.8006 0 15.8337H1.33333C1.33333 13.6791 3.11719 11.9448 5.33333 11.9448C7.54948 11.9448 9.33333 13.6791 9.33333 15.8337H10.6667C10.6667 13.6791 12.4505 11.9448 14.6667 11.9448C16.8828 11.9448 18.6667 13.6791 18.6667 15.8337H20C20 13.8006 18.7786 12.0435 17.0208 11.1953C18.0156 10.4864 18.6667 9.34205 18.6667 8.05588C18.6667 5.91649 16.8672 4.16699 14.6667 4.16699C12.4661 4.16699 10.6667 5.91649 10.6667 8.05588C10.6667 9.34205 11.3177 10.4864 12.3125 11.1953C11.3411 11.6637 10.526 12.403 10 13.3221C9.47396 12.403 8.65885 11.6637 7.6875 11.1953C8.68229 10.4864 9.33333 9.34205 9.33333 8.05588C9.33333 5.91649 7.53385 4.16699 5.33333 4.16699ZM5.33333 5.46329C6.8151 5.46329 8 6.61527 8 8.05588C8 9.49649 6.8151 10.6485 5.33333 10.6485C3.85156 10.6485 2.66667 9.49649 2.66667 8.05588C2.66667 6.61527 3.85156 5.46329 5.33333 5.46329ZM14.6667 5.46329C16.1484 5.46329 17.3333 6.61527 17.3333 8.05588C17.3333 9.49649 16.1484 10.6485 14.6667 10.6485C13.1849 10.6485 12 9.49649 12 8.05588C12 6.61527 13.1849 5.46329 14.6667 5.46329Z"
        fill="#150823"
      />
    </svg>
  );
}

export default FollowersCount;
