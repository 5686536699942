import React, { useState, useRef, useContext } from 'react';
import { Formik, Field } from 'formik';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BackArrow from '@/assets/svgs/BackArrow';
import Search from '@/assets/svgs/Search';
import {
  firstLetterCapital,
  getInitial,
  formatAmountInUSFormat,
  ConvertAmountToUSCurrencyFormat
} from '@/helper';
import CustomPagination from '@/elements/pagination/customPagination';
import GoTOPage from '@/elements/gotopage/gotopage';
import MultiSelectFilter from '@/components/filter';
import { ApproveCandidatesBilling } from '@/services/billing-service/billing-service';
import { LoaderContext } from '@/components/Loader/Loader';

const RecruiterCandidateListing = ({
  addResponsiveClass,
  setAddResponsiveClass,
  candidateList,
  activeRecruiter,
  candidatesPagination,
  changeCandidatesPage,
  recruitersJobsList,
  searchInCandidateListing,
  applyCandidateFilter,
  clearCandidateFilter,
  getCandidateList,
  getRecruiterJobsList
}) => {
  const setLoading = useContext(LoaderContext);
  const searchRef = useRef();
  const [searchanchorEl, setSeachAnchorEl] = useState(null);
  const [CandidateAmounts, setCandidateAmounts] = useState('');

  const handleAmountChange = (e, index) => {
    const value = formatAmountInUSFormat(e.target.value);
    if (value === '' || !isNaN(parseFloat(value))) {
      let newAmounts = [...CandidateAmounts];
      newAmounts[index] = value;
      setCandidateAmounts(newAmounts);
    }
  };

  const candidatesFilters = ['Paid', 'Unpaid'];

  const handleSeachClick = (event) => {
    setSeachAnchorEl(event.currentTarget);
  };

  const handleApproveCandidate = async (candidate, index) => {
    setLoading(true);
    let body = {
      screeningId: candidate?.sreeningId,
      amount: CandidateAmounts[index],
      type: 'single'
    };
    const response = await ApproveCandidatesBilling(body);
    const { meta } = response;
    if (meta?.status) {
      let newAmounts = [...CandidateAmounts];
      setCandidateAmounts(newAmounts);
      getCandidateList();
      getRecruiterJobsList();
    }
    setLoading(false);
  };

  return (
    <>
      {recruitersJobsList?.length > 0 ? (
        <div
          className={`layoutGrid__col layoutGrid--responsive  ${
            addResponsiveClass ? 'layoutGrid--show' : ''
          }`}
        >
          <div className="dataCol">
            <div className="page page__full">
              <div className="page__head">
                <h2 className="page__head--title">
                  <button
                    className="btn btn__transparent btn__back border_0 p_0"
                    onClick={() => setAddResponsiveClass(false)}
                  >
                    <BackArrow />
                  </button>
                  {`${firstLetterCapital(activeRecruiter?.firstName)} ${firstLetterCapital(activeRecruiter?.lastName)}`}
                </h2>
              </div>
              <div className="page__body">
                <div className="tabContainer tabContainer--inner">
                  <div className="tabContainer__item bpContainer">
                    <div className="tabContainer__item--head ">
                      <div className="listCol__head--actions w_100">
                        <div className="formField formFieldSearch">
                          <div className="formField__group sm">
                            <span
                              className="formField__icon"
                              onClick={handleSeachClick}
                            >
                              <Search />
                            </span>
                            <input
                              placeholder="Search a candidate"
                              type="text"
                              className="formField__input"
                              ref={searchRef}
                              onChange={(e) =>
                                searchInCandidateListing(e?.target?.value)
                              }
                            />
                          </div>
                        </div>
                        <MultiSelectFilter
                          labels={candidatesFilters}
                          applyFilter={applyCandidateFilter}
                          clearFilter={clearCandidateFilter}
                        />
                      </div>
                    </div>
                    <TableContainer
                      component={Paper}
                      className="tableContainer"
                    >
                      <Formik>
                        <Table className="table tableClickable">
                          <TableHead>
                            <TableRow>
                              <TableCell>S.</TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell>Email address</TableCell>
                              <TableCell>Status</TableCell>
                              <TableCell>Enter Amount ($)</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          {candidateList?.length > 0 ? (
                            <TableBody>
                              {candidateList?.map((candidate, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                      {candidatesPagination?.currentPage > 1
                                        ? (candidatesPagination?.currentPage -
                                            1) *
                                            20 +
                                          (index + 1)
                                        : index + 1}
                                    </TableCell>
                                    <TableCell>
                                      <div className="profile">
                                        <div className="profile__initials">
                                          {getInitial(
                                            candidate?.candidate?.firstName
                                          )}
                                        </div>
                                        <span className="profile__name">
                                          {`${firstLetterCapital(candidate?.candidate?.firstName)} ${firstLetterCapital(candidate?.candidate?.lastName)}`}
                                        </span>
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <div className="cellText">
                                        <span className="cellText__dark">
                                          {candidate?.candidate?.email
                                            ? candidate?.candidate?.email
                                            : '--'}
                                        </span>
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <div className="cellText">
                                        <span
                                          className={`cellText__dark ${candidate?.recruiterAmountPaid === false ? 'text--danger' : 'text--primary'}`}
                                        >
                                          {candidate?.recruiterAmountPaid ===
                                          false
                                            ? 'Unpaid'
                                            : 'Paid'}
                                        </span>
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <div className="formField">
                                        <Field
                                          type="text"
                                          className="formField__input sm"
                                          placeholder="Enter amount"
                                          disabled={
                                            candidate?.recruiterAmountPaid ===
                                            true
                                          }
                                          value={
                                            CandidateAmounts[index]
                                              ? ConvertAmountToUSCurrencyFormat(
                                                  CandidateAmounts[index]
                                                )
                                              : candidate?.recruiterAmount > 0
                                                ? ConvertAmountToUSCurrencyFormat(
                                                    candidate?.recruiterAmount.toString()
                                                  )
                                                : ''
                                          }
                                          onChange={(e) =>
                                            handleAmountChange(e, index)
                                          }
                                          name={`candidateAmount_${index}`}
                                        />
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <div className="actions flex--end">
                                        <button
                                          disabled={
                                            CandidateAmounts[index] === '' ||
                                            candidate?.recruiterAmountPaid ===
                                              true
                                          }
                                          className="btn btn__white"
                                          onClick={() =>
                                            handleApproveCandidate(
                                              candidate,
                                              index
                                            )
                                          }
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          ) : (
                            <div className="no-data floating">
                              No Results Found!
                            </div>
                          )}
                        </Table>
                      </Formik>
                    </TableContainer>

                    <div className="pagination">
                      <div className="pagination__result">{`${
                        candidatesPagination?.totalRecord >
                        candidatesPagination?.currentPage *
                          candidatesPagination?.recordsPerPage
                          ? candidatesPagination?.recordsPerPage
                          : candidatesPagination?.totalRecord
                      } of ${candidatesPagination?.totalRecord} results`}</div>

                      {candidatesPagination?.totalRecord ? (
                        <CustomPagination
                          totalPageCount={Math.ceil(
                            candidatesPagination?.totalRecord /
                              candidatesPagination.recordsPerPage
                          )}
                          page={candidatesPagination?.currentPage}
                          changePage={(event, value) =>
                            changeCandidatesPage(event, value)
                          }
                        />
                      ) : (
                        ''
                      )}
                      {candidatesPagination?.totalRecord ? (
                        <div className="pagination__actions">
                          <GoTOPage changePage={changeCandidatesPage} />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="no-data">No Results Found!</div>
      )}
    </>
  );
};

export default RecruiterCandidateListing;
