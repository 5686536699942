export const addCandidate = {
  message: {
    successfull: 'Candidate has been added successfully.',
    heading: 'Candidate Added!'
  }
};
export const updateCandidate = {
  message: {
    successfull: 'Candidate details has been updated successfully.',
    heading: 'Candidate Details Updated!'
  }
};
export const resume = {
  message: {
    allowed: 'Allowed JPG or PNG or JPEG or DOC or DOCX or PDF only.'
  }
};
export const addCompany = {
  message: {
    successfull: 'Company has been added successfully.',
    heading: 'Company Added!'
  }
};
export const updateCompany = {
  message: {
    successfull: 'Company details has been updated successfully.',
    heading: 'Company Updated!'
  }
};
export const addEmployee = {
  message: {
    successfull: 'Employee has been added successfully.',
    heading: 'Employee Added!'
  }
};
export const updateEmployee = {
  message: {
    successfull: 'Employee details has been updated successfully.',
    heading: 'Employee Updated!',
    errorMsg: 'Something went wrong!'
  }
};
export const deleteEmployee = {
  message: {
    successfull: 'Employee has been deleted successfully.',
    heading: 'Deleted successfully!',
    confirmationMsg: 'Are you sure want to deactivate this account?',
    confirmationHeading: 'Deactivate Employee!'
  }
};
export const deleteCompany = {
  message: {
    successfull: 'Company has been deleted successfully.',
    heading: 'Deleted successfully!',
    confirmationMsg: 'Are you sure want to deactivate this account?',
    confirmationHeading: 'Deactivate Company!'
  }
};
export const feedback = {
  message: {
    heading: 'Send us your feedback!',
    text: ' We would like to hear from you, please share your experience or feedback with us. We’ll be very happy improve ourself'
  }
};
export const approveCandidate = {
  message: {
    successfull: 'Candidate has been approved successfully.',
    heading: 'Candidate Approved!'
  }
};
export const addNote = {
  message: {
    successfull: 'Note has been added successfully.',
    heading: 'Note Added!'
  }
};
export const updateNote = {
  message: {
    successfull: 'Note has been updated successfully.',
    heading: 'Note Updated!'
  }
};
export const deleteNote = {
  message: {
    successfull: 'Note has been deleted successfully.',
    heading: 'Note Deleted!',
    confirmationMsg: 'Are you sure want to delete this note?',
    confirmationHeading: 'Deleting Note!'
  }
};
export const addQuestion = {
  message: {
    heading: 'Question Added!',
    successfull: 'Question has been added successfully.',
    errorMsg: 'Question cannot be blank!'
  }
};
export const updateQuestion = {
  message: {
    heading: 'Question Updated!',
    successfull: 'Question has been updated successfully.'
  }
};
export const deleteQuestion = {
  message: {
    heading: 'Question Deleted!',
    successfull: 'Question has been deleted successfully.',
    confirmationHeading: 'Deleting Question!',
    confirmationMsg: 'Are you sure want to delete this question?'
  }
};
export const updateRecruiter = {
  message: {
    heading: 'Recruiter Updated!',
    successfull: 'Recruiter has been updated successfully.'
  }
};
export const addRecruiter = {
  message: {
    heading: 'Recruiter Added!',
    successfull: 'Recruiter has been added successfully.'
  }
};
export const deleteRecruiter = {
  message: {
    heading: 'Account Deactivated!',
    successfull: 'This account has been deactivated successfully.',
    confirmationHeading: 'Deactivate Recruiter!',
    confirmationMsg: 'Are you sure want to deactivate this account?'
  }
};
export const password = {
  message: {
    heading: 'Password Changed!',
    successfull: 'Your password has been changed successfully.'
  }
};
export const forgotPassword = {
  message: {
    heading: 'Email Sent!',
    successfull: 'Email has been sent to your registered email address.'
  }
};
export const login = {
  message: {
    errorMsg: 'Please enter your email & password!'
  }
};
export const deleteEduDetails = {
  message: {
    successMsg: 'Educational Detail deleted successfully.',
    heading: 'Educational Detail Deleted!'
  }
};
export const activateRecruiter = {
  message: {
    confirmationMsg: 'Are you sure want to activate this account?',
    heading: 'Activate Recruiter!'
  }
};
export const markAspaid = {
  message: {
    confirmationMsg:
      'Are you sure you want to mark selected recruiters as paid?',
    heading: 'Mark all as paid?'
  }
};
