import httpService from '@/services/http-service';
import {
  BILLING_JOB_LIST,
  BILLING_CANDIDATE_LIST,
  BILLING_CUSTOMER_LIST,
  BILLING_ADD_AMOUNT,
  BILLING_SEND_INVOICE,
  BILLING_RECRUITER_LIST,
  BILLING_RECRUITER_JOBS_LIST,
  BILLING_RECRUITER_CANDIDATES_LIST,
  APPROVE_CANDIDATES_BILLING,
  RECRUITERS_OUTSTANDING_AMOUNT,
  PAY_RECRUITERS_OUTSTANDING_AMOUNT,
  DOWNLOAD_ACH_PAYPAL_TRANSFER_CSV
} from '@/services/end-points';

export const getBillingCustomersList = async (
  page,
  limit,
  search,
  status,
  industryType
) => {
  try {
    const response = await httpService.post(BILLING_CUSTOMER_LIST, {
      page,
      limit,
      search,
      status,
      industryType
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getBillingJobsList = async (
  page,
  limit,
  search,
  status,
  companyId
) => {
  try {
    const response = await httpService.post(BILLING_JOB_LIST, {
      page,
      limit,
      search,
      status,
      companyId
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getJobsList = async (limit, page, search, status, companyId) => {
  try {
    const response = await httpService.post(BILLING_JOB_LIST, {
      limit,
      page,
      search,
      status,
      companyId
    });
    const { data, meta } = response;
    if (meta?.status) {
      return data;
    }
  } catch (error) {
    return error;
  }
};

export const getcandidateList = async (limit, page, search, status, jobId) => {
  try {
    const response = await httpService.post(BILLING_CANDIDATE_LIST, {
      limit,
      page,
      search,
      status,
      jobId
    });
    const { data, meta } = response;
    if (meta?.status) {
      return data;
    }
  } catch (error) {
    return error;
  }
};

export const addBillingAmount = async (screeningId, amount) => {
  try {
    const response = await httpService.put(BILLING_ADD_AMOUNT, {
      screeningId,
      amount
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const sendBillingInvoice = async (screeningId) => {
  try {
    const response = await httpService.put(BILLING_SEND_INVOICE, {
      screeningId
    });
    return response;
  } catch (error) {
    return error;
  }
};

// ************** RECRUITER SERVICES *********************************
export const getBillingRecruitersList = async (limit, page, search, status) => {
  try {
    const response = await httpService.post(BILLING_RECRUITER_LIST, {
      limit,
      page,
      search,
      status
    });
    return response;
  } catch (error) {
    return error;
  }
};
export const getBillingRecruiterJobsList = async (
  limit,
  page,
  search,
  status,
  recruiterId
) => {
  try {
    const response = await httpService.post(BILLING_RECRUITER_JOBS_LIST, {
      limit,
      page,
      search,
      status,
      recruiterId
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getBillingRecruiterCandidates = async (
  limit,
  page,
  search,
  status,
  jobId,
  recruiterId
) => {
  try {
    const response = await httpService.post(BILLING_RECRUITER_CANDIDATES_LIST, {
      limit,
      page,
      search,
      status,
      jobId,
      recruiterId
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const ApproveCandidatesBilling = async (body) => {
  try {
    const response = await httpService.put(APPROVE_CANDIDATES_BILLING, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const getRecruitersOutstandingAmount = async (
  limit,
  page,
  search,
  method
) => {
  try {
    const response = await httpService.put(RECRUITERS_OUTSTANDING_AMOUNT, {
      limit,
      page,
      search,
      method
    });
    return response;
  } catch (error) {
    return error;
  }
};
export const payRecruitersOutstandingAmount = async (body) => {
  try {
    const response = await httpService.put(
      PAY_RECRUITERS_OUTSTANDING_AMOUNT,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const downloadACHPaypalTransferCsv = async (body) => {
  try {
    const response = await httpService.post(
      DOWNLOAD_ACH_PAYPAL_TRANSFER_CSV,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
};
