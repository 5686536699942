import React from 'react';

export default function EyeIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1648_9383)">
        <path
          d="M0.833008 10.0002C0.833008 10.0002 4.16634 3.3335 9.99967 3.3335C15.833 3.3335 19.1663 10.0002 19.1663 10.0002C19.1663 10.0002 15.833 16.6668 9.99967 16.6668C4.16634 16.6668 0.833008 10.0002 0.833008 10.0002Z"
          stroke="#28A0E8"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <path
          d="M9.99967 12.5002C11.3804 12.5002 12.4997 11.3809 12.4997 10.0002C12.4997 8.61945 11.3804 7.50016 9.99967 7.50016C8.61896 7.50016 7.49967 8.61945 7.49967 10.0002C7.49967 11.3809 8.61896 12.5002 9.99967 12.5002Z"
          stroke="#28A0E8"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>

      <defs>
        <clipPath id="clip0_1648_9383">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
