import httpService from '@/services/http-service';
import {
  INTERVIEW_CUSTOMER_LIST,
  INTERVIEW_RECRUITER_LIST,
  INTERVIEW_COMPANY_EMPLOYEE_LIST,
  ADD_SCHEDULED_INTERVIEW_DATA,
  GET_SCHEDULED_INTERVIEW_DATA,
  INTERVIEW_CREATE_MEETING,
  INTERVIEW_CHECK_MEETING,
  INTERVIEW_JOIN_MEETING,
  INTERVIEW_GET_RECORDINGS
} from '@/services/end-points';
import { GetAdminDetails } from '@/helper';

export const getInterviewCustomerList = async (
  page,
  limit,
  search,
  industryType
) => {
  try {
    const response = await httpService.post(INTERVIEW_CUSTOMER_LIST, {
      page,
      limit,
      search,
      industryType
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getInterviewRecruiterList = async (
  page,
  limit,
  search,
  skillSet,
  status
) => {
  try {
    const response = await httpService.post(INTERVIEW_RECRUITER_LIST, {
      page,
      limit,
      search,
      skillSet,
      status
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getCompanyEmployeeList = async (
  page,
  limit,
  search,
  companyId,
  columnName = '',
  sort = ''
) => {
  try {
    const response = await httpService.post(INTERVIEW_COMPANY_EMPLOYEE_LIST, {
      page,
      limit,
      search,
      companyId,
      columnName,
      sort
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const addScheduledInterviewData = async (postData) => {
  try {
    const response = await httpService.post(
      ADD_SCHEDULED_INTERVIEW_DATA,
      postData
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getCompanyInterviews = async (
  page,
  limit,
  search,
  tab,
  type,
  companyId,
  recruiterId
) => {
  try {
    const response = await httpService.post(GET_SCHEDULED_INTERVIEW_DATA, {
      page,
      limit,
      search,
      tab,
      type,
      companyId,
      recruiterId
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getInterviewRecordings = async (meetingId) => {
  try {
    const response = await httpService.post(INTERVIEW_GET_RECORDINGS, {
      meetingId
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const createMeeting = async () => {
  try {
    const response = await httpService.post(INTERVIEW_CREATE_MEETING, {
      accessToken: GetAdminDetails()?.nylasAccessToken,
      from: 'Admin'
    });
    return response;
  } catch (error) {
    return error;
  }
};
export const checkMeeting = async (meetingId) => {
  try {
    const response = await httpService.post(INTERVIEW_CHECK_MEETING, {
      meetingId
    });
    return response;
  } catch (error) {
    return error;
  }
};
export const joinMeeting = async (meetingId, name, email) => {
  try {
    const response = await httpService.post(INTERVIEW_JOIN_MEETING, {
      meetingId,
      name,
      email
    });
    return response;
  } catch (error) {
    return error;
  }
};
