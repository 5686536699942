import React, { useState, useEffect, useContext } from 'react';
import { getInterviewRecruiterList } from '@/services/interview-service/interview-service';
import { firstLetterCapital } from '@/helper';
import CustomPagination from '@/elements/pagination/customPagination';
import { LoaderContext } from '@/components/Loader/Loader';

const InterviewRecruiters = ({
  setAddResponsiveClass,
  activeRecruiterId,
  onRecruiterSelection,
  searchText = '',
  setTabValue
}) => {
  const setLoading = useContext(LoaderContext);
  const [recruiters, setRecruiters] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0,
    search: searchText,
    skillSet: [],
    status: ''
  });

  useEffect(() => {
    getRecruitersList(
      pagination?.currentPage,
      pagination?.recordsPerPage,
      searchText,
      pagination?.skillSet,
      pagination?.status
    );
  }, [searchText]);

  useEffect(() => {
    setTabValue('1');
  }, []);

  const getRecruitersList = async (
    currentPage = pagination?.currentPage,
    recordsPerPage = pagination?.recordsPerPage,
    search = pagination?.search,
    skillSet = pagination?.skillSet,
    status = pagination?.status
  ) => {
    setLoading(true);
    const response = await getInterviewRecruiterList(
      currentPage,
      recordsPerPage,
      search,
      skillSet,
      status
    );
    const { meta, data } = response;
    if (meta?.status) {
      setPagination((prev) => ({
        ...prev,
        totalRecord: data?.count ? data?.count : 0
      }));
      if (data?.count) {
        onRecruiterSelection(
          data?.rows[0]?.recruiterId,
          data?.rows[0]?.email,
          data?.rows[0]?.deleteStatus
        );
      }
      setRecruiters(data?.rows);
    }
    setLoading(false);
  };

  const changePage = (event, value) => {
    setPagination({ ...pagination, currentPage: value });
    getRecruitersList(
      value,
      pagination?.recordsPerPage,
      pagination?.search,
      pagination?.skillSet,
      pagination?.status
    );
  };

  return (
    <>
      <ul className="listCol__list listing__list">
        {recruiters?.map((row, index) => {
          let liClassName = 'listCol__list--item sm';
          liClassName =
            activeRecruiterId === row?.recruiterId
              ? liClassName + ' ' + 'listCol__item--selected'
              : liClassName;
          liClassName = row?.deleteStatus
            ? liClassName + ' ' + 'listCol__item--alerterror'
            : liClassName;
          return (
            <li
              key={`interviewRecruiter${row?.recruiterId}`}
              className={liClassName}
              onClick={() => {
                onRecruiterSelection(
                  row?.recruiterId,
                  row?.email,
                  row?.deleteStatus
                );
                setAddResponsiveClass(true);
                setTabValue('1');
              }}
            >
              <div className="item item--recruiter">
                <div className="initials initials__md">
                  {row?.firstName?.charAt(0).toUpperCase()}
                </div>
                <div className="item__info">
                  <h4 className="item__info--title">
                    {firstLetterCapital(row?.firstName) +
                      ' ' +
                      firstLetterCapital(row?.lastName)}
                  </h4>
                  <div className="item__info--subtitle">
                    <span>
                      {row?.education}{' '}
                      {row?.education && row?.education ? '/' : ''}{' '}
                      {row?.experience} years experience
                    </span>
                  </div>
                  <div className="item__info--subtitle">
                    {row?.phoneNumber && (
                      <p>
                        <b>P: </b> {row?.phoneNumber}
                      </p>
                    )}
                    {row?.email && (
                      <p>
                        <b>E: </b> {row?.email}
                      </p>
                    )}
                    {row?.deleteStatus && (
                      <div className="item__info--subtitle item__info--subtitle--error">
                        Account Deactivated
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </li>
          );
        })}
        {recruiters?.length ? (
          ''
        ) : (
          <div className="no-data">No Results Found!</div>
        )}
      </ul>
      <div className="pagination pagination__center">
        {recruiters.length ? (
          <CustomPagination
            page={pagination.currentPage}
            totalPageCount={Math.ceil(
              pagination?.totalRecord / pagination?.recordsPerPage
            )}
            changePage={(event, value) => changePage(event, value)}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default InterviewRecruiters;
