import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthImage from '@/assets/images/auth-img.png';
import { closeEyeSvg, KeyLockSvg, LockSvg, openEyeSvg } from '@/assets/svgs';
import { handleValidation, SaveUserDetails, SaveAdminDetails } from '@/helper';
import { Link } from 'react-router-dom';
import ErrorModal from '@/components/modals/error-modal';
import { LogInUser } from '@/services/common-service/common-service';
import * as MESSAGES from '@/utils/popupMessages';

const SignIn = () => {
  const navigate = useNavigate();
  const [fields, setFields] = useState({
    email: '',
    signinPassword: ''
  });
  const [errors, setErrors] = useState({ formIsValid: false });
  const [isPasswordView, setIsPasswordView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [signInError, setSignInError] = useState('');

  const closeErrorModal = () => {
    setOpenErrorModal(false);
  };
  const handleChange = (e) => {
    let field = fields;
    const { name, value } = e?.target;
    field[name] = value == '' ? null : value;
    const { errorsT } = handleValidation(e, errors, field);

    setErrors({
      ...errorsT
    });
    setFields({ ...field });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const { formIsValid } = handleValidation(e, errors, fields);
    if (formIsValid && fields?.email && fields?.signinPassword) {
      let signInVariables = {
        email: fields?.email ?? null,
        password: fields?.signinPassword ?? null
      };
      const response = await LogInUser(signInVariables);
      const { data, meta } = response;
      if (meta?.status) {
        SaveUserDetails({
          token: data?.session_token,
          email: fields?.email,
          sessionid: data?.session?.id,
          Oryid: data?.session?.identity?.id,
          adminId: data?.adminId,
          firstName: data?.adminDetails?.firstName,
          lastName: data?.adminDetails?.lastName
        });
        SaveAdminDetails({
          nylasAccessToken: data?.adminDetails?.nylasAccessToken
        });
        navigate('/dashboard');
      } else {
        setSignInError(response?.meta?.msg);
        setOpenErrorModal(true);
        setTimeout(() => {
          setOpenErrorModal(false);
        }, 3000);
      }
    } else {
      setSignInError(MESSAGES?.login?.message?.errorMsg);
      setOpenErrorModal(true);
    }
    setLoading(false);
  };

  return (
    <div className="container__fullScreen bg--light">
      <div className="auth__container">
        <form className="auth__form" onSubmit={(e) => handleSubmit(e)}>
          <div className="auth__form--logo">{KeyLockSvg}</div>
          <img
            src={AuthImage}
            className="auth__form--img"
            alt="Authentication"
          />
          <h4 className="auth__form--heading">Sign In</h4>
          <div className={`formField ${errors?.emailErr ? 'fieldError' : ''}`}>
            <label className="formField__label">Email address</label>
            <div className="formField__group">
              <span className="formField__icon">{LockSvg}</span>
              <input
                type="text"
                className="formField__input"
                placeholder="Enter email address"
                name="email"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <span className="formField__error">{errors['email']}</span>
          </div>

          <div
            className={`formField ${
              errors?.signinPassword ? 'fieldError' : ''
            }`}
          >
            <label className="formField__label">Password</label>
            <div className="formField__group">
              <span className="formField__icon">{LockSvg}</span>
              <input
                type={isPasswordView ? 'text' : 'password'}
                className="formField__input"
                placeholder="Enter password"
                name="signinPassword"
                onChange={(e) => handleChange(e)}
              />
              <div className="formField__icon cursor--pointer">
                {isPasswordView ? (
                  <span onClick={() => setIsPasswordView(false)}>
                    {openEyeSvg}
                  </span>
                ) : (
                  <span onClick={() => setIsPasswordView(true)}>
                    {closeEyeSvg}
                  </span>
                )}
              </div>
            </div>
            <span className="formField__error">{errors['signinPassword']}</span>
          </div>
          <button
            disabled={loading ? true : false}
            type="submit"
            className="btn btn__primary auth__form--submit"
          >
            Sign In
          </button>

          <Link to="/forgot-password" className="link link__primary">
            Forgot password?
          </Link>
        </form>
      </div>
      <ErrorModal
        openModal={openErrorModal}
        errorMessage={signInError}
        closeModal={closeErrorModal}
      />
    </div>
  );
};

export default SignIn;
