import React, { useState, useEffect } from 'react';

export const RecordingModal = ({ openModal, closeModal, recordingLink }) => {
  const [recording, setRecording] = useState(recordingLink);

  useEffect(() => {
    setRecording(recordingLink);
  }, [recordingLink]);

  return (
    <div className={`modal modalSuccessfull ${openModal ? 'modal--open' : ''}`}>
      <div className="modal__dialog">
        <div className="modal__content">
          {recording ? (
            <video
              key={recording}
              id="videoPlayer"
              width="400"
              autoPlay={true}
              height="200"
              controls
            >
              <source src={recording} type="video/mp4" />
              <source src={recording} type="video/ogg" />
            </video>
          ) : (
            <>
              <div style={{ alignContent: 'center' }}>Sorry!</div>{' '}
              <div>
                <p>This video cannot be played.</p>
              </div>
            </>
          )}

          <div className="modal__footer">
            <div className="btn__group">
              <button
                className="btn btn__white"
                onClick={() => {
                  closeModal();
                  document.getElementById('videoPlayer')?.pause();
                }}
              >
                Got it
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal__backdrop"></div>
    </div>
  );
};
