import React from 'react';

export default function ClosedJobs() {
  return (
    <svg
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="80" height="80" rx="40" fill="#28A0E8" />
      <g clipPath="url(#clip0_1172_47129)">
        <path
          d="M49.6663 60.625V23.9583C49.6663 22.7428 49.1835 21.577 48.3239 20.7174C47.4644 19.8579 46.2986 19.375 45.083 19.375H35.9163C34.7008 19.375 33.535 19.8579 32.6754 20.7174C31.8159 21.577 31.333 22.7428 31.333 23.9583V60.625M22.1663 28.5417H58.833C61.3643 28.5417 63.4163 30.5937 63.4163 33.125V56.0417C63.4163 58.573 61.3643 60.625 58.833 60.625H22.1663C19.635 60.625 17.583 58.573 17.583 56.0417V33.125C17.583 30.5937 19.635 28.5417 22.1663 28.5417Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.2354 62.1963L66.8542 27.3955"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1172_47129">
          <rect x="13" y="12.5" width="55" height="55" rx="5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
