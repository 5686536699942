import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tooltip from '@mui/material/Tooltip';
import CompanyLogo1 from '@/assets/images/company-img-1.svg';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import CompanyListing from './CompanyListing';
import JobsListing from '@/pages/Customer/JobsListing';
import SideBar from '@/components/layout/SideBar';
import AddCustomer from '@/pages/Customer/add-customer';
import JobPost from '@/pages/Customer/job-posts';
import { AddEmployee } from '@/pages/Customer/add-employee';
import CustomPagination from '@/elements/pagination/customPagination';
import GoTOPage from '@/elements/gotopage/gotopage';
import ConfirmationModal from '@/components/modals/confirmation-modal';
import { firstLetterCapital, formatPhoneNumber, getInitial } from '@/helper';
import { SuccessfullyModal } from '@/components/modals/successfully-modal';
import JobsBilling from '@/pages/Customer/billing';
import JobsBillingDetails from '@/pages/Customer/jobs-candidates';
import { LoaderContext } from '@/components/Loader/Loader';
import BackArrow from '@/assets/svgs/BackArrow';
import EditCompanyIcon from '@/assets/svgs/EditCompanyIcon';
import Search from '@/assets/svgs/Search';
import PlusIcon from '@/assets/svgs/PlusIcon';
import Deleteicon from '@/assets/svgs/Deleteicon';
import SwitchIcon from '@/assets/svgs/SwitchIcon';
import ActivateIcon from '@/assets/svgs/ActivateIcon';
import DeactivateIcon from '@/assets/svgs/DeactivateIcon';
import ScheduledInterviews from '@/pages/interviews/scheduled-interviews';
import PastInterviews from '@/pages/interviews/past-interviews';
import SortColumn from '@/elements/SortColumn';
import { getCompanyEmployeeList } from '@/services/interview-service/interview-service';
import {
  deactivateCompany,
  activateCompany,
  companyDeactivationCheck,
  activateEmployee
} from '@/services/customers-service/index';
import { employeeDelete } from '@/services/customers-service';
import * as MESSAGES from '@/utils/popupMessages';
import InformationModal from '@/components/modals/InformationModal';
import ErrorModal from '@/components/modals/error-modal';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (array && array.length) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
}

const headCells = [
  {
    id: 'id',
    label: 'S.'
  },
  {
    id: 'name',
    label: 'Name'
  },
  {
    id: 'emailAddress',
    label: 'Email Address'
  },
  {
    id: 'phoneNumber',
    label: 'Phone Number'
  },
  {
    id: 'designation',
    label: 'Role'
  },
  {
    id: 'action',
    label: 'Action'
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const Customer = () => {
  const [value, setValue] = useState('1');
  const setLoading = useContext(LoaderContext);

  const [billingCompanyId, setBillingCompanyId] = useState('');
  const [billingJobId, setBillingJobId] = useState('');
  const [UnbilledJobAmount, setUnbilledJobAmount] = useState('');
  const [AddCustomerScreen, setAddCustomerScreen] = useState(false);
  const [EditCustomerMode, setEditCustomerMode] = useState(false);
  const [AddEmployeeScreen, setAddEmployeeScreen] = useState(false);
  const [EditEmployeeMode, setEditEmployeeMode] = useState(false);
  const [EditEmployeeData, setEditEmployeeData] = useState();

  const [activeCompanyId, setActiveCompanyId] = useState(0);
  const [activeCompanyEmployees, setActiveCompanyEmployees] = useState(null);
  const [ActiveCompanyData, setActiveCompanyData] = useState();
  const [refreshCompanyListing, setRefreshCompanyListing] = useState(false);
  const [openDeleteEmpModal, setopenDeleteEmpModal] = useState(false);
  const [seletedEmplyee, setSeletedEmplyee] = useState(null);

  const [openSuccessfullModal, setOpenSuccessfullModal] = useState(false);
  const [successfullMsg, setSuccessfullMsg] = useState('');
  const [SuccessHeading, setSuccessHeading] = useState('');
  const [empSearchText, setEmpSearchText] = useState('');
  const [addResponsiveClass, setAddResponsiveClass] = useState(false);
  const [interviewType, setInterviewType] = useState('schedule');
  const [haveCustomersInSearch, setHaveCustomersInSearch] = useState(true);

  const [JobsLengthCount, setJobsLengthCount] = useState();
  const [sortByASC, setSortByASC] = useState(true);
  const [sortByColoumn, setSortByColoumn] = useState('');
  const [openDeleteCmpModal, setOpenDeleteCmpModal] = useState(false);
  const [OpenInfoModal, setOpenInfoModal] = useState(false);
  const [InfoModalMsg, setInfoModalMsg] = useState('');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMsgs, setErrorMsgs] = useState('');
  const [activateConModal, setActivateConModal] = useState(false);
  const [empIdForActivate, setEmpIdForActivate] = useState(null);

  const closeSucessfullModal = () => {
    setSuccessHeading('');
    setSuccessfullMsg('');
    setOpenSuccessfullModal(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [employeeList, setEmployeeList] = useState([]);

  const [empPagination, setEmpPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0,
    search: ''
  });

  const valuesArray = [
    ActiveCompanyData?.address,
    ActiveCompanyData?.city,
    ActiveCompanyData?.stateName,
    ActiveCompanyData?.zipcode,
    ActiveCompanyData?.country
  ].filter((value) => value);
  const formattedAddress = valuesArray.join(', ');

  const searchInEmployeeListing = (e) => {
    setEmpSearchText(e?.target?.value);
    getEmployeeList(
      activeCompanyId,
      empPagination?.recordsPerPage,
      empPagination?.currentPage,
      e?.target?.value
    );
  };

  const getEmployeeList = async (
    companyId,
    recordsPerPage = empPagination?.recordsPerPage,
    currentPage = empPagination?.currentPage,
    search = empPagination?.search,
    columnName = sortByColoumn,
    sort = sortByASC ? 'ASC' : 'DESC'
  ) => {
    try {
      setLoading(true);
      const response = await getCompanyEmployeeList(
        currentPage,
        recordsPerPage,
        search,
        companyId,
        columnName,
        sort
      );
      const { data, meta } = response;
      if (meta?.status) {
        setEmployeeList(data?.rows);
        setEmpPagination((prev) => ({
          ...prev,
          totalRecord: data?.count ? data?.count : 0
        }));
      }
    } catch (error) {
      console.log('axios error --->', error);
    }
    setLoading(false);
  };

  const visibleRows = React.useMemo(() => {
    setEmpPagination({ ...empPagination, search: empSearchText });
    const sortedList = stableSort(employeeList, getComparator(order, orderBy));
    const slicedList = sortedList?.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    return slicedList;
  }, [order, orderBy, page, rowsPerPage, employeeList]);

  const onCompanySelection = (companyId, data, fromListing) => {
    fromListing ? setAddResponsiveClass(false) : setAddResponsiveClass(true);
    getEmployeeList(companyId);
    setAddCustomerScreen(false);
    setAddEmployeeScreen(false);
    setActiveCompanyId(companyId);
    setActiveCompanyData(data);
    const empCount = data?.employees.filter(
      (item) => item.status === 'active'
    )?.length;
    setActiveCompanyEmployees(empCount);
    setValue(value);
  };

  const backToCustomerListing = (activeCompanyKey, companyData = {}) => {
    setAddCustomerScreen(false);
    setEditCustomerMode(false);
    setAddEmployeeScreen(false);
    setRefreshCompanyListing(!refreshCompanyListing);
    setActiveCompanyId(activeCompanyKey);
    setActiveCompanyData(companyData);
  };

  const backToEmployeeListing = () => {
    setAddEmployeeScreen(false);
    setAddCustomerScreen(false);
    setEditEmployeeMode(false);
    setRefreshCompanyListing(!refreshCompanyListing);
  };

  const handleEditCompany = () => {
    setAddCustomerScreen(true);
    setEditCustomerMode(true);
  };
  const handleEditEmployee = (row) => {
    setAddEmployeeScreen(true);
    setEditEmployeeMode(true);
    setEditEmployeeData(row);
  };

  const deleteEmployee = async () => {
    try {
      const response = await employeeDelete({
        employeeId: seletedEmplyee
      });
      const { meta } = response;
      if (meta?.status) {
        setSuccessfullMsg(meta?.msg);
        setSuccessHeading('Account Deactivated!');
        setOpenSuccessfullModal(true);
        setRefreshCompanyListing(!refreshCompanyListing);
        getEmployeeList(
          activeCompanyId,
          empPagination?.recordsPerPage,
          empPagination?.currentPage,
          empPagination?.search
        );
      } else {
        setErrorMsgs(meta?.msg);
        setOpenErrorModal(true);
      }
      setopenDeleteEmpModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const changePage = (event, value) => {
    setEmpPagination({ ...empPagination, currentPage: value });
    getEmployeeList(
      activeCompanyId,
      empPagination?.recordsPerPage,
      value,
      empPagination?.search
    );
  };

  const deSelectActiveCompanyId = () => {
    setActiveCompanyId(null);
    setActiveCompanyData(null);
  };

  const onBillingRowClick = (companyId, JobId, TotalUnbilledJobsAmount) => {
    setBillingCompanyId(companyId);
    setBillingJobId(JobId);
    setUnbilledJobAmount(TotalUnbilledJobsAmount);
    setAddResponsiveClass(true);
  };

  const changeInterviewType = (interviewType) => {
    setInterviewType(interviewType);
  };
  const [jobId, setJobId] = useState('');

  const selectNewJob = (newJobId, newJobTitle) => {
    setBillingJobId(newJobId);
    setJobId(newJobId);
  };

  const getSortByData = (columnName) => {
    getEmployeeList(
      activeCompanyId,
      empPagination?.recordsPerPage,
      empPagination?.currentPage,
      empPagination?.search,
      columnName,
      sortByASC ? 'ASC' : 'DESC'
    );
    setSortByColoumn(columnName);
    setSortByASC(!sortByASC);
  };

  const checkForDeactivateCompany = async (companyId) => {
    setLoading(true);
    const response = await companyDeactivationCheck(companyId);
    const { data, meta } = response;
    if (meta?.status) {
      setOpenDeleteCmpModal(true);
    } else {
      setInfoModalMsg(meta?.msg);
      setOpenInfoModal(true);
      setLoading(false);
    }
  };

  const goForDeactivateCompany = async () => {
    setOpenDeleteCmpModal(false);
    setLoading(true);
    const response = await deactivateCompany({ companyId: activeCompanyId });
    const { data, meta } = response;
    if (meta?.status) {
      setSuccessHeading('Account Deactivated!');
      setSuccessfullMsg(meta?.msg);
      setOpenSuccessfullModal(true);
      setActiveCompanyId(0);
      setRefreshCompanyListing(!refreshCompanyListing);
    } else {
      setErrorMsgs(meta?.msg);
      setOpenErrorModal(true);
    }
    setLoading(false);
  };

  const confirmReactivateRecruiter = () => {
    setActivateConModal(true);
  };

  const checkForActivateCompany = async (companyId = ActiveCompanyData?.id) => {
    setLoading(true);
    const response = await activateCompany({
      companyId
    });
    const { data, meta } = response;
    if (meta?.status) {
      setActiveCompanyId(0);
      setRefreshCompanyListing(!refreshCompanyListing);
      setSuccessHeading('Account Activated!');
      setSuccessfullMsg(meta?.msg);
      setLoading(false);
      getEmployeeList(companyId);
      setOpenSuccessfullModal(true);
    }
    setActivateConModal(false);
  };

  const closeCompDeleteModal = () => {
    setOpenDeleteCmpModal(false);
    setLoading(false);
  };

  const confirmActivateEmployee = (empId) => {
    setEmpIdForActivate(empId);
    setActivateConModal(true);
  };

  const callActivateEmployee = async () => {
    setLoading(true);
    const response = await activateEmployee({
      employeeId: empIdForActivate
    });
    const { data, meta } = response;
    if (meta?.status) {
      setSuccessHeading('Account Activated!');
      setSuccessfullMsg(meta?.msg);
      getEmployeeList(activeCompanyId);
      setOpenSuccessfullModal(true);
    } else {
      setOpenErrorModal(true);
      setErrorMsgs(meta?.msg);
    }
    setLoading(false);
    setActivateConModal(false);
    setEmpIdForActivate(null);
  };

  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid">
          {billingCompanyId ? (
            <JobsListing
              activeCompanyId={billingCompanyId}
              ActiveCompanyData={ActiveCompanyData}
              activeJobId={billingJobId}
              selectNewJob={selectNewJob}
              setAddResponsiveClass={setAddResponsiveClass}
              onCompanySelection={onCompanySelection}
              setBillingCompanyId={setBillingCompanyId}
              setJobsLengthCount={setJobsLengthCount}
            />
          ) : (
            <CompanyListing
              EditCustomerMode={EditCustomerMode}
              setValue={setValue}
              activeCompanyId={activeCompanyId}
              setAddResponsiveClass={setAddResponsiveClass}
              onCompanySelection={onCompanySelection}
              setAddCustomerScreen={setAddCustomerScreen}
              setEditCustomerMode={setEditCustomerMode}
              ActiveCompanyData={ActiveCompanyData}
              refreshCompanyListing={refreshCompanyListing}
              deSelectActiveCompanyId={deSelectActiveCompanyId}
              checkHaveCustomersInSearch={(value) =>
                setHaveCustomersInSearch(value)
              }
              setActiveCompanyId={setActiveCompanyId}
            />
          )}
          {AddCustomerScreen ? (
            <AddCustomer
              setAddResponsiveClass={setAddResponsiveClass}
              addResponsiveClass={addResponsiveClass}
              backToCustomerListing={backToCustomerListing}
              EditCustomerMode={EditCustomerMode}
              ActiveCompanyData={ActiveCompanyData}
              activeCompanyId={activeCompanyId}
            />
          ) : AddEmployeeScreen ? (
            <AddEmployee
              setAddResponsiveClass={setAddResponsiveClass}
              addResponsiveClass={addResponsiveClass}
              backToEmployeeListing={backToEmployeeListing}
              activeCompanyId={activeCompanyId}
              ActiveCompanyData={ActiveCompanyData}
              getEmployeeList={getEmployeeList}
              EditEmployeeData={EditEmployeeData}
              EditEmployeeMode={EditEmployeeMode}
            />
          ) : (
            <div
              className={`layoutGrid__col layoutGrid--responsive  ${
                addResponsiveClass ? 'layoutGrid--show' : ''
              }`}
            >
              {haveCustomersInSearch && activeCompanyId ? (
                <div className="dataCol">
                  <div className="dataCol__head">
                    <button
                      className="btn btn__transparent btn__back border_0 p_0"
                      onClick={() => setAddResponsiveClass(false)}
                    >
                      <BackArrow />
                    </button>
                    <div className="item">
                      {ActiveCompanyData?.companyLogo ? (
                        <div className="item__logo">
                          <img
                            src={ActiveCompanyData?.companyLogo ?? CompanyLogo1}
                            alt="Logo"
                          />
                        </div>
                      ) : (
                        <div className="initials initials__lg">
                          {ActiveCompanyData?.companyName
                            ?.charAt(0)
                            .toUpperCase()}
                        </div>
                      )}
                      <div className="item__info">
                        <h4 className="item__info--title">
                          {firstLetterCapital(ActiveCompanyData?.companyName)}
                        </h4>
                        <div className="item__info--subtitle">
                          {' '}
                          {ActiveCompanyData?.industryType?.join(' , ')}
                        </div>
                        <p className="item__info--desc">{formattedAddress}</p>
                      </div>
                    </div>

                    <div className="dataCol__head--actions ml_auto">
                      <Tooltip title="Edit" arrow placement="top">
                        <span>
                          <button
                            className="btn btn__white p_0"
                            onClick={handleEditCompany}
                            disabled={ActiveCompanyData?.status === 'delete'}
                          >
                            <EditCompanyIcon />
                          </button>
                        </span>
                      </Tooltip>
                      {ActiveCompanyData?.status === 'delete' ? (
                        <Tooltip title="Enable Account" arrow placement="top">
                          <button
                            onClick={() => confirmReactivateRecruiter()}
                            className="btn btn__successDark p_0"
                          >
                            <ActivateIcon />
                          </button>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Disable Account" arrow placement="top">
                          <button
                            onClick={() =>
                              checkForDeactivateCompany(ActiveCompanyData?.id)
                            }
                            className="btn btn__danger p_0"
                          >
                            <DeactivateIcon />
                          </button>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                  <div className="dataCol__body">
                    <Box className="tabContainer">
                      <TabContext value={value}>
                        <Box className="tabContainer__head">
                          <TabList
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                          >
                            <Tab label="Employees" value="1" />
                            <Tab label="Jobs Posted" value="2" />
                            <Tab label="Billing" value="3" />
                            <Tab label="Interviews" value="4" />
                          </TabList>
                        </Box>

                        <TabPanel value="1" className="tabContainer__item">
                          <Box sx={{ width: '100%' }}>
                            <Paper sx={{ width: '100%' }}>
                              <div className="tabContainer__item--head">
                                <div className="listCol__head--actions">
                                  <div className="formField formFieldSearch flex-100">
                                    <div className="formField__group sm">
                                      <span className="formField__icon">
                                        <Search />
                                      </span>
                                      <input
                                        type="text"
                                        className="formField__input"
                                        placeholder="Search an employee"
                                        value={empSearchText}
                                        onChange={(e) =>
                                          searchInEmployeeListing(e)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <button
                                  className="btn btn__white"
                                  onClick={() => setAddEmployeeScreen(true)}
                                  disabled={
                                    ActiveCompanyData?.status === 'delete'
                                  }
                                >
                                  <PlusIcon />
                                  Add Employee
                                </button>
                              </div>
                              <TableContainer className="tableContainer">
                                {activeCompanyId &&
                                visibleRows &&
                                visibleRows.length ? (
                                  <Table className="table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>S.</TableCell>
                                        <TableCell
                                          onClick={() =>
                                            getSortByData('firstName')
                                          }
                                        >
                                          <SortColumn
                                            thName={'Name'}
                                            sortByASC={sortByASC}
                                          />
                                        </TableCell>
                                        <TableCell
                                          onClick={() => getSortByData('email')}
                                        >
                                          <SortColumn
                                            thName={'Email Address'}
                                            sortByASC={sortByASC}
                                          />
                                        </TableCell>
                                        <TableCell>Phone Number</TableCell>
                                        <TableCell
                                          onClick={() => getSortByData('role')}
                                        >
                                          <SortColumn
                                            thName={'Role'}
                                            sortByASC={sortByASC}
                                          />
                                        </TableCell>
                                        <TableCell>Action</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {visibleRows &&
                                        visibleRows.length &&
                                        visibleRows.map((row, index) => {
                                          let tableRowClassName =
                                            ActiveCompanyData?.status ===
                                              'delete' ||
                                            row?.status === 'delete'
                                              ? 'tableRowError'
                                              : '';
                                          return (
                                            <TableRow
                                              className={tableRowClassName}
                                              hover
                                              key={index + 1}
                                              sx={{ cursor: 'pointer' }}
                                            >
                                              <TableCell>
                                                {empPagination?.currentPage > 1
                                                  ? (empPagination?.currentPage -
                                                      1) *
                                                      20 +
                                                    (index + 1)
                                                  : index + 1}
                                              </TableCell>
                                              <TableCell>
                                                <div className="profile">
                                                  <div className="profile__initials">
                                                    {getInitial(row?.firstName)}
                                                  </div>
                                                  <span className="profile__name">
                                                    {firstLetterCapital(
                                                      row.firstName
                                                    ) ?? '--'}{' '}
                                                    {row?.lastName
                                                      ? firstLetterCapital(
                                                          row?.lastName
                                                        )
                                                      : ''}
                                                  </span>
                                                </div>
                                              </TableCell>
                                              <TableCell>{row.email}</TableCell>
                                              <TableCell>
                                                {row?.phoneNumber
                                                  ? row?.countryCode +
                                                    ' ' +
                                                    formatPhoneNumber(
                                                      row?.phoneNumber
                                                    )
                                                  : '--'}
                                              </TableCell>
                                              <TableCell>{row.role}</TableCell>
                                              <TableCell>
                                                <div className="actions">
                                                  <Tooltip
                                                    title="Edit"
                                                    arrow
                                                    placement="top"
                                                  >
                                                    <span>
                                                      <button
                                                        disabled={
                                                          ActiveCompanyData?.status ===
                                                            'delete' ||
                                                          row?.status ===
                                                            'delete'
                                                        }
                                                        className="btn p_0 border_0"
                                                        onClick={() =>
                                                          handleEditEmployee(
                                                            row
                                                          )
                                                        }
                                                      >
                                                        <EditCompanyIcon />
                                                      </button>
                                                    </span>
                                                  </Tooltip>
                                                  {row?.status === 'delete' ? (
                                                    <Tooltip
                                                      title="Enable User"
                                                      arrow
                                                      placement="top"
                                                    >
                                                      <span>
                                                        <button
                                                          onClick={() =>
                                                            confirmActivateEmployee(
                                                              row?.employeeId
                                                            )
                                                          }
                                                          className="btn p_0 border_0"
                                                        >
                                                          <ActivateIcon />
                                                        </button>
                                                      </span>
                                                    </Tooltip>
                                                  ) : (
                                                    <Tooltip
                                                      title="Disable User"
                                                      arrow
                                                      placement="top"
                                                    >
                                                      <span>
                                                        <button
                                                          disabled={
                                                            ActiveCompanyData?.status ===
                                                            'delete'
                                                          }
                                                          className="btn p_0 border_0"
                                                          onClick={() => {
                                                            setopenDeleteEmpModal(
                                                              true
                                                            );
                                                            setSeletedEmplyee(
                                                              row?.employeeId
                                                            );
                                                          }}
                                                        >
                                                          <DeactivateIcon />
                                                        </button>
                                                      </span>
                                                    </Tooltip>
                                                  )}
                                                </div>
                                              </TableCell>
                                            </TableRow>
                                          );
                                        })}
                                    </TableBody>
                                  </Table>
                                ) : (
                                  <div className="no-data">
                                    No Results Found!
                                  </div>
                                )}
                              </TableContainer>
                              <div className="pagination">
                                <div className="pagination__result">{`${
                                  empPagination?.totalRecord >
                                  empPagination?.currentPage *
                                    empPagination?.recordsPerPage
                                    ? empPagination?.recordsPerPage
                                    : empPagination?.totalRecord
                                } of ${
                                  empPagination?.totalRecord
                                } results`}</div>

                                {empPagination?.totalRecord ? (
                                  <CustomPagination
                                    totalPageCount={Math.ceil(
                                      empPagination?.totalRecord /
                                        empPagination.recordsPerPage
                                    )}
                                    changePage={(event, value) =>
                                      changePage(event, value)
                                    }
                                  />
                                ) : (
                                  ''
                                )}
                                {empPagination?.totalRecord ? (
                                  <div className="pagination__actions">
                                    <GoTOPage changePage={changePage} />
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </Paper>
                          </Box>
                        </TabPanel>

                        <TabPanel value="2" className="tabContainer__item">
                          <JobPost activeCompanyId={activeCompanyId} />
                        </TabPanel>

                        <TabPanel
                          value="3"
                          className="tabContainer__item bpContainer"
                        >
                          {billingCompanyId ? (
                            <JobsBillingDetails
                              activeJobId={billingJobId}
                              ActiveCompanyData={ActiveCompanyData}
                              UnbilledJobAmount={UnbilledJobAmount}
                              haveCustomersInSearch={haveCustomersInSearch}
                              JobsLengthCount={JobsLengthCount}
                            />
                          ) : (
                            <JobsBilling
                              activeCompanyId={activeCompanyId}
                              ActiveCompanyData={ActiveCompanyData}
                              onRowClick={onBillingRowClick}
                              haveCustomersInSearch={haveCustomersInSearch}
                            />
                          )}
                        </TabPanel>

                        <TabPanel value="4" className="tabContainer__item">
                          {interviewType == 'schedule' ? (
                            <ScheduledInterviews
                              activeCompanyId={activeCompanyId}
                              changeInterviewType={changeInterviewType}
                              activeCompanyEmployees={activeCompanyEmployees}
                              companyDeleteStatus={ActiveCompanyData?.status}
                            />
                          ) : (
                            <PastInterviews
                              activeCompanyId={activeCompanyId}
                              changeInterviewType={changeInterviewType}
                            />
                          )}
                        </TabPanel>
                      </TabContext>
                    </Box>
                  </div>
                </div>
              ) : (
                <div className="no-data">No Results Found!</div>
              )}
            </div>
          )}

          {/* Specific Data Column End */}
        </div>
        <ConfirmationModal
          openModal={openDeleteEmpModal}
          closeModal={() => setopenDeleteEmpModal(false)}
          onSubmit={deleteEmployee}
          confirmationMessage={
            MESSAGES?.deleteEmployee?.message?.confirmationMsg
          }
          heading={MESSAGES?.deleteEmployee?.message?.confirmationHeading}
        />
        <ConfirmationModal
          openModal={openDeleteCmpModal}
          closeModal={closeCompDeleteModal}
          onSubmit={goForDeactivateCompany}
          confirmationMessage={
            MESSAGES?.deleteCompany?.message?.confirmationMsg
          }
          heading={MESSAGES?.deleteCompany?.message?.confirmationHeading}
        />
      </div>
      <SuccessfullyModal
        openModal={openSuccessfullModal}
        closeModal={closeSucessfullModal}
        Heading={SuccessHeading}
        sucessfullMsg={successfullMsg}
      />
      <ErrorModal
        openModal={openErrorModal}
        errorMessage={errorMsgs}
        closeModal={() => setOpenErrorModal(false)}
      />
      {OpenInfoModal && (
        <InformationModal
          closeModal={() => setOpenInfoModal(false)}
          info={InfoModalMsg}
          heading={'Deactivate Company!'}
        />
      )}
      {activateConModal && (
        <ConfirmationModal
          openModal={activateConModal}
          closeModal={() => setActivateConModal(false)}
          onSubmit={
            empIdForActivate ? callActivateEmployee : checkForActivateCompany
          }
          confirmationMessage={
            MESSAGES?.activateRecruiter?.message?.confirmationMsg
          }
          heading={
            empIdForActivate ? 'Activate Employee!' : 'Activate Company!'
          }
        />
      )}
    </>
  );
};

export default Customer;
