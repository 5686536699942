import React from 'react';

export default function ActiveJobs() {
  return (
    <svg
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="80" height="80" rx="40" fill="#6C737F" />
      <path
        d="M48.8332 58.75V25.4167C48.8332 24.3116 48.3942 23.2518 47.6128 22.4704C46.8314 21.689 45.7716 21.25 44.6665 21.25H36.3332C35.2281 21.25 34.1683 21.689 33.3869 22.4704C32.6055 23.2518 32.1665 24.3116 32.1665 25.4167V58.75M23.8332 29.5833H57.1665C59.4677 29.5833 61.3332 31.4488 61.3332 33.75V54.5833C61.3332 56.8845 59.4677 58.75 57.1665 58.75H23.8332C21.532 58.75 19.6665 56.8845 19.6665 54.5833V33.75C19.6665 31.4488 21.532 29.5833 23.8332 29.5833Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
