import httpService from '@/services/http-service';
import {
  JOBS_LIST,
  JOBS_DETAILS,
  RECRUITER_DETAILS,
  GET_JOB_NOTES,
  ADD_JOB_NOTES,
  EDIT_JOB_NOTES,
  DELETE_JOB_NOTES,
  GET_JOB_QUESTIONNAIRES,
  ADD_JOB_QUESTIONNAIRES
} from '@/services/end-points';

export const getJobsList = async (
  page,
  limit,
  search,
  columnName,
  sort,
  status,
  companyId,
  tagArray
) => {
  try {
    const response = await httpService.post(JOBS_LIST, {
      page,
      limit,
      search,
      columnName,
      sort,
      status,
      companyId,
      tagArray
    });
    const { meta } = response;
    if (meta?.status) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const getJobDetails = async (jobId) => {
  try {
    const response = await httpService.get(`${JOBS_DETAILS}${jobId}`);
    const { meta } = response;
    if (meta?.status) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const getRecruiterDetails = async (recruiterId) => {
  try {
    const response = await httpService.get(
      `${RECRUITER_DETAILS}${recruiterId}`
    );
    const { meta } = response;
    if (meta?.status) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const getJobsNotes = async (jobId) => {
  try {
    const response = await httpService.get(
      `${GET_JOB_NOTES}${jobId}?id=Laila Team`
    );
    const { meta } = response;
    if (meta?.status) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const addJobsNotes = async (
  title,
  description,
  jobId,
  addedBy,
  from,
  shareWith
) => {
  try {
    const response = await httpService.post(ADD_JOB_NOTES, {
      title,
      description,
      jobId,
      addedBy,
      from,
      shareWith
    });
    const { meta } = response;
    if (meta?.status) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const editJobsNotes = async (
  notesId,
  title,
  description,
  jobId,
  from,
  updatedById,
  shareWith
) => {
  try {
    const response = await httpService.put(EDIT_JOB_NOTES, {
      notesId,
      title,
      description,
      jobId,
      from,
      updatedById,
      shareWith
    });
    const { meta } = response;
    if (meta?.status) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const deleteJobsNotes = async (notesId, jobId) => {
  try {
    const response = await httpService.put(DELETE_JOB_NOTES, {
      notesId,
      jobId
    });
    const { meta } = response;
    if (meta?.status) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const getJobsQuestionaire = async (jobId) => {
  try {
    const response = await httpService.get(`${GET_JOB_QUESTIONNAIRES}${jobId}`);
    const { meta } = response;
    if (meta?.status) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const addJobsQuestionaire = async (jobId, questionireArray) => {
  try {
    const response = await httpService.put(ADD_JOB_QUESTIONNAIRES, {
      jobId,
      questionireArray
    });
    const { meta } = response;
    if (meta?.status) {
      return response;
    }
  } catch (error) {
    return error;
  }
};
