import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef
} from 'react';
import { LoaderContext } from '@/components/Loader/Loader';
import SideBar from '@/components/layout/SideBar';
import ArrowDown from '@/elements/ArrowDown';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CustomPagination from '@/elements/pagination/customPagination';
import { CandidateBilling } from './candidate-billing';
import { getJobsList } from '@/services/billing-service/billing-service';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { firstLetterCapital } from '@/helper';
import BackArrow from '@/assets/svgs/BackArrow';
import Search from '@/assets/svgs/Search';
import Filter from '@/assets/svgs/Filter';
import SearchCross from '@/assets/svgs/SearchCross';
import Dot from '@/assets/svgs/Dot';

export const JobsBilling = () => {
  const statusRef = useRef();
  const setLoading = useContext(LoaderContext);
  const location = useLocation();
  const navigate = useNavigate();

  const [filteranchorEl, setFilterAnchorEl] = useState(null);
  const [searchanchorEl, setSeachAnchorEl] = useState(null);
  const searchOpen = Boolean(searchanchorEl);
  const filterOpen = Boolean(filteranchorEl);
  const searchPopoverid = searchOpen ? 'simple-popover' : '';
  const filterPopoverid = filterOpen ? 'simple-popover' : '';

  const [JobsListing, setJobsListing] = useState([]);
  const [addResponsiveClass, setAddResponsiveClass] = useState(
    location?.state?.addResponsiveClass ?? false
  );
  const [SelectedJobId, setSelectedJobId] = useState(location?.state?.jobid);
  const [FilterApplied, setFilterApplied] = useState(false);
  const [JobTitle, setJobTitle] = useState(location?.state?.jobTitle ?? '');
  const [ActiveCompanyData, setActiveCompanyData] = useState(
    location?.state?.activeCompanyData
  );
  const [SelectedStatus, setSelectedStatus] = useState([]);
  const BillingStatus = ['Billed', 'Unbilled'];

  const [pagination, setPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0,
    search: '',
    status: []
  });

  useEffect(() => {
    getJobsListing();
  }, []);

  const changePage = (event, value) => {
    setPagination({ ...pagination, currentPage: value });
  };

  const handleSeachClick = (event) => {
    setSeachAnchorEl(event.currentTarget);
  };

  const handleSearchClose = () => {
    setPagination({ ...pagination, search: '', currentPage: 1 });
    statusRef.current.value = '';
    setSeachAnchorEl(null);
    getJobsListing(
      pagination?.recordsPerPage,
      1,
      pagination.search,
      pagination?.status,
      ActiveCompanyData?.id
    );
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleStatusFilterChange = (event) => {
    setSelectedStatus(event?.target?.value);
  };

  const getJobsListing = async (
    recordsPerPage = pagination?.recordsPerPage,
    currentPage = pagination?.currentPage,
    search = pagination?.search,
    status = pagination?.status,
    companyId = ActiveCompanyData?.id
  ) => {
    setLoading(true);
    const data = await getJobsList(
      recordsPerPage,
      currentPage,
      search,
      status,
      companyId
    );
    setPagination((prev) => ({
      ...prev,
      totalRecord: data?.count ? data?.count : 0
    }));
    setJobsListing(data?.rows);
    if (data?.count) {
      let row = data?.row?.map((item) => {
        item = item?.jobId === SelectedJobId;
        onJobSelect(item, true);
      });
    }
    setLoading(false);
  };

  const onJobSelect = (job, responsiveClass) => {
    responsiveClass
      ? setAddResponsiveClass(false)
      : setAddResponsiveClass(true);
    setSelectedJobId(job?.jobId);
    setJobTitle(job?.title);
  };

  const searchjobListing = useCallback(
    _.debounce(
      (searchText) =>
        getJobsListing(
          pagination?.recordsPerPage,
          pagination?.currentPage,
          searchText,
          pagination?.status,
          ActiveCompanyData?.id
        ),
      500
    ),
    []
  );

  const closeFilter = (e) => {
    setSelectedStatus([]);
    getJobsListing(
      pagination?.recordsPerPage,
      pagination?.currentPage,
      pagination?.search,
      pagination?.status,
      ActiveCompanyData?.id
    );
    handleFilterClose();
  };

  const applyFilter = (e) => {
    getJobsListing(
      pagination?.recordsPerPage,
      pagination?.currentPage,
      pagination?.search,
      [SelectedStatus],
      ActiveCompanyData?.id
    );
    handleFilterClose();
  };

  const backToCustomerScreen = () => {
    navigate('/billing-payments');
  };

  const getBilledStatus = (screenings) => {
    let approvedArray = screenings.filter((t) => t.status === 'approved');
    let checkStatus = approvedArray.filter((t) => t.billStatus === false).length
      ? 'Unbilled'
      : 'Billed';
    return checkStatus;
  };

  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid">
          <div className="layoutGrid__col">
            <div
              className={`listCol ${FilterApplied ? 'filterApplied' : ''} ${
                filteranchorEl
                  ? 'listCol__filter'
                  : searchanchorEl
                    ? 'listCol__search'
                    : ''
              }`}
            >
              <div className="listCol__head listing__head">
                <h2 className="listCol__head--title">
                  <button
                    className="btn btn__transparent btn__back border_0 p_0"
                    onClick={backToCustomerScreen}
                  >
                    <BackArrow />
                  </button>
                  {ActiveCompanyData?.companyName}
                </h2>
                <div className="listCol__head--actions">
                  <div className="formField">
                    <button
                      className="btn btn__white p_0"
                      aria-describedby={searchPopoverid}
                      onClick={handleSeachClick}
                    >
                      <Search />
                    </button>
                  </div>

                  <div>
                    <button
                      className="btn btn__white p_0"
                      aria-describedby={filterPopoverid}
                      onClick={handleFilterClick}
                    >
                      <Filter />
                    </button>
                  </div>
                </div>

                <div className="searchContainer">
                  <div className="formField">
                    <div className="formField__group sm">
                      <span className="formField__icon">
                        <Search />
                      </span>
                      <input
                        type="text"
                        ref={statusRef}
                        className="formField__input"
                        placeholder="Search for a job"
                        onChange={(e) => searchjobListing(e?.target?.value)}
                      />
                    </div>
                  </div>

                  <button
                    className="btn btn__transparent border_0 p_0"
                    onClick={handleSearchClose}
                  >
                    <SearchCross />
                  </button>
                </div>
              </div>

              <div className="filter__head">
                <h2 className="listCol__head--title">Filter</h2>
                <button
                  className="btn btn__transparent border_0 p_0"
                  onClick={closeFilter}
                >
                  <SearchCross />
                </button>
              </div>

              <div className="filterContainer">
                <ul className="listCol__list filter__list">
                  <li>
                    <div className="formRow">
                      <div className="formCol">
                        <div className="formField">
                          <label className="formField__label">Status</label>
                          <FormControl
                            fullWidth
                            size="small"
                            className="formField__select"
                          >
                            <Select
                              onChange={(e) => handleStatusFilterChange(e)}
                              IconComponent={() => <ArrowDown />}
                              name="Status"
                              displayEmpty
                              value={
                                SelectedStatus?.length ? SelectedStatus : ''
                              }
                              renderValue={(selected) => {
                                if (!selected) {
                                  return (
                                    <span style={{ opacity: '0.8' }}>
                                      Select status
                                    </span>
                                  );
                                }

                                return selected;
                              }}
                            >
                              {BillingStatus?.map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item}>
                                    {item}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>

                <div className="filter__footer">
                  <div className="btn__group flex--between">
                    <button
                      className="btn btn__transparent p_0"
                      onClick={closeFilter}
                    >
                      Clear
                    </button>
                    <button className="btn btn__primary" onClick={applyFilter}>
                      Apply
                    </button>
                  </div>
                </div>
              </div>

              {SelectedStatus.length ? (
                <div className="listColTitle filterClear">
                  <p>Filters applied ({SelectedStatus.length ? '1' : '0'})</p>
                  <button className="filterClear__btn" onClick={closeFilter}>
                    Clear filter
                  </button>
                </div>
              ) : (
                ''
              )}
              {/* JOBS LISTING */}
              {JobsListing?.length ? (
                <ul className="listCol__list listing__list">
                  {JobsListing?.map((job, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          SelectedJobId == job?.jobId
                            ? 'listCol__list--item listCol__item--selected'
                            : 'listCol__list--item '
                        }
                        onClick={() => onJobSelect(job)}
                      >
                        <div className="item item--recruiter item--jobs">
                          <div className="item__info">
                            <h4 className="item__info--title">
                              {job?.title ? job?.title : ''}
                            </h4>
                            <div className="item__info--subtitle">
                              <p>
                                <span>Department:</span>{' '}
                                {job?.department ? job?.department : ''}
                              </p>
                              <span className="status">
                                <Dot />
                                Status:{' '}
                                <span
                                  className={
                                    getBilledStatus(job?.screenings) ===
                                    'Unbilled'
                                      ? 'text--danger'
                                      : 'text--primary'
                                  }
                                >
                                  {getBilledStatus(job?.screenings)}
                                </span>
                              </span>
                            </div>
                            <div className="item__info--subtitle">
                              {job?.recruiter ? (
                                <p>
                                  <span>Recruiter:</span>{' '}
                                  {firstLetterCapital(
                                    job?.recruiter?.firstName
                                  ) +
                                    ' ' +
                                    firstLetterCapital(
                                      job?.recruiter?.lastName
                                    )}
                                </p>
                              ) : (
                                ''
                              )}
                              <span>
                                <Dot />
                                {job?.location}
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <div className="no-data">No Results Found!</div>
              )}
              <div className="pagination pagination__center">
                {pagination?.totalRecord ? (
                  <CustomPagination
                    totalPageCount={Math.ceil(
                      pagination?.totalRecord / pagination.recordsPerPage
                    )}
                    changePage={(event, value) => changePage(event, value)}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <CandidateBilling
            SelectedJobId={SelectedJobId}
            JobTitle={JobTitle}
            addResponsiveClass={addResponsiveClass}
            setAddResponsiveClass={setAddResponsiveClass}
            ActiveCompanyData={ActiveCompanyData}
            JobsListing={JobsListing}
          />
        </div>
      </div>
    </>
  );
};
