const currentYear = new Date().getFullYear();
const maxYear = currentYear + 10;
const minYear = 1930;

// *************SCHEMA CONSTANTS**********************************
export const GRADUATION_YEAR = {
  label: 'Graduation Year',
  validation: {
    valid: 'Graduation year should be greater than 1929.',
    maxValid: `Graduation year should be less than ${maxYear}.`,
    maxminValid: `Please enter between ${minYear} - ${maxYear}.`
  }
};
export const NAME = {
  label: 'Name',
  validation: {
    valid: 'Please enter your name.'
  }
};
export const FIRST_NAME = {
  label: 'First Name',
  validation: {
    required: 'First name is required.',
    valid: 'Please enter a valid first name.'
  }
};
export const LAST_NAME = {
  label: 'Last Name',
  validation: {
    required: 'Last name is required.',
    valid: 'Please enter a valid last name.'
  }
};
export const COUNTRY = {
  label: 'COUNTRY',
  values: {
    US: 'United States',
    CA: 'Canada'
  },
  validation: {
    valid: 'Please select the country'
  }
};
export const ZIP_CODE = {
  label: 'Zip code',
  validation: {
    valid: 'Please enter a valid zip code.'
  }
};
export const POSTAL_CODE = {
  label: 'Postal code',
  validation: {
    valid: 'Please enter a valid postal code.'
  }
};
export const PHONE_NUMBER = {
  label: 'Phone number',
  validation: {
    required: 'Phone number is required.',
    valid: 'Please enter a valid phone number.'
  }
};
export const EMAIL = {
  label: 'Email',
  validation: {
    required: 'Email address is required.',
    valid: 'Please enter a valid email address.'
  }
};
export const PASSWORD = {
  label: 'Password',
  validation: {
    required: 'Password is required.',
    emailDiff: 'Password must be different from Email ID.',
    maxChar: 'Password must be at least 8 characters.',
    includeChar:
      'Password must be at least one uppercase letter, one lowercase letter, one special character, and one digit.',
    valid: 'Your password must meet the following requirements.',
    match: 'Password & Confirm password should match.'
  }
};
export const CONFIRM_PASSWORD = {
  label: 'confirmPassword',
  validation: {
    required: 'Confirm Password is required.',
    match: 'Password & Confirm password should match.'
  }
};
export const EXPERIENCE = {
  label: 'Experience',
  validation: {
    required: 'Experience is required.',
    valid: 'Experience should be between 0-100'
  }
};
export const SELECTED_SKILL_SET = {
  label: 'Recruiting Fields',
  validation: {
    valid: 'Recruiting fields must have at least 1 items'
  }
};
export const LINKEDIN = {
  label: 'linkedIn',
  validation: {
    valid: 'Please enter a valid LinkedIn Account.'
  }
};
export const EXPERIENCE_MIN_ERROR = {
  label: 'Experience',
  validation: {
    valid: 'Experience must be greater than or equal to 0'
  }
};
export const EXPERIENCE_MAX_ERROR = {
  label: 'Experience',
  validation: {
    valid: 'Experience must be less than 99'
  }
};
export const EXPERIENCE_REQUIRED_ERROR = {
  label: 'Experience',
  validation: {
    valid: 'Please enter valid experience',
    required: 'Please enter valid experience'
  }
};

export const HIGHER_EDUCATION = {
  label: 'higherEducation',
  validation: {
    required: 'Highest education is required.'
  }
};
export const SKILL_SET = {
  label: 'skillSet',
  validation: {
    required: 'Skillset is required.'
  }
};
export const COMPANY_NAME = {
  label: 'companyName',
  validation: {
    required: 'Company name is required.',
    valid: 'Please enter valid company name.'
  }
};
export const INDUSTRY_TYPE = {
  label: 'industryType',
  validation: {
    required: 'Industry type is required.'
  }
};
export const WEBSITE_URL = {
  label: 'companyWebsite',
  validation: {
    valid: 'Please enter a valid Website URL.'
  }
};
export const ROLE = {
  label: 'role',
  validation: {
    required: 'Role is required.'
  }
};
