import React, { useState, useEffect, useContext, useCallback } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ArrowDown from '@/elements/ArrowDown';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import _ from 'lodash';
import moment from 'moment';

import {
  getCompanyInterviews,
  getInterviewRecordings
} from '@/services/interview-service/interview-service';
import PlayIcon from '@/assets/svgs/PlayIcon';
import CustomPagination from '@/elements/pagination/customPagination';
import GoTOPage from '@/elements/gotopage/gotopage';
import { LoaderContext } from '@/components/Loader/Loader';
import { RecordingModal } from '@/components/modals/recording-modal';

const PastInterviews = ({
  activeCompanyId = null,
  originType = null,
  changeInterviewType,
  activeRecruiterId = null,
  tabValue
}) => {
  const setLoading = useContext(LoaderContext);
  const [interviews, setInterviews] = useState([]);
  const [recordingLink, setRecordingLink] = useState('');
  const [openRecordingModal, setOpenRecordingModal] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0,
    search: '',
    tab: 'past',
    type: activeRecruiterId ? 'adminToRecruiter' : 'adminToEmployee'
  });

  useEffect(() => {
    getInterviews();
  }, [activeCompanyId, activeRecruiterId, tabValue]);

  const changePage = (event, value) => {
    setPagination({ ...pagination, currentPage: value });
  };

  const handleSelectChange = (e) => {
    changeInterviewType(e?.target?.value);
  };

  const getInterviews = async (
    currentPage = pagination?.currentPage,
    recordsPerPage = pagination?.recordsPerPage,
    search = pagination?.search,
    tab = pagination?.tab,
    type = pagination?.type
  ) => {
    setLoading(true);
    const response = await getCompanyInterviews(
      currentPage,
      recordsPerPage,
      search,
      tab,
      type,
      activeCompanyId,
      activeRecruiterId
    );
    const { meta, data } = response;
    if (meta?.status) {
      setPagination((prev) => ({
        ...prev,
        totalRecord: data?.count ? data?.count : 0
      }));
      setInterviews(data?.rows);
    }
    setLoading(false);
  };

  const getPastInterviewRecordings = async (meetingid) => {
    setLoading(true);
    const response = await getInterviewRecordings(meetingid);
    const { meta, data } = response;
    if (meta?.status) {
      setOpenRecordingModal(true);
      setRecordingLink(data?.length ? data[0]?.link : '');
    }
    setLoading(false);
  };

  const searchInInterviewListing = useCallback(
    _.debounce(
      (searchText) =>
        getInterviews(
          searchText ? 1 : pagination?.currentPage,
          pagination?.recordsPerPage,
          searchText,
          pagination?.tab,
          pagination?.type
        ),
      500
    ),
    []
  );

  return (
    <>
      <div className="tabContainer__item--head head--responsive bg--white">
        <div className="listCol__head--actions flex--between w_100">
          <div className="formRow formRow__3 m_0 w_100">
            <div className="formCol">
              <div
                className={`formField ${
                  originType == 'interview' ? 'formFieldSearch' : ''
                }`}
              >
                <div className="formField__group sm">
                  <span className="formField__icon">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                        stroke="#6C737F"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="formField__input"
                    placeholder="Search an interview"
                    onChange={(e) => searchInInterviewListing(e?.target?.value)}
                  />
                </div>
              </div>
            </div>
            {originType == 'interview' ? (
              ''
            ) : (
              <div className="formCol">
                <div className="formField">
                  <FormControl
                    fullWidth
                    size="small"
                    className="formField__select"
                    sx={{ minHeight: '42px' }}
                  >
                    <Select
                      onChange={(e) => handleSelectChange(e)}
                      IconComponent={() => <ArrowDown />}
                      placeholder="Country"
                      name="country"
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return (
                            <span style={{ opacity: '0.8' }}>
                              Past Interviews
                            </span>
                          );
                        }
                        return 'Past Interviews';
                      }}
                    >
                      <MenuItem kay="schedule" value="schedule">
                        Scheduled Interviews
                      </MenuItem>
                      <MenuItem kay="past" value="past">
                        Past Interviews
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <TableContainer
        component={Paper}
        className="tableContainer"
        style={{ height: 'calc(100dvh - 249px)' }}
      >
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell>S.</TableCell>
              <TableCell>Interview Title</TableCell>
              <TableCell>Interview Date</TableCell>
              <TableCell>Interview Time</TableCell>
              <TableCell align="right">View Recording</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {interviews.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>
                    <div className="cellText">
                      <span className="cellText__dark">
                        {row?.title ? row?.title : 'Interview'}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="cellText">
                      <span className="cellText__dark">
                        {moment(row?.meeting_start_time).format('D MMM , YYYY')}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="cellText">
                      <span className="cellText__dark">
                        {moment(row?.meeting_start_time).format('hh:mm A') +
                          '-' +
                          moment(row?.meeting_end_time).format('hh:mm A')}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    <div className="actions flex--end">
                      <button
                        className="btn btn__white"
                        onClick={() => {
                          getPastInterviewRecordings(
                            row?.meetingInfo?.location.split('/')[4]
                          );
                        }}
                      >
                        View Rec.
                        <PlayIcon />
                      </button>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {interviews?.length ? (
          ''
        ) : (
          <div className="no-data">No Results Found!</div>
        )}
      </TableContainer>

      <div className="pagination">
        <div className="pagination__result">{`${
          pagination?.totalRecord >
          pagination?.currentPage * pagination?.recordsPerPage
            ? pagination?.recordsPerPage
            : pagination?.totalRecord
        } of ${pagination?.totalRecord} results`}</div>
        {pagination?.totalRecord ? (
          <CustomPagination
            totalPageCount={Math.ceil(
              pagination?.totalRecord / pagination.recordsPerPage
            )}
            changePage={(event, value) => changePage(event, value)}
          />
        ) : (
          ''
        )}
        {pagination?.totalRecord ? (
          <div className="pagination__actions">
            <GoTOPage changePage={changePage} />
          </div>
        ) : (
          ''
        )}
      </div>
      <RecordingModal
        openModal={openRecordingModal}
        closeModal={() => setOpenRecordingModal(false)}
        recordingLink={recordingLink}
      />
    </>
  );
};

export default PastInterviews;
