import React, { useState, useEffect, useContext } from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { GetUserDetails } from '@/helper';
import { SuccessfullyModal } from '@/components/modals/successfully-modal';
import ErrorModal from '@/components/modals/error-modal';
import ConfirmationModal from '@/components/modals/confirmation-modal';
import {
  getJobsNotes,
  addJobsNotes,
  editJobsNotes,
  deleteJobsNotes
} from '@/services/job-service/job-service';
import * as MESSAGES from '@/utils/popupMessages';
import { LoaderContext } from '@/components/Loader/Loader';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CloseIconGray from '@/assets/svgs/CloseIconGray';

const JobNotes = ({ jobId }) => {
  const setLoading = useContext(LoaderContext);
  const [allJobNotes, setAllJobNotes] = useState([]);
  const [selectedNotes, setSelectedNotes] = useState([]);
  const [radioValue, setRadioValue] = useState('Admin');
  const [openAddNotes, setOpenAddNotes] = useState(false);
  const [noteDescription, setNoteDescription] = useState('');
  const [noteTitle, setNoteTitle] = useState('');
  const [editData, setEditData] = useState('');
  const [OpenSuccessfullModal, setOpenSuccessfullModal] = useState(false);
  const [OpenErrorModal, setOpenErrorModal] = useState(false);
  const [OpenConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [SuccessfullMsg, setSuccessfullMsg] = useState('Successfully Done!');
  const [SuccessfullHeading, setSuccessfullHeading] = useState('Sucessfull');
  const [ErrorMsg, setErrorMsg] = useState('Error!');
  const [DeleteNoteId, setDeleteNoteId] = useState('');
  const [ShareWithArray, setShareWithArray] = useState([]);

  const closeSucessfullModal = () => {
    setOpenSuccessfullModal(false);
  };
  const closeErrorModal = () => {
    setOpenErrorModal(false);
  };
  const closeConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  useEffect(() => {
    getJobNotes();
  }, [radioValue]);

  const getJobNotes = async () => {
    try {
      setLoading(true);
      const response = await getJobsNotes(jobId);
      const { data, meta } = response;
      if (meta?.status) {
        setAllJobNotes(data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const addJobNotes = async () => {
    const userDetails = GetUserDetails();
    if (editData == '') {
      setLoading(true);
      try {
        const response = await addJobsNotes(
          noteTitle,
          noteDescription,
          jobId,
          userDetails?.adminId,
          'Admin',
          [
            {
              id: 'Laila Team',
              value: 'Laila Team'
            }
          ]
        );
        const { meta } = response;
        const { message } = MESSAGES?.addNote;
        if (meta?.status) {
          getJobNotes();
          setOpenAddNotes(false);
          setOpenSuccessfullModal(true);
          setSuccessfullHeading(message?.heading);
          setSuccessfullMsg(message?.successfull);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await editJobsNotes(
          editData?.id,
          noteTitle,
          noteDescription,
          jobId,
          'Admin',
          userDetails?.adminId,
          ShareWithArray
        );
        const { meta } = response;
        const { message } = MESSAGES?.updateNote;
        if (meta?.status) {
          setOpenAddNotes(false);
          setSuccessfullHeading(message?.heading);
          setSuccessfullMsg(message?.successfull);
          setOpenSuccessfullModal(true);
          getJobNotes();
        }
      } catch (error) {
        console.log(error);
      }
    }
    setEditData('');
    setNoteTitle('');
    setNoteDescription('');
    setLoading(false);
  };

  const changeRadioButton = (e) => {
    setRadioValue(e?.target?.value);
  };

  const deleteNotes = async (id) => {
    setLoading(true);
    try {
      const response = await deleteJobsNotes(DeleteNoteId, jobId);
      const { meta } = response;
      const { message } = MESSAGES?.deleteNote;
      if (meta?.status) {
        setOpenConfirmationModal(false);
        setOpenSuccessfullModal(true);
        setSuccessfullHeading(message?.heading);
        setSuccessfullMsg(message?.successfull);
        setTimeout(() => {
          setOpenSuccessfullModal(false);
        }, 3000);
        getJobNotes();
      } else {
        setOpenErrorModal(true);
        setErrorMsg(response?.meta?.msg);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const editNotes = (data) => {
    setEditData(data);
    setNoteTitle(data?.title);
    setNoteDescription(data?.description);
    setShareWithArray(data?.shareWith);
    setOpenAddNotes(true);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link', 'image'],
      ['clean']
    ]
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image'
  ];

  const editorStyle = {
    width: '100%'
  };

  const handleTitleChange = (e) => {
    setNoteTitle(e?.target?.value);
  };

  const handleTextAreaChange = (value) => {
    if (value === '<p><br></p>') {
      setNoteDescription('');
    } else {
      setNoteDescription(value);
    }
  };

  return (
    <>
      <div className="page">
        <div className="notes">
          <div className="notes__head">
            <FormControl>
              <RadioGroup>
                <FormControlLabel
                  value="Admin"
                  control={<Radio />}
                  label="By Admin"
                  checked={radioValue == 'Admin'}
                  onChange={(e) => changeRadioButton(e)}
                />
                <FormControlLabel
                  value="Customer"
                  control={<Radio />}
                  label="By Customer"
                  checked={radioValue === 'Customer'}
                  onChange={(e) => changeRadioButton(e)}
                />
              </RadioGroup>
            </FormControl>
            {radioValue == 'Admin' && (
              <button
                className="btn btn__primary"
                onClick={() => {
                  setOpenAddNotes(true);
                  setEditData('');
                }}
              >
                Add Note
              </button>
            )}
          </div>
          {allJobNotes?.filter((note) => note.from === radioValue).length >
          0 ? (
            <ul className="notes__list">
              {allJobNotes
                ?.filter((note) => note.from === radioValue)
                .map((item, index) => {
                  return (
                    <li className="notes__list--item" key={index}>
                      <div className="notes--title">
                        <p>{item?.title}</p>
                        <div className="btn__group">
                          {radioValue === 'Admin' && (
                            <>
                              <button
                                className="btn btn__white btn--sm border_0"
                                onClick={(e) => editNotes(item)}
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10 16.6667H17.5M13.75 2.91669C14.0815 2.58517 14.5312 2.39893 15 2.39893C15.2321 2.39893 15.462 2.44465 15.6765 2.53349C15.891 2.62233 16.0858 2.75254 16.25 2.91669C16.4142 3.08085 16.5444 3.27572 16.6332 3.4902C16.722 3.70467 16.7678 3.93455 16.7678 4.16669C16.7678 4.39884 16.722 4.62871 16.6332 4.84319C16.5444 5.05766 16.4142 5.25254 16.25 5.41669L5.83333 15.8334L2.5 16.6667L3.33333 13.3334L13.75 2.91669Z"
                                    stroke="#4D5761"
                                    strokeWidth="1.67"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                              <button
                                className="btn btn__danger btn--sm border_0"
                                onClick={() => {
                                  setOpenConfirmationModal(true);
                                  setDeleteNoteId(item?.id);
                                }}
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2.5 4.99984H4.16667M4.16667 4.99984H17.5M4.16667 4.99984V16.6665C4.16667 17.1085 4.34226 17.5325 4.65482 17.845C4.96738 18.1576 5.39131 18.3332 5.83333 18.3332H14.1667C14.6087 18.3332 15.0326 18.1576 15.3452 17.845C15.6577 17.5325 15.8333 17.1085 15.8333 16.6665V4.99984H4.16667ZM6.66667 4.99984V3.33317C6.66667 2.89114 6.84226 2.46722 7.15482 2.15466C7.46738 1.8421 7.89131 1.6665 8.33333 1.6665H11.6667C12.1087 1.6665 12.5326 1.8421 12.8452 2.15466C13.1577 2.46722 13.3333 2.89114 13.3333 3.33317V4.99984M8.33333 9.1665V14.1665M11.6667 9.1665V14.1665"
                                    stroke="#F04438"
                                    strokeWidth="1.67"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="notes--description">
                        <div
                          key={new Date().getTime()}
                          className="showQuillTags"
                          dangerouslySetInnerHTML={{
                            __html: item?.description ? item?.description : '--'
                          }}
                        ></div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          ) : (
            <div className="tableContainer">
              <div className="no-data floating">No Results Found!</div>
            </div>
          )}
        </div>
      </div>
      <div
        className={`modal modalPrompt modalForm ${
          openAddNotes ? 'modal--open' : ''
        }`}
      >
        <div className="modal__dialog">
          <div className="modal__content">
            <div className="modal__header">
              <h2 className="modal__header--title">
                {editData == '' ? 'Add Note' : 'Edit Note'}
              </h2>
              <button
                onClick={() => {
                  setOpenAddNotes(false);
                  setNoteTitle('');
                  setNoteDescription('');
                }}
                className="btn btn__transparent btnClose"
              >
                <CloseIconGray />
              </button>
            </div>
            <div className="modal__body">
              <div className="formField">
                <label className="formField__label">Note Title</label>
                <input
                  type="text"
                  value={noteTitle}
                  onChange={(e) => handleTitleChange(e)}
                  maxLength={30}
                  className="formField__input sm"
                  placeholder="Enter your title"
                />
              </div>

              <div className="formField">
                <label className="formField__label">Note</label>
                {/* <textarea
                  type="text"
                  value={noteDescription}
                  onChange={(e) =>
                    setNoteDescription(e?.target?.value.trimStart())
                  }
                  rows={6}
                  className="formField__textarea"
                  placeholder="Enter your note here..."
                ></textarea> */}
                <ReactQuill
                  name="notesText"
                  style={editorStyle}
                  value={noteDescription}
                  modules={modules}
                  formats={formats}
                  placeholder="Enter your note here..."
                  onChange={(newValue) => handleTextAreaChange(newValue)}
                />
              </div>
            </div>
            <div className="modal__footer">
              <div className="btn__group">
                <button
                  className="btn btn__white"
                  onClick={() => {
                    setOpenAddNotes(false);
                    setEditData('');
                    setNoteTitle('');
                    setNoteDescription('');
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn__primary"
                  onClick={() => addJobNotes()}
                  disabled={noteTitle && noteDescription ? false : true}
                >
                  {editData == '' ? 'Add' : 'Update'}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
      <SuccessfullyModal
        openModal={OpenSuccessfullModal}
        closeModal={closeSucessfullModal}
        Heading={SuccessfullHeading}
        sucessfullMsg={SuccessfullMsg}
      />
      <ErrorModal
        openModal={OpenErrorModal}
        closeModal={closeErrorModal}
        errorMessage={ErrorMsg}
      />
      <ConfirmationModal
        openModal={OpenConfirmationModal}
        closeModal={closeConfirmationModal}
        onSubmit={deleteNotes}
        heading={MESSAGES?.deleteNote?.message?.confirmationHeading}
        confirmationMessage={MESSAGES?.deleteNote?.message?.confirmationMsg}
      />
    </>
  );
};

export default JobNotes;
