import React, { useState, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import _ from 'lodash';
import SideBar from '@/components/layout/SideBar';
import CustomPagination from '@/elements/pagination/customPagination';
// import BillingCustomers from '@/pages/billing-payments/customer-billing';
import GoTOPage from '@/elements/gotopage/gotopage';
import { getBillingJobsList } from '@/services/billing-service/billing-service';
import { firstLetterCapital } from '@/helper';
import MultiSelectFilter from '@/components/filter';
import { LoaderContext } from '@/components/Loader/Loader';
import BackArrow from '@/assets/svgs/BackArrow';
import Search from '@/assets/svgs/Search';
import Filter from '@/assets/svgs/Filter';
import Dot from '@/assets/svgs/Dot';
import FileIconBlue from '@/assets/svgs/FileIconBlue';
import { Currency } from '@/helper';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SearchCross from '@/assets/svgs/SearchCross';

const BillingPayments2 = () => {
  const navigate = useNavigate();
  const setLoading = useContext(LoaderContext);
  const [activeCompanyData, setActiveCompanyData] = useState({});
  const [jobList, setJobList] = useState([]);
  const [addResponsiveClass, setAddResponsiveClass] = useState(false);
  const [customerLengthCount, setCustomerLengthCount] = useState();
  const [BillingFilterStatus, setBillingFilterStatus] = useState([
    'Billed',
    'Unbilled'
  ]);
  const [value, setValue] = useState('1');
  const [pagination, setPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0,
    search: '',
    status: []
  });
  const [searchanchorEl, setSeachAnchorEl] = useState(null);

  const searchOpen = Boolean(searchanchorEl);
  const searchPopoverid = searchOpen ? 'simple-popover' : '';

  const onCompanySelection = (companyData, responsiveFromListing) => {
    setPagination({ ...pagination, currentPage: 1 });
    responsiveFromListing
      ? setAddResponsiveClass(false)
      : setAddResponsiveClass(true);
    setActiveCompanyData(companyData);
    getJoblist(
      pagination?.recordsPerPage,
      1,
      pagination?.search,
      pagination?.status,
      companyData?.id
    );
  };

  const getJoblist = async (
    recordsPerPage = pagination?.recordsPerPage,
    currentPage = pagination?.currentPage,
    search = pagination?.search,
    status,
    companyId = activeCompanyData?.id
  ) => {
    setLoading(true);
    const response = await getBillingJobsList(
      currentPage,
      recordsPerPage,
      search,
      status,
      companyId
    );
    const { meta, data } = response;
    if (meta?.status) {
      setPagination((prev) => ({
        ...prev,
        totalRecord: data?.count ? data?.count : 0
      }));
      setJobList(data?.rows);
    }
    setLoading(false);
  };

  const changePage = (event, value) => {
    setPagination({ ...pagination, currentPage: value });
    getJoblist(
      pagination?.recordsPerPage,
      value,
      pagination?.search,
      pagination?.status,
      activeCompanyData?.id
    );
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const searchJobPost = useCallback(
    _.debounce(
      (searchText, companyId) =>
        getJoblist(
          pagination?.recordsPerPage,
          searchText ? 1 : pagination?.currentPage,
          searchText,
          pagination?.status,
          companyId
        ),
      500
    ),
    []
  );

  const navigateToJobsCandidateScreen = (activeCompanyData, jobid, title) => {
    navigate('/jobs-billing', {
      state: {
        activeCompanyData: activeCompanyData,
        jobid: jobid,
        addResponsiveClass: true,
        jobTitle: title
      }
    });
  };

  const handleStatusFilterChange = (selectedOption) => {
    getJoblist(
      pagination?.recordsPerPage,
      pagination?.currentPage,
      pagination?.search,
      selectedOption,
      activeCompanyData?.id
    );
  };

  const handleFilterClose = () => {
    getJoblist(
      pagination?.recordsPerPage,
      pagination?.currentPage,
      pagination?.search,
      [],
      activeCompanyData?.id
    );
  };

  const CalculateUnbilledAmount = (obj) => {
    const sumFilteredValues = obj.screenings
      .filter((item) => !item.billStatus && item.status === 'approved')
      .reduce((sum, item) => sum + item.amount, 0);
    return Currency(sumFilteredValues);
  };

  const getBilledUnbilled = (screenings) => {
    const getApprovedScreenings =
      screenings.filter(
        (item) => !item.billStatus && item.status === 'approved'
      ).length > 0
        ? 'Unbilled'
        : 'Billed';
    return getApprovedScreenings;
  };

  return (
    <>
      <div className="layoutContainer">
        <SideBar />

        <div className="layoutGrid">
          <div className="layoutGrid__col">
            <div
              className={`listCol ${searchanchorEl ? 'listCol__search' : ''}`}
            >
              <div className="listCol__head listing__head">
                <h2 className="listCol__head--title">
                  {value === '1' ? 'Customers' : 'Recruiters'}
                </h2>
                <div className="listCol__head--actions">
                  <div className="formField">
                    <button
                      className="btn btn__white p_0"
                      aria-describedby={searchPopoverid}
                    >
                      <Search />
                    </button>
                  </div>
                  <div className="formField">
                    <button className="btn btn__white p_0" type="button">
                      <Filter />
                    </button>
                  </div>
                </div>

                <div className="searchContainer">
                  <div className="formField">
                    <div className="formField__group sm">
                      <span className="formField__icon">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                            stroke="#9DA4AE"
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <input
                        type="text"
                        className="formField__input"
                        placeholder="company"
                      />
                    </div>
                  </div>

                  <button className="btn btn__transparent border_0 p_0">
                    <SearchCross />
                  </button>
                </div>
              </div>

              <Box className="tabContainer">
                <TabContext value={value}>
                  <Box className="tabContainer__head">
                    <TabList onChange={handleChange}>
                      <Tab label="Customers" value="1" />
                      <Tab label="Recruiters" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1" className="tabContainer__item">
                    <div className="listColTitle totalBilled">
                      <p>Total Unpaid</p>
                      <h4>$435,435</h4>
                    </div>
                    <ul className="listCol__list listing__list">
                      <li className="listCol__list--item listCol__item--selected">
                        <div className="item item--recruiter item--jobs">
                          <div cclassNamelass="item__info">
                            <h4 className="item__info--title">
                              Software Developer
                            </h4>
                            <div className="item__info--subtitle">
                              <p>
                                <span>Department:</span> Programming
                              </p>
                              <span className="status">
                                <Dot />
                                <span className="fw500">Status:</span>
                                <span className="text--primary">Paid</span>
                              </span>
                            </div>
                            <div className="item__info--subtitle">
                              <p>
                                <span>Recruiter:</span> Orlando Diggs
                              </p>
                              <span>
                                <Dot />
                                Los Angeles, US
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="listCol__list--item">
                        <div className="item item--recruiter item--jobs">
                          <div cclassNamelass="item__info">
                            <h4 className="item__info--title">
                              Accounts Executive
                            </h4>
                            <div className="item__info--subtitle">
                              <p>
                                <span>Department:</span> Accounts & Finance
                              </p>
                              <span className="status">
                                <Dot />
                                <span className="fw500">Status:</span>
                                <span className="text--danger">Unpaid</span>
                              </span>
                            </div>
                            <div className="item__info--subtitle">
                              <p>
                                <span>Recruiter:</span> Drew Cano
                              </p>
                              <span>
                                <Dot />
                                Chicago, US
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="listCol__list--item">
                        <div className="item item--recruiter item--jobs">
                          <div cclassNamelass="item__info">
                            <h4 className="item__info--title">
                              Accounts Executive
                            </h4>
                            <div className="item__info--subtitle">
                              <p>
                                <span>Department:</span> Accounts & Finance
                              </p>
                              <span className="status">
                                <Dot />
                                <span className="fw500">Status:</span>
                                <span className="text--danger">Unpaid</span>
                              </span>
                            </div>
                            <div className="item__info--subtitle">
                              <p>
                                <span>Recruiter:</span> Drew Cano
                              </p>
                              <span>
                                <Dot />
                                Chicago, US
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="listCol__list--item">
                        <div className="item item--recruiter item--jobs">
                          <div cclassNamelass="item__info">
                            <h4 className="item__info--title">
                              Software Developer
                            </h4>
                            <div className="item__info--subtitle">
                              <p>
                                <span>Department:</span> Programming
                              </p>
                              <span className="status">
                                <Dot />
                                <span className="fw500">Status:</span>
                                <span className="text--primary">Paid</span>
                              </span>
                            </div>
                            <div className="item__info--subtitle">
                              <p>
                                <span>Recruiter:</span> Orlando Diggs
                              </p>
                              <span>
                                <Dot />
                                Los Angeles, US
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="listCol__list--item">
                        <div className="item item--recruiter item--jobs">
                          <div cclassNamelass="item__info">
                            <h4 className="item__info--title">
                              Accounts Executive
                            </h4>
                            <div className="item__info--subtitle">
                              <p>
                                <span>Department:</span> Accounts & Finance
                              </p>
                              <span className="status">
                                <Dot />
                                <span className="fw500">Status:</span>
                                <span className="text--danger">Unpaid</span>
                              </span>
                            </div>
                            <div className="item__info--subtitle">
                              <p>
                                <span>Recruiter:</span> Drew Cano
                              </p>
                              <span>
                                <Dot />
                                Chicago, US
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="listCol__list--item">
                        <div className="item item--recruiter item--jobs">
                          <div cclassNamelass="item__info">
                            <h4 className="item__info--title">
                              Software Developer
                            </h4>
                            <div className="item__info--subtitle">
                              <p>
                                <span>Department:</span> Programming
                              </p>
                              <span className="status">
                                <Dot />
                                <span className="fw500">Status:</span>
                                <span className="text--primary">Paid</span>
                              </span>
                            </div>
                            <div className="item__info--subtitle">
                              <p>
                                <span>Recruiter:</span> Orlando Diggs
                              </p>
                              <span>
                                <Dot />
                                Los Angeles, US
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </TabPanel>

                  <TabPanel value="2" className="tabContainer__item">
                    <ul>
                      <li className="listCol__list--item">
                        <div className="item item--recruiter">
                          <div className="initials initials__md circle circle__approve">
                            A
                          </div>
                          <div className="item__info">
                            <h4 className="item__info--title">Andi Lane</h4>
                            <div className="item__info--subtitle">
                              <p>Post Graduate / 5 years experience</p>
                              <span className="status">
                                <Dot />
                                <span className="fw500">Status:</span>
                                <span className="text--primary">Paid</span>
                              </span>
                            </div>
                            <div className="item__info--subtitle">
                              <p>
                                <b>P:</b>784-234-9745
                              </p>
                              <span>
                                <b>E:</b>andi.lane@salesforce.com
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="listCol__list--item">
                        <div className="item item--recruiter">
                          <div className="initials initials__md circle circle__approve">
                            A
                          </div>
                          <div className="item__info">
                            <h4 className="item__info--title">Andi Lane</h4>
                            <div className="item__info--subtitle">
                              <p>Post Graduate / 5 years experience</p>
                              <span className="status">
                                <Dot />
                                <span className="fw500">Status:</span>
                                <span className="text--warning">Pending</span>
                              </span>
                            </div>
                            <div className="item__info--subtitle">
                              <p>
                                <b>P:</b>784-234-9745
                              </p>
                              <span>
                                <b>E:</b>andi.lane@salesforce.com
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="listCol__list--item listCol__item--selected">
                        <div className="item item--recruiter">
                          <div className="initials initials__md circle circle__approve">
                            A
                          </div>
                          <div className="item__info">
                            <h4 className="item__info--title">Andi Lane</h4>
                            <div className="item__info--subtitle">
                              <p>Post Graduate / 5 years experience</p>
                              <span className="status">
                                <Dot />
                                <span className="fw500">Status:</span>
                                <span className="text--primary">Paid</span>
                              </span>
                            </div>
                            <div className="item__info--subtitle">
                              <p>
                                <b>P:</b>784-234-9745
                              </p>
                              <span>
                                <b>E:</b>andi.lane@salesforce.com
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="listCol__list--item">
                        <div className="item item--recruiter">
                          <div className="initials initials__md circle circle__reject">
                            A
                          </div>
                          <div className="item__info">
                            <h4 className="item__info--title">Andi Lane</h4>
                            <div className="item__info--subtitle">
                              <p>Post Graduate / 5 years experience</p>
                              <span className="status">
                                <Dot />
                                <span className="fw500">Status:</span>
                                <span className="text--danger">Unpaid</span>
                              </span>
                            </div>
                            <div className="item__info--subtitle">
                              <p>
                                <b>P:</b>784-234-9745
                              </p>
                              <span>
                                <b>E:</b>andi.lane@salesforce.com
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="listCol__list--item">
                        <div className="item item--recruiter">
                          <div className="initials initials__md circle circle__approve">
                            A
                          </div>
                          <div className="item__info">
                            <h4 className="item__info--title">Andi Lane</h4>
                            <div className="item__info--subtitle">
                              <p>Post Graduate / 5 years experience</p>
                              <span className="status">
                                <Dot />
                                <span className="fw500">Status:</span>
                                <span className="text--warning">Pending</span>
                              </span>
                            </div>
                            <div className="item__info--subtitle">
                              <p>
                                <b>P:</b>784-234-9745
                              </p>
                              <span>
                                <b>E:</b>andi.lane@salesforce.com
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="listCol__list--item listCol__item--selected">
                        <div className="item item--recruiter">
                          <div className="initials initials__md circle circle__approve">
                            A
                          </div>
                          <div className="item__info">
                            <h4 className="item__info--title">Andi Lane</h4>
                            <div className="item__info--subtitle">
                              <p>Post Graduate / 5 years experience</p>
                              <span className="status">
                                <Dot />
                                <span className="fw500">Status:</span>
                                <span className="text--primary">Paid</span>
                              </span>
                            </div>
                            <div className="item__info--subtitle">
                              <p>
                                <b>P:</b>784-234-9745
                              </p>
                              <span>
                                <b>E:</b>andi.lane@salesforce.com
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="listCol__list--item">
                        <div className="item item--recruiter">
                          <div className="initials initials__md circle circle__reject">
                            A
                          </div>
                          <div className="item__info">
                            <h4 className="item__info--title">Andi Lane</h4>
                            <div className="item__info--subtitle">
                              <p>Post Graduate / 5 years experience</p>
                              <span className="status">
                                <Dot />
                                <span className="fw500">Status:</span>
                                <span className="text--danger">Unpaid</span>
                              </span>
                            </div>
                            <div className="item__info--subtitle">
                              <p>
                                <b>P:</b>784-234-9745
                              </p>
                              <span>
                                <b>E:</b>andi.lane@salesforce.com
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </TabPanel>
                </TabContext>
              </Box>
            </div>
          </div>
          <div
            className={`layoutGrid__col layoutGrid--responsive  ${
              addResponsiveClass ? 'layoutGrid--show' : ''
            }`}
          >
            <div className="dataCol">
              <div className="page page__full">
                <div className="page__head">
                  <h2 className="page__head--title">
                    <button
                      className="btn btn__transparent btn__back border_0 p_0"
                      onClick={() => setAddResponsiveClass(false)}
                    >
                      <BackArrow />
                    </button>
                    Joshua Wilson
                  </h2>
                  <div className="page__head__actions recuiterbilling">
                    {/* <span className="successMessage">
                      Total Received
                      <strong className="successMessage__price">$435,435</strong>
                    </span> */}
                    <span className="errorMessage">
                      Total Unpaid Bill
                      <strong className="errorMessage__price">$435,435</strong>
                    </span>
                  </div>
                </div>

                <div className="page__body">
                  <div className="tabContainer tabContainer--inner">
                    <div className="tabContainer__item bpContainer">
                      <div className="tabContainer__item--head ">
                        <div className="listCol__head--actions billingPayment w_100">
                          <div className="formField formFieldSearch">
                            <div className="formField__group sm">
                              <span className="formField__icon">
                                <Search />
                              </span>
                              <input
                                type="text"
                                className="formField__input"
                                placeholder="Search a Job"
                                onChange={(e) =>
                                  searchJobPost(e?.target?.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col">
                            <button className="paymentlink">
                              <FileIconBlue />
                              View Bill
                            </button>
                          </div>
                        </div>
                      </div>
                      <TableContainer
                        component={Paper}
                        className="tableContainer"
                      >
                        <Table className="table tableClickable">
                          <TableHead>
                            <TableRow>
                              <TableCell>S.</TableCell>
                              <TableCell>Job Title</TableCell>
                              <TableCell>Department</TableCell>
                              <TableCell>Customer</TableCell>
                              <TableCell>Total Paid</TableCell>
                              <TableCell>Total Unpaid</TableCell>
                              <TableCell>Status</TableCell>
                              <TableCell>Bills</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                1
                              </TableCell>
                              <TableCell>
                                <div className="cellText">
                                  <span className="cellText__title">
                                    Software Developer
                                  </span>
                                </div>
                              </TableCell>
                              <TableCell>
                                <div className="cellText">
                                  <span className="cellText__dark">
                                    Programming
                                  </span>
                                </div>
                              </TableCell>
                              <TableCell>
                                <div className="cellText">
                                  <span className="cellText__dark">
                                    Los Angeles, US
                                  </span>
                                </div>
                              </TableCell>
                              <TableCell>
                                <div className="cellText">
                                  <span className="cellText__dark">
                                    $120,344
                                  </span>
                                </div>
                              </TableCell>
                              <TableCell>
                                <div className="cellText">
                                  <span className="cellText__dark">$ 0</span>
                                </div>
                              </TableCell>
                              <TableCell>
                                <div className="cellText">
                                  <span className="cellText__primary">
                                    Paid
                                  </span>
                                </div>
                              </TableCell>
                              <TableCell>
                                <div className="cellText">
                                  <span className="cellText__primary cellText__primary--icon">
                                    <FileIconBlue />
                                    View Bill
                                  </span>
                                </div>
                              </TableCell>
                              <TableCell>
                                <div className="actions flex--end">
                                  <button className="btn btn__white">
                                    Approve
                                  </button>
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                1
                              </TableCell>
                              <TableCell>
                                <div className="cellText">
                                  <span className="cellText__title">
                                    Software Developer
                                  </span>
                                </div>
                              </TableCell>
                              <TableCell>
                                <div className="cellText">
                                  <span className="cellText__dark">
                                    Programming
                                  </span>
                                </div>
                              </TableCell>
                              <TableCell>
                                <div className="cellText">
                                  <span className="cellText__dark">
                                    Los Angeles, US
                                  </span>
                                </div>
                              </TableCell>
                              <TableCell>
                                <div className="cellText">
                                  <span className="cellText__dark">$200</span>
                                </div>
                              </TableCell>
                              <TableCell>
                                <div className="cellText">
                                  <span className="cellText__dark">$100</span>
                                </div>
                              </TableCell>
                              <TableCell>
                                <div className="cellText">
                                  <span className="cellText__danger">
                                    Unpaid
                                  </span>
                                </div>
                              </TableCell>
                              <TableCell>
                                <div className="cellText">
                                  <span className="cellText__primary cellText__primary--none">
                                    --
                                  </span>
                                </div>
                              </TableCell>
                              <TableCell>
                                <div className="actions flex--end">
                                  <button className="btn btn__white">
                                    Approve
                                  </button>
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>

                      <div className="pagination">
                        <div className="pagination__result">{`${
                          pagination?.totalRecord >
                          pagination?.currentPage * pagination?.recordsPerPage
                            ? pagination?.recordsPerPage
                            : pagination?.totalRecord
                        } of ${pagination?.totalRecord} results`}</div>

                        {pagination?.totalRecord ? (
                          <CustomPagination
                            totalPageCount={Math.ceil(
                              pagination?.totalRecord /
                                pagination.recordsPerPage
                            )}
                            page={pagination?.currentPage}
                            changePage={(event, value) =>
                              changePage(event, value)
                            }
                          />
                        ) : (
                          ''
                        )}
                        {pagination?.totalRecord ? (
                          <div className="pagination__actions">
                            <GoTOPage changePage={changePage} />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingPayments2;
