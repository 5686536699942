import React, { useRef, useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { GetUserDetails, formatPhoneNumber } from '@/helper';
import ErrorModal from '@/components/modals/error-modal';
import { SuccessfullyModal } from '@/components/modals/successfully-modal';
import { recruiterFormSchema } from '@/components/schema/schema';
import { Formik, Form, Field } from 'formik';
import ArrowDown from '@/elements/ArrowDown';
import BackArrow from '@/assets/svgs/BackArrow';
import NameIcon from '@/assets/svgs/NameIcon';
import EmailIcon from '@/assets/svgs/EmailIcon';
import LinkedinIcon from '@/assets/svgs/LinkedinIcon';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { getStaticData } from '@/services/static-data-service';
import {
  addRecruiter,
  updateRecruiter
} from '@/services/recruiters-service/recruiters-service';
import * as MESSAGES from '@/utils/popupMessages';
import DeleteIcon from '@/assets/svgs/Deleteicon';
import PlusIcon from '@/assets/svgs/PlusIcon';
import { INSTITUTION_LOGO } from '@/config';
import UniversitySvg from '@/assets/svgs/UniversitySvg';

const AddEditRecruiter = ({
  backToRecruiterListing,
  editRecruiterMode,
  activeRecruiterData,
  addResponsiveClass,
  setAddResponsiveClass
}) => {
  const bottomRef = useRef(null);
  const [initialValues, setInitialValues] = useState({
    firstName: editRecruiterMode ? activeRecruiterData.firstName : '',
    lastName: editRecruiterMode ? activeRecruiterData.lastName : '',
    phoneNumber: editRecruiterMode ? activeRecruiterData.phoneNumber : '',
    email: editRecruiterMode ? activeRecruiterData.email : '',
    experience: editRecruiterMode ? activeRecruiterData.experience : '',
    linkedin: editRecruiterMode ? activeRecruiterData.linkedin : '',
    qualificationArray: editRecruiterMode
      ? activeRecruiterData?.qualificationArray
      : [
          {
            degree: '',
            institutionName: '',
            startMonth: '',
            startYear: '',
            endMonth: '',
            endYear: '',
            fieldOfStudy: ''
          }
        ],
    countryCode: '+1',
    selectedSkillSet: editRecruiterMode ? activeRecruiterData.skillSet : []
  });

  const [Loading, setLoading] = useState(false);
  const [CountryCodes, setCountryCodes] = useState([]);
  const [skillSets, setSkillSets] = useState([]);
  const [institutionName, setInstitutionName] = useState([]);
  const [majorStudyFields, setMajorStudyFields] = useState([]);
  const [successfullMsg, setSuccessfullMsg] = useState('');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMsgs, setErrorMsgs] = useState('');
  const [openSuccessfullModal, setOpenSuccessfullModal] = useState(false);
  const [HigherEducationArr, setHigherEducationArr] = useState([]);
  const [Years, setYears] = useState([]);
  const [staticStartYear, setstaticStartYear] = useState('');
  const [Months, setMonths] = useState([]);

  useEffect(() => {
    getDropdownsData();
    getYears();
  }, []);

  const closeSucessfullModal = () => {
    setOpenSuccessfullModal(false);
  };

  const closeErrorModal = () => {
    setOpenErrorModal(false);
  };

  const getDropdownsData = async () => {
    try {
      const response = await getStaticData();
      const { data, meta } = response;
      if (meta?.status) {
        setCountryCodes(data?.countryCode);
        setSkillSets(data?.skillSet);
        setInstitutionName(data?.nameOfInstitutionObject);
        setMajorStudyFields(data?.majorFieldOfStudy);
        setHigherEducationArr(data?.qualification);
        setMonths(data?.yearMonth?.month);
        setstaticStartYear(data?.yearMonth?.startYear);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormSubmit = async (fields) => {
    setLoading(true);
    const userDetails = GetUserDetails();
    if (editRecruiterMode) {
      let body = {
        firstName: fields?.firstName,
        lastName: fields?.lastName,
        phoneNumber: fields?.phoneNumber,
        experience: `${fields?.experience}`,
        countryCode: fields?.countryCode,
        recruiterId: activeRecruiterData.recruiterId,
        linkedin: fields?.linkedin,
        skillSet: fields?.selectedSkillSet,
        qualificationArray: fields?.qualificationArray
      };
      const response = await updateRecruiter(body);
      const { meta } = response;
      const { message } = MESSAGES?.updateRecruiter;
      if (meta?.status) {
        setSuccessfullMsg(message?.successfull);
        setOpenSuccessfullModal(true);
        setTimeout(() => {
          setOpenSuccessfullModal(false);
          backToRecruiterListing();
        }, 3000);
      } else {
        setErrorMsgs(response?.meta?.msg);
        setOpenErrorModal(true);
      }
    } else {
      let body = {
        firstName: fields?.firstName ?? '',
        lastName: fields?.lastName ?? '',
        phoneNumber: fields?.phoneNumber ?? '',
        email: fields?.email ?? '',
        experience: `${fields?.experience}` ?? '',
        countryCode: fields?.countryCode ?? '',
        addedBy: userDetails?.adminId,
        linkedin: fields?.linkedin ?? '',
        skillSet: fields?.selectedSkillSet ?? '',
        qualificationArray: fields?.qualificationArray
      };
      const response = await addRecruiter(body);
      const { meta } = response;
      const { message } = MESSAGES?.addRecruiter;
      if (meta?.status) {
        setSuccessfullMsg(message?.successfull);
        setOpenSuccessfullModal(true);
        setTimeout(() => {
          setOpenSuccessfullModal(false);
          backToRecruiterListing();
        }, 3000);
      } else {
        setErrorMsgs(meta?.msg);
        setOpenErrorModal(true);
      }
    }
    setLoading(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const getYears = () => {
    const startYear = 1930;
    const currentYear = new Date().getFullYear();
    const yearsArray = Array.from(
      { length: currentYear - startYear + 1 },
      (_, index) => (startYear + index).toString()
    );
    setYears(yearsArray);
  };

  const handleEduDetailsChange = (
    index,
    field,
    newValue,
    qualificationArray
  ) => {
    if (field === 'institutionName') {
      qualificationArray[index]['institutionName'] = newValue?.value;
      qualificationArray[index]['logoName'] = newValue?.logoName
        ? newValue?.logoName
        : null;
    } else {
      qualificationArray[index][field] = newValue;
    }

    setInitialValues({
      ...initialValues,
      qualificationArray: qualificationArray
    });
    return qualificationArray;
  };

  const deleteEducationalDetail = (index, qualificationArray) => {
    qualificationArray.splice(index, 1);
    setInitialValues({
      ...initialValues,
      qualificationArray: qualificationArray
    });
    return qualificationArray;
  };
  const addEducationalDetails = (valuesQualificationArray) => {
    let qualificationArray = JSON.parse(
      JSON.stringify(valuesQualificationArray)
    );
    qualificationArray.splice(0, 0, {
      institutionName: '',
      degree: '',
      fieldOfStudy: '',
      startMonth: '',
      startYear: '',
      endMonth: '',
      endYear: ''
    });
    return qualificationArray;
  };
  useEffect(() => {
    setInitialValues({
      ...initialValues,
      qualificationArray: activeRecruiterData?.qualificationArray
    });
  }, [activeRecruiterData]);

  const getUnivercityLogo = (univercityName) => {
    let logo =
      institutionName?.length &&
      institutionName.find((company) => company.value === univercityName)
        ?.logoName;
    return logo;
  };

  return (
    <>
      <div
        className={`layoutGrid__col layoutGrid--responsive  ${
          addResponsiveClass ? 'layoutGrid--show' : ''
        }`}
      >
        <div className="dataCol">
          <div className="page page__full">
            <Formik
              initialValues={initialValues}
              validationSchema={recruiterFormSchema}
              onSubmit={(fields) => handleFormSubmit(fields)}
              validateOnChange={true}
            >
              {({
                errors,
                values,
                handleChange,
                handleBlur,
                touched,
                setFieldValue,
                setError
              }) => (
                <Form className="form p_0" onKeyPress={handleKeyPress}>
                  <div className="page__head">
                    <h2 className="page__head--title">
                      <button
                        className="btn btn__transparent btn__back border_0 p_0"
                        onClick={() => setAddResponsiveClass(false)}
                      >
                        <BackArrow />
                      </button>
                      {editRecruiterMode
                        ? `${`${activeRecruiterData?.firstName[0].toUpperCase()}${activeRecruiterData?.firstName.slice(
                            1
                          )}`} ${`${activeRecruiterData?.lastName[0].toUpperCase()}${activeRecruiterData?.lastName.slice(
                            1
                          )}`}`
                        : 'Add Recruiter'}
                    </h2>
                    <div className="page__head--actions">
                      <button
                        type="button"
                        className="btn btn__white"
                        onClick={() => backToRecruiterListing()}
                      >
                        Cancel
                      </button>
                      <button className="btn btn__primary" type="submit">
                        {editRecruiterMode ? 'Update' : 'Add'}
                      </button>
                    </div>
                  </div>
                  <div className="page__body p_2">
                    <section className="form__section">
                      <h4 className="form__section--title">Basic Details</h4>
                      <div className="formRow formRow__2">
                        <div className="formCol">
                          <div
                            className={`formField ${
                              errors?.firstName && touched?.firstName
                                ? 'fieldError'
                                : ''
                            }`}
                          >
                            <label className="formField__label">
                              First Name
                            </label>
                            <div className="formField__group sm">
                              <Field
                                type="text"
                                className="formField__input"
                                placeholder="First Name"
                                name="firstName"
                                onBlur={handleBlur}
                              />
                              <span className="formField__icon">
                                <NameIcon />
                              </span>
                            </div>
                            <span className="formField__error">
                              {errors?.firstName &&
                                touched?.firstName &&
                                errors?.firstName}
                            </span>
                          </div>
                        </div>
                        <div className="formCol">
                          <div
                            className={`formField ${
                              errors?.lastName &&
                              touched?.lastName &&
                              errors?.lastName
                                ? 'fieldError'
                                : ''
                            }`}
                          >
                            <label className="formField__label">
                              Last Name
                            </label>
                            <div className="formField__group sm">
                              <Field
                                type="text"
                                className="formField__input"
                                placeholder="Last Name"
                                name="lastName"
                                onBlur={handleBlur}
                              />
                              <span className="formField__icon">
                                <NameIcon />
                              </span>
                            </div>
                            <span className="formField__error">
                              {errors?.lastName &&
                                touched?.lastName &&
                                errors?.lastName}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="formRow formRow__2">
                        <div className="formCol">
                          <div
                            className={`formField ${
                              errors?.phoneNumber &&
                              touched?.phoneNumber &&
                              errors?.phoneNumber
                                ? 'fieldError'
                                : ''
                            }`}
                          >
                            <label className="formField__label">
                              Phone Number
                            </label>
                            <div className="formField__group formField__phoneNumber sm">
                              <FormControl
                                fullWidth
                                size="small"
                                className="formField__select"
                              >
                                <Select
                                  onChange={handleChange}
                                  IconComponent={() => <ArrowDown />}
                                  name="countryCode"
                                  value={values?.countryCode}
                                  displayEmpty
                                  onBlur={handleBlur}
                                >
                                  {CountryCodes?.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>
                                      {item.value}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <Field
                                type="text"
                                maxLength="10"
                                className="formField__input sm"
                                placeholder="Phone Number"
                                name="phoneNumber"
                                value={formatPhoneNumber(values?.phoneNumber)}
                              />
                            </div>
                          </div>
                          <span className="formField__error">
                            <span className="formField__error">
                              {errors?.phoneNumber &&
                                touched?.phoneNumber &&
                                errors?.phoneNumber}
                            </span>
                          </span>
                        </div>
                        <div className="formCol">
                          <div
                            className={`formField ${
                              errors?.email && touched?.email && errors?.email
                                ? 'fieldError'
                                : ''
                            }`}
                          >
                            <label className="formField__label">
                              Email Address
                            </label>
                            <div className="formField__group sm">
                              <Field
                                type="text"
                                className="formField__input"
                                placeholder="Email Address"
                                name="email"
                                onBlur={handleBlur}
                              />
                              <span className="formField__icon">
                                <EmailIcon />
                              </span>
                            </div>
                            <span className="formField__error">
                              {errors?.email && touched?.email && errors?.email}
                            </span>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="form__section">
                      <h4 className="form__section--title">
                        Professional Details
                      </h4>
                      <div className="formRow">
                        <div className="formCol">
                          <div
                            className={`formField ${
                              errors?.selectedSkillSet &&
                              touched?.selectedSkillSet &&
                              errors?.selectedSkillSet
                                ? 'fieldError'
                                : ''
                            }`}
                          >
                            <label className="formField__label">
                              Recruiting Fields
                            </label>
                            <Autocomplete
                              multiple
                              id="tags-filled"
                              options={skillSets}
                              defaultValue={values?.selectedSkillSet}
                              name="selectedSkillSet"
                              onChange={(e, newValue) =>
                                setFieldValue('selectedSkillSet', newValue)
                              }
                              freeSolo
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    variant="outlined"
                                    label={option}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  placeholder="Select"
                                  className="autoCompleteInput"
                                />
                              )}
                            />
                            <span className="formField__error">
                              {errors?.selectedSkillSet &&
                                touched?.selectedSkillSet &&
                                errors?.selectedSkillSet}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="formRow formRow__2">
                        <div className="formCol">
                          <div
                            className={`formField ${
                              errors?.experience &&
                              touched?.experience &&
                              errors?.experience
                                ? 'fieldError'
                                : ''
                            }`}
                          >
                            <label className="formField__label">
                              Total Experience (years)
                            </label>
                            <Field
                              type="number"
                              className="formField__input sm"
                              placeholder="Total Experience"
                              name="experience"
                            />
                            <span className="formField__error">
                              {errors?.experience &&
                                touched?.experience &&
                                errors?.experience}
                            </span>
                          </div>
                        </div>
                        <div className="formCol">
                          <div
                            className={`formField ${
                              errors?.linkedin &&
                              touched?.linkedin &&
                              errors?.linkedin
                                ? 'fieldError'
                                : ''
                            }`}
                          >
                            <label className="formField__label">
                              LinkedIn Account
                            </label>
                            <div className="formField__group sm">
                              <Field
                                type="text"
                                className="formField__input"
                                placeholder="LinkedIn Account"
                                name="linkedin"
                                value={values?.linkedin}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <span className="formField__icon">
                                <LinkedinIcon />
                              </span>
                            </div>
                            <span className="formField__error">
                              {errors?.linkedin &&
                                touched?.linkedin &&
                                errors?.linkedin}
                            </span>
                          </div>
                        </div>
                      </div>
                    </section>

                    <section className="form__section ">
                      <h4 className="form__section--title details__section--title">
                        Educational Details
                        <button
                          type="button"
                          className="btn btn__white btn--sm"
                          onClick={() => {
                            let newArray = addEducationalDetails(
                              values?.qualificationArray
                            );
                            setFieldValue('qualificationArray', newArray);
                          }}
                        >
                          <PlusIcon />
                        </button>
                      </h4>
                      <div className="educationalInfoList">
                        {values?.qualificationArray?.map(
                          (qualification, index) => {
                            const isLastItem =
                              values.qualificationArray.length === 1;
                            return (
                              <>
                                <div className="educationalInfoList_row">
                                  <div
                                    className="educationalInfoList_row_formbox"
                                    key={index}
                                  >
                                    <div className="formRow formRow__2">
                                      {/* EDUCATION */}
                                      <div className="formCol">
                                        <div
                                          className={`formField ${
                                            errors?.qualificationArray
                                              ?.length &&
                                            errors?.qualificationArray[index]
                                              ?.degree
                                              ? 'fieldError'
                                              : ''
                                          }`}
                                        >
                                          <label className="formField__label">
                                            Education
                                          </label>
                                          <Autocomplete
                                            id="degree"
                                            options={HigherEducationArr}
                                            name="degree"
                                            value={qualification?.degree}
                                            onChange={(event, newValue) => {
                                              const newArray =
                                                handleEduDetailsChange(
                                                  index,
                                                  'degree',
                                                  newValue,
                                                  values?.qualificationArray
                                                );
                                              setFieldValue(
                                                'qualificationArray',
                                                newArray
                                              );
                                            }}
                                            freeSolo
                                            renderTags={(value, getTagProps) =>
                                              value.map((option, index) => (
                                                <Chip
                                                  variant="outlined"
                                                  label={option}
                                                  {...getTagProps({ index })}
                                                />
                                              ))
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                variant="filled"
                                                placeholder="Select"
                                                className="autoCompleteInput"
                                                onBlur={(e) => {
                                                  if (
                                                    params?.inputProps?.value
                                                  ) {
                                                    setFieldValue(
                                                      'degree',
                                                      params.inputProps.value
                                                    );
                                                  }
                                                }}
                                              />
                                            )}
                                          />
                                          <span className="formField__error">
                                            {errors?.qualificationArray
                                              ?.length &&
                                              errors?.qualificationArray[index]
                                                ?.degree}
                                          </span>
                                        </div>
                                      </div>
                                      {/* INSTITUTION NAME */}
                                      <div className="formCol">
                                        <div
                                          className={`formField ${
                                            errors?.qualificationArray
                                              ?.length &&
                                            errors?.qualificationArray[index]
                                              ?.institutionName
                                              ? 'fieldError'
                                              : ''
                                          }`}
                                        >
                                          <label className="formField__label">
                                            Name of Institution
                                          </label>
                                          <div className="formField__autoComplete formField__autoComplete--withLogo">
                                            <Autocomplete
                                              id="tags-filled"
                                              options={institutionName}
                                              getOptionLabel={(option) => {
                                                return option?.value
                                                  ? option?.value
                                                  : option;
                                              }}
                                              value={
                                                qualification?.institutionName
                                              }
                                              name="institutionName"
                                              onChange={(event, newValue) => {
                                                const newArray =
                                                  handleEduDetailsChange(
                                                    index,
                                                    'institutionName',
                                                    newValue,
                                                    values?.qualificationArray
                                                  );
                                                setFieldValue(
                                                  'qualificationArray',
                                                  newArray
                                                );
                                              }}
                                              freeSolo
                                              renderOption={(props, option) => (
                                                <li {...props}>
                                                  {option.logoName ? (
                                                    <img
                                                      src={
                                                        INSTITUTION_LOGO +
                                                        option.logoName
                                                      }
                                                      alt={option.value}
                                                      style={{
                                                        width: 25,
                                                        marginRight: 5
                                                      }}
                                                    />
                                                  ) : (
                                                    <div className="autocompletelogo">
                                                      <UniversitySvg />
                                                    </div>
                                                  )}
                                                  {option?.value || ''}{' '}
                                                </li>
                                              )}
                                              renderInput={(params) => (
                                                <TextField
                                                  placeholder="Select"
                                                  className="autoCompleteInput"
                                                  {...params}
                                                  InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: (
                                                      <>
                                                        {
                                                          params.InputProps
                                                            .startAdornment
                                                        }
                                                        {params.inputProps
                                                          .value &&
                                                        getUnivercityLogo(
                                                          params.inputProps
                                                            .value
                                                        ) ? (
                                                          <img
                                                            className="autocompletelogo"
                                                            src={
                                                              params.inputProps
                                                                .value &&
                                                              INSTITUTION_LOGO +
                                                                getUnivercityLogo(
                                                                  params
                                                                    .inputProps
                                                                    .value
                                                                )
                                                            }
                                                            alt="Selected"
                                                          />
                                                        ) : (
                                                          <div className="autocompletelogo">
                                                            <UniversitySvg />
                                                          </div>
                                                        )}
                                                      </>
                                                    )
                                                  }}
                                                  onBlur={(e) => {
                                                    if (
                                                      params?.inputProps?.value
                                                    ) {
                                                      setFieldValue(
                                                        'institutionName',
                                                        params.inputProps.value
                                                      );
                                                    }
                                                  }}
                                                />
                                              )}
                                            />
                                          </div>
                                          <span className="formField__error">
                                            {errors?.qualificationArray
                                              ?.length &&
                                              errors?.qualificationArray[index]
                                                ?.institutionName}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="formRow formRow__4">
                                      {/* START MONTH */}
                                      <div className="formCol">
                                        <div
                                          className={`formField ${
                                            errors?.qualificationArray
                                              ?.length &&
                                            errors?.qualificationArray[index]
                                              ?.startMonth
                                              ? 'fieldError'
                                              : ''
                                          }`}
                                        >
                                          <label className="formField__label">
                                            Start Month
                                          </label>
                                          <FormControl
                                            fullWidth
                                            size="small"
                                            className="formField__select"
                                          >
                                            <Select
                                              IconComponent={() => (
                                                <ArrowDown />
                                              )}
                                              name="startMonth"
                                              displayEmpty
                                              value={qualification?.startMonth}
                                              onChange={(event) => {
                                                const newArray =
                                                  handleEduDetailsChange(
                                                    index,
                                                    'startMonth',
                                                    event?.target?.value,
                                                    values?.qualificationArray
                                                  );
                                                setFieldValue(
                                                  'startMonth',
                                                  newArray
                                                );
                                              }}
                                              renderValue={(selected) => {
                                                if (!selected) {
                                                  return (
                                                    <span
                                                      style={{ opacity: '0.8' }}
                                                    >
                                                      Select Month
                                                    </span>
                                                  );
                                                }

                                                return selected;
                                              }}
                                            >
                                              {Months?.map((item, index) => {
                                                return (
                                                  <MenuItem
                                                    key={index}
                                                    value={item}
                                                  >
                                                    {item}
                                                  </MenuItem>
                                                );
                                              })}
                                            </Select>
                                          </FormControl>
                                          <span className="formField__error">
                                            {errors?.qualificationArray
                                              ?.length &&
                                              errors?.qualificationArray[index]
                                                ?.startMonth}
                                          </span>
                                        </div>
                                      </div>
                                      {/* START YEAR */}
                                      <div className="formCol">
                                        <div
                                          className={`formField ${
                                            errors?.qualificationArray
                                              ?.length &&
                                            errors?.qualificationArray[index]
                                              ?.startYear
                                              ? 'fieldError'
                                              : ''
                                          }`}
                                        >
                                          <label className="formField__label">
                                            Start Year
                                          </label>
                                          <FormControl
                                            fullWidth
                                            size="small"
                                            className="formField__select"
                                          >
                                            <Select
                                              IconComponent={() => (
                                                <ArrowDown />
                                              )}
                                              name="startYear"
                                              displayEmpty
                                              value={qualification?.startYear}
                                              onChange={(event) => {
                                                const newArray =
                                                  handleEduDetailsChange(
                                                    index,
                                                    'startYear',
                                                    event?.target?.value,
                                                    values?.qualificationArray
                                                  );
                                                setFieldValue(
                                                  'startYear',
                                                  newArray
                                                );
                                              }}
                                              renderValue={(selected) => {
                                                if (!selected) {
                                                  return (
                                                    <span
                                                      style={{ opacity: '0.8' }}
                                                    >
                                                      Select Year
                                                    </span>
                                                  );
                                                }

                                                return selected;
                                              }}
                                            >
                                              {Years?.map((item, index) => {
                                                return (
                                                  <MenuItem
                                                    key={index}
                                                    value={item}
                                                  >
                                                    {item}
                                                  </MenuItem>
                                                );
                                              })}
                                            </Select>
                                          </FormControl>
                                          <span className="formField__error">
                                            {errors?.qualificationArray
                                              ?.length &&
                                              errors?.qualificationArray[index]
                                                ?.startYear}
                                          </span>
                                        </div>
                                      </div>
                                      {/* END MONTH */}
                                      <div className="formCol">
                                        <div
                                          className={`formField ${
                                            errors?.qualificationArray
                                              ?.length &&
                                            errors?.qualificationArray[index]
                                              ?.endMonth
                                              ? 'fieldError'
                                              : ''
                                          }`}
                                        >
                                          <label className="formField__label">
                                            End Month
                                          </label>
                                          <FormControl
                                            fullWidth
                                            size="small"
                                            className="formField__select"
                                          >
                                            <Select
                                              IconComponent={() => (
                                                <ArrowDown />
                                              )}
                                              name="endMonth"
                                              displayEmpty
                                              value={qualification?.endMonth}
                                              onChange={(event) => {
                                                const newArray =
                                                  handleEduDetailsChange(
                                                    index,
                                                    'endMonth',
                                                    event?.target?.value,
                                                    values?.qualificationArray
                                                  );
                                                setFieldValue(
                                                  'endMonth',
                                                  newArray
                                                );
                                              }}
                                              renderValue={(selected) => {
                                                if (!selected) {
                                                  return (
                                                    <span
                                                      style={{ opacity: '0.8' }}
                                                    >
                                                      Select Month
                                                    </span>
                                                  );
                                                }

                                                return selected;
                                              }}
                                            >
                                              {Months?.map((item, index) => {
                                                return (
                                                  <MenuItem
                                                    key={index}
                                                    value={item}
                                                  >
                                                    {item}
                                                  </MenuItem>
                                                );
                                              })}
                                            </Select>
                                          </FormControl>
                                          <span className="formField__error">
                                            {errors?.qualificationArray
                                              ?.length &&
                                              errors?.qualificationArray[index]
                                                ?.endMonth}
                                          </span>
                                        </div>
                                      </div>
                                      {/* END YEAR */}
                                      <div className="formCol">
                                        <div
                                          className={`formField ${
                                            errors?.qualificationArray
                                              ?.length &&
                                            errors?.qualificationArray[index]
                                              ?.endYear
                                              ? 'fieldError'
                                              : ''
                                          }`}
                                        >
                                          <label className="formField__label">
                                            End Year
                                          </label>
                                          <FormControl
                                            fullWidth
                                            size="small"
                                            className="formField__select"
                                          >
                                            <Select
                                              IconComponent={() => (
                                                <ArrowDown />
                                              )}
                                              name="endYear"
                                              displayEmpty
                                              value={qualification?.endYear}
                                              onChange={(event) => {
                                                const newArray =
                                                  handleEduDetailsChange(
                                                    index,
                                                    'endYear',
                                                    event?.target?.value,
                                                    values?.qualificationArray
                                                  );
                                                setFieldValue(
                                                  'endYear',
                                                  newArray
                                                );
                                              }}
                                              renderValue={(selected) => {
                                                if (!selected) {
                                                  return (
                                                    <span
                                                      style={{ opacity: '0.8' }}
                                                    >
                                                      Select Year
                                                    </span>
                                                  );
                                                }

                                                return selected;
                                              }}
                                            >
                                              {Years?.map((item, index) => {
                                                return (
                                                  <MenuItem
                                                    key={index}
                                                    value={item}
                                                  >
                                                    {item}
                                                  </MenuItem>
                                                );
                                              })}
                                            </Select>
                                          </FormControl>
                                          <span className="formField__error">
                                            {errors?.qualificationArray
                                              ?.length &&
                                              errors?.qualificationArray[index]
                                                ?.endYear}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="formRow formRow__1">
                                      <div className="fieldBox w_100 m_0">
                                        <div
                                          className={`formField ${
                                            errors?.qualificationArray
                                              ?.length &&
                                            errors?.qualificationArray[index]
                                              ?.fieldOfStudy
                                              ? 'fieldError'
                                              : ''
                                          }`}
                                        >
                                          <label className="formField__label">
                                            Major/Field of Study
                                          </label>
                                          <Autocomplete
                                            id="tags-filled"
                                            options={majorStudyFields}
                                            name="fieldOfStudy"
                                            value={qualification?.fieldOfStudy}
                                            onChange={(event, newValue) => {
                                              const newArray =
                                                handleEduDetailsChange(
                                                  index,
                                                  'fieldOfStudy',
                                                  newValue,
                                                  values?.qualificationArray
                                                );
                                              setFieldValue(
                                                'fieldOfStudy',
                                                newArray
                                              );
                                            }}
                                            freeSolo
                                            renderTags={(value, getTagProps) =>
                                              value.map((option, index) => (
                                                <Chip
                                                  variant="outlined"
                                                  label={option}
                                                  {...getTagProps({ index })}
                                                />
                                              ))
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                variant="filled"
                                                placeholder="Select"
                                                className="autoCompleteInput"
                                                onBlur={(e) => {
                                                  if (
                                                    params?.inputProps?.value
                                                  ) {
                                                    setFieldValue(
                                                      'fieldOfStudy',
                                                      params.inputProps.value
                                                    );
                                                  }
                                                }}
                                              />
                                            )}
                                          />
                                          <span className="formField__error">
                                            {errors?.qualificationArray
                                              ?.length &&
                                              errors?.qualificationArray[index]
                                                ?.fieldOfStudy}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {!isLastItem && (
                                    <div className="educationalInfoList_row_button">
                                      <button
                                        type="button"
                                        className="btn btn__danger btn--sm"
                                        onClick={() =>
                                          deleteEducationalDetail(
                                            index,
                                            values?.qualificationArray
                                          )
                                        }
                                      >
                                        <DeleteIcon />
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </>
                            );
                          }
                        )}
                      </div>
                      <div ref={bottomRef}></div>
                    </section>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <SuccessfullyModal
        openModal={openSuccessfullModal}
        closeModal={closeSucessfullModal}
        Heading={
          editRecruiterMode
            ? MESSAGES?.updateRecruiter?.message?.heading
            : MESSAGES?.addRecruiter?.message?.heading
        }
        sucessfullMsg={successfullMsg}
      />
      <ErrorModal
        openModal={openErrorModal}
        errorMessage={errorMsgs}
        closeModal={closeErrorModal}
      />
    </>
  );
};

export default AddEditRecruiter;
