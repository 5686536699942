import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SideBar from '@/components/layout/SideBar';
import Box from '@mui/material/Box';
import CustomPagination from '@/elements/pagination/customPagination';
import GoTOPage from '@/elements/gotopage/gotopage';
import MultiSelectFilter from '@/components/filter';
import { firstLetterCapital, toTitleCase } from '@/helper';
import { LoaderContext } from '@/components/Loader/Loader';
import SortColumn from '@/elements/SortColumn';
import { getJobsList } from '@/services/job-service/job-service';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import MultiSelectTagFilter from '@/components/multiSelectTagFilter';
import { getStaticData } from '@/services/static-data-service';

const Jobs = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const setLoading = useContext(LoaderContext);
  const [sortByASC, setSortByASC] = useState(true);
  const [sortByColoumn, setSortByColoumn] = useState('');
  const [allJobs, setAllJobs] = useState([]);
  const [filterStatus, setFilterStatus] = useState([]);
  const [AllTags, setAllTags] = useState([]);
  const [jobPostsFilter, setJobPostsFilter] = useState([
    'Assigned',
    'Unassigned'
  ]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0,
    search: '',
    status: [],
    tagArray: []
  });
  const [searchText, setSearchText] = useState(
    state ? state?.jobDetails?.title : ''
  );

  useEffect(() => {
    if (!state) {
      getAllJobs();
    }
  }, []);

  useEffect(() => {
    if (state && allJobs?.length > 0) {
      let filteredData = allJobs?.filter(
        (item) => item?.jobId === state?.jobDetails?.jobId
      );
      setAllJobs(filteredData);
    }
  }, [state?.jobDetails?.jobId, allJobs?.length]);

  useEffect(() => {
    getAllStaticData();
  }, []);

  const getAllStaticData = async () => {
    try {
      const response = await getStaticData();
      const { data, meta } = response;
      if (meta?.status) {
        setAllTags(data?.tag);
      }
    } catch (error) {
      console.log('axios error --->', error);
    }
  };

  const getAllJobs = async (
    recordsPerPage = pagination?.recordsPerPage,
    currentPage = pagination?.currentPage,
    search = pagination?.search,
    status = pagination?.status,
    columnName = sortByColoumn,
    sort = sortByASC ? 'ASC' : 'DESC',
    tagArray = pagination?.tagArray
  ) => {
    setLoading(true);
    const response = await getJobsList(
      currentPage,
      recordsPerPage,
      search,
      columnName,
      sort,
      status,
      '',
      tagArray
    );
    const { data, meta } = response;
    if (meta?.status) {
      setPagination((prev) => ({
        ...prev,
        totalRecord: data?.count ? data?.count : 0
      }));
      setAllJobs(data?.rows);
      setLoading(false);
    }
  };

  const goToJobDetails = (jobId, jobTitle, companyId) => {
    navigate('/job-listing', {
      state: {
        companyId: companyId,
        jobId: jobId,
        jobTitle: jobTitle,
        addResponsiveClass: true,
        fromJobsTab: true
      }
    });
  };

  const changePage = (event, value) => {
    setPagination({ ...pagination, currentPage: value });
    getAllJobs(
      pagination?.recordsPerPage,
      value,
      pagination?.search,
      pagination?.status,
      pagination?.tagArray
    );
  };

  useEffect(() => {
    if (state?.jobDetails) {
      setPagination({
        ...pagination,
        search: state?.jobDetails?.title
      });
      getAllJobs(
        pagination?.recordsPerPage,
        1,
        state?.jobDetails?.title,
        pagination?.status,
        sortByColoumn,
        sortByASC ? 'ASC' : 'DESC',
        pagination?.tagArray
      );
    }
  }, []);

  const searchInJobPosts = (searchText) => {
    setPagination({ ...pagination, currentPage: 1, search: searchText });
    setSearchText(searchText);
    getAllJobs(
      pagination?.recordsPerPage,
      1,
      searchText,
      pagination?.status,
      pagination?.tagArray
    );
    navigate(location.pathname, { replace: true });
  };

  const applyFilterSelection = (selectedOptions) => {
    setFilterStatus(selectedOptions);
    setPagination({ ...pagination, status: selectedOptions });
    getAllJobs(
      pagination?.recordsPerPage,
      1,
      pagination?.search,
      selectedOptions,
      pagination?.tagArray
    );
  };

  const clearFilterSelection = () => {
    setFilterStatus([]);
    getAllJobs(
      pagination?.recordsPerPage,
      pagination?.currentPage,
      pagination?.search,
      [],
      pagination?.tagArray
    );
  };

  const getSortByData = (columnName) => {
    setPagination({ ...pagination, status: filterStatus });
    getAllJobs(
      pagination?.recordsPerPage,
      pagination?.currentPage,
      pagination?.search,
      filterStatus,
      columnName,
      sortByASC ? 'ASC' : 'DESC',
      pagination?.tagArray
    );
    setSortByColoumn(columnName);
    setSortByASC(!sortByASC);
  };

  const handleTagFilterChange = (selectedOptions) => {
    setPagination({ ...pagination, tagArray: selectedOptions });
    getAllJobs(
      pagination?.recordsPerPage,
      pagination?.currentPage,
      pagination?.search,
      filterStatus,
      sortByColoumn,
      sortByASC ? 'ASC' : 'DESC',
      selectedOptions
    );
  };

  const handleTagFilterClose = () => {
    setPagination({ ...pagination, tagArray: [] });
    getAllJobs(
      pagination?.recordsPerPage,
      pagination?.currentPage,
      pagination?.search,
      filterStatus,
      sortByColoumn,
      sortByASC ? 'ASC' : 'DESC',
      []
    );
  };

  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid__col">
          <div className="page page__full jobPage">
            <div className="page__head">
              <h2 className="listCol__head--title">
                <span>All Jobs</span>
              </h2>
              <div className="listCol__head--actions">
                <div className="formField formFieldSearch">
                  <div className="formField__group sm">
                    <span className="formField__icon">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                          stroke="#6C737F"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <input
                      type="text"
                      className="formField__input"
                      placeholder="Search a job/recruiter"
                      value={searchText}
                      onChange={(e) => searchInJobPosts(e?.target?.value)}
                    />
                  </div>
                </div>
                <MultiSelectFilter
                  labels={jobPostsFilter}
                  applyFilter={applyFilterSelection}
                  clearFilter={clearFilterSelection}
                />
              </div>
            </div>

            <div className="page__body">
              <Box className="tabContainer">
                <div className="tabContainer__item">
                  <TableContainer component={Paper} className="tableContainer">
                    <Table className="table tableClickable">
                      <TableHead>
                        <TableRow>
                          <TableCell>S.</TableCell>
                          <TableCell onClick={() => getSortByData('title')}>
                            <SortColumn
                              thName={'Job Title'}
                              sortByASC={sortByASC}
                            />
                          </TableCell>
                          <TableCell>Department/ Location</TableCell>
                          <TableCell>Company Name</TableCell>
                          <TableCell>Qualification / Exp.</TableCell>
                          <TableCell>
                            <div className="sortingDiv tagFilter">
                              Tags
                              <MultiSelectTagFilter
                                labels={AllTags}
                                applyFilter={handleTagFilterChange}
                                clearFilter={handleTagFilterClose}
                              />
                            </div>
                          </TableCell>
                          <TableCell>Recruiter</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allJobs.length ? (
                          allJobs.map((row, index) => {
                            return (
                              <TableRow
                                key={`jobstable${row?.jobId}`}
                                onClick={() =>
                                  goToJobDetails(
                                    row?.jobId,
                                    row?.title,
                                    row?.companyId
                                  )
                                }
                              >
                                <TableCell component="th" scope="row">
                                  {pagination?.currentPage === 1
                                    ? index + 1
                                    : (pagination?.currentPage - 1) *
                                        pagination?.recordsPerPage +
                                      (index + 1)}
                                </TableCell>
                                <TableCell>
                                  <div className="cellText">
                                    <span className="cellText__title">
                                      {firstLetterCapital(row?.title)}
                                    </span>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="cellText">
                                    <span className="cellText__dark">
                                      {row?.department}
                                    </span>
                                    <span className="cellText__light">
                                      {row?.location}
                                    </span>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="cellText">
                                    <span className="cellText__light">
                                      {row?.employee?.company?.companyName}
                                    </span>
                                  </div>
                                </TableCell>

                                <TableCell>
                                  <div className="cellText">
                                    <span className="cellText__dark">
                                      {row?.qualification.join(', ')}
                                    </span>
                                    <span className="cellText__light">
                                      {row?.minExperience && row?.maxExperience
                                        ? row?.minExperience +
                                          '-' +
                                          row?.maxExperience +
                                          ' Years'
                                        : '--'}
                                    </span>
                                  </div>
                                </TableCell>
                                <TableCell className="widthAuto">
                                  <div className="cellText cellText--tag">
                                    {row?.tag?.length
                                      ? row?.tag?.map((item, index) => (
                                          <span
                                            key={index}
                                            className="btn btn__primary btn__rounded"
                                            style={{
                                              background: item?.colorCode
                                            }}
                                          >
                                            {toTitleCase(item?.tagName)}
                                          </span>
                                        ))
                                      : '--'}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  {row?.assignedStatus
                                    ? firstLetterCapital(
                                        row?.recruiter?.firstName
                                      ) +
                                      ' ' +
                                      firstLetterCapital(
                                        row?.recruiter?.lastName
                                      )
                                    : '--'}
                                </TableCell>
                                <TableCell>
                                  {firstLetterCapital(row?.status)} /{' '}
                                  {row?.assignedStatus
                                    ? 'Assigned'
                                    : 'Unassigned'}
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <div className="no-data floating tableFloating">
                            No Results Found!
                          </div>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <div className="pagination">
                    <div className="pagination__result">{`${
                      pagination?.totalRecord >
                      pagination?.currentPage * pagination?.recordsPerPage
                        ? pagination?.currentPage * pagination?.recordsPerPage
                        : pagination?.totalRecord
                    } of ${pagination?.totalRecord} results`}</div>
                    {pagination?.totalRecord ? (
                      <CustomPagination
                        totalPageCount={Math.ceil(
                          pagination?.totalRecord / pagination.recordsPerPage
                        )}
                        changePage={(event, value) => changePage(event, value)}
                        page={pagination?.currentPage}
                      />
                    ) : (
                      ''
                    )}
                    {pagination?.totalRecord ? (
                      <div className="pagination__actions">
                        <GoTOPage changePage={changePage} />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Jobs;
