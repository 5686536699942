import React from 'react';

const InformationModal = ({ closeModal, info, heading }) => {
  return (
    <>
      <div className={`modal modalPrompt modal--open`}>
        <div className="modal__dialog">
          <div className="modal__content">
            <div className="modal__header">
              <h2 className="modal__header--title">{heading ? heading : ''}</h2>
            </div>
            <div className="modal__body">
              <h4 className="promptMessage">{info}</h4>
            </div>
            <div className="modal__footer">
              <div className="btn__group">
                <button
                  className="btn btn__primary"
                  onClick={() => closeModal()}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
    </>
  );
};

export default InformationModal;
