import React, { useState, useEffect, useCallback, useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import _ from 'lodash';
import moment from 'moment';
import Pagination from '@/components/pagination';
import CustomPagination from '@/elements/pagination/customPagination';
import GoTOPage from '@/elements/gotopage/gotopage';
import ArrowDown from '@/elements/ArrowDown';
import { Currency, firstLetterCapital } from '@/helper';
import { getcandidateList } from '@/services/billing-service/billing-service';
import { Link } from 'react-router-dom';
import { BillingAmountModal } from '@/components/modals/billingAmount-modal';
import { PreviewInvoiceModal } from '@/components/modals/previewInvoice-modal';
import { LoaderContext } from '@/components/Loader/Loader';
import Search from '@/assets/svgs/Search';
import EditIcon from '@/assets/svgs/EditIcon';
import EyeIcon from '@/assets/svgs/EyeIcon';

const JobsBillingDetails = ({
  activeJobId,
  ActiveCompanyData,
  UnbilledJobAmount,
  JobsLengthCount
}) => {
  const setLoading = useContext(LoaderContext);

  const [CandidatesList, setCandidatesList] = useState([]);
  const [ScreeningId, setScreeningId] = useState('');
  const [OpenAddAmountModal, setOpenAddAmountModal] = useState(false);
  const [OpenPreviewInvoiceModal, setOpenPreviewInvoiceModal] = useState(false);
  const [BillingAmount, setBillingAmount] = useState('');

  const [pagination, setPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0,
    search: '',
    status: ''
  });

  useEffect(() => {
    getBillingJobCandidates();
  }, [activeJobId]);

  const getBillingJobCandidates = async (
    recordsPerPage = pagination?.recordsPerPage,
    currentPage = pagination?.currentPage,
    search = pagination?.search,
    status = pagination?.status,
    jobId = activeJobId
  ) => {
    setLoading(true);
    const response = await getcandidateList(
      recordsPerPage,
      currentPage,
      search,
      status,
      jobId
    );
    if (response) {
      setPagination((prev) => ({
        ...prev,
        totalRecord: response?.count ? response?.count : 0
      }));
      setCandidatesList(response?.rows);
      setLoading(false);
    }
  };

  const searchInBillingJobs = useCallback(
    _.debounce(
      (searchText) =>
        getBillingJobCandidates(
          pagination?.recordsPerPage,
          pagination?.currentPage,
          searchText,
          pagination?.status
        ),
      500
    ),
    []
  );

  const handleChange = (e) => {
    setPagination({ ...pagination, status: e?.target?.value });
    getBillingJobCandidates(
      pagination?.recordsPerPage,
      pagination?.currentPage,
      pagination?.search,
      e?.target?.value
    );
  };

  const changePage = (event, value) => {
    setPagination({ ...pagination, currentPage: value });
  };

  const handleCloseAmountModal = () => {
    setOpenAddAmountModal(false);
  };

  return (
    <>
      {JobsLengthCount ? (
        <>
          <ul className="tags__list">
            {ActiveCompanyData?.lastBillAmount &&
              ActiveCompanyData?.lastBilledDate && (
                <li className="tags__list--item">
                  Last Billed /Date:
                  <b>
                    {Currency(
                      ActiveCompanyData?.lastBillAmount
                        ? ActiveCompanyData?.lastBillAmount
                        : 0
                    )}
                    ,{' '}
                    {moment(ActiveCompanyData?.lastBilledDate).format(
                      'D MMM,YYYY'
                    )}
                  </b>
                </li>
              )}
            {UnbilledJobAmount && (
              <li className="tags__list--item text--danger">
                Total Unbilled:
                <b>{Currency(UnbilledJobAmount ? UnbilledJobAmount : 0)}</b>
              </li>
            )}
          </ul>
          <div className="tabContainer__item--head">
            <div className="listCol__head--actions w_100">
              <div className="formRow formRow__3 m_0 w_100">
                <div className="formCol">
                  <div className="formField">
                    <div className="formField__group sm">
                      <span className="formField__icon">
                        <Search />
                      </span>
                      <input
                        type="text"
                        className="formField__input"
                        placeholder="Search a candidate"
                        onChange={(e) => searchInBillingJobs(e?.target?.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="formCol">
                  <div className="formField">
                    <FormControl
                      fullWidth
                      size="small"
                      className="formField__select"
                      sx={{ minHeight: '42px' }}
                    >
                      <Select
                        onChange={(e) => handleChange(e)}
                        IconComponent={() => <ArrowDown />}
                        placeholder="Country"
                        name="country"
                        value={pagination?.status ?? null}
                        displayEmpty
                        renderValue={(selected) => {
                          if (!selected) {
                            return (
                              <span style={{ opacity: '0.8' }}>
                                Select status
                              </span>
                            );
                          }

                          return pagination?.status;
                        }}
                      >
                        <MenuItem key="Billed" value="Billed">
                          Billed
                        </MenuItem>
                        <MenuItem key="Unbilled" value="Unbilled">
                          Unbilled
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TableContainer
            component={Paper}
            className="tableContainer tableContainerBilling"
          >
            <Table className="table tableClickable">
              <TableHead>
                <TableRow>
                  <TableCell>S.</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email Address</TableCell>
                  <TableCell>Approved On</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              {CandidatesList?.length ? (
                <TableBody>
                  {CandidatesList.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell>
                        <div className="cellText">
                          <span className="cellText__title">
                            {firstLetterCapital(row?.candidate?.firstName) +
                              ' ' +
                              firstLetterCapital(row?.candidate?.lastName)}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="cellText">
                          <span className="cellText__dark">
                            {row?.candidate?.email}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="cellText">
                          <span className="cellText__dark">
                            {moment(row?.updatedAt).format('D MMM , YYYY')}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="cellText">
                          <span
                            className={
                              row?.billStatus === false
                                ? 'cellText__dark text--danger'
                                : 'cellText__dark text--primary'
                            }
                          >
                            {row?.billStatus ? 'Billed' : 'Unbilled'}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="actions flex--end">
                          {row?.invoiceLink && !row?.invoiceSent && (
                            <button
                              className="btn btn__white btn--sm"
                              onClick={() => {
                                setOpenAddAmountModal(true);
                                setScreeningId(row?.sreeningId);
                              }}
                            >
                              <EditIcon />
                            </button>
                          )}
                          {row?.invoiceSent && row?.invoiceLink && (
                            <Link
                              className="btn btn__primary btn__outlined btn--sm"
                              to={row?.invoiceLink}
                              style={{ cursor: 'pointer' }}
                              target="_blank"
                            >
                              <EyeIcon />
                            </Link>
                          )}
                          {row?.invoiceLink && (
                            <button
                              className={`btn btn__white w_fitContent ${
                                row?.invoiceSent && row?.invoiceLink
                                  ? 'formField__disabled'
                                  : ''
                              }`}
                              onClick={() => {
                                if (!row?.invoiceSent) {
                                  setOpenPreviewInvoiceModal(true);
                                  setScreeningId(row?.sreeningId);
                                }
                              }}
                            >
                              {row?.invoiceSent ? 'Sent' : 'Send'}
                            </button>
                          )}
                          {!row?.invoiceLink && (
                            <button
                              className="btn btn__white"
                              onClick={() => {
                                setOpenAddAmountModal(true);
                                setScreeningId(row?.sreeningId);
                              }}
                            >
                              Approve
                            </button>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <div className="no-data floating">No Results Found!</div>
              )}
            </Table>
          </TableContainer>
          <div className="pagination">
            <div className="pagination__result">{`${
              Pagination?.totalRecord >
              pagination?.currentPage * pagination?.recordsPerPage
                ? pagination?.recordsPerPage
                : pagination?.totalRecord
            } of ${pagination?.totalRecord} results`}</div>
            {pagination?.totalRecord ? (
              <CustomPagination
                totalPageCount={Math.ceil(
                  pagination?.totalRecord / pagination.recordsPerPage
                )}
                changePage={(event, value) => changePage(event, value)}
              />
            ) : (
              ''
            )}
            {pagination?.totalRecord ? (
              <div className="pagination__actions">
                <GoTOPage changePage={changePage} />
              </div>
            ) : (
              ''
            )}
          </div>
        </>
      ) : (
        <div className="no-data">No Results Found!</div>
      )}
      <BillingAmountModal
        openModal={OpenAddAmountModal}
        handleCloseAmountModal={handleCloseAmountModal}
        ScreeningId={ScreeningId}
        SelectedJobId={activeJobId}
        getCandidateListing={getBillingJobCandidates}
        setBillingAmount={setBillingAmount}
        BillingAmount={BillingAmount}
      />
      <PreviewInvoiceModal
        openModal={OpenPreviewInvoiceModal}
        closeModal={() => setOpenPreviewInvoiceModal(false)}
        ScreeningId={ScreeningId}
        SelectedJobId={activeJobId}
        onSendClick={true}
        getCandidateListing={getBillingJobCandidates}
      />
    </>
  );
};

export default JobsBillingDetails;
