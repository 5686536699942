import httpService from '@/services/http-service';
import { STATIC_DATA } from '@/services/end-points';

export const getStaticData = async () => {
  try {
    const response = await httpService.get(STATIC_DATA, {});
    return response;
  } catch (error) {
    return error;
  }
};
