import httpService from '@/services/http-service';
import {
  ADD_TAGS,
  TAG_LIST,
  UPDATE_TAGS,
  DELETE_TAG,
  CHANGE_STATUS_TAG,
  GET_COMPANY_LIST,
  GET_CUSTOMER_TAG_LIST
} from '@/services/end-points';

export const addTagsDetails = async (tagName, colorCode, role, employeeId) => {
  try {
    const response = await httpService.post(ADD_TAGS, {
      tagName,
      colorCode,
      role,
      employeeId
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllTagList = async (limit = 20, page = 1, tagName, status) => {
  try {
    const response = await httpService.post(TAG_LIST, {
      limit,
      page,
      tagName,
      status
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateTagsDetails = async (
  id,
  tagName,
  colorCode,
  role,
  employeeId,
  companyId
) => {
  try {
    const response = await httpService.put(UPDATE_TAGS, {
      id,
      tagName,
      colorCode,
      role,
      employeeId,
      companyId
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteTag = async (tagId) => {
  try {
    const response = await httpService.put(DELETE_TAG, {
      tagId
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getCustomerTagsList = async (
  limit,
  page,
  tagName,
  status,
  companyArray,
  employeeArray
) => {
  try {
    const response = await httpService.post(GET_CUSTOMER_TAG_LIST, {
      limit,
      page,
      tagName,
      status,
      companyArray,
      employeeArray
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const changeTagStatus = async (tagId, status, employeeId) => {
  try {
    const response = await httpService.put(CHANGE_STATUS_TAG, {
      tagId,
      status,
      employeeId
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getCompanyList = async () => {
  try {
    const response = await httpService.get(GET_COMPANY_LIST);
    return response;
  } catch (error) {
    return error;
  }
};
