import httpService from '@/services/http-service';
import {
  ADD_CANDIDATE,
  UPDATE_CANDIDATE,
  DELETE_CANDIDATE,
  GET_CANDIDATE_LISTING,
  UPLOAD_CANDIDATE_RESUME
} from '@/services/end-points';

export const addCandidate = async (data) => {
  try {
    const response = await httpService.post(ADD_CANDIDATE, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateCandidate = async (data) => {
  try {
    const response = await httpService.put(UPDATE_CANDIDATE, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteCandidate = async (data) => {
  try {
    const response = await httpService.put(DELETE_CANDIDATE, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getCandidateListing = async (data) => {
  try {
    const response = await httpService.post(GET_CANDIDATE_LISTING, data);
    return response;
  } catch (error) {
    return error;
  }
};
export const uploadCandidateResume = async (formData) => {
  try {
    const response = await httpService.post(UPLOAD_CANDIDATE_RESUME, formData);
    return response;
  } catch (error) {
    return error;
  }
};
