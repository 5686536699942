import React from 'react';

export default function Recruiter() {
  return (
    <svg
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="80" height="80" rx="40" fill="#F79009" />
      <path
        d="M55.083 46.25C59.6854 46.25 63.4163 49.981 63.4163 54.5833V58.75H59.2497M48.833 37.6541C52.4272 36.729 55.083 33.4663 55.083 29.5833C55.083 25.7003 52.4272 22.4376 48.833 21.5125M42.583 29.5833C42.583 34.1857 38.852 37.9167 34.2497 37.9167C29.6473 37.9167 25.9163 34.1857 25.9163 29.5833C25.9163 24.981 29.6473 21.25 34.2497 21.25C38.852 21.25 42.583 24.981 42.583 29.5833ZM25.9163 46.25H42.583C47.1854 46.25 50.9163 49.981 50.9163 54.5833V58.75H17.583V54.5833C17.583 49.981 21.314 46.25 25.9163 46.25Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
