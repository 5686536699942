import React from 'react';

function DotGreenIcon() {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="5.5" cy="5.5" r="5.5" fill="#039855" />
    </svg>
  );
}

export default DotGreenIcon;
