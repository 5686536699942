import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ArrowDownTag from '@/assets/svgs/ArrowDownTag';
import EditTagIcon from '@/assets/svgs/EditTagIcon';
import Tooltip from '@mui/material/Tooltip';
import ColorPickersDropdown from '@/elements/colorPicker/colorPickersDropdown';
import { updateTagsDetails } from '@/services/settings-service/tags/tags-service';
import { convertToTitleCase } from '@/helper';
import DisableTagIcon from '@/assets/svgs/DisableTagIcon';
import EnableTagIcon from '@/assets/svgs/EnableTagIcon';

const TagsList = ({ tagsData, editTag, deleteTag, refreshPage }) => {
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [openColorPickerIndex, setOpenColorPickerIndex] = useState(false);

  const clickToOpenColorPicker = (index) => {
    if (index === openColorPickerIndex) {
      setOpenColorPicker(!openColorPicker);
    } else {
      setOpenColorPicker(true);
    }
    setOpenColorPickerIndex(index);
  };

  const updateTagColor = async (tagData) => {
    const updateTagRes = await updateTagsDetails(
      tagData?.id,
      tagData?.tagName,
      tagData?.colorCode
    );

    if (updateTagRes?.meta?.status) {
      setOpenColorPicker(false);
      setOpenColorPickerIndex(false);
      refreshPage();
    }
  };

  return (
    <>
      <div className="tabContainer">
        <div className="tabContainer__item">
          <TableContainer className="tableContainer">
            <Table className="table">
              <TableHead>
                <TableRow>
                  <TableCell>S.</TableCell>
                  <TableCell>Tag Color</TableCell>
                  <TableCell>Tag Name</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              {tagsData?.length !== 0 ? (
                <TableBody>
                  {tagsData?.map((item, index) => {
                    return (
                      <TableRow
                        key={`tagRow${index}`}
                        className={`${!item?.activate ? 'tagError' : ''}`}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          <div
                            className="tagDropdown"
                            onClick={() => clickToOpenColorPicker(index)}
                          >
                            <span
                              className="tagDropdown__colorFill"
                              style={{
                                backgroundColor: item?.colorCode
                              }}
                            ></span>
                            <ArrowDownTag />
                          </div>
                          {openColorPicker &&
                            openColorPickerIndex === index && (
                              <ColorPickersDropdown
                                open={openColorPicker}
                                handleColorChange={() => {}}
                                close={() => setOpenColorPicker(false)}
                                onSubmit={updateTagColor}
                                tagData={item}
                              />
                            )}
                        </TableCell>
                        <TableCell>
                          <button
                            className="btnTag"
                            style={{
                              backgroundColor: item?.colorCode
                            }}
                          >
                            {convertToTitleCase(item?.tagName)}
                          </button>
                        </TableCell>
                        <TableCell>
                          <div className="actions flex--end">
                            <Tooltip title="Edit" arrow placement="top">
                              <button
                                className="btn p_0 border_0"
                                onClick={() => editTag(item)}
                              >
                                <EditTagIcon />
                              </button>
                            </Tooltip>
                            {item?.activate ? (
                              <Tooltip title="Deactivate" arrow placement="top">
                                <span
                                  onClick={() => deleteTag(item)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <DisableTagIcon />
                                </span>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Activate" arrow placement="top">
                                <span
                                  onClick={() => deleteTag(item)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <EnableTagIcon />
                                </span>
                              </Tooltip>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <div className="no-data floating">No Results Found!</div>
              )}
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default TagsList;
