import React from 'react';

export default function ArrowUpIcon() {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.833313 9.16732L9.16665 0.833984M9.16665 0.833984H0.833313M9.16665 0.833984V9.16732"
        stroke="#28A0E8"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
