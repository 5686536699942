import { useEffect, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import CustomPagination from '@/elements/pagination/customPagination';
import { getRecruitersJobAssignedData } from '@/services/recruiters-service/recruiters-service';

function JobAssigned({ recruiterId, RefreshJobAssignedData }) {
  const [jobAssignedData, setJobAssignedData] = useState([]);
  const [totalResults, setTotalResults] = useState(null);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0
  });

  useEffect(() => {
    getRecruitersJobAssigned();
  }, []);

  useEffect(() => {
    getRecruitersJobAssigned();
  }, [RefreshJobAssignedData]);

  async function getRecruitersJobAssigned() {
    const response = await getRecruitersJobAssignedData({
      page: '1',
      limit: '20',
      recruiterId
    });
    setJobAssignedData(response?.rows);
    setTotalResults(response?.count);
    setTotalPageCount(Math.ceil(response?.count / pagination.recordsPerPage));
  }

  const changePage = (event, value) => {
    setPagination({ ...pagination, currentPage: value });
  };

  const getJobStatus = (JobData) => {
    let desiredRecruiter = JobData?.approvedRecruiter?.find(
      (recruiter) => recruiter.recruiterId === recruiterId
    );
    const status =
      desiredRecruiter?.status === 'active'
        ? 'Open'
        : desiredRecruiter?.status === 'paused'
          ? 'Paused'
          : desiredRecruiter?.status === 'reopen'
            ? 'Reopen'
            : 'Closed';
    return status;
  };

  return (
    <>
      {jobAssignedData.length ? (
        <>
          <TableContainer
            component={Paper}
            className="tableContainer tableContainer--recruiter"
          >
            <Table className="table tableClickable">
              <TableHead>
                <TableRow>
                  <TableCell>S.</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Company Name</TableCell>
                  <TableCell>Department</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Experience</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Candidates</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobAssignedData.length &&
                  jobAssignedData.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell>
                          <div className="cellText">
                            <span className="cellText__title">
                              {item.title}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="cellText">
                            <span className="cellText__dark">
                              {item.company?.companyName}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="cellText">
                            <span className="cellText__dark">
                              {item.department}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>{item.location}</TableCell>
                        <TableCell>
                          {item.minExperience} - {item.maxExperience}{' '}
                          {item.maxExperience > 1 ? 'Years' : 'Year'}
                        </TableCell>
                        <TableCell>{getJobStatus(item)}</TableCell>
                        <TableCell>{item?.screenings?.length}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="pagination">
            <div className="pagination__result">{`${totalResults} of ${totalResults} results`}</div>
            {jobAssignedData.length && (
              <CustomPagination
                totalPageCount={totalPageCount}
                changePage={(event, value) => changePage(event, value)}
              />
            )}
            <div className="pagination__actions">
              <h6>Go to page</h6>
              <input type="text" className="formField__input sm" />
              <button
                disabled={totalResults <= pagination.recordsPerPage}
                className="btn btn__primary"
              >
                Go
              </button>
            </div>
          </div>
        </>
      ) : (
        <TableContainer className="tableContainer tableContainer--recruiter">
          <div className="no-data">No Results Found!</div>
        </TableContainer>
      )}
    </>
  );
}

export default JobAssigned;
