import httpService from '@/services/http-service';
import {
  GET_NPS_REVIEWS_RATINGS,
  DELETE_RECRUITER_REVIEWS_RATINGS,
  UNHIDE_RECRUITER_REVIEWS_RATINGS,
  GET_NPS_SCORE
} from '@/services/end-points';

export const getNPSReviewsRatings = async (
  limit,
  page,
  search,
  startDate,
  endDate,
  forCSV
) => {
  try {
    const response = await httpService.post(GET_NPS_REVIEWS_RATINGS, {
      limit,
      page,
      search,
      startDate: startDate,
      endDate: endDate,
      forCSV: forCSV
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteRecruiterReviewsRatings = async (body) => {
  try {
    const response = await httpService.put(
      DELETE_RECRUITER_REVIEWS_RATINGS,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const unhideRecruiterReviewsRatings = async (body) => {
  try {
    const response = await httpService.put(
      UNHIDE_RECRUITER_REVIEWS_RATINGS,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getNPSScore = async () => {
  try {
    const response = await httpService.get(GET_NPS_SCORE);
    return response;
  } catch (error) {
    return error;
  }
};
