import { React, useState } from 'react';
import AuthImage from '@/assets/images/auth-img.png';
import { Link, useNavigate } from 'react-router-dom';
import { KeyLockSvg, LockSvg, openEyeSvg, closeEyeSvg } from '@/assets/svgs';
import { passwordRegex } from '@/regex';
import { SuccessfullyModal } from '@/components/modals/successfully-modal';
import ErrorModal from '@/components/modals/error-modal';
import { SaveUserDetails } from '@/helper';
import { resetPassword } from '@/services/common-service/common-service';
import * as CONSTANTS from '@/utils/constants';
import * as MESSAGES from '@/utils/popupMessages';

const CreatePassword = () => {
  const navigate = useNavigate();
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isPasswordView, setIsPasswordView] = useState(false);
  const [isConfirmPasswordView, setIsConfirmPasswordView] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [openSuccessfullModal, setOpenSuccessfullModal] = useState(false);
  const [successfullMsg, setSuccessfullMsg] = useState('');
  const url = new URL(window?.location?.href);
  const searchParams = url.searchParams;
  const RegisteredEmail = searchParams.get('email');
  const key = searchParams.get('key');

  const closeErrorModal = () => {
    setOpenErrorModal(false);
  };
  const closeSucessfullModal = () => {
    setOpenSuccessfullModal(false);
  };

  const handleChange = (fieldname, event) => {
    let field = fields;
    handleValidation(fieldname, event);
    field[fieldname] = event.target.value;
    setFields({ ...field });
  };

  const handleValidation = (fieldname, e) => {
    let errorsT = errors;
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    let updatedValue = fieldValue;

    if (fieldname === undefined || fieldname === 'password') {
      if (fieldname === undefined) {
        fieldValue = fields['password'] ? fields['password'] : '';
        updatedValue = fieldValue;
      }
      delete errorsT['password'];
      delete errorsT['passwordError'];
      if (!updatedValue || updatedValue.trim() === '') {
        formIsValid = false;
        errorsT['password'] = CONSTANTS?.PASSWORD?.validation?.required;
        errorsT['passwordError'] = true;
      } else if (RegisteredEmail === updatedValue.toLowerCase()) {
        formIsValid = false;
        errorsT['password'] = CONSTANTS?.PASSWORD?.validation?.emailDiff;
        errorsT['passwordError'] = true;
      } else if (updatedValue.length < 8) {
        formIsValid = false;
        errorsT['password'] = CONSTANTS?.PASSWORD?.validation?.maxChar;
        errorsT['passwordError'] = true;
      } else if (!passwordRegex.test(updatedValue)) {
        formIsValid = false;
        errorsT['password'] = CONSTANTS?.PASSWORD?.validation?.includeChar;
        errorsT['passwordError'] = true;
      } else if (
        fields?.confirmPassword &&
        fields?.confirmPassword !== updatedValue
      ) {
        formIsValid = false;
        errorsT['password'] = '';
        errorsT['confirmPassword'] = CONSTANTS?.PASSWORD?.validation?.match;
        errorsT['confirmPasswordError'] = true;
      } else if (
        fields?.confirmPassword &&
        fields?.confirmPassword === updatedValue
      ) {
        delete errorsT['confirmPassword'];
        delete errorsT['confirmPasswordError'];
      }
    }

    if (fieldname === undefined || fieldname === 'confirmPassword') {
      if (fieldname === undefined) {
        fieldValue = fields['confirmPassword'] ? fields['confirmPassword'] : '';
        updatedValue = fieldValue;
      }
      delete errorsT['confirmPassword'];
      delete errorsT['confirmPasswordError'];
      if (!updatedValue || updatedValue.trim() === '') {
        formIsValid = false;
        errorsT['confirmPassword'] =
          CONSTANTS?.CONFIRM_PASSWORD?.validation?.required;
        errorsT['confirmPasswordError'] = true;
      } else if (fields?.password !== updatedValue) {
        formIsValid = false;
        errorsT['confirmPassword'] =
          CONSTANTS?.CONFIRM_PASSWORD?.validation?.match;
        errorsT['confirmPasswordError'] = true;
      } else if (fields?.password && fields?.password === updatedValue) {
        delete errorsT['password'];
        delete errorsT['passwordError'];
      }
    }

    setErrors({
      ...errorsT
    });
    return {
      formIsValid,
      updatedValue
    };
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const { formIsValid } = handleValidation();
    if (formIsValid) {
      let body = {
        email: RegisteredEmail ?? null,
        password: fields?.password ?? null,
        key: key ?? null,
        role: 'admin'
      };
      const response = await resetPassword(body);
      const { data, meta } = response;
      const { message } = MESSAGES?.password;
      if (meta?.status) {
        setSuccessfullMsg(message?.successfull);
        setOpenSuccessfullModal(true);
        SaveUserDetails({
          token: data?.session_token,
          email: fields?.email,
          sessionid: data?.session?.id,
          Oryid: data?.session?.identity?.id,
          adminId: data?.adminId
        });
        setTimeout(() => {
          navigate('/dashboard');
        }, 3000);
      } else {
        setErrorMsg(response?.meta?.msg);
        setOpenErrorModal(true);
        setTimeout(() => {
          setOpenErrorModal(false);
        }, 3000);
      }
    } else {
      console.log('form is invalid');
    }
    setLoading(false);
  };

  return (
    <>
      <div className="container__fullScreen bg--light">
        <div className="auth__container">
          <form className="auth__form">
            <div className="auth__form--logo">{KeyLockSvg}</div>
            <img
              src={AuthImage}
              className="auth__form--img"
              alt="Authentication"
            />
            <h4 className="auth__form--heading">Password Recovery</h4>

            <div
              className={`formField ${
                errors?.passwordError ? 'fieldError' : ''
              }`}
            >
              <label className="formField__label">Password</label>
              <div className="formField__group">
                <span className="formField__icon">{LockSvg}</span>
                <input
                  type={isPasswordView ? 'text' : 'password'}
                  className="formField__input"
                  placeholder="Enter password"
                  onChange={(e) => handleChange('password', e)}
                  value={fields?.password ?? ''}
                />
                <div className="formField__icon">
                  {isPasswordView ? (
                    <span onClick={() => setIsPasswordView(false)}>
                      {openEyeSvg}
                    </span>
                  ) : (
                    <span onClick={() => setIsPasswordView(true)}>
                      {closeEyeSvg}
                    </span>
                  )}
                </div>
              </div>
              <span className="formField__error">{errors['password']}</span>
            </div>

            <div
              className={`formField ${
                errors?.confirmPasswordError ? 'fieldError' : ''
              }`}
            >
              <label className="formField__label">Confirm Password</label>
              <div className="formField__group">
                <span className="formField__icon">{LockSvg}</span>
                <input
                  type={isConfirmPasswordView ? 'text' : 'password'}
                  className="formField__input"
                  placeholder="Enter password"
                  onChange={(e) => handleChange('confirmPassword', e)}
                  value={fields?.confirmPassword ?? ''}
                />
                <div className="formField__icon">
                  {isConfirmPasswordView ? (
                    <span onClick={() => setIsConfirmPasswordView(false)}>
                      {openEyeSvg}
                    </span>
                  ) : (
                    <span onClick={() => setIsConfirmPasswordView(true)}>
                      {closeEyeSvg}
                    </span>
                  )}
                </div>
              </div>
              <span className="formField__error">
                {errors['confirmPassword']}
              </span>
            </div>

            <button
              onClick={(e) => {
                handleSubmit(e);
              }}
              disabled={loading ? true : false}
              type="submit"
              className="btn btn__primary auth__form--submit"
            >
              Submit
            </button>

            <div className="auth__form--divider">
              <span>Or</span>
            </div>

            <Link to="/" className="link link__primary">
              Back to Sign in
            </Link>
          </form>
        </div>
      </div>
      <SuccessfullyModal
        openModal={openSuccessfullModal}
        closeModal={closeSucessfullModal}
        sucessfullMsg={successfullMsg}
        Heading={MESSAGES?.password?.message?.heading}
      />
      <ErrorModal
        openModal={openErrorModal}
        errorMessage={errorMsg}
        closeModal={closeErrorModal}
      />
    </>
  );
};

export default CreatePassword;
