import React from 'react';

export default function DownloadFileIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.6665 11.3334L11.3332 4.66675M11.3332 4.66675H4.6665M11.3332 4.66675V11.3334"
        stroke="#384250"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
