import httpService from '@/services/http-service';
import {
  RECRUITERS_JOB_ASSIGNED,
  RECRUITER_STATUS_CHANGE,
  RECRUITERS_LIST,
  ADD_RECRUITER,
  UPDATE_RECRUITER,
  DELETE_RECRUITER,
  ADD_RECRUITER_EDUDETAILS,
  ACTIVATE_RECRUITER,
  CHECK_RECRUITER_DEACTIVATION,
  FETCH_RECRUITER_TAX_INFO,
  GET_RECRUITER_DETAILS,
  UPDATE_REVIEW_RATINGS
} from '@/services/end-points';

export const fetchRecruiterTaxInfo = async (recruiterId) => {
  try {
    const response = await httpService.get(
      `${FETCH_RECRUITER_TAX_INFO}${recruiterId}`
    );
    return response;
  } catch (error) {
    console.log('---->axios error', error);
    return error;
  }
};

export const getRecruiterDetails = async (recruiterId) => {
  try {
    const response = await httpService.get(
      `${GET_RECRUITER_DETAILS}${recruiterId}`
    );
    return response;
  } catch (error) {
    console.log('---->axios error', error);
    return error;
  }
};

export const addRecruiter = async (body) => {
  try {
    const response = await httpService.post(ADD_RECRUITER, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateRecruiter = async (body) => {
  try {
    const response = await httpService.put(UPDATE_RECRUITER, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const getRecruiterList = async ({
  page,
  limit,
  search,
  skillSet,
  status
}) => {
  try {
    const response = await httpService.post(RECRUITERS_LIST, {
      page,
      limit,
      search,
      skillSet,
      status
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getRecruitersJobAssignedData = async ({
  page,
  limit,
  recruiterId
}) => {
  try {
    const response = await httpService.post(RECRUITERS_JOB_ASSIGNED, {
      page,
      limit,
      recruiterId
    });
    const { data, meta } = response;
    if (meta?.status) {
      return data;
    }
  } catch (error) {
    return error;
  }
};

export const changeRecruiterStatus = async ({ status, recruiterId }) => {
  try {
    const response = await httpService.put(RECRUITER_STATUS_CHANGE, {
      status,
      recruiterId
    });
    const { data, meta } = response;
    if (meta?.status) {
      return data;
    }
  } catch (error) {
    return error;
  }
};

export const deleteRecruiter = async (body) => {
  try {
    const response = await httpService.put(DELETE_RECRUITER, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const activateRecruiter = async (body) => {
  try {
    const response = await httpService.put(ACTIVATE_RECRUITER, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const addRecruiterEducationalDetails = async (body) => {
  try {
    const response = await httpService.put(ADD_RECRUITER_EDUDETAILS, body);
    const { data, meta } = response;
    if (meta?.status) {
      return data;
    }
  } catch (error) {
    return error;
  }
};

export const checkForRecruiterDeactivation = async (recruiterId) => {
  try {
    const response = await httpService.get(
      `${CHECK_RECRUITER_DEACTIVATION}${recruiterId}`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const UpdateReviewRatings = async (body) => {
  try {
    const response = await httpService.put(UPDATE_REVIEW_RATINGS, body);
    return response;
  } catch (error) {
    return error;
  }
};
