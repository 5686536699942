import React from 'react';
import Dot from '@/assets/svgs/Dot';
import CustomPagination from '@/elements/pagination/customPagination';
import { Currency } from '@/helper';

const JobsListing = ({
  jobList,
  activeJobId,
  onJobSelect,
  recruiterJobsPagination,
  JobsPageChange,
  totalUnpaidAmount,
  setAddResponsiveClass
}) => {
  const getJobPaidUnpaidStatus = (screenings) => {
    if (!screenings.length) {
      return 'Unpaid';
    }
    const allPaid = screenings.filter((t) => !t.recruiterAmountPaid).length
      ? 'Unpaid'
      : screenings
            .filter((item) => item.recruiterAmountAdded)
            .filter((item) => !item.recruiterAmountPaid).length
        ? 'Unpaid'
        : 'Paid';
    return allPaid;
  };
  return (
    <>
      {jobList?.length > 0 ? (
        <>
          <div className="listColTitle totalBilled">
            <p>Total Unpaid</p>
            <h4>{Currency(totalUnpaidAmount)}</h4>
          </div>
          <ul className="listCol__list listing__list">
            {jobList?.map((job, index) => {
              return (
                <li
                  className={`listCol__list--item ${activeJobId === job?.jobId ? 'listCol__item--selected' : ''}`}
                  onClick={() => {
                    onJobSelect(job);
                    setAddResponsiveClass(true);
                  }}
                >
                  <div className="item item--recruiter item--jobs">
                    <div cclassNamelass="item__info">
                      <h4 className="item__info--title">{job?.title}</h4>
                      <div className="item__info--subtitle">
                        {job?.department && (
                          <p>
                            <span>Department:</span>
                            {job?.department}
                          </p>
                        )}
                        <span className="status">
                          {job?.department && <Dot />}
                          <span className="fw500">Status:</span>
                          <span
                            className={`${getJobPaidUnpaidStatus(job?.screenings) === 'Paid' ? 'text--primary' : 'text--danger'}`}
                          >
                            {getJobPaidUnpaidStatus(job?.screenings)}
                          </span>
                        </span>
                      </div>
                      <div className="item__info--subtitle">
                        {job?.company?.companyName && (
                          <>
                            <p>
                              <span>Customer:</span> {job?.company?.companyName}
                            </p>
                            <span>
                              {job?.company?.companyName && <Dot />}
                              {job?.location}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </>
      ) : (
        <div className="no-data">No Results Found!</div>
      )}
      <div className="pagination pagination__center">
        {jobList.length ? (
          <CustomPagination
            totalPageCount={Math.ceil(
              recruiterJobsPagination?.totalRecord /
                recruiterJobsPagination.recordsPerPage
            )}
            changePage={(event, value) => JobsPageChange(event, value)}
            page={recruiterJobsPagination?.currentPage}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default JobsListing;
