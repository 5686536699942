import React, { useState } from 'react';
import { sendBillingInvoice } from '@/services/billing-service/billing-service';
import { SuccessfullyModal } from '@/components/modals/successfully-modal';
import { Link } from 'react-router-dom';

export const PreviewInvoiceModal = ({
  openModal,
  closeModal,
  ScreeningId,
  getCandidateListing,
  SelectedJobId,
  onSendClick,
  InvoiceUrl,
  setBillingAmount
}) => {
  const [EmailSentMessage, setEmailSentMessage] = useState('');
  const [openSuccessfullModal, setOpenSuccessfullModal] = useState(false);

  const onSubmit = async () => {
    let screeningid = ScreeningId;
    try {
      const response = await sendBillingInvoice(screeningid);
      const { meta, data } = response;
      if (meta?.status) {
        closeModal();
        setEmailSentMessage(response?.meta?.msg);
        setOpenSuccessfullModal(true);
        getCandidateListing();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div
        className={`modal modalPrompt modalForm ${
          openModal ? 'modal--open' : ''
        }`}
      >
        <div className="modal__dialog sm">
          <div className="modal__content">
            <div className="modal__header">
              <h2 className="modal__header--title">
                {onSendClick ? '' : 'Amount Entered Successfully!'}
              </h2>
            </div>
            <div className="modal__body">
              <div className="modal__body--message text--dark">
                Kindly review the invoice before forwarding it to the customer.
              </div>
            </div>
            <div className="modal__footer">
              <div className="btn__group">
                <Link
                  className="btn btn__white w_100"
                  to={InvoiceUrl}
                  target="_blank"
                >
                  Invoice Preview
                </Link>
                <button className="btn btn__primary w_100" onClick={onSubmit}>
                  Send Invoice
                </button>
              </div>
              <button
                className="link link__danger mt_1"
                onClick={() => {
                  closeModal(false);
                  getCandidateListing();
                  setBillingAmount('');
                }}
              >
                Not Now
              </button>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
      <SuccessfullyModal
        openModal={openSuccessfullModal}
        closeModal={() => setOpenSuccessfullModal(false)}
        sucessfullMsg={EmailSentMessage}
        Heading={'Email Sent!'}
      />
    </>
  );
};
