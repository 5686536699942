import React, { useState, useEffect } from 'react';
import CloseIconGray from '@/assets/svgs/CloseIconGray';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import { UpdateReviewRatings } from '@/services/recruiters-service/recruiters-service';
import { SuccessfullyModal } from '@/components/modals/successfully-modal';
import ErrorModal from '@/components/modals/error-modal';
import { GetUserDetails } from '@/helper';

const AddEditReviewsRatingsModal = ({
  openModal,
  closeModal,
  SelectedRating,
  setOpenAddEditReviewModal,
  setRefreshRecruiterDetails,
  refreshRecruiterDetails,
  setSelectedRating
}) => {
  const loggedInUser = GetUserDetails();
  const [ratingsArray, setRatingsArray] = useState([]);
  const [QualityRatingValue, setQualityRatingValue] = useState(0);
  const [CommunicationRatingValue, setCommunicationRatingValue] = useState(0);
  const [ExpertiseRatingvalue, setExpertiseRatingvalue] = useState(0);
  const [ProfessionalismRatingValue, setProfessionalismRatingValue] =
    useState(0);
  const [HireAgainRatingValue, setHireAgainRatingValue] = useState(0);
  const [Explainationtext, setExplainationtext] = useState('');
  const [OpenSuccessfullModal, setOpenSuccessfullModal] = useState(false);
  const [OpenErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (SelectedRating?.rating?.length) {
      setExplainationtext(SelectedRating?.notes);
      setRatingsArray(SelectedRating?.rating);
      SelectedRating?.rating?.map((item) => {
        if (item?.type === 'quality') {
          setQualityRatingValue(item?.rating);
        } else if (item?.type === 'communication') {
          setCommunicationRatingValue(item?.rating);
        } else if (item?.type === 'expertise') {
          setExpertiseRatingvalue(item?.rating);
        } else if (item?.type === 'professionalism') {
          setProfessionalismRatingValue(item?.rating);
        } else if (item?.type === 'hireAgain') {
          setHireAgainRatingValue(item?.rating);
        }
      });
      setHasChanges(false);
    }
  }, [SelectedRating?.rating?.length]);

  const handleTextAreaChange = (e) => {
    setExplainationtext(e?.target?.value);
    setHasChanges(true);
  };

  const handleRatings = (type, value) => {
    const ratingIndex = ratingsArray.findIndex(
      (rating) => rating.type === type
    );
    if (ratingIndex !== -1) {
      const updatedRatingsArray = [...ratingsArray];
      updatedRatingsArray[ratingIndex].rating = value;
      setRatingsArray(updatedRatingsArray);
    } else {
      const newRating = { type: type, rating: value };
      setRatingsArray((prevRatingsArray) => [...prevRatingsArray, newRating]);
    }
    setHasChanges(true);
  };

  const handleSubmitRatings = async () => {
    let body = {
      updatedById: loggedInUser?.adminId,
      ratingId: SelectedRating?.ratingId,
      ratingArray: ratingsArray,
      notes: Explainationtext ? Explainationtext : ''
    };
    try {
      const response = await UpdateReviewRatings(body);
      const { meta } = response;
      if (meta?.status) {
        setOpenSuccessfullModal(true);
        setOpenAddEditReviewModal(false);
        setRefreshRecruiterDetails(!refreshRecruiterDetails);
        setSelectedRating({});
      } else {
        setOpenErrorModal(true);
        setErrorMessage(meta?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div
        className={`modal modalGiveFeedback ${openModal ? 'modal--open' : ''}`}
      >
        <div className="modal__dialog">
          <div className="modal__content modal__content--xxxl">
            <div className="modal__header">
              <h2 className="modal__header--title">Review / Rating</h2>
              <button
                onClick={closeModal}
                className="btn btn__transparent btnClose"
              >
                <CloseIconGray />
              </button>
            </div>
            <div className="modal__body modal-body-left">
              <h4>Your Rating</h4>
              <div className="rattingList">
                <div className="rattingList__item">
                  <div className="label">Quality</div>
                  <div className="ratings">
                    <span className="ratings__star ratings__star--fill">
                      <Rating
                        name="hover-feedback"
                        value={QualityRatingValue}
                        precision={1}
                        onChange={(event, newValue) => {
                          handleRatings('quality', newValue);
                          setQualityRatingValue(newValue);
                        }}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                    </span>
                  </div>
                </div>
                <div className="rattingList__item">
                  <div className="label">Communication</div>
                  <div className="ratings">
                    <span className="ratings__star ratings__star--fill">
                      <Rating
                        name="hover-feedback"
                        value={CommunicationRatingValue}
                        precision={1}
                        onChange={(event, newValue) => {
                          handleRatings('communication', newValue);
                          setCommunicationRatingValue(newValue);
                        }}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                    </span>
                  </div>
                </div>
                <div className="rattingList__item">
                  <div className="label">Expertise</div>
                  <div className="ratings">
                    <span className="ratings__star ratings__star--fill">
                      <Rating
                        name="hover-feedback"
                        value={ExpertiseRatingvalue}
                        precision={1}
                        onChange={(event, newValue) => {
                          handleRatings('expertise', newValue);
                          setExpertiseRatingvalue(newValue);
                        }}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                    </span>
                  </div>
                </div>
                <div className="rattingList__item">
                  <div className="label">Professionalism</div>
                  <div className="ratings">
                    <span className="ratings__star ratings__star--fill">
                      <Rating
                        name="hover-feedback"
                        value={ProfessionalismRatingValue}
                        precision={1}
                        onChange={(event, newValue) => {
                          handleRatings('professionalism', newValue);
                          setProfessionalismRatingValue(newValue);
                        }}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                    </span>
                  </div>
                </div>
                <div className="rattingList__item">
                  <div className="label">Hire Again?</div>
                  <div className="ratings">
                    <span className="ratings__star ratings__star--fill">
                      <Rating
                        name="hover-feedback"
                        value={HireAgainRatingValue}
                        precision={1}
                        onChange={(event, newValue) => {
                          handleRatings('hireAgain', newValue);
                          setHireAgainRatingValue(newValue);
                        }}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <h5>Share Your Experience</h5>
                <p>
                  Your feedback is important! By sharing your experience, you
                  can help others make informed decisions when choosing a
                  recruiter to work with.
                </p>
              </div>
              <div className="formField">
                <div className="fromField__label">
                  Note: Your review will be publicly available to others.
                </div>
                <div className="feedbackCommentBox">
                  <textarea
                    className="formField__textarea"
                    rows={3}
                    value={Explainationtext}
                    onChange={(e) => handleTextAreaChange(e)}
                    placeholder="Add your comments..."
                    maxLength={500}
                  ></textarea>
                  <span className="feedbackCommentBox__charactersText">
                    {Explainationtext?.length}/500 characters
                  </span>
                </div>
              </div>
              <div className="btn__group">
                <button className="btn btn__white" onClick={closeModal}>
                  Cancel
                </button>
                <button
                  className="btn btn__primary"
                  onClick={handleSubmitRatings}
                  disabled={!hasChanges}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
      <SuccessfullyModal
        openModal={OpenSuccessfullModal}
        closeModal={() => setOpenSuccessfullModal(false)}
        sucessfullMsg={
          'Review / rating has been updated successfully for the recruiter.'
        }
        Heading={'Review Updated!'}
      />
      <ErrorModal
        openModal={OpenErrorModal}
        errorMessage={errorMessage}
        closeModal={() => setOpenErrorModal(false)}
      />
    </>
  );
};

export default AddEditReviewsRatingsModal;
