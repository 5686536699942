import React, { useEffect, useState, useContext, useRef } from 'react';
import CustomPagination from '@/elements/pagination/customPagination';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import ArrowDown from '@/elements/ArrowDown';
import { formatPhoneNumber } from '@/helper';
import { LoaderContext } from '@/components/Loader/Loader';
import Search from '@/assets/svgs/Search';
import SearchCross from '@/assets/svgs/SearchCross';
import Filter from '@/assets/svgs/Filter';
import PlusIcon from '@/assets/svgs/PlusIcon';
import { getRecruiterList } from '@/services/recruiters-service/recruiters-service';

const statusesArray = ['All', 'pending', 'approved', 'rejected', 'deactivate'];

const RecruiterListing = ({
  activeRecruiterId,
  onRecruiterSelection,
  setValue,
  setAddRecruiterScreen,
  editRecruiterMode,
  refreshRecruitersListing,
  setAddResponsiveClass,
  editMode
}) => {
  const setLoading = useContext(LoaderContext);
  const searchRef = useRef('');

  const [recruiters, setRecruiters] = useState([]);
  const [searchanchorEl, setSeachAnchorEl] = useState(null);
  const [filteranchorEl, setFilterAnchorEl] = useState(null);
  const searchOpen = Boolean(searchanchorEl);
  const filterOpen = Boolean(filteranchorEl);
  const searchPopoverid = searchOpen ? 'simple-popover' : '';
  const filterPopoverid = filterOpen ? 'simple-popover' : '';
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [TotalListCount, setTotalListCount] = useState();
  const [pagination, setPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0
  });
  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('All');
  const [statusFilterApply, setStatusFilterApply] = useState('');

  useEffect(() => {
    getFilterData();
  }, [
    pagination.currentPage,
    refreshRecruitersListing,
    statusFilterApply,
    search
  ]);

  const getFilterData = async () => {
    setLoading(true);
    const response = await getRecruiterList({
      page: `${pagination.currentPage}`,
      limit: `${pagination.recordsPerPage}`,
      search: search,
      skillSet: [],
      status: statusFilterApply
    });
    const { data, meta } = response;
    if (meta?.status) {
      setRecruiters(data?.rows);
      setTotalPageCount(Math.ceil(data?.count / pagination.recordsPerPage));
      setTotalListCount(data?.count);
      onRecruiterSelection(
        data?.rows[0]?.recruiterId,
        data?.rows[0],
        data?.count,
        true
      );
    }

    setLoading(false);
  };

  const filterStatusChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleSeachClick = (event) => {
    setSeachAnchorEl(event.currentTarget);
  };

  const handleSearchClose = () => {
    setSeachAnchorEl(null);
    setSearch('');
    searchRef.current.value = '';
    setPagination({ ...pagination, currentPage: 1 });
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const addRecruiterFormOpen = () => {
    setAddResponsiveClass(true);
    setAddRecruiterScreen(true);
    editRecruiterMode(false);
  };

  const changePage = (event, value) => {
    setPagination({ ...pagination, currentPage: value });
  };

  const searchRecruiterListing = (e) => {
    setSearch(e.target.value);
    setPagination({ ...pagination, currentPage: 1 });
    setValue('1');
  };

  return (
    <div className="layoutGrid__col">
      <div
        className={`listCol ${
          filteranchorEl
            ? 'listCol__filter'
            : searchanchorEl
              ? 'listCol__search'
              : ''
        }`}
      >
        <div className="listCol__head listing__head">
          <div className="searchContainer">
            <div className="formField">
              <div className="formField__group sm">
                <span className="formField__icon">
                  <Search />
                </span>
                <input
                  type="text"
                  className="formField__input"
                  ref={searchRef}
                  placeholder="Search recruiter"
                  onChange={(e) => searchRecruiterListing(e)}
                />
              </div>
            </div>
            <button
              className="btn btn__transparent border_0 p_0"
              onClick={handleSearchClose}
            >
              <SearchCross />
            </button>
          </div>
          <h2 className="listCol__head--title">
            <span>Recruiters</span>
          </h2>
          <div className="listCol__head--actions">
            <div className="formField">
              <button
                className="btn btn__white p_0"
                aria-describedby={searchPopoverid}
                onClick={handleSeachClick}
              >
                <Search />
              </button>
            </div>

            <div>
              <button
                className="btn btn__white p_0"
                aria-describedby={filterPopoverid}
                onClick={handleFilterClick}
              >
                <Filter />
              </button>
            </div>
            <button
              className="btn btn__white p_0"
              onClick={addRecruiterFormOpen}
              disabled={editMode ? true : false}
            >
              <PlusIcon />
            </button>
          </div>
        </div>

        <ul className="listCol__list listing__list">
          {recruiters.length ? (
            <>
              {recruiters.map((row, index) => {
                let liClassName = 'listCol__list--item sm';
                liClassName =
                  activeRecruiterId === row?.recruiterId
                    ? liClassName + ' ' + 'listCol__item--selected'
                    : liClassName;
                liClassName = row?.deleteStatus
                  ? liClassName + ' ' + 'listCol__item--alerterror'
                  : liClassName;

                let initialClassName = 'initials initials__md circle';
                initialClassName =
                  row?.status === 'approved'
                    ? initialClassName + ' ' + 'circle__approve'
                    : initialClassName;
                initialClassName =
                  row?.status === 'pending'
                    ? initialClassName + ' ' + 'circle__reject'
                    : initialClassName;
                initialClassName = row?.deleteStatus
                  ? 'initials initials__md circle'
                  : initialClassName;
                return (
                  <li
                    key={`recruiterList${row?.recruiterId}`}
                    className={liClassName}
                    onClick={() => {
                      onRecruiterSelection(
                        row?.recruiterId,
                        row,
                        TotalListCount
                      );
                      setValue('1');
                    }}
                  >
                    <div className="item item--recruiter">
                      <div className={initialClassName}>
                        {row?.firstName?.[0].toUpperCase()}
                      </div>
                      <div className="item__info">
                        {row?.firstName?.[0] && row?.lastName?.[0] && (
                          <h4 className="item__info--title">
                            {`${`${row?.firstName?.[0].toUpperCase()}${row?.firstName.slice(
                              1
                            )}`} ${`${row?.lastName?.[0].toUpperCase()}${row?.lastName.slice(
                              1
                            )}`}`}
                          </h4>
                        )}
                        <div className="item__info--subtitle">
                          {row?.education && (
                            <span>
                              {`${row.education}`}
                              <span>/</span>
                            </span>
                          )}
                          {row?.experience && (
                            <span>
                              {row?.experience === 0
                                ? 'Fresher'
                                : row?.experience > 1
                                  ? `${row?.experience} years`
                                  : `${row?.experience} year`}
                            </span>
                          )}
                        </div>
                        <div className="item__info--subtitle">
                          <p>
                            <b>E: </b>
                            {row?.email}
                          </p>
                        </div>
                        <div className="item__info--subtitle">
                          <p>
                            {row?.phoneNumber && (
                              <>
                                <b>P: </b>
                                {row.countryCode}{' '}
                                {formatPhoneNumber(row?.phoneNumber)}
                              </>
                            )}
                          </p>
                          {row?.deleteStatus && (
                            <div className="item__info--subtitle item__info--subtitle--error">
                              Account Deactivated
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </>
          ) : (
            <div className="no-data">No Results Found!</div>
          )}
        </ul>

        <div className="pagination pagination__center">
          {recruiters.length ? (
            <CustomPagination
              page={pagination.currentPage}
              totalPageCount={totalPageCount}
              changePage={(event, value) => changePage(event, value)}
            />
          ) : (
            ''
          )}
        </div>
        <div className="filter__head">
          <h2 className="listCol__head--title">Filter</h2>
          <button
            className="btn btn__transparent border_0 p_0"
            onClick={handleFilterClose}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                stroke="#150823"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>

        <div className="filterContainer">
          <ul className="listCol__list filter__list">
            <li>
              <div className="formRow">
                <div className="formCol">
                  <div className="formField">
                    <label className="formField__label">Status</label>
                    <FormControl
                      fullWidth
                      size="small"
                      className="formField__select"
                    >
                      <Select
                        onChange={(e) => filterStatusChange(e)}
                        IconComponent={() => <ArrowDown />}
                        name="statusFilter"
                        value={
                          statusFilter &&
                          `${statusFilter[0].toUpperCase()}${statusFilter.slice(
                            1
                          )}`
                        }
                        displayEmpty
                        renderValue={(selected) => {
                          if (!selected) {
                            return (
                              <span style={{ opacity: '0.8' }}>Select</span>
                            );
                          }
                          return selected;
                        }}
                      >
                        {statusesArray?.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {`${item[0].toUpperCase()}${item.slice(1)}`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <div className="filter__footer">
            <div className="btn__group flex--between">
              <button
                className="btn btn__transparent p_0"
                onClick={(e) => {
                  setStatusFilter('');
                  setStatusFilterApply('');
                  handleFilterClose();
                }}
              >
                Clear
              </button>
              <button
                className="btn btn__primary"
                onClick={(e) => {
                  setStatusFilterApply(statusFilter);
                  setPagination({ ...pagination, currentPage: 1 });
                  handleFilterClose();
                  setValue('1');
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruiterListing;
