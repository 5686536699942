import React from 'react';

export default function EditFormIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_680_6601)">
        <path
          d="M3.28854 11.5616L6.36931 14.6424C6.50777 14.7808 6.71546 14.7808 6.85393 14.6424L14.5385 6.92315C14.677 6.78469 14.677 6.577 14.5385 6.43854L11.4924 3.39238C11.3539 3.25392 11.1462 3.25392 11.0078 3.39238L3.28854 11.1116C3.15008 11.2501 3.15008 11.4578 3.28854 11.5616ZM12.4962 1.97315C12.3578 2.11161 12.3578 2.31931 12.4962 2.45777L15.5424 5.50392C15.6808 5.64238 15.8885 5.64238 16.027 5.50392L16.8924 4.63854C17.4462 4.11931 17.4462 3.28854 16.8924 2.73469L15.2655 1.10777C14.7116 0.553921 13.8462 0.553921 13.2924 1.10777L12.4962 1.97315ZM0.727003 16.6847C0.657773 17.0308 0.969311 17.3424 1.31546 17.2732L5.08854 16.3732C5.227 16.3385 5.33085 16.2693 5.40008 16.2001L5.46931 16.1308C5.53854 16.0616 5.57316 15.8193 5.4347 15.6808L2.31931 12.5655C2.18085 12.427 1.93854 12.4616 1.86931 12.5308L1.80008 12.6001C1.69623 12.7039 1.66162 12.8078 1.627 12.9116L0.727003 16.6847Z"
          fill="#585858"
        />
      </g>
      <defs>
        <clipPath id="clip0_680_6601">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
