import { React, useState } from 'react';
import AuthImage from '@/assets/images/auth-img.png';
import { Link, useNavigate } from 'react-router-dom';
import { KeyLockSvg, LockSvg } from '@/assets/svgs';
import { EmailRegex } from '@/regex';
import ErrorModal from '@/components/modals/error-modal';
import { SuccessfullyModal } from '@/components/modals/successfully-modal';
import { forgotPassword } from '@/services/common-service/common-service';
import * as CONSTANTS from '@/utils/constants';
import * as MESSAGES from '@/utils/popupMessages';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const [openSuccessfullModal, setOpenSuccessfullModal] = useState(false);
  const [successfullMsg, setSuccessfullMsg] = useState('');

  const closeSucessfullModal = () => {
    setOpenSuccessfullModal(false);
  };

  const closeErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleChange = (fieldName, event) => {
    let field = fields;
    handleValidation(fieldName, event);
    field[fieldName] = event.target.value;
    setFields({ ...field });
  };

  const handleValidation = (fieldname, e) => {
    let errorsT = errors;
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    let updatedValue = fieldValue;

    if (fieldname === undefined || fieldname === 'email') {
      if (fieldname === undefined) {
        fieldValue = fields['email'] ? fields['email'] : '';
        updatedValue = fieldValue;
      }
      delete errorsT['email'];
      delete errorsT['emailErr'];
      if (!updatedValue || updatedValue.trim() === '') {
        formIsValid = false;
        errorsT['email'] = CONSTANTS?.EMAIL?.validation?.required;
        errorsT['emailErr'] = true;
      } else if (!EmailRegex.test(updatedValue)) {
        formIsValid = false;
        errorsT['email'] = CONSTANTS?.EMAIL?.validation?.valid;
        errorsT['emailErr'] = true;
      }
    }

    setErrors({
      ...errorsT
    });
    return {
      formIsValid,
      updatedValue
    };
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const { formIsValid } = handleValidation();
    if (formIsValid) {
      let body = {
        email: fields?.email
      };
      const response = await forgotPassword(body);
      const { meta } = response;
      const { message } = MESSAGES?.forgotPassword;
      if (meta?.status) {
        setSuccessfullMsg(message?.successfull);
        setOpenSuccessfullModal(true);
        setTimeout(() => {
          navigate('/');
        }, 3000);
      } else {
        setErrorMsg(response?.meta?.msg);
        setOpenErrorModal(true);
      }
    } else {
      console.log('form is invalid');
    }
    setLoading(false);
  };

  return (
    <>
      <div className="container__fullScreen bg--light">
        <div className="auth__container">
          <form className="auth__form">
            <div className="auth__form--logo">{KeyLockSvg}</div>
            <img
              src={AuthImage}
              className="auth__form--img"
              alt="Authentication"
            />
            <h4 className="auth__form--heading">Forgot Password?</h4>

            <p className="auth__form--text">
              Please enter your email address. We will send you an email to
              reset your password.
            </p>

            <div className={`formField ${errors?.email ? 'fieldError' : ''}`}>
              <label className="formField__label">Email address</label>
              <div className="formField__group">
                <span className="formField__icon">{LockSvg}</span>
                <input
                  type="text"
                  className="formField__input"
                  placeholder="Enter email address"
                  onChange={(e) => handleChange('email', e)}
                  value={fields?.email ?? ''}
                />
              </div>
              <span className="formField__error">{errors['email']}</span>
            </div>

            <button
              onClick={(e) => {
                handleSubmit(e);
              }}
              disabled={loading ? true : false}
              type="submit"
              className="btn btn__primary auth__form--submit"
            >
              Send Email
            </button>

            <div className="auth__form--divider">
              <span>Or</span>
            </div>

            <Link to="/" className="link link__primary">
              Back to Sign in
            </Link>
          </form>
        </div>
      </div>
      <SuccessfullyModal
        openModal={openSuccessfullModal}
        closeModal={closeSucessfullModal}
        sucessfullMsg={successfullMsg}
        Heading={MESSAGES?.forgotPassword?.message?.heading}
      />
      <ErrorModal
        openModal={openErrorModal}
        errorMessage={errorMsg}
        closeModal={closeErrorModal}
      />
    </>
  );
};

export default ForgotPassword;
