import React, { useState, useEffect, useContext } from 'react';
import { getInterviewCustomerList } from '@/services/interview-service/interview-service';
import FollowersCount from '@/assets/svgs/FollowersCount';
import CustomPagination from '@/elements/pagination/customPagination';
import { LoaderContext } from '@/components/Loader/Loader';
import { firstLetterCapital } from '@/helper';

const InterviewCustomers = ({
  setAddResponsiveClass,
  activeCompanyId,
  onCompanySelection,
  searchText = '',
  setTabValue
}) => {
  const setLoading = useContext(LoaderContext);
  const [customers, setCustomers] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0,
    search: searchText,
    industryType: []
  });

  useEffect(() => {
    getCustomersList(
      pagination?.currentPage,
      pagination?.recordsPerPage,
      searchText,
      pagination?.industryType
    );
  }, [searchText]);

  useEffect(() => {
    setTabValue('1');
  }, []);

  const getCustomersList = async (
    currentPage = pagination?.currentPage,
    recordsPerPage = pagination?.recordsPerPage,
    search = pagination?.search,
    industryType = pagination?.industryType
  ) => {
    setLoading(true);
    const response = await getInterviewCustomerList(
      currentPage,
      recordsPerPage,
      search,
      industryType
    );
    const { meta, data } = response;
    if (meta?.status) {
      setPagination((prev) => ({
        ...prev,
        totalRecord: data?.count ? data?.count : 0
      }));
      if (data?.count && !activeCompanyId) {
        onCompanySelection(
          data?.rows[0]?.id,
          data?.rows[0]?.employees?.length,
          data?.rows[0]?.status
        );
      }
      setCustomers(data?.rows);
    }
    setLoading(false);
  };

  const getFormattedAddress = (row) => {
    const valuesArray = [
      row?.address,
      row?.city,
      row?.state,
      row?.zipcode,
      row?.country
    ].filter((value) => value);
    const formattedAddress = valuesArray.join(', ');
    return formattedAddress;
  };

  const changePage = (event, value) => {
    setPagination({ ...pagination, currentPage: value });
    getCustomersList(
      value,
      pagination?.recordsPerPage,
      pagination?.search,
      pagination?.industryType
    );
  };

  return (
    <>
      <ul className="listCol__list listing__list">
        {customers.map((row, index) => {
          let liClassName = 'listCol__list--item';
          liClassName =
            activeCompanyId === row?.id
              ? liClassName + ' ' + 'listCol__item--selected'
              : liClassName;
          liClassName =
            row?.status === 'delete'
              ? liClassName + ' ' + 'listCol__item--alerterror'
              : liClassName;
          return (
            <li
              key={`interviewCustomers${row?.id}`}
              className={liClassName}
              onClick={() => {
                onCompanySelection(
                  row?.id,
                  row?.employees?.length,
                  row?.status
                );
                setAddResponsiveClass(true);
                setTabValue('1');
              }}
            >
              <div className="item">
                {row?.companyLogo ? (
                  <div className="item__logo">
                    <img src={row?.companyLogo} alt="Logo" />
                  </div>
                ) : (
                  <div className="initials initials__lg">
                    {row?.companyName?.charAt(0).toUpperCase()}
                  </div>
                )}
                <div className="item__info">
                  <h4 className="item__info--title">
                    {firstLetterCapital(row?.companyName)}
                  </h4>
                  <div className="item__info--subtitle">
                    <p>{row?.industryType.join(', ')}</p>
                    <span>
                      <FollowersCount />
                      {row?.employees.length
                        ? row?.employees.filter(
                            (item) => item.status === 'active'
                          ).length
                        : 0}
                    </span>
                  </div>
                  <p className="item__info--desc">{getFormattedAddress(row)}</p>
                  {row?.status === 'delete' && (
                    <div className="item__info--subtitle item__info--subtitle--error">
                      Account Deactivated
                    </div>
                  )}
                </div>
              </div>
            </li>
          );
        })}
        {customers?.length ? (
          ''
        ) : (
          <div className="no-data">No Results Found!</div>
        )}
      </ul>
      <div className="pagination pagination__center">
        {customers.length ? (
          <CustomPagination
            page={pagination.currentPage}
            totalPageCount={Math.ceil(
              pagination?.totalRecord / pagination?.recordsPerPage
            )}
            changePage={(event, value) => changePage(event, value)}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default InterviewCustomers;
