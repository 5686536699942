import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext
} from 'react';
import SideBar from '@/components/layout/SideBar';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import _ from 'lodash';
import Search from '@/assets/svgs/Search';
import SearchCross from '@/assets/svgs/SearchCross';
import CustomerBilling from './Customer/CustomerBilling';
import RecruiterBilling from './Recruiter/RecruiterBilling';
import Filter from '@/assets/svgs/Filter';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowDown from '@/elements/ArrowDown';
import { LoaderContext } from '@/components/Loader/Loader';
import RecruiterJobsListing from './Recruiter/RecruiterJobsListing';
import CustomerJobsListing from './Customer/CustomerJobsListing';
import {
  getBillingJobsList,
  getBillingRecruitersList,
  getBillingCustomersList,
  getBillingRecruiterJobsList
} from '@/services/billing-service/billing-service';
import { useLocation } from 'react-router-dom';

const BillingpaymentsIndex = () => {
  const [searchanchorEl, setSeachAnchorEl] = useState(null);
  const [filteranchorEl, setFilterAnchorEl] = useState(null);
  const filterOpen = Boolean(filteranchorEl);
  const searchOpen = Boolean(searchanchorEl);
  const [OpenSeachField, setOpenSeachField] = useState(false);

  const searchPopoverid = searchOpen ? 'simple-popover' : '';
  const filterPopoverid = filterOpen ? 'simple-popover' : '';
  // COMMON STATES
  const { state } = useLocation();
  const searchRef = useRef('');
  const setLoading = useContext(LoaderContext);
  const [value, setValue] = useState(state?.value ?? '1');
  const [searchText, setSearchText] = useState(
    state ? state?.recruiterDetails?.recruiterName : ''
  );
  const [addResponsiveClass, setAddResponsiveClass] = useState(false);
  const [addRecruiterResponsiveClass, setAddRecruiterResponsiveClass] =
    useState(false);
  const [FilterApplied, setFilterApplied] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  // COMPANY STATES
  const BillingStatus = ['All', 'Billed', 'Unbilled'];
  const RecruiterFilterStatus = ['All', 'Paid', 'Unpaid'];
  const RecruiterJobsFilterStatus = ['Paid', 'Unpaid'];
  const [customerList, setCustomerList] = useState([]);
  const [UnbilledAmount, setUnbilledAmount] = useState(0);
  const [customerLengthCount, setCustomerLengthCount] = useState();
  const [activeCompanyData, setActiveCompanyData] = useState({});
  const [selectedJobsFilterStatus, setSelectedJobsFilterStatus] = useState([]);
  // RECRUITER STATES
  const [BillingFilterStatus, setBillingFilterStatus] = useState([
    'Billed',
    'Unbilled'
  ]);
  const [activeRecruiter, setActiveRecruiter] = useState({});
  const [jobList, setJobList] = useState([]);
  const [recruitersList, setRecruitersList] = useState([]);
  const [recruiterCount, setRecruiterCount] = useState();
  const [activeRecruiterId, setActiveRecruiterId] = useState('');
  const [recruiterJobsCount, setRecruiterJobsCount] = useState();
  const [recruiterJobsList, setRecruitersJobsList] = useState([]);
  const [TotalUnbilledAmount, setTotalUnbilledAmount] = useState('');
  const [fromOutstandingScreen, setFromOutstandingScreen] = useState(
    state?.fromOutstandingScreen
  );
  useEffect(() => {
    if (state?.fromOutstandingScreen) {
      window.history.replaceState({}, '');
    }
  }, []);
  // Company List pagination
  const [pagination, setPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0,
    search: '',
    status: '',
    industryType: []
  });
  // Jobs List related to particular company Pagination
  const [customerJobsPagination, setCustomerJobsPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    search: '',
    status: '',
    industryType: [],
    totalRecord: 0
  });
  // Recruiter List Pagination
  const [recruiterPagination, setRecruiterPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0,
    search: '',
    status: ''
  });
  // Jobs List related to recruiter pagination
  const [recruiterJobsPagination, setRecruiterJobsPagination] = useState({
    currentPage: 1,
    recordsPerPage: 20,
    totalRecord: 0,
    search: '',
    status: []
  });

  useEffect(() => {
    if (value === '1') {
      getCustomerList();
    }
  }, [value, pagination?.currentPage]);

  useEffect(() => {
    if (value === '2' && !fromOutstandingScreen) {
      getRecruiterList();
    }
  }, [value, recruiterPagination?.currentPage]);

  // ********************* COMMON CODE**********************************
  const handleSeachClick = (event) => {
    setSeachAnchorEl(event.currentTarget);
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleTabValueChange = (event, newValue) => {
    setRecruiterPagination({ ...pagination, search: '' });
    setOpenSeachField(false);
    setSearchText('');
    setValue(newValue);
  };

  const handleSearchClose = () => {
    setOpenSeachField(false);
    setValue(value);
    searchRef.current.value = '';
    setSeachAnchorEl(null);
    setSearchText('');
    setRecruiterPagination({ ...pagination, search: '' });
    if (value === '1') {
      getCustomerList(
        pagination?.recordsPerPage,
        pagination?.currentPage,
        '',
        pagination?.status,
        pagination?.industryType
      );
    } else {
      getRecruiterList(
        recruiterPagination?.recordsPerPage,
        recruiterPagination?.currentPage,
        '',
        selectedStatus
      );
    }
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleStatusFilterChange = (event) => {
    setSelectedStatus(event?.target?.value);
  };

  const clearFilter = () => {
    setSelectedStatus('');
    if (value === '1') {
      getCustomerList(
        pagination?.recordsPerPage,
        pagination?.currentPage,
        pagination?.search,
        '',
        pagination?.industryType
      );
    } else {
      getRecruiterList(
        recruiterPagination?.recordsPerPage,
        recruiterPagination?.currentPage,
        recruiterPagination?.search,
        ''
      );
    }
  };

  const applyFilterSelection = () => {
    if (selectedStatus.length) {
      if (value === '1') {
        getCustomerList(
          pagination?.recordsPerPage,
          selectedStatus ? 1 : pagination?.currentPage,
          pagination?.search,
          selectedStatus,
          pagination?.industryType
        );
      } else {
        getRecruiterList(
          recruiterPagination?.recordsPerPage,
          selectedStatus ? 1 : recruiterPagination?.currentPage,
          recruiterPagination?.search,
          selectedStatus
        );
      }
    }
  };

  useEffect(() => {
    searchRef.current.value = '';
  }, [value]);
  // ***************** COMPANY CODE ************************************
  const searchInCompanyListing = useCallback(
    _.debounce((searchText) => {
      setSearchText(searchText);
      getCustomerList(
        pagination?.recordsPerPage,
        searchText ? 1 : pagination?.currentPage,
        searchText,
        pagination?.status,
        pagination?.industryType
      );
    }, 500),
    []
  );

  const searchInRecruiterListing = useCallback(
    _.debounce((searchText) => {
      setSearchText(searchText);
      getRecruiterList(
        recruiterPagination?.recordsPerPage,
        searchText ? 1 : recruiterPagination?.currentPage,
        searchText,
        recruiterPagination?.status
      );
    }),
    []
  );

  const getCustomerList = async (
    recordsPerPage = pagination?.recordsPerPage,
    currentPage = pagination?.currentPage,
    search = pagination?.search,
    status = pagination?.status,
    industryType = pagination?.industryType
  ) => {
    setLoading(true);
    const response = await getBillingCustomersList(
      currentPage,
      recordsPerPage,
      search,
      status,
      industryType
    );
    const { meta, data } = response;
    if (meta?.status) {
      setPagination((prev) => ({
        ...prev,
        totalRecord: data?.count ? data?.count : 0
      }));
      setCustomerList(data?.rows);
      setCustomerLengthCount(data?.count);
      setUnbilledAmount(data?.unBilledAmount);
      if (activeCompanyData && Object.keys(activeCompanyData)?.length === 0) {
        onCompanySelection(data?.rows[0], true);
      }
    }
    setLoading(false);
  };

  const onCompanySelection = (companyData, responsiveFromListing) => {
    setPagination({ ...pagination, currentPage: 1 });
    responsiveFromListing
      ? setAddResponsiveClass(false)
      : setAddResponsiveClass(true);
    setActiveCompanyData(companyData);
    getJoblist(
      customerJobsPagination?.recordsPerPage,
      1,
      customerJobsPagination?.search,
      customerJobsPagination?.status,
      companyData?.id
    );
  };

  const changeCustomerPage = (event, value) => {
    setPagination({ ...pagination, currentPage: value });
  };

  // ****************** CUSTOMER JOB LISTING CODE *********************************************
  const getJoblist = async (
    recordsPerPage = customerJobsPagination?.recordsPerPage,
    currentPage = customerJobsPagination?.currentPage,
    search = customerJobsPagination?.search,
    status,
    companyId = activeCompanyData?.id
  ) => {
    setLoading(true);
    const response = await getBillingJobsList(
      currentPage,
      recordsPerPage,
      search,
      status,
      companyId
    );
    const { meta, data } = response;
    if (meta?.status) {
      setCustomerJobsPagination((prev) => ({
        ...prev,
        totalRecord: data?.count ? data?.count : 0
      }));
      setJobList(data?.rows);
    }
    setLoading(false);
  };

  const searchJobPost = useCallback(
    _.debounce(
      (searchText) =>
        getJoblist(
          customerJobsPagination?.recordsPerPage,
          searchText ? 1 : customerJobsPagination?.currentPage,
          searchText,
          customerJobsPagination?.status,
          activeCompanyData?.id
        ),
      500
    ),
    []
  );

  const handleCustomerJobStatusFilterChange = (selectedOption) => {
    getJoblist(
      customerJobsPagination?.recordsPerPage,
      customerJobsPagination?.currentPage,
      customerJobsPagination?.search,
      selectedOption,
      activeCompanyData?.id
    );
  };

  const handleCustomerJobFilterClose = () => {
    getJoblist(
      customerJobsPagination?.recordsPerPage,
      customerJobsPagination?.currentPage,
      customerJobsPagination?.search,
      [],
      activeCompanyData?.id
    );
  };

  const changeCustomerJobPage = (event, value) => {
    setCustomerJobsPagination({ ...pagination, currentPage: value });
    getJoblist(
      customerJobsPagination?.recordsPerPage,
      value,
      customerJobsPagination?.search,
      customerJobsPagination?.status,
      activeCompanyData?.id
    );
  };

  // ************************* RECRUITER CODE *************************

  const getRecruiterList = async (
    recordsPerPage = recruiterPagination?.recordsPerPage,
    currentPage = recruiterPagination?.currentPage,
    search = recruiterPagination?.search,
    status = recruiterPagination?.status
  ) => {
    setLoading(true);
    const response = await getBillingRecruitersList(
      recordsPerPage,
      currentPage,
      search,
      status
    );
    const { meta, data } = response;
    if (meta?.status) {
      setRecruiterPagination((prev) => ({
        ...prev,
        totalRecord: data?.count ? data?.count : 0
      }));
      setRecruiterCount(data?.count);
      setRecruitersList(data?.rows);
      onRecruiterSelect(data?.rows[0]);
      // if (fromOutstandingScreen) {
      //   let filteredData = data?.rows?.filter(
      //     (item) => item?.recruiterId === state?.recruiterDetails?.recruiterId
      //   );
      //   onRecruiterSelect(filteredData[0]);
      // } else {

      // }
    }
    setLoading(false);
  };

  useEffect(() => {
    setFromOutstandingScreen(false);
  }, [recruiterPagination?.currentPage]);

  useEffect(() => {
    if (fromOutstandingScreen) {
      setOpenSeachField(true);
      setRecruiterPagination({
        ...pagination,
        search: state?.recruiterDetails?.recruiterName
      });
      getRecruiterList(
        recruiterPagination?.recordsPerPage,
        recruiterPagination?.currentPage,
        state?.recruiterDetails?.recruiterName,
        recruiterPagination?.status
      );
    }
  }, []);

  const onRecruiterSelect = (recruiter) => {
    setActiveRecruiterId(recruiter?.recruiterId);
    setActiveRecruiter(recruiter);
  };

  const recruiterListPageChange = (event, value) => {
    setRecruiterPagination({ ...recruiterPagination, currentPage: value });
  };

  // ************* RECRUITER JOBS LISTING CODE ****************************
  useEffect(() => {
    getRecruiterJobsList();
  }, [activeRecruiterId]);

  const getRecruiterJobsList = async (
    recordsPerPage = recruiterJobsPagination?.recordsPerPage,
    currentPage = recruiterJobsPagination?.currentPage,
    search = recruiterJobsPagination?.search,
    status = recruiterJobsPagination?.status,
    recruiterId = activeRecruiterId
  ) => {
    setLoading(true);
    const response = await getBillingRecruiterJobsList(
      recordsPerPage,
      currentPage,
      search,
      status,
      recruiterId
    );
    const { meta, data } = response;
    if (meta?.status) {
      setRecruiterJobsPagination((prev) => ({
        ...prev,
        totalRecord: data?.count ? data?.count : 0
      }));
      setRecruiterJobsCount(data?.count);
      setRecruitersJobsList(data?.rows);
      setTotalUnbilledAmount(data?.unpaidAmount);
    }
    setLoading(false);
  };

  const handleJobsStatusFilterChange = (selectedOption) => {
    setSelectedJobsFilterStatus(selectedOption);
    getRecruiterJobsList(
      recruiterJobsPagination?.recordsPerPage,
      recruiterJobsPagination?.currentPage,
      recruiterJobsPagination?.search,
      selectedOption,
      activeRecruiterId
    );
  };

  const handleJobsFilterClose = () => {
    setSelectedJobsFilterStatus([]);
    getRecruiterJobsList(
      recruiterJobsPagination?.recordsPerPage,
      recruiterJobsPagination?.currentPage,
      recruiterJobsPagination?.search,
      [],
      activeRecruiterId
    );
  };

  useEffect(() => {
    onRecruiterSelect(recruitersList[0]);
  }, [
    recruiterJobsPagination?.search,
    recruiterJobsPagination?.currentPage,
    recruiterJobsPagination?.status
  ]);

  const searchRecruitersJobs = useCallback(
    _.debounce(
      (searchText, recruiterId) =>
        getRecruiterJobsList(
          recruiterJobsPagination?.recordsPerPage,
          searchText ? 1 : recruiterJobsPagination?.currentPage,
          searchText,
          recruiterJobsPagination?.status,
          recruiterId
        ),
      500
    ),
    []
  );

  const getJobPaidUnpaidStatus = (screenings) => {
    if (!screenings.length) {
      return 'Unpaid';
    }
    const status = screenings.filter((t) => !t.recruiterAmountPaid).length
      ? 'Unpaid'
      : screenings
            .filter((item) => item.recruiterAmountAdded)
            .filter((item) => !item.recruiterAmountPaid).length
        ? 'Unpaid'
        : 'Paid';
    return status;
  };

  const RecruiterJobsPageChange = (event, value) => {
    setRecruiterJobsPagination({
      ...recruiterJobsPagination,
      currentPage: value
    });
  };
  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid">
          <div className="layoutGrid__col">
            <div
              className={`listCol ${FilterApplied ? 'filterApplied' : ''} ${
                filteranchorEl
                  ? 'listCol__filter'
                  : searchanchorEl || OpenSeachField
                    ? 'listCol__search'
                    : ''
              }`}
            >
              <div className="listCol__head listing__head">
                <h2 className="listCol__head--title">
                  {value === '1' ? 'Customers' : 'Recruiters'}
                </h2>
                <div className="listCol__head--actions">
                  <div className="formField">
                    <button
                      className="btn btn__white p_0"
                      aria-describedby={searchPopoverid}
                      onClick={handleSeachClick}
                    >
                      <Search />
                    </button>
                  </div>
                  <div>
                    <button
                      className="btn btn__white p_0"
                      aria-describedby={filterPopoverid}
                      onClick={handleFilterClick}
                    >
                      <Filter />
                    </button>
                  </div>
                </div>

                <div className="searchContainer">
                  <div className="formField">
                    <div className="formField__group sm">
                      <span className="formField__icon">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                            stroke="#9DA4AE"
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <input
                        type="text"
                        className="formField__input"
                        placeholder={`Search for a ${
                          value == 1 ? 'customer' : 'recruiter'
                        }`}
                        ref={searchRef}
                        value={searchText}
                        onChange={(e) => {
                          value === '1'
                            ? searchInCompanyListing(e?.target?.value)
                            : searchInRecruiterListing(e?.target?.value);
                        }}
                      />
                    </div>
                  </div>

                  <button
                    className="btn btn__transparent border_0 p_0"
                    onClick={handleSearchClose}
                  >
                    <SearchCross />
                  </button>
                </div>
              </div>
              <div className="filter__head">
                <h2 className="listCol__head--title">Filter</h2>
                <button
                  className="btn btn__transparent border_0 p_0"
                  onClick={handleFilterClose}
                >
                  <SearchCross />
                </button>
              </div>

              <div className="filterContainer">
                <ul className="listCol__list filter__list">
                  <li>
                    <div className="formRow">
                      <div className="formCol">
                        <div className="formField">
                          <label className="formField__label">Status</label>
                          <FormControl
                            fullWidth
                            size="small"
                            className="formField__select"
                          >
                            <Select
                              onChange={(e) => handleStatusFilterChange(e)}
                              IconComponent={() => <ArrowDown />}
                              name="Status"
                              displayEmpty
                              value={selectedStatus}
                              renderValue={(selected) => {
                                if (!selected) {
                                  return (
                                    <span style={{ opacity: '0.8' }}>
                                      Select status
                                    </span>
                                  );
                                }

                                return selected;
                              }}
                            >
                              {value === '1' &&
                                BillingStatus?.map((item, index) => {
                                  return (
                                    <MenuItem key={index} value={item}>
                                      {item}
                                    </MenuItem>
                                  );
                                })}
                              {value === '2' &&
                                RecruiterFilterStatus?.map((item, index) => {
                                  return (
                                    <MenuItem key={index} value={item}>
                                      {item}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>

                <div className="filter__footer">
                  <div className="btn__group flex--between">
                    <button
                      className="btn btn__transparent p_0"
                      onClick={(e) => {
                        clearFilter();
                        handleFilterClose();
                        setFilterApplied(false);
                      }}
                    >
                      Clear
                    </button>
                    <button
                      className="btn btn__primary"
                      onClick={(e) => {
                        handleFilterClose();
                        applyFilterSelection();
                        setFilterApplied(true);
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>

              {selectedStatus.length ? (
                <div className="listColTitle filterClear">
                  <p>Filters applied ({selectedStatus.length ? '1' : '0'})</p>
                  <button
                    className="filterClear__btn"
                    onClick={() => {
                      clearFilter();
                      setFilterApplied(false);
                    }}
                  >
                    Clear filter
                  </button>
                </div>
              ) : (
                ''
              )}

              <Box className="tabContainer">
                <TabContext value={value}>
                  <Box className="tabContainer__head">
                    <TabList onChange={handleTabValueChange}>
                      <Tab label="Customers" value="1" />
                      <Tab label="Recruiters" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1" className="tabContainer__item">
                    <CustomerBilling
                      activeCompanyData={activeCompanyData}
                      customerLengthCount={customerLengthCount}
                      UnbilledAmount={UnbilledAmount}
                      customerList={customerList}
                      onCompanySelection={onCompanySelection}
                      changePage={(e, value) => changeCustomerPage(e, value)}
                      pagination={pagination}
                    />
                  </TabPanel>

                  <TabPanel value="2" className="tabContainer__item">
                    <RecruiterBilling
                      recruitersList={recruitersList}
                      onRecruiterSelect={(recruiter) =>
                        onRecruiterSelect(recruiter)
                      }
                      setAddRecruiterResponsiveClass={
                        setAddRecruiterResponsiveClass
                      }
                      activeRecruiter={activeRecruiter}
                      recruiterPagination={recruiterPagination}
                      recruiterListPageChange={(event, value) =>
                        recruiterListPageChange(event, value)
                      }
                      getJobPaidUnpaidStatus={(screenings) =>
                        getJobPaidUnpaidStatus(screenings)
                      }
                    />
                  </TabPanel>
                </TabContext>
              </Box>
            </div>
          </div>
          {value === '1' ? (
            <CustomerJobsListing
              addResponsiveClass={addResponsiveClass}
              setAddResponsiveClass={setAddResponsiveClass}
              activeCompanyData={activeCompanyData}
              jobList={jobList}
              searchJobPost={(searchText) => searchJobPost(searchText)}
              handleStatusFilterChange={(selectedOption) =>
                handleCustomerJobStatusFilterChange(selectedOption)
              }
              handleCustomerJobFilterClose={handleCustomerJobFilterClose}
              BillingFilterStatus={BillingFilterStatus}
              changePage={(e, value) => changeCustomerJobPage(e, value)}
              customerLengthCount={customerLengthCount}
              pagination={customerJobsPagination}
            />
          ) : (
            <RecruiterJobsListing
              addRecruiterResponsiveClass={addRecruiterResponsiveClass}
              setAddRecruiterResponsiveClass={setAddRecruiterResponsiveClass}
              recruiterJobsList={recruiterJobsList}
              customerLengthCount={customerLengthCount}
              recruiterJobsCount={recruiterJobsCount}
              activeRecruiter={activeRecruiter}
              recruiterJobsPagination={recruiterJobsPagination}
              searchRecruitersJobs={(searchText, recruiterId) =>
                searchRecruitersJobs(searchText, recruiterId)
              }
              getJobPaidUnpaidStatus={(screenings) =>
                getJobPaidUnpaidStatus(screenings)
              }
              RecruiterJobsPageChange={(event, value) =>
                RecruiterJobsPageChange(event, value)
              }
              recruitersList={recruitersList}
              handleJobsStatusFilterChange={(selectedOption) =>
                handleJobsStatusFilterChange(selectedOption)
              }
              handleJobsFilterClose={handleJobsFilterClose}
              RecruiterFilterStatus={RecruiterJobsFilterStatus}
              TotalUnbilledAmount={TotalUnbilledAmount}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default BillingpaymentsIndex;
