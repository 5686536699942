import * as Yup from 'yup';
import * as CONSTANTS from '@/utils/constants';
import {
  phoneNumberRegex,
  LinkedInRegex,
  isValidName,
  isValidNumberOnly
} from '@/regex';

export const recruiterFormSchema = Yup.object().shape({
  email: Yup.string().email().required(CONSTANTS.EMAIL.validation.valid),
  firstName: Yup.string()
    .trim()
    .matches(isValidName, 'Please enter valid first name')
    .min(1, CONSTANTS.FIRST_NAME.validation.valid)
    .required(CONSTANTS.FIRST_NAME.validation.required),
  lastName: Yup.string()
    .trim()
    .matches(isValidName, 'Please enter valid last name')
    .min(1, CONSTANTS.LAST_NAME.validation.valid)
    .required(CONSTANTS.LAST_NAME.validation.required),
  selectedSkillSet: Yup.array()
    .min(1, CONSTANTS.SELECTED_SKILL_SET.validation.valid)
    .of(Yup.string().required()),
  experience: Yup.number()
    .required(CONSTANTS.EXPERIENCE_REQUIRED_ERROR.validation.required)
    .min(1, CONSTANTS.EXPERIENCE_MIN_ERROR.validation.valid)
    .max(99, CONSTANTS.EXPERIENCE_MAX_ERROR.validation.valid),
  phoneNumber: Yup.string()
    .matches(phoneNumberRegex, CONSTANTS.PHONE_NUMBER.validation.valid)
    .required('Please enter phone number'),
  linkedin: Yup.string().matches(
    LinkedInRegex,
    CONSTANTS.LINKEDIN.validation.valid
  ),
  qualificationArray: Yup.array().of(
    Yup.object({
      degree: Yup.string().required('Please enter education.'),
      institutionName: Yup.string().required(
        'Please enter name of institution.'
      ),
      startMonth: Yup.string().required('Please enter start month.'),
      startYear: Yup.string().required('Please enter start year.'),
      endMonth: Yup.string().required('Please enter end month.'),
      endYear: Yup.string()
        .required('Please enter end year.')
        .test(
          'valid-end-year',
          'End year should not be less than start year.',
          function (value, { parent }) {
            const startYear = parseInt(parent.startYear, 10);
            const endYear = parseInt(value, 10);
            return !isNaN(startYear) && !isNaN(endYear) && endYear >= startYear;
          }
        )
        .test(
          'valid-end-month',
          'The start date cannot be later than the end date.',
          function (value, { parent }) {
            const startYear = parseInt(parent.startYear, 10);
            const endYear = parseInt(value, 10);
            if (startYear === endYear) {
              const startMonth = parent.startMonth;
              const endMonth = parent.endMonth;
              const month = [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec'
              ];
              return month.indexOf(endMonth) >= month.indexOf(startMonth);
            } else {
              return true;
            }
          }
        ),
      fieldOfStudy: Yup.string().required('Please enter major field of study.')
    })
  )
});

export const meetingSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .matches(isValidName, 'Please enter valid name')
    .min(1, CONSTANTS.NAME.validation.valid)
    .required(CONSTANTS.NAME.validation.valid),
  email: Yup.string().email().required(CONSTANTS.EMAIL.validation.valid)
});

export const addEducationalFormSchema = Yup.object().shape({
  degree: Yup.string().required('Education is required.'),
  institutionName: Yup.string().required('Institution name is required.'),
  startMonth: Yup.string().required('Start month is required.'),
  startYear: Yup.string().required('Start year is required.'),
  endMonth: Yup.string().required('End month is required.'),
  endYear: Yup.string()
    .required('End year is required.')
    .test(
      'valid-end-year',
      'End year should not be less than start year.',
      function (value, { parent }) {
        const startYear = parseInt(parent.startYear, 10);
        const endYear = parseInt(value, 10);
        return !isNaN(startYear) && !isNaN(endYear) && endYear >= startYear;
      }
    )
    .test(
      'valid-end-month',
      'The start date cannot be later than the end date.',
      function (value, { parent }) {
        const startYear = parseInt(parent.startYear, 10);
        const endYear = parseInt(value, 10);
        if (startYear === endYear) {
          const startMonth = parent.startMonth;
          const endMonth = parent.endMonth;
          const month = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
          ];
          return month.indexOf(endMonth) >= month.indexOf(startMonth);
        } else {
          return true;
        }
      }
    ),
  fieldOfStudy: Yup.string().required('Major field of study is required.')
});

export const addTagSchema = Yup.object().shape({
  tagName: Yup.string().trim().required('Tag name is required'),
  colorCode: Yup.string().trim().required('Color code is required')
});
