import { Navigate } from 'react-router-dom';
import { GetSessionId } from '@/helper';

export default function DashboardRoute({ children }) {
  const token = GetSessionId();
  if (token) {
    return <Navigate to="/dashboard" replace />;
  }
  return children;
}
