import React, { useRef } from 'react';
import { firstLetterCapital, formatPhoneNumber } from '@/helper';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';
import DownloadFileIcon from '@/assets/svgs/DownloadFileIcon';
import CopyToClipboardIcon from '@/assets/svgs/CopyToClipboard';
import BackArrow from '@/assets/svgs/BackArrow';
import EditFormIcon from '@/assets/svgs/EditFormIcon';

export const CandidateDetailPage = (props) => {
  const copyLinkedinText = useRef(null);
  const handleEditCompany = () => {
    props?.handleEditCustomerMode();
  };

  function copyToClipboard() {
    copyLinkedinText.current.select();
    document.execCommand('copy');
  }
  return (
    <>
      {props?.CandidateList.length ? (
        <div
          className={`layoutGrid__col layoutGrid--responsive  ${
            props?.addResponsiveClass ? 'layoutGrid--show' : ''
          }`}
        >
          <div className="dataCol">
            <div className="page page__full">
              <div className="page__head">
                <h2 className="page__head--title">
                  <button
                    className="btn btn__transparent btn__back border_0 p_0"
                    onClick={() => props?.handleAddResponsiveClass()}
                  >
                    <BackArrow />
                  </button>
                  {firstLetterCapital(props?.SelectedCandidateData?.firstName) +
                    ' ' +
                    firstLetterCapital(props?.SelectedCandidateData?.lastName)}
                </h2>
                <div className="listCol__head--actions">
                  <button
                    className="btn btn__white btn__edit p_0"
                    onClick={handleEditCompany}
                  >
                    <EditFormIcon />
                  </button>
                </div>
              </div>

              <div className="page__body">
                <div className="details">
                  <section className="details__section">
                    <div className="initials initials__lg">
                      {' '}
                      {props?.SelectedCandidateData?.firstName
                        ?.charAt(0)
                        .toUpperCase()}
                    </div>
                    <div className="details__section--name">
                      {firstLetterCapital(
                        props?.SelectedCandidateData?.firstName
                      ) +
                        ' ' +
                        firstLetterCapital(
                          props?.SelectedCandidateData?.lastName
                        )}
                    </div>
                    <div className="details__row">
                      <div className="details__row--item">
                        <span>Phone:</span>
                        {props?.SelectedCandidateData?.countryCode}{' '}
                        {formatPhoneNumber(
                          props?.SelectedCandidateData?.phoneNumber
                        )}
                      </div>
                      <div className="details__row--item">
                        <span>Email:</span>
                        {props?.SelectedCandidateData?.email}
                      </div>
                    </div>
                    <ul className="tags__list">
                      {props?.SelectedCandidateData?.education && (
                        <li
                          key="candidateDetailEducation"
                          className="tags__list--item"
                        >
                          {props?.SelectedCandidateData?.education
                            ? props?.SelectedCandidateData?.education
                            : ''}
                        </li>
                      )}
                      {props?.SelectedCandidateData?.role && (
                        <li
                          key="candidateDetailRole"
                          className="tags__list--item"
                        >
                          {props?.SelectedCandidateData?.role
                            ? props?.SelectedCandidateData?.role
                            : ''}
                        </li>
                      )}
                      {props?.SelectedCandidateData?.experience > 0 ? (
                        <li
                          key="candidateDetailExperience1"
                          className="tags__list--item"
                        >
                          {props?.SelectedCandidateData?.experience
                            ? props?.SelectedCandidateData?.experience
                            : ''}{' '}
                          Yrs Exp
                        </li>
                      ) : (
                        <li
                          key="candidateDetailExperience2"
                          className="tags__list--item"
                        >
                          {props?.SelectedCandidateData?.experience <= 0
                            ? 'Fresher'
                            : ''}
                        </li>
                      )}
                    </ul>
                  </section>
                  <section className="form__section">
                    <h4 className="form__section--title">
                      Educational Details
                    </h4>
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div className="formField">
                          <label className="formField__label">
                            Attach Resume
                          </label>
                          <label
                            className={`formField__group sm ${
                              props?.SelectedCandidateData?.resumeUrl
                                ? ''
                                : 'formField__disabled'
                            }`}
                            id="attachResumeLabel"
                          >
                            <span className="labelText">
                              {props?.SelectedCandidateData?.resumeUrl
                                ? props?.SelectedCandidateData?.resumeUrl
                                : 'Browse file'}
                            </span>
                            <input
                              type="text"
                              className={'formField__input formField__disabled'}
                              id="attachResume"
                              name="attachedResume"
                            />
                            <Link
                              className={`formField__icon ${
                                props?.SelectedCandidateData?.resumeUrl
                                  ? ''
                                  : 'iconDisabled'
                              }`}
                              to={props?.SelectedCandidateData?.resumeUrl}
                              target="_blank"
                            >
                              <DownloadFileIcon />
                            </Link>
                          </label>
                        </div>
                      </div>
                      <div className="formCol">
                        <div className="formField">
                          <label className="formField__label">
                            Highest Education
                          </label>
                          <FormControl
                            sx={{ width: '100%' }}
                            size="small"
                            className={
                              'formField__select formField__disabled disabledHighlighted'
                            }
                          >
                            <Select
                              IconComponent={''}
                              name="graduationYear"
                              displayEmpty
                            >
                              <MenuItem>
                                {props?.SelectedCandidateData?.education ?? ''}
                              </MenuItem>
                              ;
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div className="formField">
                          <label className="formField__label">
                            Name of Institution
                          </label>
                          <div className="formField__group sm">
                            <input
                              type="text"
                              className={
                                'formField__input formField__disabled disabledHighlighted'
                              }
                              name="institutionName"
                              defaultValue={
                                props?.SelectedCandidateData?.institutionName ??
                                ''
                              }
                              placeholder="Name of Institution"
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="formCol">
                        <div className="formField">
                          <label className="formField__label">
                            Graduation Year
                          </label>
                          <FormControl
                            sx={{ width: '100%' }}
                            size="small"
                            className={
                              'formField__select formField__disabled disabledHighlighted'
                            }
                          >
                            <Select
                              IconComponent={''}
                              name="graduationYear"
                              displayEmpty
                              placeholder="Graduation Year"
                              renderValue={(selected) => {
                                if (!selected) {
                                  return (
                                    <span style={{ opacity: '0.8' }}>
                                      {props?.SelectedCandidateData
                                        ?.graduationYear
                                        ? props?.SelectedCandidateData
                                            ?.graduationYear
                                        : 'Graduation Year'}
                                    </span>
                                  );
                                }

                                return selected;
                              }}
                            >
                              <MenuItem>
                                {props?.SelectedCandidateData?.graduationYear ??
                                  ''}
                              </MenuItem>
                              ;
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="details__section">
                    <h4 className="details__section--title">
                      Professional Details
                    </h4>
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div className="formField">
                          <label className="formField__label">
                            Experience (years)
                          </label>
                          <FormControl
                            sx={{ width: '100%' }}
                            size="small"
                            className="formField__select formField__disabled disabledHighlighted"
                          >
                            <Select
                              IconComponent={''}
                              name="graduationYear"
                              displayEmpty
                            >
                              <MenuItem>
                                {props?.SelectedCandidateData?.experience ?? ''}
                              </MenuItem>
                              ;
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      <div className="formCol">
                        <div className="formField">
                          <label className="formField__label">
                            LinkedIn Account
                          </label>
                          <div className="formField__group sm">
                            <input
                              type="text"
                              ref={copyLinkedinText}
                              className={
                                'formField__input formField__disabled disabledHighlighted'
                              }
                              defaultValue={
                                props?.SelectedCandidateData?.linkedin ?? ''
                              }
                              name="LinkedIn Account"
                              placeholder="LinkedIn Account"
                            />
                            <span
                              className={`formField__icon ${
                                props?.SelectedCandidateData?.linkedin
                                  ? ''
                                  : 'iconDisabled'
                              }`}
                              onClick={copyToClipboard}
                            >
                              <CopyToClipboardIcon />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="formRow">
                      <div className="formCol">
                        <label className="formField__label">Skill set</label>
                        <ul className="tags__list mt_0">
                          {props?.SelectedCandidateData?.skillset?.map(
                            (item) => {
                              return (
                                <li
                                  key={`candidateDetailSkillsets${item}`}
                                  className="tags__list--item"
                                >
                                  {item}
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="no-data">No Results Found!</div>
      )}
    </>
  );
};
